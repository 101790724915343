import React, { Fragment, useState, useEffect, useRef } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
    Button,
    CardFooter,
    Form,
    Row, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader,
    FormGroup,
    InputGroup,
} from "reactstrap";
import PageHeading from "Layout/AppMain/PageHeading";
import { ReactComponent as AddIcon } from "../../../../assets/utils/images/svgs/addBlue.svg";
import { ReactComponent as CalendarIcon } from "../../../../assets/utils/images/svgs/calendar-blue.svg"
import { ErrorMessage, Formik } from "formik";
import DatePicker from "react-datepicker";
import axiosInstance from "Api";
import moment from "moment";
import { toast } from "react-toastify";
import HistoryTable from "../Exporteren/HistoryTable/index";
import ExportTable from "../Exporteren/ExportTable/index";

const formDataState = {
    selecteer_export: 'Dagdelen', selector_scholen: '', selecteer_vervanger: '', selecteer_afwezige: '', begindatum: null, einddatum: null
};

const formFieldDataState = {
    selecteer_export: ["Dagdelen", "Alle medewerkers", "Vervanger", "Scholen", "Vervangingscode"],
}

function Exporteren() {
    const [openModal, setOpenModal] = useState(false);
    const [organizationsList, setOrganizationsList] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState('');
    const [exportData, setExportData] = useState([]);
    const [historieData, setHistorieData] = useState([]);
    const [scholenValue, setScholenValue] = useState([]);
    const [vervangerValue, setVervangerValue] = useState([]);
    const [afwezigeValue, setAfwezigeValue] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const intervalRef = useRef(null);

    useEffect(() => {
        setLoading(true);
        fetchOrganizations();
    }, []);

    useEffect(() => {
        if (selectedOrganization !== '') {
            setLoading(true);
            fetchExportData()
        }
    }, [selectedOrganization]);

    const fetchOrganizations = async () => {
        try {
            const response = await axiosInstance.get('organizations-list');
            if (response.status === 200 && response.data.data) {
                setOrganizationsList(response.data.data);
                setSelectedOrganization(response.data.data[0].iCommunityId);
            } else {
                setOrganizationsList([]);
                setSelectedOrganization('');
            }
        } catch (error) {
            console.log("Error fetching organizations:", error);
        }
    };

    const fetchExportData = () => {
        axiosInstance.post('/beheer/export/list', {
            iCommunityId: selectedOrganization
        })
            .then((response) => {
                if (response.data.status === 200) {
                    const data = response.data.data.exportLists;
                    if (data.length > 0) {
                        setExportData(data);
                    } else {
                        setExportData([]);
                    }
                }
            })
            .catch((error) => {
                console.log("Error fetching export data:", error);
            }).finally(() => {
                setLoading(false);
            })
    };

    const fetchHistoryData = () => {
            axiosInstance.get('/beheer/export/history', {
                params: { iCommunityId: selectedOrganization }
            })
                .then((response) => {
                    if (response?.data.status === 200) {
                        setHistorieData(response.data.data);
                    } else {
                        setHistorieData([]);
                    }
                })
                .catch((error) => {
                    console.log("Error fetching history data:", error);
                })
    }

    const handleExportClick = () => {
        fetchScholen();
        fetchAfwezigeVervanger();
        setOpenModal(prev => !prev);
    }

    const formSubmitHandler = (values, { setFieldError }) => {

        let hasError = false;

        if (values.selecteer_export === 'Dagdelen' && !values.selecteer_export) {
            setFieldError("selecteer_export", "Export selection is required");
            hasError = true;
        }
        if ((values.selecteer_export === 'Scholen' || values.selecteer_export === 'Dagdelen') && !values.selector_scholen) {
            setFieldError("selector_scholen", "School selection is required");
            hasError = true;
        }
        if ((values.selecteer_export === 'Vervanger' || values.selecteer_export === 'Dagdelen') && !values.selecteer_vervanger) {
            setFieldError("selecteer_vervanger", "Vervanger selection is required");
            hasError = true;
        }
        if ((values.selecteer_export === 'Alle medewerkers' || values.selecteer_export === 'Dagdelen') && !values.selecteer_afwezige) {
            setFieldError("selecteer_afwezige", "Afwezige selection is required");
            hasError = true;
        }
        if (values.selecteer_export === 'Dagdelen' && !values.begindatum) {
            setFieldError("begindatum", "Begindatum is required");
            hasError = true;
        }
        if (values.selecteer_export === 'Dagdelen' && !values.einddatum) {
            setFieldError("einddatum", "Einddatum is required");
            hasError = true;
        }

        if (hasError) {
            return;
        }

        const data = {
            ...({ iCommunityId: selectedOrganization }),
            ...({ exportType: values.selecteer_export === 'Alle medewerkers' ? "Medewerker" : values.selecteer_export }),
            ...((values.selecteer_export === 'Scholen' || values.selecteer_export === 'Dagdelen') && { iSchoolId: Number(values.selector_scholen) }),
            ...((values.selecteer_export === 'Vervanger' || values.selecteer_export === 'Dagdelen') && { substitutorId: Number(values.selecteer_vervanger) }),
            ...((values.selecteer_export === 'Alle medewerkers' || values.selecteer_export === 'Dagdelen') && { absentId: Number(values.selecteer_afwezige) }),
            ...(values.selecteer_export === 'Dagdelen' && { dateBeginPeriod: values.begindatum ? moment(values.begindatum).format("YYYY-MM-DD") : null }),
            ...(values.selecteer_export === 'Dagdelen' && { dateEndPeriod: values.einddatum ? moment(values.einddatum).format("YYYY-MM-DD") : null }),
        };

        axiosInstance
            .post("/beheer/export/save", data)
            .then((response) => {
                if (response.data.status === 200) {
                    fetchExportData();
                } else {
                    toast.error("Kan gegevens niet opslaan!");
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error("Kan gegevens niet opslaan!");
            })
            .finally(() => {
                setOpenModal(false);
            });
    };

    const fetchScholen = () => {
        axiosInstance.get('/schools', {
            params: { iCommunityId: selectedOrganization }
        })
            .then((response) => {
                if (response?.data?.status === 200) {
                    setScholenValue(response.data.data);
                } else { setScholenValue([]) }
            })
            .catch((error) => {
                console.error("Error fetching scholen data: ", error);
            });
    }

    const fetchAfwezigeVervanger = () => {
        axiosInstance.post('/beheer/export/employee-list', {
            iCommunityId: selectedOrganization
        })
            .then((response) => {
                if (response?.data?.status === 200) {
                    const data = response.data.data
                    setVervangerValue(data.substitutors);
                    setAfwezigeValue(data.absentEmployees);
                }
            })
            .catch((error) => {
                console.error("Error fetching scholen data: ", error);
            });
    }

    const toggle = () => {
        setOpenModal(!openModal);
    }
    return (
        <Fragment>
            <PageHeading />
            <TransitionGroup>
                <CSSTransition
                    component="div"
                    classNames="TabsAnimation"
                    appear={true}
                    timeout={1500}
                    enter={false}
                    exit={false}
                >
                    {
                        <div className="overig-export">
                            <Row className="mb-5">
                                <ExportTable exportData={exportData} setExportData={setExportData} fetchHistoryData={fetchHistoryData} fetchExportData={fetchExportData} isLoading={isLoading} setLoading={setLoading} selectedOrganization={selectedOrganization} intervalRef={intervalRef} />
                                {!isLoading && <HistoryTable selectedOrganization={selectedOrganization} fetchHistoryData={fetchHistoryData} historieData={historieData} />}
                            </Row>

                            <Modal isOpen={openModal} toggle={toggle} className="feedback-modal-style overig-exporteren-modal-style link-form">
                                <ModalHeader toggle={toggle}>
                                    Export samenstellen
                                </ModalHeader>
                                <ModalBody>
                                    <Formik
                                        enableReinitialize
                                        initialValues={formDataState}
                                        onSubmit={formSubmitHandler}
                                    >
                                        {({ values, handleChange, handleBlur, setFieldValue, resetForm, handleSubmit }) => (
                                            <Form onSubmit={handleSubmit}>

                                                <FormGroup className="row">
                                                    <Label for="selecteer_export" className="col">Selecteer export</Label>
                                                    <select
                                                        className="form-select col"
                                                        name="selecteer_export"
                                                        value={values.selecteer_export}
                                                        onChange={(e) => {
                                                            setFieldValue("selecteer_export", e.target.value)
                                                        }}
                                                        onBlur={handleBlur}
                                                    >
                                                        {formFieldDataState?.selecteer_export?.map((option, index) =>
                                                            <option key={option} value={option}>{option}</option>
                                                        )}
                                                    </select>
                                                    <ErrorMessage name="selecteer_export" component="small" className="block mt-2 invalid-feedback" />
                                                </FormGroup>


                                                {
                                                    (values.selecteer_export === "Dagdelen" || values.selecteer_export === "Scholen") && (
                                                        <FormGroup className="row">
                                                            <Label for="selector_scholen" className="col">Selecteer school</Label>
                                                            <select
                                                                className="form-select col"
                                                                name="selector_scholen"
                                                                value={values.selector_scholen}
                                                                onChange={(e) => setFieldValue("selector_scholen", e.target.value)}
                                                                onBlur={handleBlur}
                                                            >
                                                                <option disabled value="">Kies school</option>
                                                                {scholenValue && Array.isArray(scholenValue) && scholenValue?.map((option, index) =>
                                                                    <option key={option.iSchoolId} value={`${option.iSchoolId}`}>
                                                                        {option.vSchoolName}
                                                                    </option>
                                                                )}
                                                            </select>
                                                            <ErrorMessage name="selector_scholen" component="small" className="block mt-2 invalid-feedback" />
                                                        </FormGroup>
                                                    )
                                                }
                                                {
                                                    (values.selecteer_export === "Dagdelen" || values.selecteer_export === "Vervanger") && (
                                                        <FormGroup className="row">
                                                            <Label for="selecteer_vervanger" className="col">Selecteer vervanger</Label>
                                                            <select
                                                                className="form-select col"
                                                                name="selecteer_vervanger"
                                                                value={values.selecteer_vervanger}
                                                                onChange={(e) => setFieldValue("selecteer_vervanger", e.target.value)}
                                                                onBlur={handleBlur}
                                                            >
                                                                <option disabled value="">Kies Vervanger</option>
                                                                {vervangerValue && Array.isArray(vervangerValue) && vervangerValue?.map((option, index) =>
                                                                    <option key={option.value} value={`${option.value}`}>
                                                                        {option.label}
                                                                    </option>
                                                                )}
                                                            </select>
                                                            <ErrorMessage name="selecteer_vervanger" component="small" className="block mt-2 invalid-feedback" />
                                                        </FormGroup>
                                                    )
                                                }
                                                {
                                                    (values.selecteer_export === "Dagdelen" || values.selecteer_export === "Alle medewerkers") && (
                                                        <FormGroup className="row">
                                                            <Label for="selecteer_afwezige" className="col">Selecteer afwezige</Label>
                                                            <select
                                                                className="form-select col"
                                                                name="selecteer_afwezige"
                                                                value={values.selecteer_afwezige}
                                                                onChange={(e) => setFieldValue("selecteer_afwezige", e.target.value)}
                                                                onBlur={handleBlur}
                                                            >
                                                                <option disabled value="">Kies Afwezige</option>
                                                                {afwezigeValue && Array.isArray(afwezigeValue) && afwezigeValue?.map((option, index) =>
                                                                    <option key={option.value} value={`${option.value}`}>
                                                                        {option.label}
                                                                    </option>
                                                                )}
                                                            </select>
                                                            <ErrorMessage name="selecteer_afwezige" component="small" className="block mt-2 invalid-feedback" />
                                                        </FormGroup>
                                                    )
                                                }
                                                {
                                                    values.selecteer_export === "Dagdelen" && (
                                                        <>
                                                            <FormGroup className="row">
                                                                <Label for="beginDatum" className="col">Begindatum</Label>
                                                                <div className="col periode-input-style">
                                                                    <DatePicker
                                                                        selected={values.begindatum}
                                                                        onChange={(date) => setFieldValue("begindatum", date)}
                                                                        onBlur={handleBlur}
                                                                        placeholderText={'dd-mm-jjjj'}
                                                                        dateFormat="dd-MM-yyyy"
                                                                        className="form-control"
                                                                    />
                                                                    <CalendarIcon />
                                                                </div>
                                                                <ErrorMessage name="begindatum" component="small" className="block mt-2 invalid-feedback" />
                                                            </FormGroup>

                                                            <FormGroup className="row">
                                                                <Label for="einddatum" className="col">Einddatum</Label>
                                                                <div className="col periode-input-style">
                                                                    <DatePicker
                                                                        selected={values.einddatum}
                                                                        onChange={(date) => setFieldValue("einddatum", date)}
                                                                        onBlur={handleBlur}
                                                                        placeholderText={'dd-mm-jjjj'}
                                                                        dateFormat="dd-MM-yyyy"
                                                                        className="form-control"
                                                                    />
                                                                    <CalendarIcon />
                                                                </div>
                                                                <ErrorMessage name="einddatum" component="small" className="block mt-2 invalid-feedback" />
                                                            </FormGroup>
                                                        </>
                                                    )
                                                }

                                                <ModalFooter className="d-flex align-items-center justify-content-center border-bottom-0 py-4 mt-4">
                                                    <Button className="btn-color-style opslaan-btn" size="sm" outline color="primary" type="submit">
                                                        Samenstellen
                                                    </Button>
                                                </ModalFooter>
                                            </Form>
                                        )}
                                    </Formik>
                                </ModalBody>
                            </Modal>
                        </div>
                    }
                </CSSTransition>
            </TransitionGroup>
            <div className="chat-layout-footer verzoken-footer-height">
                <CardFooter className="chat-layout-footer__inner">
                    <div className="d-flex gap-3 mr-4">
                        <div className=" mb-0">
                            <InputGroup>
                                <Input
                                    id="sOrganization"
                                    name="sOrganization"
                                    type="select"
                                    value={selectedOrganization}
                                    onChange={e => setSelectedOrganization(parseInt(e.target.value))}
                                >
                                    {organizationsList.map((option, index) =>
                                        <option key={option.iCommunityId} value={option.iCommunityId}>{option.name}</option>
                                    )}
                                </Input>
                            </InputGroup>
                        </div>
                        <Button
                            outline
                            className="btn-outline-primary-blue btn-color-style py-2 px-3 d-flex gap-2 align-items-center"
                            onClick={handleExportClick}
                            type="button"
                            color="primary"
                        >
                            Export <AddIcon />
                        </Button>
                    </div>
                </CardFooter>
            </div>
        </Fragment>
    );
}
export default Exporteren;
