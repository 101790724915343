import React, { Fragment } from "react";
import AppHeader from "../../../Layout/AppHeader";
import Default from "./Default";
import AppFooter from "Layout/AppFooter";

const Besturen = () => {
    return (
        <Fragment>
            <AppHeader />
            <Default />
            <AppFooter />
        </Fragment>
    )
};

export default Besturen;