import React, { Fragment } from "react";
import cx from "classnames";

import { connect } from "react-redux";

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import HeaderLogo from "../AppLogo";
import UserBox from "./Components/UserBox";
import { ReactComponent as SupportIcon } from "../../assets/utils/images/svgs/supportIcon.svg";
import { Link } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ReactComponent as Save } from "../../assets/utils/images/svgs/save_two.svg";
import { ReactComponent as Small_Arrow } from "../../assets/utils/images/svgs/Small_Arrow.svg";
import { Form, Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import Tippy from "@tippyjs/react";
import axiosInstance from "Api";
import { toast } from "react-toastify";


class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isSubmitting: false,
    };
  }
  toggleModal = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };
  handleSubmit = (values, { setSubmitting }) => {
    axiosInstance.post('terugkoppeling/email', values)
      .then(response => {
        if (response.data.status) {
          toast.success("Feedback succesvol verzonden.");
        } else {
          toast.error("Kan feedback niet verzenden.");
        }
      })
      .catch(e => console.log(e))
      .finally(() => {
        setSubmitting(false);
        setTimeout(() => {
          this.toggleModal();
        }, 1000);
      });
  };
  render() {
    let {
      headerBackgroundColor,
      enableMobileMenuSmall,
      enableHeaderShadow,
      userRoles 
    } = this.props;
    const { isOpen, isSubmitting } = this.state;

    const validationSchema = Yup.object().shape({
      note: Yup.string()
        .required('Opmerking is verplicht')
        .max(50, 'Opmerking mag maximaal 50 tekens bevatten'),
    });
    return (
      <Fragment>
        <TransitionGroup>
          <CSSTransition component="div"
            className={cx("app-header", headerBackgroundColor, {
              "header-shadow": enableHeaderShadow,
            })}
            appear={true} timeout={1500} enter={false} exit={false}>
            <div>
              <HeaderLogo />
              <div className={cx("app-header__content", {
                  "header-mobile-open": enableMobileMenuSmall,
                })}>
                <div className="app-header-right">
                  {!userRoles?.roles?.includes("administrator") && <Link to={"/support"} >
                    <SupportIcon className="cursor-pointer support-icon"/>
                  </Link>
                  }
                  {
                    !userRoles?.roles?.includes("administrator") && <Tippy content={"Feedback versturen"} placement="bottom"><span>
                    <div className="cursor-pointer feedback-icon pe-7s-speaker ms-3" onClick={this.toggleModal}
                      ></div>
                      </span>
                    </Tippy>
                  }
                  <UserBox />
                </div>
              </div>
              <Modal
              id="tooltip"
              isOpen={isOpen}
              toggle={this.toggleModal}
              className="modal-dialog__over feedback-modal-style opmerking-modal-styling" style={{maxWidth: "500px"}}
            >
              <ModalHeader>
                <div className="modal-header-styling">Feedback versturen</div>
                <div className="cross-icon-color">
                  <i
                    className="nav-link-icon lnr-cross m-1 cursor-pointer"
                    onClick={this.toggleModal}
                  ></i>
                </div>
              </ModalHeader>
              <ModalBody className="d-flex bg-white rounded">
                <Formik
                  initialValues={{ note: "" }}
                  validationSchema={validationSchema}
                  onSubmit={this.handleSubmit}
                >
                  {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit} className="w-100">
                      <Field
                        as="textarea"
                        name="note"
                        className="form-style opmerking-input-styling w-100"
                        rows="6"
                        placeholder="Beschrijf hier uw feedback"
                      />
                      <ErrorMessage
                        name="note"
                        component="small"
                        className="block mt-2 invalid-feedback"
                      />
                      <ModalFooter className="mt-4">
                        <Button
                          className="d-flex align-items-center btn-color-style opslaan-btn"
                          size="sm"
                          outline
                          color="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          <Save />
                          Verzenden
                        </Button>
                        <Button
                          className="d-flex align-items-center btn-color-style back-btn"
                          size="sm"
                          outline
                          color="primary"
                          onClick={this.toggleModal}
                        >
                          <Small_Arrow />
                          Terug
                        </Button>
                      </ModalFooter>
                    </Form>
                  )}
                </Formik>
              </ModalBody>
            </Modal>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
  userRoles: state.UserPermission
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
