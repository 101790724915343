import axiosInstance from "Api";
import TextLimit from "Api/TextLimit";
import React, { createContext, useState, useContext } from "react";

const AbsentsContext = createContext();

export const AbsentsProvider = ({ children }) => {
	const [absentsData, setAbsentsData] = useState([]);
	const [error, setError] = useState(null);

	const sortAbsents = (absents) => {
		return absents.sort((a, b) => {
			if (b.bSelected === a.bSelected) {
				return a.name.localeCompare(b.name);
			}
			return b.bSelected - a.bSelected;
		});
	};

	const updateLikeStatus = (adminId, isLiked) => {
		setAbsentsData((prevState) => {
			const updatedList = prevState.map((absent) =>
				absent.iAdminId === adminId ? { ...absent, bSelected: isLiked ? 0 : 1 } : absent
			);

			return sortAbsents(updatedList);
		});
	};

	const handleLikeStatus = (adminId, schoolId, isLiked) => {
		const url = `admin-school${isLiked ? "" : "/create"}?iSchoolId=${
			schoolId
		}&iAdminId=${adminId}`;
		const method = isLiked ? "delete" : "post";
		try {
			axiosInstance[method](url).then((res) => console.log(res.data));
			updateLikeStatus(adminId, isLiked);
		} catch (error) {
			console.error("Error updating admin like status:", error);
			setError(error)
		}
	};

	const fetchAbsentsList = (schoolId, communityId) => {
		if (!schoolId || !communityId) {
			console.error("Provide schoolId and communityId to fetch absents.");
			return;
		}

		axiosInstance
			.get("absents-list?iSchoolId=" + schoolId + "&iCommunityId=" + communityId)
			.then((response) => {
				if (response.status == 200) {
					if (response.data.data != null && response.data.data != undefined) {
						let userData = response.data.data;
						userData.forEach((element) => {
							element.name = TextLimit.AfwezigeLimitOptionOne(
								element.vFirstName,
								element.vMiddleName,
								element.vLastName
							);
						});
						setAbsentsData(userData);
					}
				}
			})
			.catch((error) => {
				console.log("catch error:", error);
				setError(error);
			});
	};

	return (
		<AbsentsContext.Provider
			value={{
				absentsData,
				error,
				setAbsentsData,
				updateLikeStatus,
				fetchAbsentsList,
				handleLikeStatus,
				setError,
			}}
		>
			{children}
		</AbsentsContext.Provider>
	);
};

export const useAbsentsContext = () => useContext(AbsentsContext);
