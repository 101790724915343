import React, { Fragment, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
    Card, CardBody, Col, CardFooter, Row, Button
} from "reactstrap";
import PageHeading from "Layout/AppMain/PageHeading";
import FunctiesTable from "./FunctiesTable";
import { ReactComponent as AddIcon } from "../../../assets/utils/images/svgs/addBlue.svg";

const Functies = () => {
    const [openModal, setOpenModal] = useState(false);
    const [editFunctie, setEditFunctie] = useState(null);

    const handleFunctieClick = () => {
        setOpenModal(prev => !prev);
        setEditFunctie({
            type: "save"
        })
    }

    const toggle = () => {
        setOpenModal(!openModal);
    }
    return (
        <Fragment>
            <PageHeading />
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    <div>
                        <Row>
                            <Col md="12">
                                <Card className="main-card mb-3">
                                    <CardBody className="p-3">
                                        <FunctiesTable toggle={toggle} openModal={openModal} editFunctie={editFunctie} setEditFunctie={setEditFunctie}/>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        
                    </div>
                </CSSTransition>
            </TransitionGroup>
            <div className="chat-layout-footer verzoken-footer-height">
                <CardFooter className="chat-layout-footer__inner">
                    <div className="d-flex gap-3 mr-4">

                        <Button
                            outline
                            className="btn-outline-primary-blue btn-color-style py-2 px-3 d-flex gap-2 align-items-center"
                            onClick={handleFunctieClick}
                            type="button"
                            color="primary"
                        >
                            Functie <AddIcon />
                        </Button>
                    </div>
                </CardFooter>
            </div>
        </Fragment>
    );
};
export default Functies;

