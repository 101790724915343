import { Form, Formik } from "formik";
import { Card, CardBody, FormGroup, Input, Label } from 'reactstrap';

const FreeFields = ({ data, changeHandler, errorField }) => {
  
    const defaultValues = {
        vrije_veld_1: data?.vrije_veld_1 ?? '',
        vrije_veld_2: data?.vrije_veld_2 ?? '',
        vrije_veld_3: data?.vrije_veld_3 ?? '',
        vrije_veld_4: data?.vrije_veld_4 ?? '',
        vrije_veld_5: data?.vrije_veld_5 ?? '',
    };

    return (
        <Card className="main-card">
            <CardBody className="d-flex flex-column">
                <h5 className="mb-4">Vrije velden</h5>
                <Formik
                    enableReinitialize
                    initialValues={defaultValues}
                >
                    {({ values, handleChange, setFieldValue }) => (
                        <Form>
                            <FormGroup className={`form-element ${errorField === 'vrije_veld_1' ? 'error' : ''}`}>
                                <Label for="vrije_veld_1">Vrije veld 1</Label>
                                <Input
                                    name="vrije_veld_1"
                                    type="text"
                                    value={values.vrije_veld_1}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vrije_veld_1', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vrije_veld_2' ? 'error' : ''}`}>
                                <Label for="vrije_veld_2">Vrije veld 2</Label>
                                <Input
                                    name="vrije_veld_2"
                                    type="text"
                                    value={values.vrije_veld_2}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vrije_veld_2', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vrije_veld_3' ? 'error' : ''}`}>
                                <Label for="vrije_veld_3">Vrije veld 3</Label>
                                <Input
                                    name="vrije_veld_3"
                                    type="text"
                                    value={values.vrije_veld_3}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vrije_veld_3', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vrije_veld_4' ? 'error' : ''}`}>
                                <Label for="vrije_veld_4">Vrije veld 4</Label>
                                <Input
                                    name="vrije_veld_4"
                                    type="text"
                                    value={values.vrije_veld_4}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vrije_veld_4', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vrije_veld_5' ? 'error' : ''}`}>
                                <Label for="vrije_veld_5">Vrije veld 5</Label>
                                <Input
                                    name="vrije_veld_5"
                                    type="text"
                                    value={values.vrije_veld_5}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vrije_veld_5', e.target.value);
                                    }}
                                />
                            </FormGroup>
                        </Form>
                    )}
                </Formik>
            </CardBody>
        </Card>
    )
}

export default FreeFields