import moment from 'moment';
import { Card, CardBody, Table } from 'reactstrap'

const PeriodOverview = ({ startDate, endDate, data}) => {
    return (
        <Card className="main-card">
            <CardBody className="d-flex flex-column">
                <h5 className="mb-4">Periode-overzicht</h5>
                <div className='row'>
                    <div className='col-lg-6'>
                        <Table size="sm" className="mb-0 table  table-sm table-hover">
                            <thead>
                                <tr>
                                    <th>Periode</th>
                                    <th>Uren  totaal</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{moment(startDate).format('DD-MM-yyyy') + ' t/m ' + moment(endDate).format('DD-MM-yyyy')}</td>
                                    <td>{data?.uren}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}

export default PeriodOverview