import React, { Fragment, useEffect, useMemo, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Button, Card, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import PageHeading from "Layout/AppMain/PageHeading";
import ReactTableGeneric from "Components/ReactTableGeneric";
import Loader from "react-loaders";
import { ReactComponent as DeleteIcon } from "../../../../assets/utils/images/svgs/Delete.svg";
import { ReactComponent as AddIcon } from "../../../../assets/utils/images/svgs/addBlue.svg";
import { ReactComponent as EditIcon } from "../../../../assets/utils/images/svgs/editPen.svg";
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import CoordinatorInstellingenService from "Api/Instellingen";
import InstellingenCostService from "Api/Instellingen/CostCenterUnits";

const validationSchema = Yup.object().shape({
    description: Yup.string().required('Beschrijving is vereist.')
});

const CostCenterUnit = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [communityId, setCommunityId] = useState('');
    const [costCenterLoader, setCostCenterLoader] = useState(false);
    const [costUnitLoader, setCostUnitLoader] = useState(false);
    const [communityList, setCommunityList] = useState([]);
    const [costCenterData, setCostCenterData] = useState([]);
    const [costUnitData, setCostUnitData] = useState([]);
    const [modal, setModal] = useState({
        modalType: "",
        state: false,
        isEditMode: false,
        rowData: ""
    });

    const orgName = useMemo(()=>{
        return communityList?.find(item => item.iCommunityId === Number(communityId))?.name || ""
    },[communityId])

    useEffect(() => {
        setCostCenterLoader(true);
        setCostUnitLoader(true);
        fetchCommunityList();
    }, []);

    useEffect(() => {
        if (!communityId) return;
        fetchCostData("kostenplaat");
        fetchCostData("kostendrager");
    }, [communityId])

    const fetchCostData = async (type) => {
        const isKostenplaat = type === "kostenplaat";
        isKostenplaat ? setCostCenterLoader(true) : setCostUnitLoader(true);
        try {
            const response = await InstellingenCostService.fetchCostData(communityId, type);

            if (response.status) {
                isKostenplaat
                    ? setCostCenterData(response.data.data)
                    :
                    setCostUnitData(response.data.data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            isKostenplaat ? setCostCenterLoader(false) : setCostUnitLoader(false);
        }
    };

    const fetchCommunityList = () => {
        CoordinatorInstellingenService.communityList().then((response) => {
            if (response.status) {
                if (response.data.communityList?.length > 0) {
                    setCommunityList(response.data.communityList);
                    setCommunityId(response.data.communityList[0]?.iCommunityId);
                }
            }
        }).catch(() => console.error);
    }

    const costCenterCols = React.useMemo(
        () => [
            {
                header: () => 'Kostenplaats',
                accessorKey: 'vKostenplaats',
            },
            {
                header: () => <div onClick={(e) => e.stopPropagation()}>Actie</div>,
                accessorKey: 'rows',
                cell: (value) => {
                    return <div className="d-flex gap-2 justify-content-center">
                        <EditIcon className="cursor-pointer" onClick={() => {
                            setModal({
                                modalType: "kostenplaat",
                                state: true,
                                isEditMode: true,
                                rowData: value.row.original
                            });
                        }} />
                        <DeleteIcon className="cursor-pointer"
                            onClick={() => {
                                setShowAlert(true);
                                setModal({
                                    modalType: "kostenplaat",
                                    state: false,
                                    isEditMode: false,
                                    rowData: value.row.original
                                });
                            }} />
                    </div>
                },
            },
        ],
        [communityId]
    );

    const costUnitCols = React.useMemo(
        () => [
            {
                header: () => 'Kostendrager',
                accessorKey: 'vKostendrager',
            },
            {
                header: () => <div onClick={(e) => e.stopPropagation()}>Actie</div>,
                accessorKey: 'rows',
                cell: (value) => {
                    return <div className="d-flex gap-2 justify-content-center">
                        <EditIcon className="cursor-pointer" onClick={() => {
                            setModal({
                                modalType: "kostendrager",
                                state: true,
                                isEditMode: true,
                                rowData: value.row.original
                            });
                        }} />
                        <DeleteIcon className="cursor-pointer"
                            onClick={() => {
                                setShowAlert(true);
                                setModal({
                                    modalType: "kostendrager",
                                    state: false,
                                    isEditMode: false,
                                    rowData: value.row.original
                                });
                            }} />
                    </div>
                },
            },
        ],
        [communityId]
    );

    const toggle = () => {
        setModal({
            modalType: "",
            state: false,
            isEditMode: false,
            rowData: ""
        });
    }

    const handleOrgSubmit = (values) => {
        const data = {
            iCommunityId: communityId,
            type: modal.isEditMode ? "edit" : "add"
        };
        if (modal.modalType === "kostenplaat") {
            data.vKostenplaats = values.description
        } else {
            data.vKostendrager = values.description
        }
        if (modal.isEditMode) {
            data.id = modal.rowData.id
        }
        InstellingenCostService.saveCostApi(data, modal.modalType).then((response) => {
            if (response.status) {
                if (modal.modalType === "kostenplaat") fetchCostData("kostenplaat");
                else fetchCostData("kostendrager");
                setModal({
                    modalType: "",
                    state: false,
                    isEditMode: false,
                    rowData: ""
                });
            } else {
                toast.error("Er is iets misgegaan");
            }
        }).catch(() => console.error);
    }

    const deleteCostHandler = () => {
        InstellingenCostService.deleteCost(modal.rowData.id, communityId, modal.modalType).then((response) => {
            if (response.status) {
                if (modal.modalType === "kostenplaat") fetchCostData("kostenplaat");
                else fetchCostData("kostendrager");
                setModal({
                    modalType: "",
                    state: false,
                    isEditMode: false,
                    rowData: ""
                });
            } else {
                toast.error("Er is iets misgegaan");
            }
        }).catch(() => console.error);
    }

    return (
        <Fragment>
            <PageHeading orgName={orgName}/>
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    <Row className="mb-5">
                        <Col>
                            <div className="row">
                                <div className="col">
                                    <Card className="main-card mb-3">
                                        <CardBody className="p-3">
                                            {costCenterLoader ?
                                                <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
                                                : <ReactTableGeneric
                                                    data={costCenterData || []}
                                                    columns={costCenterCols}
                                                    tableClassName="mb-0 table table-borderless table-sm table-hover tb-api tb-links"
                                                    rowCount={40}
                                                    filters={{ search: false, pagination: false, sorting: false }}
                                                />}
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className="col">
                                    <Card className="main-card mb-3">
                                        <CardBody className="p-3">
                                            {costUnitLoader ?
                                                <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
                                                :
                                                <ReactTableGeneric
                                                    data={costUnitData || []}
                                                    columns={costUnitCols}
                                                    tableClassName="mb-0 table table-borderless table-sm table-hover tb-api tb-links"
                                                    rowCount={40}
                                                    filters={{ search: false, pagination: false, sorting: false }}
                                                />}
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        </Col>
                        <Modal isOpen={modal.state} toggle={toggle} className="feedback-modal-style link-form">
                            <ModalHeader toggle={toggle}>
                                {modal.modalType === "kostenplaat" ? "Toevoegen/wijzigen Kostenplaats" : "Toevoegen/wijzigen Kostendrager"}
                            </ModalHeader>
                            <ModalBody>
                                <Formik
                                    initialValues={{
                                        description: modal.modalType === "kostenplaat"
                                            ? modal?.rowData?.vKostenplaats
                                            : modal.modalType === "kostendrager"
                                                ? modal?.rowData?.vKostendrager
                                                : "",
                                    }}
                                    enableReinitialize={true}
                                    validationSchema={validationSchema}
                                    onSubmit={handleOrgSubmit}
                                >
                                    {({ handleSubmit, values, handleBlur, handleChange }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <FormGroup>
                                                <Label for="description">Omschrijving</Label>
                                                <Input name="description"
                                                    type="text" onChange={handleChange} onBlur={handleBlur}
                                                    value={values.description} />
                                                <ErrorMessage name="description" component="small"
                                                    className="block mt-2 invalid-feedback" />
                                            </FormGroup>
                                            <ModalFooter className="d-flex align-items-center justify-content-center border-bottom-0 py-4 mt-4">
                                                <Button className="btn-color-style  opslaan-btn" size="sm" outline color="primary" type="submit">
                                                    Toepassen
                                                </Button>
                                            </ModalFooter>
                                        </Form>
                                    )}
                                </Formik>
                            </ModalBody>
                        </Modal>
                    </Row>
                </CSSTransition>
            </TransitionGroup>
            <div className="chat-layout-footer verzoken-footer-height">
                <CardFooter className="chat-layout-footer__inner">
                    <div className="d-flex gap-3 mr-4">
                        <div>
                            <select className={`form-select`} value={communityId} onChange={(e) => { setCommunityId(e.target.value) }}>
                                <option value={''} disabled>Alle besturen</option>
                                {communityList?.map(item => {
                                    return <option key={item.iCommunityId} value={item.iCommunityId}>{item.name}</option>
                                })}
                            </select>
                        </div>
                        <Button
                            outline
                            className="btn-outline-primary-blue btn-color-style py-2 px-3 d-flex gap-2 align-items-center"
                            onClick={() => {
                                setModal({
                                    modalType: "kostenplaat",
                                    state: true,
                                    isEditMode: false,
                                    rowData: ""
                                });
                            }}
                            type="button"
                            color="primary"
                        >
                            Kostenplaats <AddIcon />
                        </Button>
                        <Button
                            outline
                            className="btn-outline-primary-blue btn-color-style py-2 px-3 d-flex gap-2 align-items-center"
                            onClick={() => {
                                setModal({
                                    modalType: "kostendrager",
                                    state: true,
                                    isEditMode: false,
                                    rowData: ""
                                });
                            }}
                            type="button"
                            color="primary"
                        >
                            Kostendrager <AddIcon />
                        </Button>
                    </div>
                </CardFooter>
            </div>
            <SweetAlert title="Weet u zeker dat u deze selectie wilt verwijderen?" show={showAlert}
                type="warning"
                onConfirm={() => { setShowAlert(false); deleteCostHandler() }}
                onCancel={() => setShowAlert(false)}
                cancelBtnText="Nee"
                confirmBtnText="Ja"
                showCancel={true}
            />
        </Fragment>
    )
}

export default CostCenterUnit