import { useEffect, useState } from 'react';
import { Card, CardBody, FormGroup, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { ReactComponent as DeleteIcon } from "../../../../../../assets/utils/images/svgs/Delete.svg";
import { ReactComponent as AddIcon } from "../../../../../../assets/utils/images/svgs/Add.svg";
import { ReactComponent as SearchIcon } from "../../../../../../assets/utils/images/svgs/search.svg";
import ScholenService from 'Api/Instellingen/Scholen';
import Loader from 'react-loaders';
import { debounce } from '../../utils';

const Melders = ({ setModal, modal, iCommunityId, iSchoolId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
    const [search, setSearch] = useState("");

    const fetchMeldersList = async () => {
        setIsLoading(true);
        const payLoad = {
            iCommunityId,
            iSchoolId,
            search
        }
        try {
            const response = await ScholenService.fetchMeldersList(payLoad);
            if (response.data.status) {
                setData(response.data.data);
            }
        } catch (error) {
            console.error(`API error:`, error);
        } finally {
            setIsLoading(false);
        }
    };
    const debouncedFetchMeldersList = debounce(fetchMeldersList, 1000);

    useEffect(() => {
        debouncedFetchMeldersList();
    }, [search]);

    const addOrDeleteMelderHandler = async (type, iAdminId) => {
        setIsLoading(true);
        const payload = {
            iAdminId,
            iSchoolId,
            type
        }
        try {
            const response = await ScholenService.addAndDeleteMelder(payload);
            if (response.data.status) {
                fetchMeldersList()
            }
        } catch (error) {
            console.error(`API error:`, error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Card className="main-card">
            <CardBody className="d-flex flex-column">
                <h5 className="mb-4">Melders</h5>
                {isLoading ?
                    <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
                    : <>
                        <div className="melder-list" >
                            {data?.reporterLists?.map(item => {
                                return <FormGroup className="form-element" item={item.iAdminId}>
                                    <Label for="iKetenregeling">{item.name}</Label>
                                    <DeleteIcon className='cursor-pointer' onClick={() => {
                                        addOrDeleteMelderHandler('delete', item.iAdminId)
                                    }} />
                                </FormGroup>
                            })}
                        </div>
                    </>
                }
                <Modal isOpen={modal.state} toggle={() => setModal(prev => ({ ...prev, state: !prev.state }))} className="feedback-modal-style link-form modal-centered">
                    <ModalHeader toggle={() => setModal(prev => ({ ...prev, state: !prev.state }))}>
                        Selecteer melder
                    </ModalHeader>
                    <ModalBody className="pb-5">
                        <div className="pb-4 mt-3 melder-search">
                            <input placeholder="Zoeken" onChange={(e) => setSearch(e.target.value)} />
                            <SearchIcon />
                        </div>
                        <div className="melder-list" >
                            {data?.adminSchoolLists?.map(item => {
                                return <div className="py-2 melder-li" key={item.iAdminId}>
                                    <Label for="iKetenregeling">{item.name}</Label>
                                    <AddIcon className='cursor-pointer' onClick={() => {
                                        addOrDeleteMelderHandler('add', item.iAdminId)
                                    }} />
                                </div>
                            })}
                        </div>
                    </ModalBody>
                </Modal>
            </CardBody>
        </Card>
    )
}

export default Melders