import React from "react";
import { Outlet } from "react-router-dom";
import AppHeader from "../../../Layout/AppHeader/";
import AppSidebar from "../../../Layout/AppSidebar/";
import AppFooter from "Layout/AppFooter";


const Beheer = () => {
    return (
        <>
            <AppHeader />
            <div className="app-main">
                <AppSidebar />
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        <Outlet/>
                    </div>
                </div>
            </div>
            <AppFooter />
        </>
    )
};

export default Beheer;