import React, { Fragment, useEffect, useMemo, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Button, Card, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import PageHeading from "Layout/AppMain/PageHeading";
import ReactTableGeneric from "Components/ReactTableGeneric";
import Loader from "react-loaders";
import { ReactComponent as AddIcon } from "../../../../assets/utils/images/svgs/addBlue.svg";
import { BsCheckCircle } from "react-icons/bs";
import { toast } from "react-toastify";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import CoordinatorInstellingenService from "Api/Instellingen";
import InstellingenDayTypeService from "Api/Instellingen/DayTypes";
import { ReactComponent as CrossIcon } from "../../../../assets/utils/images/svgs/Cross.svg";
import GroupStatus from "Components/IconDropdown/Cross_Tick/GroupStatus";
import { values } from "lodash";

const validationSchema = Yup.object().shape({
    abbr: Yup.string()
        .min(1, 'Afkorting moet minimaal 1 teken bevatten')
        .max(3, 'Afkorting mag maximaal 3 tekens bevatten')
        .required('Afkorting is verplicht'),
    desc: Yup.string()
        .max(20, 'Naam Dagtype mag maximaal 20 tekens bevatten')
        .required('Naam Dagtype is verplicht'),
});

const DayTypes = () => {
    const [isLoading, setLoading] = useState(false);
    const [communityId, setCommunityId] = useState('');
    const [communityList, setCommunityList] = useState([]);
    const [dayTypeData, setDayTypeData] = useState();
    const [modal, setModal] = useState(false);
    const [loadingKeys, setLoadingKeys] = useState({});
    const [handleModalData, setHandleModalData] = useState({ type: '', value: {} });

    useEffect(() => {
        setLoading(true);
        fetchCommunityList();
    }, []);

    useEffect(() => {
        if (!communityId) return;
        fetchDayTypeData();
    }, [communityId]);

    const fetchDayTypeData = () => {
        InstellingenDayTypeService.fetchDaytypeData(communityId).then((response) => {
            if (response.status) {
                setDayTypeData(response.data.data)
            }
            setLoading(false);
        }).catch(() => console.error);
    };

    const fetchCommunityList = () => {
        CoordinatorInstellingenService.communityList().then((response) => {
            if (response.status) {
                if (response.data.communityList?.length > 0) {
                    setCommunityList(response.data.communityList);
                    setCommunityId(response.data.communityList[0]?.iCommunityId);
                }
            }
        }).catch(() => console.error);
    };

    const toggleFunctieHandler = (rowData, communityId, type, data) => {
        if (parseInt(data) === rowData[type]) return
        setLoadingKeys(prevLoadingKeys => {
            const updatedLoadingKeys = Object.fromEntries(
                Object.entries(prevLoadingKeys).filter(([_, value]) => value !== false)
            );

            return { ...updatedLoadingKeys, [type]: true, id: rowData?.id };
        });

        const values = {
            iCommunityId: communityId,
            type,
            value: parseInt(data),
            id: rowData.id
        };
        InstellingenDayTypeService.toggleDaytypeValue(values).then((response) => {
            if (response.status) {
                setDayTypeData((prevData) =>
                    prevData.map((item) =>
                        item.id === rowData.id ? { ...item, [type]: parseInt(data) } : item
                    )
                );
                setLoadingKeys(prevLoadingKeys => ({ ...prevLoadingKeys, [type]: false }));
            } else {
                toast.error("Kan gegevens niet updaten");
            }
        }).catch(() => console.error);
    };

    const columns = [
        {
            header: () => 'Dagtype',
            accessorKey: 'desc',
            cell: (value) => {
                return (
                    <div className="cursor-pointer" onClick={() => {
                        toggle();
                        setHandleModalData({ type: 'update', value: value?.row?.original });
                    }}>
                        {value?.row?.original.desc}
                    </div>
                );
            },
        },
        {
            header: () => 'Afkorting',
            accessorKey: 'abbr',
        },
        {
            header: () => 'AFAS',
            accessorKey: 'bluebox',
            cell: (value) => {
                return <div className="cursor-pointer d-inline-flex justify-content-center">
                    {
                        loadingKeys["bluebox"] && loadingKeys["id"] === value.row?.original.id ? (
                            <div className="spinner-icon-change">
                                <div><Spinner size="sm" color="primary" /></div>
                            </div>
                        ) :
                            <GroupStatus iconChangeHandler={(field, data) => toggleFunctieHandler(value.row.original, communityId, field, data)} field={"bluebox"} data={value.row?.original.bluebox ? 1 : 0} />
                    }
                </div>
            },
        },
        {
            header: () => 'Reiskosten',
            accessorKey: 'travel',
            cell: (value) => {
                return <div className="cursor-pointer d-inline-flex justify-content-center">
                    {
                        loadingKeys["travel"] && loadingKeys["id"] === value.row?.original.id ? (
                            <div className="spinner-icon-change">
                                <div><Spinner size="sm" color="primary" /></div>
                            </div>
                        ) :
                            <GroupStatus iconChangeHandler={(field, data) => toggleFunctieHandler(value.row.original, communityId, field, data)} field={"travel"} data={value.row?.original.travel ? 1 : 0} />
                    }
                </div>
            },
        },
        {
            header: () => 'Weekstaat',
            accessorKey: 'weekstaat',
            cell: (value) => {
                return <div className="cursor-pointer d-inline-flex justify-content-center">
                    {
                        loadingKeys["weekstaat"] && loadingKeys["id"] === value.row?.original.id ? (
                            <div className="spinner-icon-change">
                                <div><Spinner size="sm" color="primary" /></div>
                            </div>
                        ) :
                            <GroupStatus iconChangeHandler={(field, data) => toggleFunctieHandler(value.row.original, communityId, field, data)} field={"weekstaat"} data={value.row?.original.weekstaat ? 1 : 0} />
                    }
                </div>
            },
        },
        {
            header: () => 'Actief',
            accessorKey: 'active',
            cell: (value) => {
                return <div className="cursor-pointer d-inline-flex justify-content-center">
                    {
                        loadingKeys["active"] && loadingKeys["id"] === value.row?.original.id ? (
                            <div className="spinner-icon-change">
                                <div><Spinner size="sm" color="primary" /></div>
                            </div>
                        ) :
                            <GroupStatus iconChangeHandler={(field, data) => toggleFunctieHandler(value.row.original, communityId, field, data)} field={"active"} data={value.row?.original.active ? 1 : 0} />
                    }
                </div>
            },
        }]

    const toggle = () => {
        setModal(prev => !prev);
        setHandleModalData({ type: '', value: {} });
    };
    const handleOrgSubmit = (values) => {
        const data = {
            iCommunityId: communityId,
            abbr: values.abbr,
            desc: values.desc,
            active: values.active,
            bluebox: values.bluebox,
            weekstaat: values.weekstaat,
            travel: values.travel,
            ...handleModalData.type === 'update' && { iDagTypeId: handleModalData.value?.id }
        };
        if (handleModalData.type === 'update') {
            InstellingenDayTypeService.updateDaytypeApi(data).then((response) => {
                if (response.status) {
                    setDayTypeData((prevData) =>
                        prevData.map((item, index) =>
                            item.id === data.iDagTypeId ? { ...item, ...data } : item
                        )
                    );
                    toggle()
                } else {
                    toast.error("Er is iets misgegaan");
                }
            }).catch(() => console.error);
        } else{
            InstellingenDayTypeService.saveDaytypeApi(data).then((response) => {
                if (response.status) {
                    fetchDayTypeData();
                    setModal(false);
                } else {
                    toast.error("Er is iets misgegaan");
                }
            }).catch(() => console.error);
        }
    };

    const orgName = useMemo(() => {
        return communityList?.find(item => item.iCommunityId === Number(communityId))?.name || ""
    }, [communityId, communityList]);

    const saveInitialValue = useMemo(() => {
        return {
            abbr: '',
            desc: '',
            weekstaat: 1,
            active: 1,
            bluebox: 1,
            travel: 1
        }
    }, [handleModalData]);

    const updateInitialValue = useMemo(() => {
        return {
            abbr: handleModalData?.value?.abbr,
            desc: handleModalData?.value?.desc,
            weekstaat: handleModalData?.value?.weekstaat,
            active: handleModalData?.value?.active,
            bluebox: handleModalData?.value?.bluebox,
            travel: handleModalData?.value?.travel
        }
    }, [handleModalData]);

    return (
        <Fragment>
            <PageHeading orgName={orgName} />
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    <Row className="mb-5">
                        <Col>
                            <Card className="main-card mb-3">
                                <CardBody className="p-3">
                                    {isLoading ?
                                        <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} /> :
                                        <ReactTableGeneric
                                            data={dayTypeData || []}
                                            columns={columns}
                                            tableClassName="mb-0 table table-borderless table-sm table-hover tb-api tb-links"
                                            rowCount={40}
                                            filters={{ search: false, pagination: false, sorting: false }}
                                        />
                                    }
                                </CardBody>
                            </Card>
                            <p className="mt-4" style={{ color: '#076396' }}>Klik op icoontjes om te wijzigen</p>
                        </Col>
                        <Modal isOpen={modal} toggle={toggle} className="feedback-modal-style link-form">
                            <ModalHeader toggle={toggle}>
                                Dagtype toevoegen/wijzigen
                            </ModalHeader>
                            <ModalBody>
                                <Formik
                                    initialValues={handleModalData.type === 'update' ? updateInitialValue : saveInitialValue}
                                    validationSchema={validationSchema}
                                    onSubmit={handleOrgSubmit}
                                >
                                    {({ setFieldValue, handleSubmit, values, handleChange, handleBlur }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <FormGroup>
                                                <Label for="desc">Naam Dagtype</Label>
                                                <Input name="desc"
                                                    type="text" onChange={handleChange} onBlur={handleBlur}
                                                    value={values.desc} />
                                                <ErrorMessage name="desc" component="small"
                                                    className="block mt-2 invalid-feedback" />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="abbr">Afkorting</Label>
                                                <Input name="abbr"
                                                    type="text" onChange={handleChange} onBlur={handleBlur}
                                                    value={values.abbr} />
                                                <ErrorMessage name="abbr" component="small"
                                                    className="block mt-2 invalid-feedback" />
                                            </FormGroup>
                                            <FormGroup className="d-flex justify-content-between">
                                                <Label for="active">Actief</Label>
                                                <div
                                                    onClick={() => {
                                                        setFieldValue("active", values.active === 1 ? 0 : 1);
                                                    }}
                                                    style={{ cursor: 'pointer', display: 'inline-block' }}
                                                >
                                                    {values.active === 1 ?
                                                        <BsCheckCircle color='#39A825' fontSize={"18px"} /> :
                                                        <CrossIcon color='#FB2E5F' fontSize={"24px"} />
                                                    }
                                                </div>
                                            </FormGroup>
                                            <FormGroup className="d-flex justify-content-between">
                                                <Label for="weekstaat">Weekstaat</Label>
                                                <div
                                                    onClick={() => {
                                                        setFieldValue("weekstaat", values.weekstaat === 1 ? 0 : 1);
                                                    }}
                                                    style={{ cursor: 'pointer', display: 'inline-block' }}
                                                >
                                                    {values.weekstaat === 1 ?
                                                        <BsCheckCircle color='#39A825' fontSize={"18px"} /> :
                                                        <CrossIcon color='#FB2E5F' fontSize={"24px"} />
                                                    }
                                                </div>
                                            </FormGroup>
                                            <FormGroup className="d-flex justify-content-between">
                                                <Label for="bluebox">AFAS</Label>
                                                <div
                                                    onClick={() => {
                                                        setFieldValue("bluebox", values.bluebox === 1 ? 0 : 1);
                                                    }}
                                                    style={{ cursor: 'pointer', display: 'inline-block' }}
                                                >
                                                    {values.bluebox === 1 ?
                                                        <BsCheckCircle color='#39A825' fontSize={"18px"} /> :
                                                        <CrossIcon color='#FB2E5F' fontSize={"24px"} />
                                                    }
                                                </div>
                                            </FormGroup>
                                            <FormGroup className="d-flex justify-content-between">
                                                <Label for="travel">Reiskosten</Label>
                                                <div
                                                    onClick={() => {
                                                        setFieldValue("travel", values.travel === 1 ? 0 : 1);
                                                    }}
                                                    style={{ cursor: 'pointer', display: 'inline-block' }}
                                                >
                                                    {values.travel === 1 ?
                                                        <BsCheckCircle color='#39A825' fontSize={"18px"} /> :
                                                        <CrossIcon color='#FB2E5F' fontSize={"24px"} />
                                                    }
                                                </div>
                                            </FormGroup>

                                            <ModalFooter className="d-flex align-items-center justify-content-center border-bottom-0 py-4 mt-4">
                                                <Button className="btn-color-style opslaan-btn" size="sm" outline color="primary" type="submit">
                                                    Toepassen
                                                </Button>
                                            </ModalFooter>
                                        </Form>
                                    )}
                                </Formik>
                            </ModalBody>
                        </Modal>
                    </Row>
                </CSSTransition>
            </TransitionGroup>
            <div className="chat-layout-footer verzoken-footer-height">
                <CardFooter className="chat-layout-footer__inner">
                    <div className="d-flex gap-3 mr-4">
                        <div>
                            <select className={`form-select`} value={communityId} onChange={(e) => { setCommunityId(e.target.value) }}>
                                <option value={''} disabled>Alle besturen</option>
                                {communityList?.map(item => {
                                    return <option key={item.iCommunityId} value={item.iCommunityId}>{item.name}</option>
                                })}
                            </select>
                        </div>
                        <Button
                            outline
                            className="btn-outline-primary-blue btn-color-style py-2 px-3 d-flex gap-2 align-items-center"
                            onClick={toggle}
                            type="button"
                            color="primary"
                        >
                            Dagtype <AddIcon />
                        </Button>
                    </div>
                </CardFooter>
            </div>
        </Fragment>
    );
};

export default DayTypes;
