import axios from 'axios';
import Auth from "../services/Auth";
import Bugsnag from '@bugsnag/js';
import AuthService from "../Api/Auth/index";



const baseUrl = process.env.REACT_APP_BASE_URL || `http://127.0.0.1:8000`


const axiosInstance = axios.create({
    baseURL: baseUrl+"/api/",
});

export const authInterceptor = axiosInstance.interceptors.request.use(
    (config) => {
        config.meta = config.meta || {}
        config.meta.requestStartedAt = new Date().getTime();
        const token = Auth.getToken();
        if(token !== '') {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },
    (error) => Promise.reject(error),
);


export const authInterceptorResponse =  axiosInstance.interceptors.response.use(
    (response) => {
        
        let responseTime = (new Date().getTime() - response.config.meta.requestStartedAt );
       
        if( process.env.REACT_APP_DISABLE_BUGSNAG == 'false' ) {
            if( responseTime > Number( process.env.REACT_APP_API_RESPONSE__IN_MILLISECONS )) {
                Bugsnag.notify(new Error('Track Response time for Api'), function (event) {
                    event.severity = 'info'
                    event.context = 'Slow Api Response Time ('+responseTime+ 'ms)'
                    event.addMetadata("API Response Time Report",{ url: response.config.url, header: response.config.headers })
                  })
            }
        }
        return response;
    },
    (error) => {
        //const originalRequest = error.config;
        // if(AuthService.checkUserLoggedIn()) {
            if (error.response?.status == 401) {
                //TODO:refactor to redux state
                localStorage.clear();
                window.location = "#/login";
                return error.response;

                // Auth.logout()
                //     .then((res)=>{
                //     window.location = "#/login";
                // }).catch(err=>{
                //     console.log(err)
                // })
                //window.location = "#/login";
                // AuthService.regenerateRefreshToken().then((result)=>{
                //     if(result) {
                //         const token = Auth.getToken();
                //         if(token !== '') {
                //             window.location.reload(false);
                //             //originalRequest.headers['Authorization'] = "Bearer "+token;
                //             //return axios.request( originalRequest );
                //         }
                //     }
                // });
            }
            if (error.response?.status === 403) {
                window.location = '#/do-not-have-permmison';
                throw error.response;
            }
        // }
    }
);


export default axiosInstance;
