import axiosInstance from 'Api';

const fetchContracts = (communityId) => {
    return axiosInstance.get(`instellingen/contracts?iCommunityId=${communityId}`)
};

const saveContract = (data) => {
    return axiosInstance.post(`instellingen/contract/save`, data);
};
const deleteContract = (data) => {
    return axiosInstance.post(`instellingen/contract/delete`, data);
};

const ContractTypenService = {
    fetchContracts,
    saveContract,
    deleteContract
};
export default ContractTypenService;