import React, {useState} from 'react';
import {Field, Form, Formik} from "formik";
// import FormikInputGroup from "../common/FormikInputGroup";
import * as Yup from 'yup';

import {Button, Col, Container, FormGroup, Label, Row} from "reactstrap";
import BootstrapInputGroup from "Pages/Profile/Forms/BootstrapInputGroup";
import axiosInstance from "Api";
import FormStatus from "Pages/Profile/Forms/FormStatus";
import DefaultButton from 'Components/Buttons/DefaultButton';
import TwoFAModal from 'Components/Modal/TwoFAModal';
import { toast } from 'react-toastify';

const DisplayingErrorMessagesSchema = Yup.object().shape({
    password_current: Yup.string().trim()
        .required('Huidig wachtwoord is vereist')
        .test('Password Correct', 'Wachtwoord komt niet overeen met uw huidige wachtwoord',
            function (value) {
                if (value && value.length >= 8) {
                    return new Promise((resolve, reject) => {
                        axiosInstance.post('users/validate', {'password_current': value}).then(res => {
                            if (res.data.status === 'success') {
                                resolve(true);
                            }
                            resolve(false);
                        })
                    })
                }
            }
        ),
    password: Yup.string()
        .required('Wachtwoord is verplicht')
        .min(8, "Wachtwoord moet minimaal 8 tekens lang zijn")
        .max(50, "Het wachtwoord mag maximaal 50 tekens lang zijn")
        .matches("^\\S*$", "Wachtwoord mag geen spaties hebben")
        .matches("[A-Za-z]+", "Gebruik hoofdletters en kleine letters a-z en A-Z tekens")
        .matches("[0-9]+", "Wachtwoord moet cijfers bevatten")
        .matches("[`!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>\\/?~]+", "Wachtwoord moet speciale tekens bevatten !@#$%^&*()_+"),
    password_confirmation: Yup.string()
        .required('Wachtwoordbevestiging is vereist')
        .oneOf([Yup.ref('password'), null], 'Wachtwoorden moeten overeenkomen')
});

const ChangePasswordForm = (props) => {
    const [message, setMessage] = useState({type: null, text: ''});

    const requestUrl = props.url ?? 'users/change-password';
    const errorStatus = true;
    const [show2FAModal, setShow2FAModal] = useState(false);
    const [formData, setFormData] = useState({});
    const [otp, setOtp] = useState('');
    const [buttonPress, setButtonPress] = useState(false);

    const open2FAModal = (values, {setSubmitting, setErrors, resetForm}) => {
        if( !props?.userProfileData?.user?.impersonate && props?.userProfileData?.enabled ) {
            setFormData(values);
            setShow2FAModal(true);
        } else {
            handleSubmit(values, {setSubmitting, setErrors, resetForm});
        }
    }

    const handleSubmit = (values, {setSubmitting, setErrors, resetForm}) => {
        let data;
        if( !props?.userProfileData?.user?.impersonate && props?.userProfileData?.enabled ) {
            data = {...formData, one_time_password:otp}
        } else {
            data = values
        }

        show2FAModal && setButtonPress(true);
        
        setTimeout(() => {
            axiosInstance.post(requestUrl, data).then(response => {
                if (response?.data?.status === "success") {
                    setMessage({type: 'success', text: 'Wachtwoord succesvol aangepast'})
                    resetForm();
                    setShow2FAModal(false);
                } else if (response?.data?.error) {
                    toast.error("Ongeldige otp");
                }
            }).catch(err => {
                
                if (err.response) {
                    if (err.response.status === 422) {
                        let errors = {};
                        for (let key in err.response.data.errors) {
                            errors[key] = err.response.data.errors[key][0];
                        }
                        setErrors(errors);
                    }
                }
            }).finally(() => {
                    setSubmitting(false);
                    setOtp('');
                    show2FAModal && setButtonPress(false);
                }
            );
    
        }, 500)
    }

    return (
        <>
            <Formik
                initialValues={{password_current: '', password: '', password_confirmation: ''}}
                validationSchema={DisplayingErrorMessagesSchema}
                onSubmit={open2FAModal}
            >
                {(formik) => (
                    <Form>
                        {< Container>
                            {message.type && <FormStatus message={message} status="success" changeMessage={setMessage}/>}
                            <Row>
                                <Col sm="6">
                                    <Row>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Label for="password_current">
                                                    Je huidige wachtwoord
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Field name="password_current">
                                                    {({field, form, meta}) => (
                                                        <BootstrapInputGroup field={field} meta={meta}/>
                                                    )}
                                                </Field>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Label for="password">
                                                    Kies een nieuwe wachtwoord
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Field name="password">
                                                    {({field, form, meta}) => (
                                                        <BootstrapInputGroup field={field} meta={meta}/>
                                                    )}
                                                </Field>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Label for="password_confirmation">
                                                    Bevestig je nieuwe wachtwoord
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Field name="password_confirmation">
                                                    {({field, form, meta}) => (
                                                        <BootstrapInputGroup field={field} meta={meta}/>
                                                    )}
                                                </Field>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={{size: 6, offset: 6}} className=" px-2">
                                            <DefaultButton type="submit" disabled={formik.isValid ? false : true}
                                                    className="mb-2 me-0 btn-color-style" color="primary">
                                                Aanpassen
                                            </DefaultButton>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>}
                    </Form>
                )}
            </Formik>

            <TwoFAModal buttonPress={buttonPress} setShow2FAModal={setShow2FAModal} showRememberMe={false} showOTPModal={show2FAModal} handleOTPSubmit={handleSubmit} otp={otp} setOtp={setOtp}/>
        </>
    )
}

export default ChangePasswordForm;
