import React, { useState } from "react";
import { Form, Formik } from "formik";
import { Card, CardBody, FormGroup, Label } from "reactstrap";
import { downloadBase64File } from "helperFunctions";
import { ReactComponent as PDFIcon } from "../../../../../assets/utils/images/svgs/PDF.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/utils/images/svgs/Delete.svg";
import "tippy.js/themes/light.css";
import BesturenService from "Api/Instellingen/Besturen";
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";

const Vervangersprotocol = ({ replacementProtocolData, replacementProtocol }) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedField, setSelectedField] = useState(null);

    const removeData = (iProtocolId, iCommunityId) => {
        const data = {
            iProtocolId,
            iCommunityId
        }

        BesturenService.removeReplacementProtocol(data)
            .then(res => {
                if (res.data.status) {
                    replacementProtocol();
                } else {
                    toast.error("Error updating data");
                }

            })
            .catch((error) => console.log("catch error:", error));
    };

    const initialValues = Object.keys(replacementProtocolData || {}).reduce((acc, key) => {
        acc[key] = replacementProtocolData[key] || "";
        return acc;
    }, {});

    return (
        <Card className="main-card h-auto pb-4">
            <CardBody className="d-flex flex-column">
                <div className="btn-color-style travelCost-blue-text mb-4">Vervangersprotocol</div>

                <Formik enableReinitialize initialValues={initialValues} validateOnChange={true}>
                    {({ values }) => {
                        return Object.keys(values).length > 0 ? (
                            <Form className="melder-list">
                                {Object.keys(values).map((field, index) => (
                                    <FormGroup key={index} className="protocol-coord">
                                        <Label for={field}>{values[field]?.vFileName}</Label>
                                        <div className="position-relative">
                                            <div className="d-flex gap-1 align-items-center">
                                                <div className="d-flex">
                                                    <div className="svg-pointer me-4"
                                                        onClick={() => downloadBase64File(values[field]?.fileLink, values[field]?.vFileName)}>
                                                        <PDFIcon />
                                                    </div>
                                                    <div className="svg-pointer"
                                                        onClick={() => {
                                                            setShowDeleteModal(true);
                                                            setSelectedField(values[field]);
                                                        }}>
                                                        <DeleteIcon />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </FormGroup>
                                ))}
                            </Form>
                        ) : (
                            <p className="text-center">Gegevens zijn niet aanwezig</p>
                        );
                    }}
                </Formik>

            </CardBody>
            <SweetAlert
                title="Weet je zeker dat je dit wilt verwijderen?"
                show={showDeleteModal}
                type="warning"
                confirmBtnText="OK"
                cancelBtnText="Annuleren"
                showCancel
                onConfirm={() => {
                    if (selectedField) {
                        removeData(selectedField?.iProtocolId, selectedField?.iCommunityId);
                    }
                    setShowDeleteModal(false);
                }}
                onCancel={() => setShowDeleteModal(false)}
            />
        </Card>
    );
};

export default Vervangersprotocol;
