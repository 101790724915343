import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router";
import Auth from "services/Auth";


const PermissionDeny = () => {
    const navigate = useNavigate();
    const handleLogOut = () => {
        Auth.logout().then((response) => {
            navigate("/login");
        }).catch(() => console.error)
    };

    useEffect(() => {
        let logoutTimer = setTimeout(() => handleLogOut(), 1000);
        return () => {
            clearTimeout(logoutTimer);
        };
    }, [])
    
    return (
        <Fragment>
            <div className="position-absolute top-50 start-50 translate-middle">
                <div className="fs-3 fw-bolder">
                    User does not have the right role.
                </div>
                <div className="cursor-pointer" onClick={() => handleLogOut()}><u>Go Back</u></div>
            </div>
        </Fragment>
    )
};

export default PermissionDeny;