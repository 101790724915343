import { React } from "react";
import { ReactComponent as Historie } from "../../../../assets/utils/images/svgs/Historie.svg";
import { ReactComponent as Decenteralized } from "../../../../assets/utils/images/svgs/Decenteralized.svg";
import { ReactComponent as Centeralized } from "../../../../assets/utils/images/svgs/Centeralized.svg";
import { ReactComponent as PIcon } from "../../../../assets/utils/images/svgs/iconoir_parking.svg";
import { ReactComponent as GrrayCircle } from "../../../../assets/utils/images/svgs/GrrayCircle.svg";
import { HiOutlineDotsCircleHorizontal } from "react-icons/hi";
import { BsCheckCircle } from "react-icons/bs";
import Tippy from "@tippyjs/react";
import moment from "moment";
import TextLimit from "Api/TextLimit";
import ReactTableGenericForUsers from "Components/ReactTableGenericForUsers";
import { Link } from "react-router-dom";
import { ReactComponent as ShowSchool } from "../../../../assets/utils/images/svgs/ShowSchool.svg";
import { ReactComponent as Home } from "../../../../assets/utils/images/svgs/home.svg";
import { ReactComponent as Teachershow } from "../../../../assets/utils/images/svgs/Teacher-show.svg";
import { ReactComponent as Teacher } from "../../../../assets/utils/images/svgs/Teacher.svg";

const Record = (props) => {

    const iconSwitch = (param) => {
        switch (param) {
            case 'Openstaand':
                return <Tippy content={param} placement="bottom"><span><HiOutlineDotsCircleHorizontal fontSize={"24px"} /></span></Tippy>;
            case 'Afgesloten':
                return <Tippy content={param} placement="bottom"><span><Historie /></span></Tippy>;
            case 'Behandeld':
                return <Tippy content={param} placement="bottom"><span><BsCheckCircle color='#39A825' fontSize={"21px"} /></span></Tippy>;
            case 'Geparkeerd':
                return <Tippy content={param} placement="bottom"><PIcon /></Tippy>;
            default:
                return <Tippy content={param} placement="bottom"><span><BsCheckCircle color='#39A825' fontSize={"21px"} /></span></Tippy>;
        }
    }

    const homeIconSwitch = (value) => {
        switch (value) {
            case 'Yes':
                return <Home />;
            case 'Yes1':
                return <ShowSchool />;
            default:
                return '';
        }
    }

    const userIconSwitch = (value) => {
        switch (value) {
            case 'Yes':
                return <Teacher />;
            case 'Yes1':
                return <Teachershow />;
            default:
                return '';
        }
    }

    const columns = [
        {
            header: () => 'ID',
            cell: value => {
                return (
                    <Link
                        to={`/verzoeken/overzicht/${value.row.original.iSubstitutionId}`}
                        key={value}
                    >
                        {value.row.original.iSubstitutionId}
                    </Link>)
            },
            accessorKey: 'iSubstitutionId',
        },
        {
            header: () => <Tippy content={"Kleur"} placement="top"><span>K</span></Tippy>,
            accessorKey: 'k',
            cell: value => (
                <GrrayCircle fill={value.row.original.markereColor !== null ? value.row.original.markereColor : '#878EA6'} />
            )
        },
        {
            header: () => <Tippy content={"Planning"} placement="top"><span>P</span></Tippy>,
            accessorKey: 'type',
            cell: value => (
                value.row.original.iPlanOption === 0 || value.row.original.iPlanOption === 3 ?
                    <Tippy content={'Centrale Planning'} placement="bottom"><Centeralized /></Tippy> : <Tippy content={'School Planning'} placement="bottom"><Decenteralized /></Tippy>
            ),
        },
        {
            header: () => <Tippy content={"Status"} placement="top"><span>S</span></Tippy>,
            accessorKey: 'eStatus',
            cell: value => (
                value.row.original.eStatus === 'Openstaand' && new Date(value.row.original.dParkdate) >= new Date() ?
                    <Tippy content={'Geparkeerd'} placement="bottom"><PIcon /></Tippy>
                    : iconSwitch(value.row.original.eStatus)
            )
        },
        {
            header: () => <Tippy content={"Memo School"} placement="top"><span>M</span></Tippy>,
            accessorKey: 'm',
            cell: value => (
                homeIconSwitch(value.row.original.eOpmerkingen)
            )
        },
        {
            header: () => <Tippy content={"Memo Vervanger"} placement="top"><span>V</span></Tippy>,
            accessorKey: 'v',
            cell: value => (
                userIconSwitch(value.row.original.eMarkeren)
            )
        },
        {
            header: () => 'Datum',
            accessorKey: 'dAddedByDir',
        },
        {
            header: () => 'Organisatie',
            accessorKey: 'vOrgName',
        },
        {
            header: () => 'School',
            accessorKey: 'vSchoolName',
            cell: value => (
                window.innerWidth > 1250 ?
                    TextLimit.limitStringLargeScreen(value.row.original.vSchoolName)
                    : TextLimit.limitStringSmallScreen(value.row.original.vSchoolName)
            )
        },
        {
            header: () => <Tippy content={"Leerlingaantal"} placement="top"><span>Ll</span></Tippy>,
            accessorKey: 'iLeerlingen',
        },
        {
            header: () => 'Afwezige',
            accessorKey: 'Vervanger',
            cell: (value) => {
                return (
                    window.innerWidth > 1250 ? TextLimit.AfwezigeLimitOptionOne(value.row.original.vFirstName, value.row.original.vMiddleName, value.row.original.vLastName)
                        : TextLimit.AfwezigeLimitOptionTwo(value.row.original.vFirstName, value.row.original.vMiddleName, value.row.original.vLastName)
                )
            }
        },
        {
            header: () => 'Code',
            accessorKey: 'vCode',
            cell: value => (
                <Tippy content={value.row.original.vCodeValue} placement="bottom"><span>{
                    TextLimit.codeLimitOptionOne(value.row.original.vCode)
                }</span></Tippy>
            ),
        },
        {
            header: () => <Tippy content={<>1<sup>e</sup> dag verzoek</>} placement="top"><span>1<sup>e</sup> dag</span></Tippy>,
            accessorKey: 'vSubsDate',
            cell: value => (
                (value.row.original.vSubsDate !== '0000-00-00' && value.row.original.vSubsDate !== null) ? moment(new Date(value.row.original.vSubsDate)).format('DD-MM-YY') : ''
            ),
        },
        {
            header: () => <Tippy content={<>1<sup>e</sup> dag niet ingepland</>} placement="top"><span>1<sup>e</sup> niet</span></Tippy>,
            accessorKey: 'gvSubsDate',
        },
        {
            header: () => 'P-Datum',
            accessorKey: 'dParkdate',
            cell: value => (
                (value.row.original.dParkdate !== '0000-00-00' && value.row.original.dParkdate !== null && new Date(value.row.original.dParkdate) > new Date()) ? moment(value.row.original.dParkdate).format('DD-MM-YY') : ''
            )
        }
    ];

    return (
        <ReactTableGenericForUsers data={props.data} tableClassName="mb-5 table table-sm table-hover tb-req"
            userTablePageSize={props.userTablePageSize}
            totalPageCount={props.totalPageCount}
            pageNumber={props.pageNumber}
            pageSizeCount={props.pageSizeCount}
            showLoader={props.showLoader}
            setPageNumber={props.setPageNumber}
            paginate={props.paginate}
            filterList={props.filterList}
            columns={columns} filters={{ search: true, pagination: false, sorting: true }} rowCount={50}
        />
    );
};
export default Record;
