import React, { Fragment, useState, useEffect } from 'react';
import { Button, Input, Table } from "reactstrap";
import axiosInstance from "Api";
import DefaultButton from "Components/Buttons/DefaultButton";
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import DefaultModal from 'Components/Modal/DefaultModal';
import SweetAlert from 'react-bootstrap-sweetalert';
import AdminService from 'Api/Admin';
import { toast } from 'react-toastify';
import { MdErrorOutline } from 'react-icons/md';
import { FaRegCheckCircle } from 'react-icons/fa';
import TwoFAModal from 'Components/Modal/TwoFAModal';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';



const passwordSchema = Yup.object().shape({
    password: Yup.string()
        .matches("^\\S*$", "Wachtwoord mag geen spaties hebben")
        .required('Wachtwoord is verplicht')
        .min(8, "Wachtwoord moet minimaal 8 tekens lang zijn")
        .matches("[A-Za-z]+", "Gebruik hoofdletters en kleine letters a-z en A-Z tekens")
        .matches("[0-9]+", "Wachtwoord moet cijfers bevatten")
        .matches("[`!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>\\/?~]+", "Wachtwoord moet speciale tekens bevatten !@#$%^&*()_+"),
});

const BSNSchema = Yup.object().shape({
    bsn: Yup.number()
        .required('BSN is verplicht')
});

const BSNValidateRegExp = /[A-Za-z\s`!@#$%^&*()_+\-\=\[\]{};':\"\\\|,\.<>\/\?~]+/;
const specialCharactersRegExp = /[`!@#$%^&*()_+\-\=\[\]{};':\"\\\|,\.<>\/\?~]+/;

const UsersModal = ({ userDetails, reporterId, setReporterId, activeRowData, refetchUserList }) => {
    const navigate = useNavigate();
    // PASSWORD DEALS
    const [generatedPassword, setGeneratedPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [passwordMessage, setPasswordMessage] = useState([]);
    const [showPassword, setShowPassword] = useState(true);
    const [is2FAEnabled, set2FAEnabled] = useState(0);
    const [show2FAModal, setShow2FAModal] = useState(false);
    const [showPassModal, setShowPassModal] = useState(false);
    const [otp, setOtp] = useState('');

    // BSN DEALS
    const [changeBSN, setChangeBSN] = useState(false);
    const [showBSN, setShowBSN] = useState(false);
    const [newBSN, setNewBSN] = useState('');
    const [BSNMessage, setBSNMessage] = useState('');

    // IF PASSWORD/BSN IS SUCCESSFULLY SAVED
    const [isSuccess, setSuccess] = useState(false);

    // 2FA
    const [isShowAlertModalOpen, setIsShowALertModalOpen] = useState(false)
    const useriAdminId = useSelector((state) => state.UserProfile.iAdminId);
    const [buttonPress, setButtonPress] = useState(false);

    const [showImpersonateClickModal, setShowImpersonateClickModal] = useState(false);

    const toggleALertModal = () => {
        setIsShowALertModalOpen(!isShowAlertModalOpen)
    }

    const getCookie = (cookieName) => {
        const cookies = document.cookie.split("; ");
        for (let cookie of cookies) {
            const [name, value] = cookie.split("=");
            if (name === cookieName) {
                return value;
            }
        }
        return null;
    };

    const open2FAModal = () => {
        if (userDetails?.isAuthenticated && userDetails?.enabled) {
            setShow2FAModal(true);
            toggleALertModal();
        } else {
            disable2FA();
        }
    }

    const open2FAModalForPassword = (reporterId) => {
        console.log(userDetails);
        if (userDetails?.isAuthenticated && userDetails?.enabled) {
            setShowPassModal(true);
        } else {
            updatePassword(reporterId);
        }
    }

    const handleOTPSubmit = (for2FAOrpass) => {
        disable2FA();
    }

    const disable2FA = () => {
        const id = activeRowData.iAdminId;
        const rememberToken = getCookie("rememberToken");
        const data = {
            rememberToken: rememberToken,
            ...(!(id === useriAdminId) && { iAdminId: id })
        };

        show2FAModal && setButtonPress(true);

        AdminService.disable2FA(data).then((response) => {
            if (response?.status) {
                toast.success(response.data.message);
                set2FAEnabled(0);
                toggle();
                deleteCookie("rememberToken");
                refetchUserList();
            } else {
                toast.error("Niet in staat om uit te schakelen. Probeer het opnieuw!");
            }
            setIsShowALertModalOpen(false);
        }).catch((error) => {
            console.error(error);
            toast.error("Niet in staat om uit te schakelen. Probeer het opnieuw!");
        }).finally(() => {
            setOtp('');
            show2FAModal && setButtonPress(false);
        });
    }

    const deleteCookie = (cookieName) => {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; secure; samesite=strict`;
    };

    useEffect(() => {
        set2FAEnabled(activeRowData?.enabled)
    }, [activeRowData])

    useEffect(() => {
        setPasswordMessage([])
        setBSNMessage('');
        setSuccess(false);
    }, [newPassword, newBSN, showBSN])

    const toggle = () => {
        setReporterId(null);
        setNewPassword('');
        setNewBSN('');
        setPasswordMessage([]);
        setBSNMessage('');
        setGeneratedPassword('');
        setShowBSN(false);
        setChangeBSN(false);
        setShow2FAModal(false);
    }

    const generator = require('generate-password');
    const generatePassword = () => {
        const password = generator.generate({
            length: 8,
            numbers: true,
            symbols: true,
            lowercase: true,
            uppercase: true,
            strict: true
        });

        setGeneratedPassword(password);
        setNewPassword(password);
    };

    const updatePassword = id => {
        setShowPassModal && setButtonPress(true);
        passwordSchema
            .validate({
                password: newPassword
            })
            .then(valid => {
                if (valid) {
                    axiosInstance.patch(`admins/users/` + id, { newPassword }).then((response) => {
                        if (response.data.status === true) {
                            setSuccess(true);
                            setPasswordMessage(['Wachtwoord is succesvol gewijzigd'])
                        }
                    }).catch(error => console.error(error));
                }
                setShowPassModal(false);
            })
            .catch(error => setPasswordMessage(error.errors)).finally(() => {
                setOtp('');
                setShowPassModal && setButtonPress(false);
            });
    }

    const updateBSN = (oldBSN) => {
        if (newBSN == oldBSN) {
            setBSNMessage("BSN bestaat al");
        } else if (newBSN.length !== 9) {
            setBSNMessage("BSN moet 9 tekens lang zijn");
        } else {
            BSNSchema
                .validate({
                    bsn: newBSN
                })
                .then(valid => {
                    if (valid) {
                        axiosInstance.patch(`admins/users/` + reporterId, { newBSN }).then((response) => {
                            if (response.data.status) {
                                setSuccess(true);
                                setBSNMessage(['BSN succesvol aangepast'])
                            } else {
                                setSuccess(false);
                                setBSNMessage(response.data.message)
                            }
                        }).catch(error => console.error(error));
                    }
                })
                .catch(error => setBSNMessage("Het BSN mag alleen cijfers bevatten"))
        }
    }

    const unblockUser = (isMobile = false) => {
        axiosInstance.patch('admins/' + reporterId, { isMobile: isMobile}).then((response) => {
            console.log(response)
            if (response.data?.status == 200) {
                refetchUserList()
            }
        }).catch(() => console.error);
    }

    const handleImpersonateClick = () => {
        setShowImpersonateClickModal(!showImpersonateClickModal)
    }
    const getStatus = (isBlocked) => {
        return { 
            status: isBlocked,
            text: isBlocked ? "Block" : "Active", 
            className: isBlocked ? "text-danger" : "text-success",
            icon: isBlocked ? <MdErrorOutline color="danger" /> : <FaRegCheckCircle color="success" />
        }
    }

    const blockStatus = getStatus(userDetails?.isBlock ?? false);
    const mobileBlockStatus = getStatus(userDetails?.isMobileBlock ?? false);

    return (
        <DefaultModal modal={reporterId && true} toggle={toggle} showFooterBtns={false} header={'Instellingen'}
            classes="modal-dialog__over feedback-modal-style modal-color" headerColor="modal-header-color"
        >
            <Table borderless className="mb-0">
                <tbody>
                    {userDetails &&
                        <Fragment>
                            <tr>
                                <th className="py-2 table-heading" scope="row">Naam</th>
                                <td className="d-block mx-5 table-data">{userDetails.fullname}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <th className="py-2 table-heading" scope="row">Gebruikersnaam</th>
                                <td className="d-block mx-5 table-data">{userDetails.vUserName}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <th className="py-2 table-heading" scope="row">Nieuw wachbwoord invoeren</th>
                                <td className="d-block mx-5 table-data">
                                    <div className='d-flex justify-content-between input-width'>
                                        <Input
                                            type={showPassword ? "text" : "password"}
                                            className={`${isSuccess ? 'active-border' : ''}`}
                                            value={newPassword}
                                            onChange={e => setNewPassword(e.target.value)}
                                        />
                                        <Button className='input-group-append d-inline' color="primary" onClick={() => setShowPassword(!showPassword)}>
                                            <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                                        </Button>
                                    </div>
                                    {passwordMessage && <p className={`${isSuccess ? 'text-success' : 'text-danger'}`}>{passwordMessage[0]}</p>}
                                </td>
                                <td className="btn-vertical-align">
                                    <div className="d-flex update-gen-btn">
                                        <DefaultButton type="button" onClick={() => open2FAModalForPassword(reporterId)} className="py-1 w-50 ">Toepassen</DefaultButton>
                                        <DefaultButton onClick={generatePassword} className="py-1 w-50 ">Genereer</DefaultButton>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="table-heading" scope="row" style={{ verticalAlign: 'middle' }}>BSN weergeven</th>
                                <td className="d-block mx-5 table-data">
                                    {showBSN && <p className='mb-0'>{userDetails.vSocialSecNumber}</p>}
                                </td>
                                <td className="">
                                    <DefaultButton
                                        onClick={() => setShowBSN(prev => !prev)}
                                        className="py-1 w-100"
                                    >{showBSN ? "Verbergen" : "Weergeven"}
                                    </DefaultButton>
                                </td>
                            </tr>
                            <tr>
                                <th className="table-heading" scope="row" style={{ verticalAlign: 'middle' }}>BSN wijzigen</th>
                                <td className="d-block mx-5 table-data">
                                    {changeBSN && <Input
                                        type="text"
                                        className="form-control"
                                        value={newBSN}
                                        onChange={e => setNewBSN(e.target.value)}
                                    />}
                                    {BSNMessage && <p className={`${isSuccess ? 'text-success mb-0' : 'text-danger mb-0'}`}>{BSNMessage}</p>}
                                </td>
                                <td className="">
                                    <DefaultButton
                                        onClick={() => {
                                            if (changeBSN) {
                                                updateBSN(userDetails.vSocialSecNumber)
                                            } else {
                                                setChangeBSN(true);
                                            }
                                        }}
                                        className="py-1 w-100"
                                    >{
                                            changeBSN ? "Update" : "Wijzigen"
                                        }</DefaultButton>
                                </td>
                            </tr>
                            <tr>
                                <th className="table-heading" scope="row" style={{ verticalAlign: 'middle' }}>Twee-Factor Authenticatie</th>

                                <td className="d-block mx-5" style={{ width: "100%" }}>
                                    <DefaultButton type="button" className='py-1 w-100 ' onClick={() => {
                                        if (userDetails?.isAuthenticated && !is2FAEnabled) {
                                            navigate('/profiel', { state: { from: 'header-2fa' } });
                                        } else if (!is2FAEnabled) {
                                            handleImpersonateClick();
                                        } else {
                                            toggleALertModal();
                                        }
                                    }}
                                    >
                                        {is2FAEnabled ? 'Twee-factor Authentication Uitschakelen' : 'Twee-factor Authentication Inschakelen'}
                                    </DefaultButton>
                                </td>
                            </tr>
                            <tr>
                                <th className="py-2 table-heading" scope="row">User lockout status web</th>
                                <th className='d-block mx-5'>
                                    <p className={`d-flex align-items-center gap-2 ${blockStatus.className}`}>
                                        {blockStatus.icon} {blockStatus.text}
                                    </p>
                                </th>
                                <th className="">
                                    <DefaultButton
                                        onClick={() => unblockUser()}
                                        className="py-1 w-100"
                                        disabled={!blockStatus.status}
                                    >
                                        Unblock
                                    </DefaultButton>
                                </th>
                            </tr>
                            <tr>
                                <th className="py-2 table-heading" scope="row">User lockout status mobiel</th>
                                <th className='d-block mx-5'>
                                    <p className={`d-flex align-items-center gap-2 ${mobileBlockStatus.className}`}>
                                        {mobileBlockStatus.icon} {mobileBlockStatus.text}
                                    </p>
                                </th>
                                <th className="">
                                    <DefaultButton
                                        onClick={() => unblockUser(true)}
                                        className="py-1 w-100"
                                        disabled={!mobileBlockStatus.status}
                                    >
                                        Unblock
                                    </DefaultButton>
                                </th>
                            </tr>
                        </Fragment>
                    }
                </tbody>
            </Table>

            <SweetAlert title="Weet je zeker dat je 2FA wilt uitschakelen?" show={isShowAlertModalOpen}
                type="warning"
                onCancel={toggleALertModal}
                onConfirm={open2FAModal}
                cancelBtnText="Nee"
                confirmBtnText="Ja"
                showCancel={true}
            />

            <SweetAlert title="Alleen ingelogde gebruikers kunnen de 2fa inschakelen." show={showImpersonateClickModal}
                type="warning"
                onConfirm={() => setShowImpersonateClickModal(!showImpersonateClickModal)}
                confirmBtnText={"OK"} />

            <TwoFAModal buttonPress={buttonPress} setShow2FAModal={setShow2FAModal} showRememberMe={false} showOTPModal={show2FAModal} handleOTPSubmit={handleOTPSubmit} otp={otp} setOtp={setOtp} />
            <TwoFAModal buttonPress={buttonPress} setShow2FAModal={setShowPassModal} showRememberMe={false} showOTPModal={showPassModal} handleOTPSubmit={() => updatePassword(reporterId)} otp={otp} setOtp={setOtp} />
        </DefaultModal>
    )
}

export default UsersModal;