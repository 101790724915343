import React from 'react'
import { Loader } from 'react-bootstrap-typeahead';
import { Card, CardBody} from 'reactstrap';
import VrijeVeldenForm from './VrijeVeldenForm';
import SalarisId from './SalarisId';

const VrijeVelden = ({ isLoading, data, setFormData,}) => {


  const handleSetFormData = (fieldName, value, setFormData) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: value
    }));
  };

  return (
    <>
      {
        isLoading ?
          <Card className="main-card">
            <CardBody className="d-flex flex-column">
              <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
            </CardBody>
          </Card> :
          <>
            <div className='row algemeen mb-4'>
              <div className='col-xl-6 mb-4'>
                <VrijeVeldenForm data={data} setFormData={setFormData} handleSetFormData={handleSetFormData} />
              </div>
              <div className='col-xl-6 mb-4'>
                <SalarisId data={data} setFormData={setFormData} handleSetFormData={handleSetFormData} />
              </div>
            </div>
          </>
      }
    </>
  )
}

export default VrijeVelden