import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import React from "react";


const DefaultModal = ({modal, toggle, select, children,handleSubmit, classes, headerColor, showFooterBtns = true, header = 'Selecteer hieronder de gewenste oplossing:'}, ...props)=> {
    return (
        <Modal isOpen={modal} toggle={toggle} className={classes} style={{borderRadius: '40px !important'}}>
            <ModalHeader toggle={toggle} className={headerColor}>{header}</ModalHeader>

            <ModalBody>
                {children}
            </ModalBody>
            <ModalFooter>
                {showFooterBtns &&
                    <>
                        <Button color="link" onClick={toggle} className={"btn-color-style"}>
                            Scherm sluiten
                        </Button>
                        <Button color="secondary" onClick={handleSubmit} className={"btn-color-style"}>
                            Toepassen
                        </Button>
                    </>
                }
            </ModalFooter>
        </Modal>
    )
}

export default DefaultModal;
