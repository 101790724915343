import axiosInstance from 'Api';

const fetchCodesData = (id) => {
    return axiosInstance.get(`instellingen/codes?communityId=${id}`)
};

const saveCodeApi = (data) => {
    return axiosInstance.post(`instellingen/code/save`, data)
};

const fetchCostData = (id, type) => {
    return axiosInstance.get(`instellingen/${type}?communityId=${id}`);
};

const ReplacementCodeService = {
    fetchCodesData,
    saveCodeApi,
    fetchCostData
};
export default ReplacementCodeService;