import { Fragment, useEffect, useMemo, useState } from 'react'
import { Button, Card, CardBody, CardFooter, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Loader from 'react-loaders'
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PageHeading from 'Layout/AppMain/PageHeading';
import axiosInstance from 'Api';
import Algemeen from './Forms/Algemeen';
import Terugkoppeling from './Forms/Terugkoppeling';
import KolommenPlanscherm from './Forms/KolommenPlanscherm';
import GebruikerViewService from 'Api/Gebruikers/GebruikersView';
import PlanningService from 'Api/Instellingen/Planning';
import { debounce } from 'helperFunctions/index';
import { toast } from 'react-toastify';

const Planning = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorField, setErrorField] = useState({});
    const [communityId, setCommunityId] = useState('');
    const [communityList, setCommunityList] = useState([]);
    const [planning, setPlanning] = useState([]);
    const [standaardContracttype, setStandaardContracttype] = useState([]);

    useEffect(() => {
        getOrganizationsList();
    }, []);

    useEffect(() => {
        if(communityList) {setCommunityId(communityList[0]?.iCommunityId);}
    }, [communityList]);

    useEffect(() => {
        setIsLoading(true);
        if (communityId) {
            fetchStandaardContracttype();
            fetchPlanning();
        }
    }, [communityId])

    let customContractTypeList;

    if(standaardContracttype) {
        customContractTypeList = standaardContracttype.map(item => ({
            ...item,
            label: `${item.vContractTypeAbbr} - ${item.vContractTypeName}`
        }));
        
    }

    const getOrganizationsList = () => {
        axiosInstance.get('organizations-list').then((response) => {
            if (response.status == 200) {
                if (response.data.data != null && response.data.data != undefined) {
                    let communityData = response.data.data;
                    setCommunityList(communityData);
                }
            }
        }).catch((error) => console.log("catch error:", error));
    }

    const fetchPlanning = (setStatusIsFalse = null) => {
        PlanningService.fetchPlanning(communityId).then((response) => {
            if (response?.data?.status === true) {
                setPlanning(response.data.data);
                setIsLoading(false)
                if(setStatusIsFalse) {setStatusIsFalse(false)}
            } else {
                setPlanning([]);
            }
        }).catch((error) => {console.log("catch error:", error)
            
        });
    }

    const fetchStandaardContracttype = () => {
        GebruikerViewService.contractType(communityId).then((response) => {
            if (response?.data?.status === 200) {
                setStandaardContracttype(response.data.data);
            } else {
                setStandaardContracttype([]);
            }
        }).catch((error) => console.log("catch error:", error)).finally(() => setIsLoading(false));
    }

    const callApi = (field, value, setFieldError, setLoadingKeys, iPlanId = null) => {
        const data = {
            iCommunityId: communityId,
            ...(field ? { attr: field } : {}),
            [field]: value,
            ...(iPlanId > 0 ? { iPlanId: iPlanId } : {})
        }

        PlanningService.saveOrUpdate(data)
            .then(response => {
                if (response.data.status) {
                    setPlanning(prev => ({
                        ...prev,
                        ...response?.data?.data
                    }));
                    setErrorField(null);
                }

                if (!response.data.status) {
                    setErrorField(field);
                    const errors = response.data.data[field];
                    if( setFieldError ) {
                        setFieldError(field, "required");
                    }

                    if (Array.isArray(errors)) {
                        toast.error(errors.join(','));
                    } else {
                        toast.error(errors);
                    }
                }
            })
            .catch(error => {
                console.error('API error:', error);
            }).finally(() => {
                if (setLoadingKeys) setLoadingKeys(prev => ({ ...prev, [field]: false }));
            });
    };

    const debouncedApiCall = useMemo(() => debounce(callApi, 1000), [communityId]);
 
    const handleFieldChange = (field, value, setFieldError = null) => {
        setErrorField('');
        debouncedApiCall(field, value, setFieldError);
    };

    const handleIconFieldChange = (field, value, setLoadingKeys, iPlanId) => {
        setErrorField('');
        callApi(field, value, null, setLoadingKeys, iPlanId);
    };

    const orgName = useMemo(() => {
        return communityList?.find(item => item.iCommunityId === Number(communityId))?.name || ""
    }, [communityId, communityList]);

    return (
        <Fragment>
            <PageHeading orgName={orgName} />
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    {
                        isLoading ?
                            <Card className="main-card">
                                <CardBody className="d-flex flex-column">
                                    <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
                                </CardBody>
                            </Card> : <>
                                {planning && <div className='row contract-typen-view mb-5'>
                                    <div className='col-xl-6 p-2'>
                                        <Algemeen planning={planning} changeHandler={handleFieldChange} handleIconFieldChange={handleIconFieldChange} customContractTypeList={customContractTypeList} errorField={errorField}/>
                                    </div>
                                    <div className='col-xl-6 p-2'>
                                        <Terugkoppeling planning={planning} changeHandler={handleFieldChange} handleIconFieldChange={handleIconFieldChange} customContractTypeList={customContractTypeList} communityId={communityId} fetchPlanning={fetchPlanning}/>
                                    </div>
                                    <div className="p-2">
                                        <KolommenPlanscherm planning={planning} handleIconFieldChange={handleIconFieldChange}/>
                                    </div>
                                </div>
                                } 
                               
                                <div className="chat-layout-footer py-5">
                                    <CardFooter className="chat-layout-footer__inner gebruikers-footer">
                                        <div className="d-flex gap-3">
                                            <div>
                                                <select className={`form-select`} value={communityId} onChange={(e) => { setCommunityId(e.target.value) }}>
                                                    <option value={''} disabled>Alle besturen</option>
                                                    {communityList?.map(item => {
                                                        return <option key={item.iCommunityId} value={item.iCommunityId}>{item.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </CardFooter>
                                </div>
                            </>
                    }
                </CSSTransition>
            </TransitionGroup>

        </Fragment>
    )
}

export default Planning