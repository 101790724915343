import { Form, Formik } from "formik";
import { Card, CardBody, FormGroup, Input, Label } from 'reactstrap';

const ContactDetails = ({ data, changeHandler, errorField }) => {
    const defaultValues = {
        vSchoolName: data?.detailList?.vSchoolName ?? '',
        vVisitoraddress: data?.detailList?.vVisitoraddress ?? '',
        vVisitpostcode: data?.detailList?.vVisitpostcode ?? '',
        vVisitcity: data?.detailList?.vVisitcity ?? '',
        vVisitorphone: data?.detailList?.vVisitorphone ?? '',
        vBrinnummer: data?.detailList?.vBrinnummer ?? '',
        vWebsite: data?.detailList?.vWebsite ?? '',
    };
    
    return (
        <Card className="main-card">
            <CardBody className="d-flex flex-column">
                <h5 className="mb-4">Contactgegevens</h5>
                <Formik
                    enableReinitialize
                    initialValues={defaultValues}
                >
                    {({ values, handleChange }) => (
                        <Form>
                            <FormGroup className={`form-element ${errorField === 'vSchoolName' ? 'error' : ''}`}>
                                <Label for="vSchoolName">Schoolnaam</Label>
                                <Input
                                    name="vSchoolName"
                                    type="text"
                                    value={values.vSchoolName}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vSchoolName', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vVisitoraddress' ? 'error' : ''}`}>
                                <Label for="vVisitoraddress">Adres</Label>
                                <Input
                                    name="vVisitoraddress"
                                    type="text"
                                    value={values.vVisitoraddress}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vVisitoraddress', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vVisitpostcode' ? 'error' : ''}`}>
                                <Label for="vVisitpostcode">Postcode</Label>
                                <Input
                                    name="vVisitpostcode"
                                    type="text"
                                    value={values.vVisitpostcode}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vVisitpostcode', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vVisitcity' ? 'error' : ''}`}>
                                <Label for="vVisitcity">Plaats</Label>
                                <Input
                                    name="vVisitcity"
                                    type="text"
                                    value={values.vVisitcity}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vVisitcity', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vVisitorphone' ? 'error' : ''}`}>
                                <Label for="vVisitorphone">Telefoon</Label>
                                <Input
                                    name="vVisitorphone"
                                    type="text"
                                    value={values.vVisitorphone}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vVisitorphone', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vBrinnummer' ? 'error' : ''}`}>
                                <Label for="vBrinnummer">Brinnummer</Label>
                                <Input
                                    name="vBrinnummer"
                                    type="text"
                                    value={values.vBrinnummer}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vBrinnummer', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vWebsite' ? 'error' : ''}`}>
                                <Label for="vWebsite">Webpagina</Label>
                                <Input
                                    name="vWebsite"
                                    type="text"
                                    value={values.vWebsite}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vWebsite', e.target.value);
                                    }}
                                />
                            </FormGroup>
                        </Form>
                    )}
                </Formik>
            </CardBody>
        </Card>
    );
};

export default ContactDetails;
