import React, { Fragment, useEffect, useMemo, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Button, Card, CardBody, CardFooter, Col, Row } from "reactstrap";
import PageHeading from "Layout/AppMain/PageHeading";
import Loader from "react-loaders";
import { ReactComponent as AddIcon } from "../../../../assets/utils/images/svgs/addBlue.svg";
import ContractTypenTable from "Pages/Coordinator/Instellingen/ContractTypen/ContractTypenTable";
import { useNavigate } from "react-router";
import axiosInstance from "Api";
import ContractTypenService from "Api/Instellingen/ContractTypen";

const Contracttypen = () => {
    const navigate = useNavigate();
    const [communityId, setCommunityId] = useState('');
    const [communityList, setCommunityList] = useState([]);
    const [contractList, setContractList] = useState([]);
    const [isLoading, setLoading] = useState(false);


    useEffect(() => {
        setLoading(true);
        getCommunityList();
    }, []);

    useEffect(() => {
        communityId && fetchContracts();
    }, [communityId]);

    const getCommunityList = () => {
        axiosInstance.get('organizations-list').then((response) => {
            if (response.status === 200) {
                if (response.data.data != null && response.data.data != undefined) {
                    let organizationsData = response.data.data;
                    setCommunityList(organizationsData);
                    setCommunityId(organizationsData[0]?.iCommunityId);
                }
            }
        }).catch((error) => console.log("catch error:", error));
    }

    const fetchContracts = () => {
        ContractTypenService.fetchContracts(communityId).then((response) => {
            if (response?.data?.status === true) {
                setContractList(response.data.data);
            } else {
                setContractList([]);
            }
        }).catch((error) => console.log("catch error:", error)).finally(() => setLoading(false));
    }

    const handleSave = () => {
        const data = { iCommunityId: communityId }
        ContractTypenService.saveContract(data).then((response) => {
            if (response?.data?.status === true) {
                navigate(`/c-instellingen/contracttypen/wijzigen-toevoegen/${response?.data?.contractId}`, {state: {contractId: response?.data?.contractId, communityId: communityId}});
            }
        }).catch((error) => console.log("catch error:", error))
    }

     const orgName = useMemo(()=>{
            return communityList?.find(item => item.iCommunityId === Number(communityId))?.name || ""
        },[communityId, communityList]);

    return (
        <Fragment>
            <PageHeading orgName={orgName} />
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    <Row className="mb-5">
                        <Col>
                            <Card className="main-card mb-3">
                                <CardBody className="p-3">
                                    {isLoading ?
                                        <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
                                        :
                                        <ContractTypenTable contractList={contractList} communityId={communityId} />
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </CSSTransition>
            </TransitionGroup>
            <div className="chat-layout-footer verzoken-footer-height">
                <CardFooter className="chat-layout-footer__inner">
                    <div className="d-flex gap-3 mr-4">
                        <div>
                            <select className={`form-select`} value={communityId} onChange={(e) => { setCommunityId(Number(e.target.value)) }}>
                                {communityList?.map(item => {
                                    return <option key={item.iCommunityId} value={item.iCommunityId}>{item.name}</option>
                                })}
                            </select>
                        </div>
                        <Button
                            outline
                            className="btn-outline-primary-blue btn-color-style py-2 px-3 d-flex gap-2 align-items-center"
                            type="button"
                            color="primary"
                            onClick={() => {
                                handleSave()
                            }}
                        >
                            Contracttype <AddIcon />
                        </Button>
                    </div>
                </CardFooter>
            </div>
        </Fragment>
    )
}

export default Contracttypen