import React, {Fragment, useEffect, useState} from "react";
import Loader from "react-loaders";
import moment from "moment";
import axiosInstance from "Api";
import { BsCheckCircle, BsXCircle, BsQuestionCircle, BsArrowRepeat } from "react-icons/bs";
import { useAvailabilityContext } from "./context/AvailabilityContext";

const DatesLayout = ({ iAdminId="" }) => {
    const { years, type, setCommunities } = useAvailabilityContext();
    const [data, setData] = useState([]);
    const [generalData, setGeneralData] = useState([]);
    const [dates, setDates] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [error, setError] = useState('')

    const weekDays = ["Ma", "Di", "Wo", "Do", 'Vr'];
    const shifts = weekDays.flatMap(day => [day+"O", day+"M"]);

    let yearsArray = years.split("-");

    const getFirstDatesOfWeeks = () => {
        const startYear = yearsArray[0];
        const endYear = yearsArray[1];

        const firstDayOfSchoolYear = moment(`${startYear}-08-01`); // Aug 1
        const lastDayOfSchoolYear = moment(`${endYear}-07-31`); // July 31
        
        let firstWorkingDay = firstDayOfSchoolYear.clone();
        let lastWorkingDay = lastDayOfSchoolYear.clone();

        if (firstDayOfSchoolYear.day() === 0) { // Sunday
            firstWorkingDay.add(1, 'days');
        } else if (firstDayOfSchoolYear.day() === 6) { // Saturday
            firstWorkingDay.add(2, 'days');
        }

        const startWeek = firstWorkingDay.isoWeek();

        if (lastWorkingDay.isoWeekday() <= 4) { // if the last working day is from Monday to Thursday it will be displayed in the next school year
            lastWorkingDay.subtract(7, 'days');
        }
        const endWeek = lastWorkingDay.isoWeek();

        const result = [];

        let currentDate = moment().year(startYear).isoWeek(startWeek).startOf('isoWeek');

        const endDate = moment().year(endYear).isoWeek(endWeek).endOf('isoWeek');
        
        while (currentDate.isBefore(endDate)) {
            result.push(currentDate.format('YYYY-MM-DD'));
            currentDate.add(1, 'week');
        }

        return result;
    }

    const createEmptyYearTable = (firstDates) => {
        return firstDates.flatMap((date) =>
            shifts.map((shift) => ({
                shift,
                date,
                available: null,
            }))
        );
    }

    const generateTableData = (comm, firstDates) => {
        let yearTable = createEmptyYearTable(firstDates);

        let result = [];
        if (comm.length) {
            for (let i = 0; i < comm.length; i++) {
                let resultData = yearTable.map(item => ({ ...item }));

                resultData = resultData.map(item => {
                    const matchedData = comm[i].dates.find((data) => {
                        return (
                            item.date === data.date &&
                            item.shift === data.shift.week + data.shift.type
                        );
                    });

                    if (matchedData) {
                        item.available = matchedData.available;
                    }

                    let shiftIndex = shifts.indexOf(item.shift);
                    item.locked = comm[i].locked.split("")[shiftIndex] != 0;

                    return item;
                });

                result.push(resultData);
            }
        }

        setGeneralData(comm.length ? result : yearTable);
    }

    useEffect(() => {
        setSpinner(true);

        const firstDatesOfWeeks = getFirstDatesOfWeeks();
        setDates(firstDatesOfWeeks);
        const url = `${iAdminId  ? `gebruiker/` : ''}availability?years[]=${yearsArray[0]}&years[]=${yearsArray[1]}${
            iAdminId  ? `&iAdminId=${iAdminId}` : ''
        }`;
        axiosInstance.get(url).then((response) => {
            if (response.data.status === "success") {
                const responseData = response.data.data;
                const communities = responseData.communities;
                if (!responseData.data || responseData.data.length === 0 && !communities || communities.length === 0) {
                    setError("User doesn't belong to any community")
                }
                generateTableData(responseData.data, firstDatesOfWeeks);
                setData(responseData.data);

                setCommunities(communities);
            }
        })
        .finally(() => setSpinner(false))
        .catch(() => console.error);
    }, [years, type]);

    const updateTableData = (data, communityIndex) => {
        const result = [...generalData];

        result[communityIndex].map((item) => {
            if (data.dates.includes(item.date)
                && (typeof data.shift == 'object' && data.shift.includes(item.shift)
                || item.shift === data.shift)
            ) {
                item.available = data.available 
            }
        });

        setGeneralData(result);
    };

    const getAvailability = (filtered) => {
        let availIsSame = filtered.every(item => item.available === filtered[0].available);

        if (availIsSame) {
            return filtered[0].available === null ? false : (filtered[0].available === false ? true : null);
        } else {
            return true;
        }
    }

    const handleSubmit = (community, communityIndex, dataToUpdate = null, shift = null, weekNumber = null) => {
        let dataToSend;

        if (dataToUpdate) {
            dataToSend = {
                communityId: community.communityId,
                shift: dataToUpdate.shift,
                order: shifts.indexOf(dataToUpdate.shift) + 1,
                dates: [dataToUpdate.date],
                available: dataToUpdate.available === null ? false : (dataToUpdate.available === false ? true : null)
            };
        } else {
            let filtered = generalData[communityIndex].filter(item => 
                !item.locked && ( shift ? item.shift === shift : item.date === dates[weekNumber] ));

            if (!filtered.length) return false;

            let order = shift ? shifts.indexOf(shift) + 1 : filtered.map(item => shifts.indexOf(item.shift) + 1);
            let date = shift ? dates : [dates[weekNumber]];
            shift = shift ?? filtered.map(item => item.shift);

            dataToSend = {
                communityId: community.communityId,
                shift,
                order: order,
                dates: date,
                available: getAvailability(filtered)
            };
        }
        if(iAdminId){
            dataToSend.iAdminId = iAdminId
        }
        const url = `${iAdminId  ? `gebruiker/` : ''}availability`;
        axiosInstance.post(url, dataToSend).then((response) => {
            if (response.data.status === "success") {
                updateTableData(dataToSend, communityIndex);
            }
        })
        .catch(() => console.error);
    }

    return (
        <Fragment>
            <table className="table table-bordered mt-4 ">
                <thead>
                    <tr className="border-bottom-0">
                        <th scope="col" className="border-end-0 w-30"></th>
                        <th scope="col" className="border-0"></th>
                        { dates.map((date, dateId) => <th key={dateId} className="p-0 availability-dates-header writing-mode" scope="col"><span>{moment(date).format('DD-MM')}</span></th> ) }
                    </tr>
                    <tr>
                        <th className="p-0 px-1 availability-week" colSpan={2}>Week</th>
                        { dates.map((date, dateId) => <th key={dateId} className="p-0 text-center fw-normal" scope="col">{moment(date).isoWeek()}</th> ) }
                    </tr>
                </thead>
                <tbody>
                    {spinner ?
                        <tr>
                            <td colSpan={getFirstDatesOfWeeks().length+2} align='center'>
                                <Loader className="mx-auto w-30" type="ball-grid-pulse"/>
                            </td>
                        </tr>
                        :
                        data && data.map((comm, commId) =>
                            <Fragment key={commId}>
                            <tr className="availability-community">
                                <th className="p-1 border-end-0" colSpan={2+dates.length}>{comm.communityName}</th>
                            </tr>
                            <tr>
                                <td colSpan={2} className="px-0"> </td>

                            {
                                Array.from(Array(getFirstDatesOfWeeks().length)).map((x, id) =>
                                    <td onClick={() => handleSubmit(comm, commId, null, null, id)} key={id} id={id} className="p-0 text-center"><BsArrowRepeat color="#B4B4B4" fontSize={"16px"} className="cursor-pointer" /></td>
                                )
                            }
                            </tr>

                            {generalData && generalData[commId] &&
                                shifts.map((shift, idx) => 
                                    <tr key={idx}>
                                        <td className="p-0 py-1 text-center" style={{fontSize:"11px"}}>{shift}</td>
                                        <td onClick={() => handleSubmit(comm, commId, null, shift)} className="p-0 text-center"><BsArrowRepeat color="#B4B4B4" fontSize={"16px"} className="cursor-pointer"/></td>
                                        {
                                            generalData[commId].map((item, id) => item.shift === shift &&
                                                <td key={id} onClick={() => !item.locked && handleSubmit(comm, commId, item) } className="p-0 text-center cursor-pointer">
                                                    {
                                                        item.available === null ? <BsQuestionCircle color={item.locked ? '#878EA6' : '#FFA500'} fontSize={"15px"}/> :
                                                        item.available ?
                                                            <BsCheckCircle color={item.locked ? '#878EA6' : '#3AC47D'} fontSize={"16px"}/>:
                                                            <BsXCircle color={item.locked ? '#878EA6' : '#FB2E5F'} fontSize={"16px"}/>                                                        
                                                    }
                                                </td>)
                                        }
                                    </tr>
                                )
                            }
                            </Fragment>
                        )
                    }
                    
                </tbody>
            </table>
            {
                !spinner && error !== '' && <div className="text-danger">{error}</div>
            }
        </Fragment>
    )
};

export default DatesLayout;
