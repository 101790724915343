import React, { Fragment, useEffect, useMemo, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Button, Card, CardBody, CardFooter, Col, Row } from "reactstrap";
import PageHeading from "Layout/AppMain/PageHeading";
import ReactTableGeneric from "Components/ReactTableGeneric";
import Loader from "react-loaders";
import { BsCheckCircle } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import { ReactComponent as CrossIcon } from "../../../../../assets/utils/images/svgs/Cross.svg";
import { useNavigate } from "react-router";
import CoordinatorInstellingenService from "Api/Instellingen";
import ScholenService from "Api/Instellingen/Scholen";
import { ReactComponent as ExportIcon } from "../../../../../assets/utils/images/svgs/Export.svg";

const SchoolsOverview = () => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [communityId, setCommunityId] = useState('');
    const [communityList, setCommunityList] = useState([]);
    const [schoolsData, setSchoolsData] = useState();

    const orgName = useMemo(()=>{
        return communityList?.find(item => item.iCommunityId === Number(communityId))?.name || ""
    },[communityId, communityList]);

    useEffect(() => {
        setLoading(true);
        fetchCommunityList();
    }, []);

    const fetchCommunityList = () => {
        CoordinatorInstellingenService.communityList().then((response) => {
            if (response.status) {
                if (response.data.communityList?.length > 0) {
                    setCommunityList(response.data.communityList);
                    setCommunityId(response.data.communityList[0]?.iCommunityId);
                }
            }
        }).catch(() => console.error);
    };

    useEffect(() => {
        if (!communityId) return;
        fetchSchoolsDataHandler();
    }, [communityId]);

    const fetchSchoolsDataHandler = () => {
        setLoading(true);
        ScholenService.getScholenData(communityId).then((response) => {
            if (response.status) {
                setSchoolsData(response.data.data);
            }
            setLoading(false);
        }).catch(() => console.error);
    };

    const columns = React.useMemo(
        () => [
            {
                header: () => 'School',
                accessorKey: 'vSchoolName',
                cell: (value)=>{
                    return <div onClick={() => navigate(`${orgName}/${value.row.original.iSchoolId}`, { state: { communityId } })} className="cursor-pointer">{value.row.original.vSchoolName}</div>
                }
            },
            {
                header: () => 'BRIN',
                accessorKey: 'vBrinnummer',
                cell: (value)=>{
                    return <div onClick={() => navigate(`${orgName}/${value.row.original.iSchoolId}`, { state: { communityId } })} className="cursor-pointer">{value.row.original.vBrinnummer}</div>
                }
            },
            {
                header: () => 'Plaats1',
                accessorKey: 'vVisitcity',
                cell: (value)=>{
                    return <div onClick={() => navigate(`${orgName}/${value.row.original.iSchoolId}`, { state: { communityId } })} className="cursor-pointer">{value.row.original.vVisitcity}</div>
                }
            },
            {
                header: () => 'Status',
                accessorKey: 'eStatus',
                cell: (value) => {
                    return <div className="d-flex justify-content-center">
                        {
                            value.row.original.eStatus === "Active" ?
                                <BsCheckCircle color='#39A825' fontSize={"18px"} /> :
                                <CrossIcon color='#FB2E5F' />
                        }
                    </div>
                },
            }],
        [communityId]
    );

    return (
        <Fragment>
            <PageHeading orgName={orgName}/>
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    <Row className="mb-5">
                        <Col>
                            <Card className="main-card mb-3">
                                <CardBody className="p-3">
                                    {isLoading ?
                                        <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} /> :
                                        <ReactTableGeneric
                                            data={schoolsData || []}
                                            columns={columns}
                                            tableClassName="mb-0 table table-borderless table-sm table-hover tb-codes"
                                            rowCount={20}
                                            filters={{ search: false, pagination: true, sorting: false }}
                                        />
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </CSSTransition>
            </TransitionGroup>
            <div className="chat-layout-footer verzoken-footer-height">
                <CardFooter className="chat-layout-footer__inner">
                    <div className="d-flex gap-3 mr-4">
                        <div>
                            <select className={`form-select`} value={communityId} onChange={(e) => { setCommunityId(e.target.value) }}>
                                <option value={''} disabled>Alle besturen</option>
                                {communityList?.map(item => {
                                    return <option key={item.iCommunityId} value={item.iCommunityId}>{item.name}</option>
                                })}
                            </select>
                        </div>
                        <Button
                            outline
                            className="btn-outline-primary-blue btn-color-style py-2 px-3 d-flex gap-2 align-items-center"
                          
                            type="button"
                            color="primary"
                        >
                            <ExportIcon/> Export Excel 
                        </Button>
                    </div>
                </CardFooter>
            </div>
        </Fragment>
    );
};

export default SchoolsOverview;
