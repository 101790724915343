import React from "react";
import { Form, Formik } from "formik";
import { Card, CardBody, FormGroup, Input, Label } from 'reactstrap'
import 'tippy.js/themes/light.css';

const VerlofVerzuimForm = ({data, handleFieldChange}) => {

    const defaultValue = {
        vVerlofmeldingLink: data?.vVerlofmeldingLink || '',
        vVerzuimmeldingLink: data?.vVerzuimmeldingLink || ''
    }

    return (
        <>
            <Card className="main-card">
                <CardBody className="d-flex flex-column mb-4">
                    <div className="btn-color-style travelCost-blue-text mb-4">Verlof / Verzuim</div>

                    <Formik
                        initialValues={defaultValue}
                        validateOnChange={true}
                    >
                        {({ values, touched, errors, handleChange, setFieldError }) => (
                            <>
                                <Form>
                                    <FormGroup className={`form-element ${(errors.vVerlofmeldingLink) ? 'error' : ''}`}>
                                        <Label for="vVerlofmeldingLink">Link verlofmelding</Label>
                                        <Input
                                            name="vVerlofmeldingLink"
                                            type="text"
                                            value={values.vVerlofmeldingLink}
                                            onChange={(e) => {
                                                handleChange(e);
                                                handleFieldChange("vVerlofmeldingLink", e.target.value, setFieldError, "Website");
                                            }}
                                        />
                                    </FormGroup>
                                </Form>
                                <Form>
                                    <FormGroup className={`form-element ${(errors.vVerzuimmeldingLink) ? 'error' : ''}`}>
                                        <Label for="vVerzuimmeldingLink">Link verzuimmelding</Label>
                                        <Input
                                            name="vVerzuimmeldingLink"
                                            type="text"
                                            value={values.vVerzuimmeldingLink}
                                            onChange={(e) => {
                                                handleChange(e);
                                                handleFieldChange("vVerzuimmeldingLink", e.target.value, setFieldError, "Website");
                                            }}
                                        />
                                    </FormGroup>
                                </Form>
                            </>
                        )}
                    </Formik>
                </CardBody>
            </Card>
        </>
    )
}

export default VerlofVerzuimForm