import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Card, CardBody, Col, CardFooter, Row } from "reactstrap";
import PageHeading from "Layout/AppMain/PageHeading";
import ReactTableGeneric from "Components/ReactTableGeneric";
import AdminService from "Api/Admin";
import Loader from "react-loaders";

const Sms = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [selectedYear, setSelectedYear] = useState('');
    const [years, setYears] = useState([]);
    const [smsData, setSmsData] = useState([]);

    useEffect(() => {
        fetchSmsYears();
    }, []);

    useEffect(() => {
        if (selectedYear) {
            fetchSmsData(selectedYear);
        }
    }, [selectedYear]);

    const fetchSmsData = async (year) => {
        try {
            setIsLoading(true);
            const response = await AdminService.smsStatistics(year);
            if (response.status) {
                setSmsData(response.data.data || []);
            }
        } catch (error) {
            console.error('Error fetching SMS data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchSmsYears = async () => {
        try {
            const response = await AdminService.smsYears();
            if (response.status) {
                const fetchedYears = response.data?.data?.years || [];
                setYears(fetchedYears);
                if (fetchedYears.length > 0) {
                    setSelectedYear(fetchedYears[0]);
                }
            }
        } catch (error) {
            console.error('Error fetching SMS years:', error);
        }
    };

    const columns = React.useMemo(
        () => [
            {
                header: () => 'Bestuur',
                accessorKey: 'vCommunityName'
            },
            {
                header: () => 'Totaal aantal SMS verzonden',
                accessorKey: 'cntSended'
            },
            {
                header: () => 'Totaal aantal credits',
                accessorKey: 'cntCredits'
            },
        ],
        []
    );

    return (
        <Fragment>
            <PageHeading />
            <TransitionGroup>
                <CSSTransition
                    component="div"
                    classNames="TabsAnimation"
                    appear={true}
                    timeout={1500}
                    enter={false}
                    exit={false}
                >
                    <Row>
                        <Col md="12">
                            <Card className="main-card mb-3">
                                <CardBody className="p-3">
                                    {isLoading ? (
                                        <Loader
                                            type="ball-grid-pulse"
                                            className={'mt-3 d-flex mx-auto justify-content-center'}
                                        />
                                    ) : (
                                        <ReactTableGeneric
                                            data={smsData} 
                                            columns={columns}
                                            tableClassName="mb-0 table table-borderless table-sm table-hover tb-links"
                                            filters={{ search: true, pagination: false, sorting: false }}
                                        />
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </CSSTransition>
            </TransitionGroup>
            <div className="chat-layout-footer verzoken-footer-height">
                <CardFooter className="chat-layout-footer__inner">
                    <select
                        className="form-select w-auto"
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}
                    >
                        <option value="" disabled>
                            Jaar
                        </option>
                        {years?.map((item) => (
                            <option key={item} value={item}>
                                {item}
                            </option>
                        ))}
                    </select>
                </CardFooter>
            </div>
        </Fragment>
    );
};
export default Sms;

