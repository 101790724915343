import React, { useState, useEffect } from 'react';
import ReactTableGenericForUsers from "Components/ReactTableGenericForUsers";
import 'react-datepicker/dist/react-datepicker.css';
import 'tippy.js/dist/tippy.css';
import axiosInstance from "Api";
import Auth from "services/Auth";

import {useNavigate} from "react-router-dom"; // optional
import { BsArrowRightCircle, BsBagDash } from 'react-icons/bs';
import UsersModal from './UsersModal';
import AuthService from 'Api/Auth';
import { useDispatch } from 'react-redux';
import { setUserRole } from 'reducers/UserPermission';
import { toast } from "react-toastify";
import { setUserProfilePicture } from "reducers/UserProfile";
import Tippy from "@tippyjs/react";
import TextLimit from 'Api/TextLimit';
import { setSubMenuClass } from 'reducers/ThemeOptions';

const UsersTable = () => {
    // This is a custom filter UI for selecting
    // a unique option from a list
    const [reporterId, setReporterId] = useState(null);
    const [data, setData] = useState([]);
    const [sortingArray, setSortingArray] = useState([]);
    const [sortingKey, setSortingKey] = useState('iAdminId');
    const [sortingOrder, setSortingOrder] = useState('asc');
    const [search, setSearch] = useState('');
    const [user, setUser] = useState('');
    const [pageSizeCount, setPageSizeCount] = useState(20);
    const [serachValue, setSerachValue] = useState('');
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showLoader, setShowLoader] = useState(false);
    const [activeRowData, setActiveRowData] = useState({});

    useEffect(() => {
        setShowLoader(true)
        const delay = 1000; // 1 seconds
        
        const timeoutId = setTimeout(() => {
            axiosInstance.get('admins/users?pageSize=' + pageSizeCount + '&page=' + pageNumber + '&search=' + serachValue + '&key=' + sortingKey + '&order='+ sortingOrder  + '&searchUser='+ user).then((response) => {
                if (response.status == 200) {
                    setShowLoader(false);
                    response.data.data.forEach(element => {
                        element.fullname = element.vFirstName + " " + element.vLastName;
                        element.vUserName = element.vUserName;
                        element.vCity = element.vCity;
                        element.vBusinessEmail = element.vBusinessEmail;
                        element.vEmail = element.vEmail;
                    });
                    setData(response.data.data);
                    setTotalPageCount(response.data.pageCount);
                }
            }).catch(() => console.error);
        }, delay);
    
        return () => {
          clearTimeout(timeoutId);
        };
    }, [serachValue, user]);

    const handleInputChange = (val) => {
        setShowLoader(true);
        setPageNumber(1);
        setSerachValue(val);
    };

    const indexPageSize = (val) => {
        setShowLoader(true);
        setPageSizeCount(val);
        axiosInstance.get('admins/users?pageSize=' + val + '&page=1&search=' + serachValue + '&key=' + sortingKey + '&order='+ sortingOrder  + '&searchUser='+ user).then((response) => {
            if (response.status == 200) {
                setShowLoader(false);
                response.data.data.forEach(element => {
                    element.fullname = element.vFirstName + " " + element.vLastName;
                    element.vUserName = element.vUserName;
                    element.vCity = element.vCity;
                    element.vBusinessEmail = element.vBusinessEmail;
                    element.vEmail = element.vEmail;
                });
                setData(response.data.data);
                setTotalPageCount(response.data.pageCount);

            }
        }).catch(() => console.error);

        // usersList();
    }

    const userTablePageSize = (val) => {
        setPageNumber(1);
        indexPageSize(val);
    }

    const impersonate = (id) => {
        dispatch(setSubMenuClass(' hidden-submenu '));
        if (id) {
            axiosInstance.post('admins?action=impersonate', {'admin_id': id}).then((response) => {
                if (response.data.status === "success") {
                    if (response.data?.roles.length !== 0 && response.data?.roles[0] !== "") {
                        AuthService.setUserRoles(response.data.roles);
                        dispatch(setUserRole(response.data.roles));
                        dispatch(setUserProfilePicture(response.data.user.profileImage));
                        Auth.setAuthenticatedUser(response.data.user);
                        navigate('/'); // move to "/" as admin got new role and can't see Beheer page
                    } else {
                        toast["warning"]("Gebruiker heeft geen rol!")
                    }
                }

            }).catch(() => console.error);
        }
    }

    useEffect(() => {
        if (data.length === 0) {
            usersList();
        }
    }, [data]);

    const usersList = () => {
        // setShowLoader(true);
        axiosInstance.get('admins/users?pageSize=' + pageSizeCount + '&page=' + pageNumber + '&search=' + serachValue + '&key=' + sortingKey + '&order='+ sortingOrder + '&searchUser='+ user).then((response) => {
            if (response.status == 200) {
                response.data.data.forEach(element => {
                    element.fullname = element.vFirstName + " " + element.vLastName;
                    element.vUserName = element.vUserName;
                    element.vCity = element.vCity;
                    element.vBusinessEmail = element.vBusinessEmail;
                    element.vEmail = element.vEmail;
                });
                setData(response.data.data);
                setTotalPageCount(response.data.pageCount);
                // setShowLoader(false);
            }
        }).catch(() => console.error);
    }

    const listSortingByKey = () => {
        var id;
        var order;
        sortingArray.find(obj => {
            setSortingKey(obj.id);
            setSortingOrder(obj.desc === true ? 'desc' : 'asc');
            id = obj.id;
            order = obj.desc === true ? 'desc' : 'asc';
        });

        axiosInstance.get('admins/users?pageSize=' + pageSizeCount + '&page=' + pageNumber + '&search=' + serachValue + '&key=' + id + '&order='+ order +  '&searchUser='+ user).then((response) => {
            response.data.data.forEach(element => {
                element.fullname = element.vFirstName + " " + element.vLastName;
                element.vUserName = element.vUserName;
                element.vCity = element.vCity;
                element.vBusinessEmail = element.vBusinessEmail;
                element.vEmail = element.vEmail;
            });
            setData(response.data.data);
            setTotalPageCount(response.data.pageCount);
        }).catch(() => console.error);

        // usersList();
    }

    const paginate = (val) => {
        setShowLoader(true);
        setPageNumber(val);
        axiosInstance.get('admins/users?pageSize=' + pageSizeCount + '&page=' + val + '&search=' + serachValue + '&key=' + sortingKey + '&order='+ sortingOrder + '&searchUser='+ user).then((response) => {
            if (response.status == 200) {
                response.data.data.forEach(element => {
                    element.fullname = element.vFirstName + " " + element.vLastName;
                    element.vUserName = element.vUserName;
                    element.vCity = element.vCity;
                    element.vBusinessEmail = element.vBusinessEmail;
                    element.vEmail = element.vEmail;
                });
                setData(response.data.data);
                setTotalPageCount(response.data.pageCount);
                setShowLoader(false);
            }
        }).catch(() => console.error);
    }

    const filterList = (val) => {
        handleInputChange(val);
    }
    

    const columns = [
            {
                header: 'Clooser-Id',
                accessorKey: 'iAdminId', // accessor is the "key" in the data
            },
            {
                header: 'Gebruikersnaam',
                accessorKey: 'vUserName'
            },
            {
                header: 'Naam',
                accessorKey: 'fullname',
                cell: value => (
                    window.innerWidth > 1250 ? TextLimit.AfwezigeLimitOptionOne(value.row.original.vFirstName, value.row.original.vMiddleName, value.row.original.vLastName)
                    :TextLimit.AfwezigeLimitOptionTwo(value.row.original.vFirstName, value.row.original.vMiddleName, value.row.original.vLastName)
                )
            },
            {
                header: 'Woonplaats',
                accessorKey: 'vCity'
            },
            {
                header: 'Mail zakelijk',
                accessorKey: 'vBusinessEmail'
            },
            {
                header: 'Mail prive',
                accessorKey: 'vEmail'
            },
            {
                header: 'Rol',
                accessorKey: 'vRole',
                cell: value => (
                    <Tippy content={value.row.original.vRole.join(", ")} placement="bottom"><span>
                        {value.row.original.vRole.map(role => role.charAt(0).toUpperCase()).join("/")}</span>
                    </Tippy>
                )
            },
            {
                Header: 'Actie',
                accessorKey: 'Actie',
                cell: value => (
                    <div className='d-flex align-items-center'>
                        <BsArrowRightCircle
                            className="cursor-pointer me-2"
                            color="light"
                            size={17}
                            onClick={() => impersonate(value.row.original.iAdminId)} />
                        <i 
                            className="pe-7s-config cursor-pointer" 
                            style={{ fontSize: '21px', color: 'light' }} 
                            onClick={() =>{ setReporterId(value.row.original.iAdminId); setActiveRowData(value.row.original)}} 
                        />
                    </div>
                    )
            },
        ];

    const filterUsers = (row, columnId, values, addMeta) => {
        let searchValues = values.split(/(\s+)/).filter( e => e.trim().length > 0);
    
        return searchValues.every((value)=>{
            return Object.entries(row.original).some(([key, val]) => {
                return key !== "vRole" && key !== "Actie" && String(val).toLowerCase().includes(value.toLowerCase());
            });
        });
    }

    const userDetails = data.find(a => a.iAdminId === reporterId)

    return (
        <div>
                <UsersModal userDetails={userDetails} reporterId={reporterId} setReporterId={setReporterId} activeRowData={activeRowData} refetchUserList={usersList}/>
                {
                        <ReactTableGenericForUsers
                            data={data}
                            columns={columns}
                            userTablePageSize={userTablePageSize}
                            listSortingByKey={listSortingByKey}
                            filterList={filterList}
                            totalPageCount={totalPageCount}
                            filterRecords={filterUsers}
                            paginate={paginate}
                            pageNumber={pageNumber}
                            setPageNumber={setPageNumber}
                            setSortingArray={setSortingArray}
                            pageSizeCount={pageSizeCount}
                            showLoader={showLoader}
                            tableClassName="mb-0 table table-borderless table-sm table-hover"
                            setUser={setUser}
                            user={user}
                        />
                }
               
            {/* {showLoader === true && 
                <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
            } */}
                                            
        </div>
        
    )
}


export default UsersTable;
