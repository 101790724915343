import { useEffect, useState } from 'react';
import { Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { ReactComponent as AddIcon } from "../../../../../assets/utils/images/svgs/Add.svg";
import { ReactComponent as SearchIcon } from "../../../../../assets/utils/images/svgs/search.svg";
import Loader from 'react-loaders';

const ReporterModal = ({ setModal, modal, fetchReporterList, reporterData, addOrDeleteMelderHandler }) => {
    const [search, setSearch] = useState("");
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        fetchReporterList();
    }, []);

    useEffect(() => {
        if (search.trim() === "") {
            setFilteredData(reporterData);
        } else {
            const filtered = reporterData.filter(item =>
                item?.name?.toLowerCase()?.includes(search.toLowerCase())
            );
            setFilteredData(filtered);
        }
    }, [search, reporterData]);

    const toggleModal = () => {
        setModal(prev => ({ ...prev, state: !prev.state }));
        setFilteredData([]);
        setSearch("");
    }


    return (
        <Modal isOpen={modal.state} toggle={toggleModal} className="feedback-modal-style link-form modal-centered">
            <ModalHeader toggle={() => setModal(prev => ({ ...prev, state: !prev.state }))}>
                Selecteer melder
            </ModalHeader>
            <ModalBody className="pb-5">
                {reporterData?.length > 0 ? (
                    <>
                        <div className="pb-4 mt-3 melder-search">
                            <input
                                placeholder="Zoeken"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <SearchIcon />
                        </div>

                        <div className="melder-list">
                            {filteredData.length > 0 ? (
                                filteredData.map(item => (
                                    <div className="py-2 melder-li" key={item.iAdminId}>
                                        <Label for="iKetenregeling">{item.name}</Label>
                                        <AddIcon className='cursor-pointer' onClick={() => addOrDeleteMelderHandler('add', item.iAdminId)} />
                                    </div>
                                ))
                            ) : (
                                <p className="text-center mt-3">Geen resultaten gevonden.</p>
                            )}
                        </div>
                    </>
                ) : (
                    <Loader type="ball-grid-pulse" className="mt-3 d-flex mx-auto justify-content-center" />
                )}
            </ModalBody>
        </Modal>
    );
};

export default ReporterModal;
