import React from "react";
import { Form, Formik } from "formik";
import { Card, CardBody, Col, FormGroup, Label, Row } from 'reactstrap'
import DropdownList from "react-widgets/DropdownList";
import TitleModal from "Components/Modal/InfoModal/TitleModal";

const SalarisId = ({ data, setFormData, handleSetFormData }) => {

    const dropdownList = [
        { label: 'Geen selectie', value: "vSalarisId" },
        { label: "1. 'Vrije veld 1'", value: data?.inputField1 },
        { label: "2. 'Vrije veld 2'", value: data?.inputField2 },
        { label: "3. 'Vrije veld 3'", value: data?.inputField3 },
    ]

    const defaultValue = {
        useSalaryColumn: data?.useSalaryColumn
    }

    return (
        <>
            <Card className="main-card">
                <CardBody className="d-flex flex-column mb-4">
                    <div className="d-flex align-items-center mb-4">
                        <div className="btn-color-style travelCost-blue-text me-3">
                            Salaris-id
                        </div>
                        <TitleModal data={"In applicatie wordt het veld salaris-id vervangen door de waarde in het gekozen vrije veld. Deze waarde wordt b.v. getoond in exports, weekstaten, etc."} />
                    </div>

                    <Formik
                        enableReinitialize
                        initialValues={defaultValue}
                        validateOnChange={true}
                    >
                        {({ values, errors, setFieldValue, handleChange, setFieldError, onBlur }) => (
                            <Form>
                                <FormGroup className={``}>
                                    <Label for="useSalaryColumn">Salaris-id vervangen door vrije veld</Label>
                                    <div className="position-relative col-6  mt-3">
                                        <div className="d-flex gap-1 align-items-center">
                                            <DropdownList dataKey='value' textField='label'
                                                placeholder="Selecteer"
                                                onChange={(e) => {
                                                    setFieldValue("useSalaryColumn", e.value);
                                                    handleSetFormData("useSalaryColumn", e.value, setFormData);
                                                }}
                                                name="useSalaryColumn"
                                                value={values.useSalaryColumn}
                                                data={dropdownList}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>

                            </Form>
                        )}
                    </Formik>
                    <Row className="mt-4 mb-2">
                        <Col md="12" lg="12" className="travelCost-light-text">
                            <span className="travelCost-bold-text">LET OP: </span>
                            Wijzigingen moeten via knop Toepassen bevestigd worden.
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    )
}

export default SalarisId;