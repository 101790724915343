import { Form, Formik } from "formik";
import { Card, CardBody, FormGroup, Input, Label } from 'reactstrap';

const Emails = ({ data, changeHandler, errorField }) => {
    const defaultValues = {
        vVisitoremail: data?.vVisitoremail ?? '',
        vWeekStaatMail: data?.vWeekStaatMail ?? '',
        vSalarisMail: data?.vSalarisMail ?? '',
        vInformMelderMail: data?.vInformMelderMail ?? '',
        vVerzuimmeldingMail: data?.vVerzuimmeldingMail ?? '',
        vVerlofmeldingMail: data?.vVerlofmeldingMail ?? '',
        vEvaluationMail: data?.vEvaluationMail ?? '',
        reminder_email: data?.reminder_email ?? '',
        reminder_email_no_one_avaliable: data?.reminder_email_no_one_avaliable ?? '',
    };

    return (
        <Card className="main-card">
            <CardBody className="d-flex flex-column">
                <h5 className="mb-4">E-mailadressen</h5>
                <Formik
                    enableReinitialize
                    initialValues={defaultValues}
                >
                    {({ values, handleChange, setFieldValue }) => (
                        <Form>
                            <FormGroup className={`form-element ${errorField === 'vVisitoremail' ? 'error' : ''}`}>
                                <Label for="vVisitoremail">E-mail</Label>
                                <Input
                                    name="vVisitoremail"
                                    type="text"
                                    value={values.vVisitoremail}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vVisitoremail', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vWeekStaatMail' ? 'error' : ''}`}>
                                <Label for="vWeekStaatMail">Weekstaat e-mail</Label>
                                <Input
                                    name="vWeekStaatMail"
                                    type="text"
                                    value={values.vWeekStaatMail}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vWeekStaatMail', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vSalarisMail' ? 'error' : ''}`}>
                                <Label for="vSalarisMail">Salaris administratie e-mail</Label>
                                <Input
                                    name="vSalarisMail"
                                    type="text"
                                    value={values.vSalarisMail}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vSalarisMail', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vInformMelderMail' ? 'error' : ''}`}>
                                <Label for="vInformMelderMail">Melder informeren e-mail</Label>
                                <Input
                                    name="vInformMelderMail"
                                    type="text"
                                    value={values.vInformMelderMail}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vInformMelderMail', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vVerzuimmeldingMail' ? 'error' : ''}`}>
                                <Label for="vVerzuimmeldingMail">Verzuimmelding e-mail</Label>
                                <Input
                                    name="vVerzuimmeldingMail"
                                    type="text"
                                    value={values.vVerzuimmeldingMail}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vVerzuimmeldingMail', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vVerlofmeldingMail' ? 'error' : ''}`}>
                                <Label for="vVerlofmeldingMail">Verlofmelding e-mail</Label>
                                <Input
                                    name="vVerlofmeldingMail"
                                    type="text"
                                    value={values.vVerlofmeldingMail}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vVerlofmeldingMail', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vEvaluationMail' ? 'error' : ''}`}>
                                <Label for="vEvaluationMail">Evaluatie e-mail</Label>
                                <Input
                                    name="vEvaluationMail"
                                    type="text"
                                    value={values.vEvaluationMail}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vEvaluationMail', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'reminder_email' ? 'error' : ''}`}>
                                <Label for="reminder_email">Herinneringsmail</Label>
                                <Input
                                    name="reminder_email"
                                    type="text"
                                    value={values.reminder_email}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('reminder_email', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'reminder_email_no_one_avaliable' ? 'error' : ''}`}>
                                <Label for="reminder_email_no_one_avaliable">Herinneringsmail Niemand beschikbaar</Label>
                                <Input
                                    name="reminder_email_no_one_avaliable"
                                    type="text"
                                    value={values.reminder_email_no_one_avaliable}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('reminder_email_no_one_avaliable', e.target.value);
                                    }}
                                />
                            </FormGroup>
                        </Form>
                    )}
                </Formik>
            </CardBody>
        </Card>
    )
}

export default Emails