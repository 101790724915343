import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Button, Card, CardBody, CardFooter, Col, Input, Row } from "reactstrap";
import PageHeading from "Layout/AppMain/PageHeading";
import ReactTableGeneric from "Components/ReactTableGeneric";
import AdminService from "Api/Admin";
import Loader from "react-loaders";
import { ReactComponent as LinkIcon } from "../../../assets/utils/images/svgs/open_link.svg";
import { ReactComponent as Document } from "../../../assets/utils/images/svgs/Document.svg";
import { BsCheckCircle } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import { toast } from "react-toastify";
import { ReactComponent as CrossIcon } from "../../../assets/utils/images/svgs/Cross.svg";


const ManageLinks = () => {
    const [communityLoader, setCommunityLoader] = useState(false);
    const [endpointLoader, setEndpointLoader] = useState(false);
    const [endpoinList, setEndpointList] = useState([]);
    const [communityId, setCommunityId] = useState([]);
    const [data, setData] = useState({ endpoint: '', docUrl: '' });
    const [selectAllChecked, setSelectAllChecked] = useState(false);

    const [endPointsData, setEndpointsData] = useState([]);

    const [communityList, setCommunityList] = useState([]);

    useEffect(() => {
        fetchCommunityList();
        fetchEndpointsList();
    }, []);

    useEffect(() => {
        if (communityList?.length > 0) {
            const communityIds = communityList.filter(item => item.api === 1).map(item => item.iCommunityId)
            if (communityIds.length > 0) {
                setCommunityId(communityIds)
            }
        }
    }, [communityList])

    useEffect(() => {
        if (endpoinList?.length > 0 && data?.endpoint) {
            fetchAllEndpoints();
        }
    }, [endpoinList, data.endpoint])

    const fetchCommunityList = () => {
        setCommunityLoader(true);
        AdminService.communityList().then((response) => {
            if (response.status === 200) {
                setCommunityList(response.data.data)
            }
            setCommunityLoader(false);
        }).catch(() => console.error);
    }

    const fetchEndpointsList = () => {
        setEndpointLoader(true);
        AdminService.endpoinList().then((response) => {
            if (response.status === 200) {
                setEndpointList(response.data.data)
                setData({ endpoint: response.data.endpoint, docUrl: response.data.doc_url })
            }
            setEndpointLoader(false);
        }).catch(() => console.error);
    }

    const fetchAllEndpoints = () => {
        endpoinList.forEach((item) => {
            fetchEndpointsData(item.name);
        })
    };

    const fetchEndpointsData = async (path) => {
        try {
            const token = process.env.REACT_APP_POWER_BI_TOKEN;
            const response = await fetch(`${data.endpoint}/${path}`, {
                headers: {
                    'AUTHENTICATION': `${token}`,
                    'Content-Type': 'application/json',
                }
            });
            if (response.status === 200) {
                const result = await response.json();
                setEndpointsData((prev) => {
                    return [...prev, { name: path, data: result }];
                });
            } else {
                setEndpointsData((prev) => {
                    return [...prev, { name: path, data: undefined }];
                });
                throw new Error(response.statusText);
            }
        } catch (err) {
            setEndpointsData((prev) => {
                return [...prev, { name: path, data: undefined }];
            });
            console.log(err);
        }
    };

    const getEndpointData = (path, cellType) => {
        const reqEndpoint = endPointsData?.find(item => item.name === path)
        if (!reqEndpoint) return undefined;
        if (cellType === "status") {
            if (reqEndpoint.data) return 1; else return -1;
        } else if (cellType === "rows") {
            if (reqEndpoint.data) return reqEndpoint.data.meta.rows; else return -1;
        } else if (cellType === "download") {
            if (reqEndpoint.data?.meta.rows > 0) return 1; else return -1;
        } else return;
    }

    const columns = React.useMemo(
        () => [
            {
                header: () => 'Endpoint/Url',
                accessorKey: 'name',
            },
            {
                header: () => 'Status',
                accessorKey: 'status',
                cell: (value) => {
                    const status = getEndpointData(value.row.original.name, "status");
                    if (status === 1) {
                        return <BsCheckCircle color='#39A825' fontSize={"18px"} className="weekstaten-check-icon" />;
                    } else if (status === -1) {
                        return <CrossIcon color='#FB2E5F' className="weekstaten-dlt-icon" />;
                    } else {
                        return  <div className="d-flex justify-content-center align-items-center"><div class="cell-loader"></div></div>
                    }
                },
            },
            {
                header: () => 'Rows',
                accessorKey: 'rows',
                cell: (value) => {
                    const rows = getEndpointData(value.row.original.name, "rows");
                    if (rows >= 0) {
                        return rows;
                    } else if (rows === -1) {
                        return '-';
                    } else {
                        return  <div className="d-flex justify-content-center align-items-center"><div class="cell-loader"></div></div>
                    }
                },
            },
            {
                header: () => 'Download',
                accessorKey: 'action',
                cell: (value) => {
                    const download = getEndpointData(value.row.original.name, "download");
                    if (download === 1) {
                        return <div onClick={() => downloadDocument(value.row.original.name)} className="cursor-pointer">
                            <Document className="mx-4" />
                        </div>;
                    } else if (download === -1) {
                        return '-';
                    } else {
                        return <div className="d-flex justify-content-center align-items-center"><div class="cell-loader"></div></div>
                    }
                },
            },
        ],
        [endPointsData]
    );

    const communityHandler = (id) => {
        setCommunityId(prevCommunityId => {
            const isIdPresent = prevCommunityId.includes(id);
            if (isIdPresent) {
                setSelectAllChecked(false)
                return prevCommunityId.filter(item => item !== id);
            } else {
                setSelectAllChecked(false)
                return [...prevCommunityId, id];
            }
        });
    };

    const handleAllCommunity = (value) => {
        setCommunityId(() => {
            if (value) {
                setSelectAllChecked(true);
                return communityList.map(item => item.iCommunityId)
            } else { setSelectAllChecked(false); return [] }
        });
    }

    const handleSubmit = () => {
        const data = {
            iCommunityIds: [...communityId]
        }
        setEndpointsData([]);
        AdminService.powerBiApi(data).then((response) => {
            if (response.status === 200 && response.data.status) {
                fetchEndpointsList();
            } else if (!response.data.status) {
                toast.error("Kan community's niet updaten.")
            } else {
                toast.error("Er is iets misgegaan!")
            }
        }).catch(() => console.error);
    };

    const downloadDocument = (pathname) => {
        const reqEndpoint = endPointsData?.find(item => item.name === pathname)
        if (!reqEndpoint) return
        const data = reqEndpoint.data.data
        const headers = Object.keys(data[0]);

        // Map the object data to CSV rows
        const csvRows = [
            headers.join(","), // First row with headers
            ...data.map(row => headers.map(header => row[header]).join(",")) // Data rows
        ];

        const csvContent = csvRows.join("\n");

        // Create a Blob and download link
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.setAttribute("href", url);
        link.setAttribute("download", `${pathname}.csv`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <Fragment>
            <PageHeading />
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    <Row className="mb-5">
                        <Col md="3">
                            <Card className="main-card mb-3">
                                <CardBody className="p-3">
                                    <p className="text-center py-2 mb-3" style={{ backgroundColor: "#FAFCFD", fontWeight: '500', color: '#026094' }}>API - Bestuur</p>
                                    {communityLoader ?
                                        <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} /> :
                                        (
                                            <>
                                                <p className="mb-3 text-end">Alle besturen selecteren?
                                                    <span>
                                                        <Input
                                                            type="checkbox"
                                                            className="col-md-12 ms-3 cursor-pointer"
                                                            checked={selectAllChecked}
                                                            onChange={(e) => handleAllCommunity(e.target.checked)}
                                                        /></span>
                                                </p>
                                                {communityList.map(item => {
                                                    return <div className="d-flex justify-content-between align-items-center py-2" key={item.iCommunityId}
                                                        style={{ borderBottom: '1px solid #dee2e6' }}>
                                                        <p className="mb-0">{item.vCommunityName}</p>
                                                        <Input
                                                            type="checkbox"
                                                            className="col-md-12 ms-3 cursor-pointer"
                                                            checked={communityId.includes(item.iCommunityId)}
                                                            onChange={() => communityHandler(item.iCommunityId)}
                                                        />
                                                    </div>
                                                })}
                                            </>
                                        )
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="9">
                            <Card className="main-card mb-3">
                                <CardBody className="p-3">
                                    <p className="text-center py-2 mb-3" style={{ backgroundColor: "#FAFCFD", fontWeight: '500', color: '#026094' }}>API - Endpoints</p>
                                    {endpointLoader ?
                                        <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} /> :
                                        (
                                            <><p className="mb-3 text-end"><b>Endpoint</b>: {data.endpoint}</p>
                                                <ReactTableGeneric
                                                    data={endpoinList}
                                                    columns={columns}
                                                    tableClassName="mb-0 table table-borderless table-sm table-hover tb-api tb-links"
                                                    rowCount={40}
                                                    filters={{ search: false, pagination: false, sorting: false }}
                                                /></>
                                        )
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </CSSTransition>
            </TransitionGroup>
            <div className="chat-layout-footer verzoken-footer-height">
                <CardFooter className="chat-layout-footer__inner">
                    <div className="d-flex justify-content-end gap-3 mr-4">
                        <a
                            href={data?.docUrl || ''}
                            target="_blank"
                            rel="noreferrer"
                            className="btn-outline-primary-blue btn-color-style py-2 px-3 border rounded btn-api"
                            type="button"
                            color="primary"
                        >
                            API Documentation <LinkIcon />
                        </a>
                        <Button
                            outline
                            className="btn-outline-primary-blue btn-color-style py-2 px-3"
                            onClick={handleSubmit}
                            type="button"
                            color="primary"
                            disabled={communityLoader || endpointLoader}
                        >
                            Save
                        </Button>
                    </div>
                </CardFooter>
            </div>
        </Fragment>
    )
}

export default ManageLinks