import React, { useState } from 'react';
import ReactTableGeneric from 'Components/ReactTableGeneric'
import { ReactComponent as LockIcon } from "../../../../../assets/utils/images/svgs/Locked.svg";
import { ReactComponent as UnLockIcon } from "../../../../../assets/utils/images/svgs/unlock.svg";
import { ReactComponent as PDFIcon } from "../../../../../assets/utils/images/svgs/PDF.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/utils/images/svgs/Delete.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/utils/images/svgs/editPen.svg";
import { downloadBase64File } from '../utils';
import { Formik, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Button, Card, CardBody, Form, FormGroup, Label, Modal, ModalFooter, ModalHeader } from 'reactstrap'
import DatePicker from "react-datepicker";
import SweetAlert from "react-bootstrap-sweetalert"
import GebruikerDocumentenService from 'Api/Gebruikers/GebruikersView/Documenten';
import moment from 'moment';
import { toast } from 'react-toastify';

const validationSchema = Yup.object().shape({
    date: Yup.date().required('Dit veld is verplicht.'),
});

const DocTable = ({ data, community, currentUser, updateDataHandler }) => {
    const [showAlert, setShowAlert] = useState({
        state: false,
        documentData: ""
    });
    const [modal, setModal] = useState({
        state: false,
        rowData: ""
    });

    const modalStateHandler = (rowData) => {
        setModal({
            state: true,
            rowData
        });
    };

    const updateDateHandler = async (values, resetForm) => {
        const data = {
            iAdminId: currentUser.data.clooserId,
            iCommunityId: community.iCommunityId,
            iDocTypeId: modal.rowData.iDocType,
            iDocTypeName: modal.rowData.Name
        };
        if (modal.rowData.Name === "vIdentite") {
            data.gelding = moment(values.date).format("YYYY-MM-DD")
        } else {
            data.uitgifte = moment(values.date).format("YYYY-MM-DD")
        };
        try {
            const response = await GebruikerDocumentenService.updateDocumentDate(data);
            if (response.data.status) {
                updateDataHandler();
                resetForm();
                setModal({
                    state: false,
                    rowData: ""
                })
            }else {
                if(modal.rowData.Name === "vIdentite"){
                    toast.error("Documentdatum niet updated");
                }
            }
        } catch (error) {
            console.error("API error:", error);
        }
    };

    const updateLockHandler = async (value, id) => {
        const data = {
            iAdminId: currentUser.data.clooserId,
            iCommunityId: community.iCommunityId,
            iDocTypeId: id,
            value
        };
        try {
            const response = await GebruikerDocumentenService.updateLockState(data);
            if (response.data.status) {
                updateDataHandler()
            }
        } catch (error) {
            console.error("API error:", error);
        }
    }
    const columns = React.useMemo(
        () => [
            {
                header: () => 'Document',
                accessorKey: 'Label',
            },
            {
                header: () => 'Uploaddatum',
                accessorKey: 'BiUpload'
            },
            {
                header: () => 'Verloopdatum',
                accessorKey: 'BvVerloopDatum',
                cell: (value) => (
                    <div className='d-flex gap-3'>
                        {value.row.original.BvVerloopDatum || "-"}
                        {(value.row.original.Name === "vIdentite" || value.row.original.Name === "vVerklaring") &&
                            <EditIcon className='cursor-pointer' onClick={() => modalStateHandler(value.row.original)} />}
                    </div>
                )
            },
            {
                header: () => 'Acties',
                accessorKey: 'action',
                cell: (value) => (
                    <div className='d-flex gap-3'>
                        <PDFIcon className='cursor-pointer' onClick={() => { downloadBase64File(value.row.original.BvDocHref, value.row.original.Label) }} />
                        <DeleteIcon className='cursor-pointer' onClick={() => setShowAlert({ state: true, documentData: value.row.original })} />
                    </div>
                )
            },
            {
                header: () => 'Blokkeren',
                accessorKey: 'Bis_locked',
                cell: (value) => (
                    <div className='ms-4'>
                        {value.row.original.Bis_locked === "N" ?
                            <UnLockIcon className='cursor-pointer' onClick={() => updateLockHandler("Y", value.row.original.iDocType)} /> :
                            <LockIcon className='cursor-pointer' onClick={() => updateLockHandler("N", value.row.original.iDocType)} />}
                    </div>
                )
            }
        ],
        []
    );

    const deleteDocumentHandler = async (data) => {
        const property = data.BvVerloopDatum ? "verloopdatum" : ""
        try {
            const response = await GebruikerDocumentenService.deleteDocument(data.BiDocId, data.iDocType, currentUser.data.clooserId, community.iCommunityId, property);
            if (response.data.status) {
                updateDataHandler()
            }
        } catch (error) {
            console.error("API error:", error);
        }
    }

    return (
        <>
            <Card className="main-card pb-5">
                <CardBody className="d-flex flex-column">
                    <h5 className="mb-4">Documenten bestuur</h5>
                    <ReactTableGeneric
                        data={data || []}
                        columns={columns}
                        tableClassName="mb-0 table table-borderless table-sm table-hover"
                        filters={{ search: false, pagination: false, sorting: false }}
                    />
                </CardBody>
            </Card>
            <Modal isOpen={modal.state} toggle={() => setModal(prev => ({ ...prev, state: !prev.state }))} className="feedback-modal-style link-form modal-centered">
                <ModalHeader toggle={() => setModal(prev => ({ ...prev, state: !prev.state }))}>
                    Update Verloopdatum
                </ModalHeader>
                <Formik
                    enableReinitialize
                    initialValues={{
                        date: modal.rowData.BvVerloopDatum ? moment(modal.rowData.BvVerloopDatum, "DD-MM-YYYY").toDate() : null
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm }) => updateDateHandler(values, resetForm)}
                >
                    {({ values, handleBlur, setFieldValue, handleSubmit }) => (
                        <Form onSubmit={handleSubmit} className='mt-5'>
                            <FormGroup className='d-flex justify-content-between'>
                                <Label for="date">{modal.rowData.Name === "vIdentite" ? "Geldig tot" : "Datum Uitgifte"}</Label>
                                <div>
                                    <DatePicker
                                        selected={values.date}
                                        onChange={(date) => setFieldValue("date", date)}
                                        onBlur={handleBlur}
                                        placeholderText={'dd-mm-jjjj'}
                                        dateFormat="dd-MM-yyyy"
                                        className="form-control"
                                    />
                                    <ErrorMessage name="date" component="small" className="block mt-2 invalid-feedback" />
                                </div>
                            </FormGroup>
                            <ModalFooter className="d-flex align-items-center justify-content-center border-bottom-0 py-4 mt-5">
                                <Button className="btn-color-style opslaan-btn" size="sm" outline color="primary" type="submit">
                                    Opslaan
                                </Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </Modal>
            <SweetAlert title="Weet u zeker dat u dit document wilt verwijderen?" show={showAlert.state}
                type="warning"
                onConfirm={() => { deleteDocumentHandler(showAlert.documentData) }}
                onCancel={() => setShowAlert({ state: false, documentData: "" })}
                cancelBtnText="Nee"
                confirmBtnText="Ja"
                showCancel={true}
            />
        </>
    )
}

export default DocTable;