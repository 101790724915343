import { Card, CardBody } from "reactstrap";
import WeekTable from "./WeekTable";

const WeeklyOverview = ({ data }) => {
    return (
        <Card className="main-card">
            <CardBody className="table-responsive">
                <h5 className="mb-4">Weekoverzicht</h5>
                {
                    data?.arrWeek !== undefined && Object.keys(data.arrWeek).map((item) => {
                        return <WeekTable weekno={item} list={data.arrWeek[item]} sum={data.arrWeekSum[item] !== undefined ? data.arrWeekSum[item] : null} />
                    })
                }
            </CardBody>
        </Card>
    );
};

export default WeeklyOverview;