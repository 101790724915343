import React, { useState } from "react";
import { Form, Formik } from "formik";
import { Card, CardBody, Col, FormGroup, Label, Spinner } from 'reactstrap';
import GroupStatus from "../../../../../Components/IconDropdown/Cross_Tick/GroupStatus";

const fieldMappings = [
    { key: "iVog", label: "VOG" },
    { key: "iIdentiteitsbewijs", label: "Identiteitsbewijs" },
    { key: "iPabo", label: "Pabo" },
    { key: "i36Maanden", label: "36 Maanden" },
    { key: "iKetenregeling", label: "Ketenregeling" },
    { key: "iBlokkade", label: "Blokkade" },
    { key: "iGym", label: "Gym" },
    { key: "iMatchcode", label: "Matchcode" },
    { key: "iFV", label: "Functies vervanger" },
    { key: "iFB", label: "Functies bestuur" },
    { key: "iContinue", label: "Continuïteit" },
    { key: "iKm", label: "Km" },
    { key: "iLeeftijd", label: "Leeftijd" },
    { key: "iSalarisschaal", label: "Salarisschaal" },
    { key: "iContacthistorie", label: "Contacthistorie" },
    { key: "iSms", label: "SMS versturen" },
    { key: "iFilterVervanger", label: "Filter vervangers" },
    { key: "iBeschikbaarheid", label: "% Beschikbaarheid" },
    { key: "iEfficiency", label: "% Efficiëncy" }
];

const KolommenPlanscherm = ({ planning, handleIconFieldChange }) => {
    const [loadingKeys, setLoadingKeys] = useState({});

    const defaultValues = fieldMappings.reduce((acc, field) => {
        acc[field.key] = planning?.[field.key];
        return acc;
    }, {});


    const handleToggle = (field, data) => {
        setLoadingKeys(prev => {
            const updatedState = Object.fromEntries(
                Object.entries({ ...prev, [field]: data }).filter(([_, value]) => value)
            );

            return { ...updatedState, [field]: true };
        });
        handleIconFieldChange(field, data, setLoadingKeys, planning?.iPlanId)
    };

    return (
        <Card className="main-card">
            <CardBody className="d-flex flex-column">
                <div className="btn-color-style travelCost-blue-text mb-4">Kolommen planscherm weergeven/verbergen</div>

                <Formik enableReinitialize initialValues={defaultValues}>
                    {({ values, setFieldValue }) => (
                        <Form className="melder-list">
                            {fieldMappings.map(({ key, label }) => (
                                <Col lg={8} className="p-0" key={key}>
                                    <FormGroup className='form-element'>
                                        <Label for={key} className="mb-0 d-flex align-items-center">{label}</Label>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center" style={{ backgroundColor: "#ffffff" }}>
                                                {loadingKeys[key] ? (
                                                    <div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div>
                                                ) : 
                                                    <GroupStatus iconChangeHandler={handleToggle} field={key} data={values[key] ? 1 : 0} />
                                                }
                                            </div>

                                        </div>


                                    </FormGroup>
                                </Col>
                            ))}
                        </Form>
                    )}
                </Formik>
            </CardBody>
        </Card>
    );
};

export default KolommenPlanscherm;