import React, { useState, useMemo, useEffect } from "react";
import {
    Card,
    CardBody,
    Col
} from "reactstrap";
import ReactTableGeneric from "Components/ReactTableGeneric";
import { ReactComponent as DownloadIcon } from "../../../../../assets/utils/images/svgs/downloadCircleDarlBlue.svg";
import { ReactComponent as PendingIcon } from "../../../../../assets/utils/images/svgs/HistoryclockionDarkBlue.svg";
import axiosInstance from "Api";
import Loader from "react-loaders";
import { IoIosRefresh } from "react-icons/io";
import TitleModal from "Components/Modal/InfoModal/TitleModal";

function ExportTable({ exportData, setExportData, fetchHistoryData, isLoading, selectedOrganization, intervalRef }) {
    const [rowLoader, setRowLoader] = useState(false);

    useEffect(() => {
        if (exportData?.length > 0) {
            const processingRowIds = exportData
                .filter(item => item.rowStatus === "in-progress" || item.rowStatus === "failed")
                .map(item => item.id);

            if (processingRowIds.length === 0) return;

            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }

            intervalRef.current = setInterval(() => fetchExport(processingRowIds), 15000);
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [exportData]);

    const fetchExport = (processingRowIds = null) => {
        setRowLoader(true);
        axiosInstance.post(`/beheer/export/list`, {
            communityId: selectedOrganization,
            ...(processingRowIds && { iExportId: processingRowIds })
        })
            .then((response) => {
                if (response.data.status === 200) {
                    const data = response.data.data.exportLists;

                    const updatedExportData = exportData.map(mainItem => {
                        const returnedObj = data.find(item => item.id === mainItem.id && item.rowStatus !== mainItem.rowStatus);
                        return returnedObj ? returnedObj : mainItem;
                    });
                    setExportData(updatedExportData);
                }
            })
            .catch((error) => {
                console.log("Error downloading export data:", error);
            }).finally(() => {
                setRowLoader(false);
            });
    }

    const handleDownload = (data) => {
        if(data) {
            const base64String = data.convertedFile;
            const a = document.createElement("a");
            a.href = base64String;
            a.download = "exported_file";
            document.body.appendChild(a);
            a.style.display = "none";
            a.click();
            document.body.removeChild(a);
            axiosInstance.post('/beheer/export/save-history', {
                iExportId: data.id,
                iCommunityId: selectedOrganization
            }).then(response => fetchHistoryData())
        }
    };

    const exportColumn = useMemo(() => (
        [
            {
                header: () => "Export ID",
                accessorKey: "id",
            },
            {
                header: () => "Bestuur",
                accessorKey: "vCommunityName",
            },
            {
                header: () => "Coördinator",
                accessorKey: "coordinatorName",
            },
            {
                header: () => "Type",
                accessorKey: "type",
            },
            {
                header: () => "Aanmaakdatum",
                accessorKey: "Created_At",
            },
            {
                header: () => "Filters",
                accessorKey: "filters",
                cell: ({ row }) => (
                    <div className="d-flex justify-content-center overig-exporteren-filter">
                        <TitleModal data={exportData[row.index]?.filters || ''} />
                    </div>
                )
            },
            {
                header: () => "Status",
                accessorKey: "iStatus",
                cell: ({ row }) => {
                    return <div>{row.original.iStatus === 0
                        ? "Wordt aangemaakt..."
                        : row.original.iStatus === 1
                            ? `Export nog ${row.original.Days_left > 1 ? row.original.Days_left + ' dagen' : '1 dag'} beschikbaar`
                            : "Is verlopen..."}</div>
                }
            },
            {
                header: () => "Download",
                accessorKey: "download",
                cell: ({ row }) => <div className="d-flex justify-content-center">
                    {(row.original.rowStatus === "in-progress" || row.original.rowStatus === "failed")
                        ? (rowLoader ? <IoIosRefresh className="spin" color="#025F94" /> : <PendingIcon />) : (row.original.rowStatus === "completed" && row.original.convertedFile !== null) ?
                            <DownloadIcon className="cursor-pointer" onClick={() => handleDownload(row.original)} /> : <PendingIcon />
                    }
                </div>
            }
        ]
    ), [exportData, rowLoader]);

    return (<Col sm={12}>
        <div className="row">
            <div className="col">
                <Card className="main-card mb-3">
                    <CardBody className="p-3">
                        {isLoading ? <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} /> :
                            <>
                                <div className="d-flex justify-content-between  ">
                                    <div className="btn-color-style travelCost-blue-text mb-2">Download</div>
                                </div>
                                <ReactTableGeneric
                                    key={exportData.length}
                                    data={exportData || []}
                                    columns={exportColumn}
                                    tableClassName="mb-0 table table-borderless table-sm table-hover wtf-table download-table"
                                    filters={{
                                        search: false,
                                        sorting: false,
                                        pagination: false
                                    }}
                                    rowCount={exportData.length}
                                /></>}
                    </CardBody>
                </Card>
            </div>
        </div>
    </Col>)
}

export default ExportTable;