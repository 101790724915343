import axiosInstance from 'Api';

const login = (username, password, rememberToken) => {
    return axiosInstance.post("auth/login",
        {
            username,
            password,
            rememberToken
        });
};

const logout = () => {
    return axiosInstance.post("/logout");
};


const getUserRoles = () => {
    return JSON.parse(localStorage.getItem("USER_ROLES"));
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const setToken = (token) => {
    localStorage.setItem('REACT_TOKEN_AUTH', JSON.stringify(token));
}

const setRefreshToken = (token) => {
    localStorage.setItem('REFRESH_TOKEN_AUTH', JSON.stringify(token));
}

const setUser = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
}

const setUserRoles = (roles) => {
    localStorage.setItem('USER_ROLES', JSON.stringify(roles));
}

const makeAuth = (data) => {
    if (data.token && data.token.access_token) {
        setToken(data.token.access_token);
        setRefreshToken(data.token.refresh_token);
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${data.token.access_token}`;

        if (data.user) {
            setUser(data.user);
        }
    }
}

const checkUserLoggedIn = () => {
    let checkUser = JSON.parse(localStorage.getItem("REACT_TOKEN_AUTH"));
    if (checkUser) {
        return true;
    }
    return false;
}

const regenerateRefreshToken = () => {
    return new Promise((resolve, reject) => {
        const refreshToken = JSON.parse(localStorage.getItem("REFRESH_TOKEN_AUTH"));

        if (refreshToken) {
            let postdata = {
                refresh_token: refreshToken
            }
            axiosInstance.post('auth/refresh', postdata).then((response) => {
                if (response.status != undefined && response.status == 200) {
                    setToken(response.data.access_token);
                    setRefreshToken(response.data.refresh_token);
                    resolve(true);
                } else {
                    localStorage.clear();
                    window.location = "/login";
                }
            }).catch((err) => {

            });
        } else {
            localStorage.clear();
            window.location = "/login";
        }
    });
}

const TwoFAVerify = (data) => {
    return axiosInstance.post('/2fa', data);
}

const AuthService = {
    login,
    logout,
    getCurrentUser,
    getUserRoles,
    setUserRoles,
    makeAuth,
    checkUserLoggedIn,
    regenerateRefreshToken,
    TwoFAVerify
};
export default AuthService;
