import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "tippy.js/dist/tippy.css";
import axiosInstance from "Api";
import LoggingReactTable from "Components/LoggingReactTable";
import TextLimit from "Api/TextLimit";

const PushMessagesTable = () => {
	const [data, setData] = useState([]);
	const [pageSizeCount, setPageSizeCount] = useState(20);
	const [pageNumber, setPageNumber] = useState(1);
	const [totalPageCount, setTotalPageCount] = useState(0);
	const [searchValue, setSearchValue] = useState("");
	const [showLoader, setShowLoader] = useState(false);

	const fetchData = () => {
		setShowLoader(true);
		axiosInstance
			.get(
				`beheer/log-push-messages?perPage=${pageSizeCount}&page=${pageNumber}&search=${searchValue}`
			)
			.then((response) => {
				if (response.status === 200) {
					setShowLoader(false);
					console.log(response.data);
					setData(response.data.data);
					setTotalPageCount(response.data.meta.last_page);
				}
			})
			.catch(() => console.error);
	};

	const paginate = (val) => {
		setPageNumber(val);
	};

	useEffect(() => {
		fetchData();
	}, [searchValue, pageSizeCount, pageNumber]);

	const handleInputChange = (val) => {
		setPageNumber(1);
		setSearchValue(val);
	};

	const filterList = (val) => {
		handleInputChange(val);
	};

	const indexPageSize = (val) => {
		console.log("pageNumber", pageNumber);
		setPageSizeCount(val);
	};

	const loggingTablePageSize = (val) => {
		setPageNumber(1);
		indexPageSize(val);
	};

	const columns = [
		{
			header: "id",
			accessorKey: "id",
		},
		{
			header: "Date/time",
			accessorKey: "date",
		},
		{
			header: "Recipient Id",
			accessorKey: "recipientId",
		},
		{
			header: "Recipient Full Name",
			accessorKey: "vRecipientFullName",
			cell: (value) =>
				window.innerWidth > 1250
					? TextLimit.AfwezigeLimitOptionOne(
							value.row.original.recipientFirstName,
							value.row.original.recipientMiddleName,
							value.row.original.recipientLastName
					  )
					: TextLimit.AfwezigeLimitOptionTwo(
							value.row.original.recipientFirstName,
							value.row.original.recipientMiddleName,
							value.row.original.recipientLastName
					  ),
		},
		{
			header: "Sender Id",
			accessorKey: "senderId",
		},
		{
			header: "Sender Full Name",
			accessorKey: "vSenderFullName",
			cell: (value) =>
				window.innerWidth > 1250
					? TextLimit.AfwezigeLimitOptionOne(
							value.row.original.senderFirstName,
							value.row.original.senderMiddleName,
							value.row.original.senderLastName
					  )
					: TextLimit.AfwezigeLimitOptionTwo(
							value.row.original.senderFirstName,
							value.row.original.senderMiddleName,
							value.row.original.senderLastName
					  ),
		},
		{
			header: "Substitution Id",
			accessorKey: "substitutionId",
		},
		{
			header: "Firebase Response Id",
			accessorKey: "firebaseResponse",
		},
	];

	return (
		<>
			<LoggingReactTable
				data={data}
				columns={columns}
				setPageNumber={setPageNumber}
				pageNumber={pageNumber}
				totalPageCount={totalPageCount}
				paginate={paginate}
				filterList={filterList}
				loggingTablePageSize={loggingTablePageSize}
				pageSizeCount={pageSizeCount}
				showLoader={showLoader}
				tableClassName="mb-0 table table-borderless table-sm table-hover table-heading-style"
			/>
		</>
	);
};

export default PushMessagesTable;
