import PageHeading from "Layout/AppMain/PageHeading";
import React, { useRef, useState } from "react";
import { Col, Row, Card, CardBody, Label, InputGroup, CardFooter, Button, Input } from "reactstrap";
import InputWrapper from "Pages/Profile/Forms/InputWrapper";
import DropdownList from "react-widgets/DropdownList";
import DatePicker, { registerLocale } from "react-datepicker";
import { ReactComponent as EditPen } from "../../../../../assets/utils/images/svgs/editPen.svg";
import { ReactComponent as CalendarIcon } from "../../../../../assets/utils/images/svgs/calendar-blue.svg"
import moment from "moment";
import GroupStatus from "Components/IconDropdown/Cross_Tick/GroupStatus";
import nl from "date-fns/locale/nl";
registerLocale("nl", nl);

const BasicInformation = ({ getTravelCostData, getSubstitutorList, formDataState, setFormDataState, selectedTravel, setSelectedTravel, communityId, setCommunityId, setStep }) => {

    const inputRefs = useRef([null, null, null, null]);
    const [focusedIndex, setFocusedIndex] = useState(null);
    const [error, setError] = useState({
    });
    const datePickerRefs = useRef([]);

    const handleRadioChange = (id, value) => {
        setSelectedTravel(prevState => {
            const updatedTravel = prevState.map(item => {
                if (item.id === id) {
                    return { ...item, value: parseInt(value) };
                }
                return item;
            });
            return updatedTravel;
        });
    };

    const handleEmailPdfChange = (field, value) => {
        setFormDataState({ ...formDataState, [field]: parseInt(value) })
    }

    const handleEditClick = (index) => {
        if (focusedIndex === index) {
            setFocusedIndex(null);
        } else {
            inputRefs.current[index]?.focus();
            setFocusedIndex(index);
        }
    };

    const handleBlur = () => {
        setFocusedIndex(null);
    };

    const handleSubmit = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let newErrors = {};

        ["startDate", "endDate"].forEach((key) => {
            newErrors[key] = formDataState[key] ? { status: false, msg: "" } : { status: true, msg: `${key} is vereist` };
        });

        if (!communityId) {
            newErrors.communityId = { status: true, msg: "Community ID is vereist" };
        }

        if (formDataState.email) {
            if (!emailRegex.test(formDataState.vTravelAdminMailAddress) && formDataState.vTravelAdminMailAddress) {
                newErrors.email = { status: true, msg: "Ongeldig e-mailadres" };
                newErrors?.email?.status && handleEditClick(3);
            } else {
                newErrors.email = { status: false, msg: "" };
            }
        }

        setError(newErrors);

        const hasErrors = Object.values(newErrors).some(error => error.status);
        if (!hasErrors) {
            setStep("step2");
            getSubstitutorList();
        }
    };

    return (
        <div className="chat-layout-wrapper">
            <div className="app-inner-layout chat-layout">
                <PageHeading />
                <div className="app-inner-layout__wrapper overflow-hidden">
                    <div className="row col-lg-12">
                        <div className="col-xxl-5 col-md-6 col-12">
                            <Card className="main-card mb-3">
                                <CardBody className="p-xl-5 p-4 table-responsive travelCost-card-height overflow-visible berekenen">
                                    <InputWrapper label="Selecteer Organisatie*" className="text-start travelCost-light-text">
                                        <InputGroup>
                                            <DropdownList
                                                placeholder="Kies Organisatie"
                                                className={"travelCost-dropdownList"}
                                                data={formDataState.communityList}
                                                value={communityId && communityId}
                                                dataKey='iCommunityId'
                                                textField='name'
                                                onChange={(nextValue) => {
                                                    getTravelCostData(nextValue.iCommunityId)
                                                    setCommunityId(nextValue.iCommunityId)
                                                    setError((prevError) => ({
                                                        ...prevError,
                                                        ...formDataState?.email && {
                                                            email: { status: false, msg: '' }
                                                        },
                                                        communityId: { status: false, msg: '' }
                                                    }));
                                                    if (focusedIndex === 3) {
                                                        setFocusedIndex(null);
                                                    }
                                                }}
                                                required="true"
                                                style={error?.communityId?.status ? { border: '1px solid red', borderRadius: '4px' } : {}}
                                            />
                                        </InputGroup>
                                    </InputWrapper>

                                    <InputWrapper label="Selecteer periode*" className='text-start travelCost-light-text'>
                                        <Row>
                                            <Col xxl="6" className="pt-2">
                                                <Label className="export-label-style travelCost-light-text">{"Begindatum"}</Label>
                                                <Col sm={12}>
                                                    <InputGroup>
                                                        <div className={`customDatePicker w-100 form-element ${error?.startDate?.status ? 'error' : ''}`}>
                                                            <DatePicker
                                                                ref={(el) => datePickerRefs.current[0] = el}
                                                                selected={(formDataState.startDate !== '' && formDataState.startDate !== undefined) ? new Date(formDataState.startDate) : ''}
                                                                onChange={(val) => {
                                                                    if (val) {
                                                                        setFormDataState({
                                                                            ...formDataState,
                                                                            startDate: moment(val).format('YYYY-MM-DD'),
                                                                        });
                                                                        setError((prevError) => ({
                                                                            ...prevError,
                                                                            startDate: { status: false, msg: '' },
                                                                        }));
                                                                    } else {
                                                                        setFormDataState({ ...formDataState, startDate: '' });
                                                                        setError((prevError) => ({
                                                                            ...prevError,
                                                                            startDate: { status: true, msg: 'Start date is vereist' },
                                                                        }));
                                                                    }
                                                                }}
                                                                placeholderText={'dd-mm-jjjj'}
                                                                dateFormat="dd-MM-yyyy"
                                                                className='form-control w-100'
                                                            />
                                                            <CalendarIcon onClick={() => datePickerRefs.current[0]?.setFocus()} className="svg-pointer" />
                                                        </div>
                                                    </InputGroup>
                                                </Col>
                                            </Col>
                                            <Col xxl="6" className="pt-2">
                                                <Label className="export-label-style travelCost-light-text">{"Einddatum"}</Label>
                                                <Col sm={12}>
                                                    <InputGroup>
                                                        <div className={`customDatePicker w-100 form-element ${error?.endDate?.status ? 'error' : ''}`}>
                                                            <DatePicker
                                                                ref={(el) => datePickerRefs.current[1] = el}
                                                                selected={(formDataState.endDate !== '' && formDataState.endDate !== undefined) ? new Date(formDataState.endDate) : ''}
                                                                onChange={(val) => {
                                                                    if (val) {
                                                                        setFormDataState({
                                                                            ...formDataState,
                                                                            endDate: moment(val).format('YYYY-MM-DD'),
                                                                        });
                                                                        setError((prevError) => ({
                                                                            ...prevError,
                                                                            endDate: { status: false, msg: '' },
                                                                        }));
                                                                    } else {
                                                                        setFormDataState({ ...formDataState, endDate: '' });
                                                                        setError((prevError) => ({
                                                                            ...prevError,
                                                                            endDate: { status: true, msg: 'Start date is vereist' },
                                                                        }));
                                                                    }
                                                                }}
                                                                placeholderText={'dd-mm-jjjj'}
                                                                dateFormat="dd-MM-yyyy"
                                                                className='form-control w-100'
                                                            />
                                                            <CalendarIcon onClick={() => datePickerRefs.current[1]?.setFocus()} className="svg-pointer" />
                                                        </div>
                                                    </InputGroup>
                                                </Col>
                                            </Col>
                                        </Row>
                                        {(error?.startDate?.status || error?.endDate?.status) &&
                                            <Row className="text-danger ms-1 mt-2">
                                                Verplicht veld
                                            </Row>
                                        }
                                    </InputWrapper>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col-xxl-7 col-md-6 col-12">
                            <Card className="main-card mb-3">
                                <CardBody className="p-xl-5 p-4 table-responsive travelCost-card-height berekenen">
                                    <Row>
                                        <Col xl="12" xxl="6">
                                            <div className="btn-color-style travelCost-blue-text mb-2">
                                                Instellingen
                                            </div>
                                            <Row className="mt-3 mb-2">
                                                <Col md="8" lg="8" className="travelCost-bold-text">
                                                    Dagtypen
                                                </Col>
                                            </Row>
                                            {Object.keys(formDataState.travelData && formDataState.travelData.daytypes && selectedTravel).map((key) => (
                                                <Row className="align-items-center">
                                                    <Col md="8" lg="8" className="travelCost-light-text">
                                                        {formDataState.travelData.daytypes[key]?.desc && formDataState.travelData.daytypes[key].desc}
                                                    </Col>
                                                    <Col md="2" lg="2" className="d-flex justify-content-center p-2">
                                                        <GroupStatus iconChangeHandler={handleRadioChange} field={formDataState.travelData.daytypes[key]?.id} data={selectedTravel[key]['value'] ? 1 : 0} />
                                                    </Col>
                                                </Row>
                                            ))}

                                        </Col>
                                        <Col xl="12" xxl="6">
                                            <div className="mb-2">
                                            </div>
                                            <Row className="travelCost-card-top">
                                                <Col md="8" lg="8" className="travelCost-light-text">
                                                    Vergoeding per km afstand
                                                </Col>
                                                <Col md="3" lg="3" className="d-flex">
                                                    <span className="mt-1 me-2">€</span>
                                                    <Input
                                                        name="vPrice"
                                                        ref={(el) => (inputRefs.current[0] = el)}
                                                        style={
                                                            focusedIndex === 0
                                                                ? { width: '60px', height: "28px", border: '1px solid #878EA6', color: '#878EA6', fontWeight: 600 }
                                                                : { width: '60px', height: "28px", border: 'none', color: '#878EA6', fontWeight: 600 }
                                                        }
                                                        className="travelCost-disabled-text"
                                                        value={String(formDataState.vPrice).replace('.', ',')}
                                                        onChange={(e) => {
                                                            setFormDataState({ ...formDataState, vPrice: e.target.value })
                                                        }}
                                                        onBlur={handleBlur}
                                                        disabled={focusedIndex === 0 ? false : true}
                                                    />
                                                </Col>
                                                <Col md="1" lg="1">
                                                    <EditPen onClick={() => handleEditClick(0)} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="8" lg="8" className="travelCost-light-text">
                                                    Aftrek aantal km
                                                </Col>
                                                <Col md="3" lg="3">
                                                    <Input
                                                        name="vKm"
                                                        ref={(el) => (inputRefs.current[1] = el)}
                                                        style={
                                                            focusedIndex === 1
                                                                ? { width: '60px', height: "28px", border: '1px solid #878EA6', color: '#878EA6', fontWeight: 600, marginLeft: "16px" }
                                                                : { width: '60px', height: "28px", border: 'none', color: '#878EA6', fontWeight: 600, marginLeft: "16px" }
                                                        }
                                                        className="travelCost-disabled-text"
                                                        value={formDataState.vKm}
                                                        onChange={(e) => {
                                                            setFormDataState({ ...formDataState, vKm: e.target.value })
                                                        }}
                                                        onBlur={handleBlur}
                                                        disabled={focusedIndex === 1 ? false : true}
                                                    />
                                                </Col>
                                                <Col md="1" lg="1">
                                                    <EditPen onClick={() => handleEditClick(1)} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="8" lg="8" className="travelCost-light-text">
                                                    Maximumbedrag per dag
                                                </Col>
                                                <Col md="3" lg="3" className="d-flex">
                                                    <span className="mt-1 me-2">€</span>
                                                    <Input
                                                        ref={(el) => (inputRefs.current[2] = el)}
                                                        style={
                                                            focusedIndex === 2
                                                                ? { width: '60px', height: "28px", border: '1px solid #878EA6', color: '#878EA6', fontWeight: 600 }
                                                                : { width: '60px', height: "28px", border: 'none', color: '#878EA6', fontWeight: 600 }
                                                        }
                                                        className="travelCost-disabled-text"
                                                        value={String(formDataState.vBedrag).replace('.', '.')}
                                                        onChange={(e) => {
                                                            setFormDataState({ ...formDataState, vBedrag: e.target.value })
                                                        }}
                                                        onBlur={handleBlur}
                                                        disabled={focusedIndex === 2 ? false : true}
                                                    />
                                                </Col>
                                                <Col md="1" lg="1">
                                                    <EditPen onClick={() => handleEditClick(2)} />
                                                </Col>
                                            </Row>

                                            {
                                                formDataState?.email ?
                                                    <Row>
                                                        <Col md="8" lg="6" className="travelCost-light-text">
                                                            E-mail reiskosten bestuur
                                                        </Col>
                                                        <Col md="3" lg="5">
                                                            <Input
                                                                type="email"
                                                                name="vTravelAdminMailAddress"
                                                                ref={(el) => (inputRefs.current[3] = el)}
                                                                style={
                                                                    error?.email?.status ? {
                                                                        height: "28px", border: '1px solid red', color: '#878EA6', fontWeight: 600, marginLeft: "16px"
                                                                    } :
                                                                        focusedIndex === 3
                                                                            ? { height: "28px", border: '1px solid #878EA6', color: '#878EA6', fontWeight: 600, marginLeft: "16px" }
                                                                            : { height: "28px", border: 'none', color: '#878EA6', fontWeight: 600, marginLeft: "16px" }
                                                                }
                                                                className={`travelCost-disabled-text`}
                                                                value={formDataState.vTravelAdminMailAddress}
                                                                onChange={(e) => {
                                                                    setFormDataState({ ...formDataState, vTravelAdminMailAddress: e.target.value })
                                                                    setError((prevError) => ({
                                                                        ...prevError,
                                                                        email: { status: false, msg: '' }
                                                                    }));
                                                                }}
                                                                onBlur={handleBlur}
                                                                disabled={focusedIndex === 3 ? false : true}
                                                            />
                                                            {error?.email?.status && <p className="text-danger ms-3">{error?.email.msg}</p>}
                                                        </Col>
                                                        <Col md="1" lg="1">
                                                            <EditPen onClick={() => {
                                                                !error?.email?.status && handleEditClick(3)
                                                            }} />
                                                        </Col>
                                                    </Row> : <></>
                                            }

                                            <Row className="mt-3 mb-2">
                                                <Col md="8" lg="8" className="travelCost-bold-text">
                                                    Informeren vervanger
                                                </Col>
                                            </Row>
                                            <Row className="align-items-center">
                                                <Col md="8" lg="8" className="travelCost-light-text">
                                                    E-mail
                                                </Col>
                                                <Col md="2" lg="2" className="d-flex justify-content-center p-2">
                                                    <GroupStatus iconChangeHandler={handleEmailPdfChange} field={"email"} data={formDataState.email} />
                                                </Col>
                                            </Row>
                                            <Row className="align-items-center">
                                                <Col md="8" lg="8" className="travelCost-light-text">
                                                    PDF in Clooser.nl
                                                </Col>
                                                <Col md="2" lg="2" className="d-flex justify-content-center p-2">
                                                    <GroupStatus iconChangeHandler={handleEmailPdfChange} field={"pdf"} data={formDataState.pdf} />
                                                </Col>
                                            </Row>

                                        </Col>
                                        <Row className="mt-4 mb-2">
                                            <Col md="12" lg="12" className="travelCost-light-text text-center">
                                                Let op: <span className="travelCost-bold-text">Deze wijziging geldt alleen voor deze reiskostenberekening</span>
                                            </Col>
                                        </Row>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
            <div className="chat-layout-footer verzoken-footer-height">
                <CardFooter className="chat-layout-footer__inner">
                    <div className="d-flex justify-content-end">
                        <Button
                            outline
                            className="btn-outline-primary-blue btn-color-style footer--coordinator-planboard__btn"
                            onClick={handleSubmit}
                            type="button"
                            color="primary"
                        >
                            Volgende
                        </Button>
                    </div>
                </CardFooter>
            </div>
        </div>
    )
};

export default BasicInformation;
