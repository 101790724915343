import React, { useState } from "react";
import { Form, Formik } from "formik";
import { Card, CardBody, FormGroup, Input, Label, Spinner } from 'reactstrap'
import 'tippy.js/themes/light.css';
import GroupStatus from "../../../../../Components/IconDropdown/Cross_Tick/GroupStatus";

const Algemeen = ({ data, changeHandler, handleIconFieldChange, errorField }) => {
    const [loadingKeys, setLoadingKeys] = useState({})

    const defaultValue = {
        regio: data?.regio || '',
        website: data?.website || '',
        i36MonthAutoFill: data?.i36MonthAutoFill || ''
    }

    const handleToggle = (field, data) => {
        setLoadingKeys(prev => ({ ...prev, [field]: true }));
        handleIconFieldChange(field, data, setLoadingKeys)
    };

    return (
        <>
            <Card className="main-card">
                <CardBody className="d-flex flex-column">
                    <div className="btn-color-style travelCost-blue-text mb-4">Algemeen</div>

                    <Formik
                        enableReinitialize
                        initialValues={defaultValue}
                        validateOnChange={true}
                    >
                        {({ values, touched, errors, handleChange, setFieldError }) => (
                            <Form>
                                <FormGroup className={`form-element ${(errors.regio) ? 'error' : ''}`}>
                                    <Label for="regio">Regio</Label>
                                    <Input
                                        name="regio"
                                        type="text"
                                        value={values.regio}
                                        onChange={(e) => {
                                            handleChange(e);
                                            changeHandler("regio", e.target.value, null, "Regio");
                                        }}
                                    />


                                </FormGroup>

                                <FormGroup className={`form-element ${(errors?.website) ? "error" : ""}`}>
                                    <Label for="website">Website</Label>
                                    <Input
                                        name="website"
                                        type="text"
                                        value={values.website}
                                        onChange={(e) => {
                                            handleChange(e);
                                            changeHandler("website", e.target.value, setFieldError, "Website");
                                        }}
                                    />
                                </FormGroup>

                                <FormGroup className={`form-element ${errorField === 'i36MonthAutoFill' ? 'error' : ''}`}>
                                    <Label for="i36MonthAutoFill">36 Maanden automatisch aanvullen</Label>
                                    <div className="position-relative">
                                        <div className="d-flex gap-1 align-items-center ">
                                            {loadingKeys["i36MonthAutoFill"] ? (
                                                <div className="spinner-icon-change">
                                                    <div><Spinner size="sm" color="primary" /></div>
                                                </div>
                                            ) :
                                                <GroupStatus iconChangeHandler={handleToggle} field={"i36MonthAutoFill"} data={values?.i36MonthAutoFill ? 1 : 0} />
                                            }
                                        </div>
                                    </div>
                                </FormGroup>
                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card>
        </>
    )
}

export default Algemeen