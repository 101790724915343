import { useEffect, useState } from 'react'
import FreeFields from './FreeFields'
import Melders from './Melders'
import HaldaysTable from './HaldaysTable'
import { Button, Card, CardBody, CardFooter } from 'reactstrap'
import Loader from 'react-loaders'
import { ReactComponent as BackIcon } from "../../../../../../assets/utils/images/svgs/arrow-left.svg";
import { ReactComponent as AddIcon } from "../../../../../../assets/utils/images/svgs/addBlue.svg"
import { debounce, schoolTabs } from '../../../Schools/utils'
import { toast } from 'react-toastify'
import Emails from './Emails'
import { useLocation, useParams } from 'react-router'
import ScholenService from 'Api/Instellingen/Scholen'

const Instellingen = ({ setActiveTab }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [melderModal, setMelderModal] = useState(false);
    const [errorField, setErrorField] = useState('');
    const { schoolId } = useParams();
    const location = useLocation();
    const { communityId } = location.state || {};
    const [data, setData] = useState({});

    useEffect(() => {
        setIsLoading(true);
        fetchInstellingenData();
    }, []);

    const fetchInstellingenData = async () => {
        try {
            const response = await ScholenService.getInstellingenData(communityId, schoolId);
            if (response.data.status) {
                setData(response.data.data);
            }
        } catch (error) {
            console.error(`API error:`, error);
        } finally {
            setIsLoading(false);
        }
    };

    const callApi = (field, value, isHalfDayTable) => {
        const bodyData = {
            attr: field,
            [field]:value,
        }
        toast.dismiss();
        ScholenService.updateScholenData(bodyData, schoolId)
            .then(response => {
                if (!response.data.status) {
                    setErrorField(field);
                    const errors = response.data.data[field];
                    if (Array.isArray(errors)) {
                        toast.error(errors.join(','));
                    } else {
                        toast.error(errors);
                    }
                }
                if (response.data.status && isHalfDayTable) {
                    fetchInstellingenData();
                }
            })
            .catch(error => {
                console.error('API error:', error);
            });
    };
    const debouncedApiCall = debounce(callApi, 500);

    const handleFieldChange = (field, value, isHalfDayTable = false) => {
        setErrorField('');
        debouncedApiCall(field, value, isHalfDayTable);
    };

    return (
        <>
            {
                isLoading ?
                    <Card className="main-card">
                        <CardBody className="d-flex flex-column">
                            <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
                        </CardBody>
                    </Card> : <>
                        <div className='row instellingen'>
                            <div className='col-xxl-6 col-12'>
                                <FreeFields data={data?.schools} errorField={errorField} changeHandler={handleFieldChange} />
                            </div>
                            <div className='col-xxl-6 col-12'>
                                <Melders setModal={setMelderModal} modal={melderModal} iSchoolId={schoolId} iCommunityId={communityId} />
                            </div>
                            <div className='col-xxl-6 col-12'>
                                <HaldaysTable data={data?.shifts} changeHandler={handleFieldChange} />
                            </div>
                            <div className='col-xxl-6 col-12'>
                                <Emails data={data?.schools} errorField={errorField} changeHandler={handleFieldChange} />
                            </div>
                        </div>
                        <div className="chat-layout-footer py-5">
                            <CardFooter className="chat-layout-footer__inner gebruikers-footer">
                                <div className="d-flex gap-3">
                                    <Button
                                        outline
                                        className="btn-outline-primary-blue d-flex justify-content-center align-items-center gap-2"
                                        onClick={() => setMelderModal({ type: "add", state: true })}
                                        type="button"
                                        color="primary"
                                    >
                                        Melder <AddIcon />
                                    </Button>
                                    <Button
                                        outline
                                        className="btn-outline-primary-blue ms-auto"
                                        onClick={() => {
                                            setActiveTab(schoolTabs.Algemeen)
                                        }}
                                        type="button"
                                        color="primary"
                                    >
                                        <BackIcon />
                                    </Button>
                                </div>
                            </CardFooter>
                        </div>
                    </>
            }
        </>
    )
}

export default Instellingen