import React, { useEffect, useState } from 'react'
import { Card, CardBody, Input } from 'reactstrap'

const Remark = ({data, changeHandler}) => {
    const [description, setDescription] = useState(data);
    const updateHandler = (e)=>{
        setDescription(e.target.value);
        changeHandler("tSchool_memo", e.target.value);
    }
    useEffect(()=>{
        setDescription(data)
    }, [data])
    return (
        <Card className="main-card">
            <CardBody className="d-flex flex-column">
                <h5 className="mb-4">Opmerking</h5>
                <Input type="textarea" name="tSchool_memo" id="tSchool_memo" rows={6} value={description} onChange={updateHandler}/>
            </CardBody>
        </Card>
    )
}

export default Remark