import React from "react";
import DefaultModal from "Components/Modal/DefaultModal";
import {
	BsCheckCircle,
	BsXCircle,
	BsQuestionCircle,
	BsArrowRepeat,
	BsFillCircleFill,
} from "react-icons/bs";
import { ListGroup, ListGroupItem } from "reactstrap";
import { useAvailabilityContext } from "./context/AvailabilityContext";

const LegendModal = () => {
	const { openLegend, setOpenLegend } = useAvailabilityContext();

	return (
		<DefaultModal
			modal={openLegend}
			headerTitle={"Legenda"}
			toggle={() => setOpenLegend(false)}
			showFooterBtns={false}
			classes="modal-dialog__over feedback-modal-style availability-modal wc-500"
		>
			<ListGroup flush>
				<ListGroupItem>
					<BsCheckCircle color="#3AC47D" fontSize={"21px"} className="me-3" />
					Beschikbaar
				</ListGroupItem>
				<ListGroupItem>
					<BsXCircle color="#FB2E5F" fontSize={"21px"} className="me-3" />
					Niet beschikbaar
				</ListGroupItem>
				<ListGroupItem>
					<BsQuestionCircle color="#FFA500" fontSize={"21px"} className="me-3" />
					Geen keuze gemaakt
				</ListGroupItem>
				<ListGroupItem>
					<BsArrowRepeat color="#B4B4B4" fontSize={"21px"} className="me-3" />
					Status van hele rij of kolom aanpassen
				</ListGroupItem>
				<ListGroupItem>
					<BsFillCircleFill color="#B4B4B4" fontSize={"21px"} className="me-3" />
					Geblokkeerd (status is niet te wijzigen door vervanger) - grijze kleur
				</ListGroupItem>
			</ListGroup>
		</DefaultModal>
	);
};

export default LegendModal;
