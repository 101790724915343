import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import axiosInstance from "Api";
import { CardBody } from "reactstrap";
import { Formik } from 'formik';
import * as Yup from "yup";
import { toast } from 'react-toastify';

const GeneralSettingsForm = () => {
    const [generalSettings, setGeneralSettings] = useState();
    const [emailModal, setEmailModal] = useState(false);
    const [smsModal, setSmsModal] = useState(false);

    const [emailData, setEmailData] = useState({
        email: "",
        emailError: ""
    })
    const [smsData, setSmsData] = useState({
        phoneNo: "",
        phoneError: ""
    })
    const [powerBiLink, setPowerBiLink] = useState({
        link: "",
        linkError: ""
    })

    useEffect(() => {
        axiosInstance.get('general-settings').then(response => {
            if (response.status === 200) {
                setGeneralSettings(response.data.data);
            }
        }).catch(e => console.log(e));
        axiosInstance.get('power-bi/fetch-link').then(response => {
            if (response.data.status) {
                setPowerBiLink({
                    link: response.data.data,
                    linkError: ""
                });
            }
        }).catch(e => console.log(e));
    }, [])

    const emailDataValidationSchema = Yup.object().shape({
        subject: Yup.string().required("veld is verplicht"),
        content: Yup.string().required("veld is verplicht")
    });

    const smsDataValidationSchema = Yup.object().shape({
        smsContent: Yup.string().required("veld is verplicht")
    });

    const textChangeHandler = (e, type) => {
        const value = e.target.value;
        if (type === "email") {
            setEmailData(prev => ({ ...prev, email: value, emailError: "" }));
        }
        if (type === "sms") {
            setSmsData(prev => ({ ...prev, phoneNo: value, phoneError: "" }));
        }
        if (type === "link") {
            setPowerBiLink(prev => ({ ...prev, link: value, linkError: "" }));
        }
    };

    const validateEmailSmsLink = (type) => {
        console.log(type, "type")
        if (type === "email") {
            const emails = emailData.email.split(",").map((email) => email.trim());
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const invalidEmails = emails.filter((email) => !emailPattern.test(email));
            if (!emailData.email) {
                setEmailData((prev) => ({ ...prev, emailError: "E-mailadres is vereist" }));
            }
            else if (invalidEmails.length > 0) {
                setEmailData((prev) => ({ ...prev, emailError: "Ongeldig e-mailadres" }));
            }
            else {
                setEmailData((prev) => ({ ...prev, emailError: "" }));
                setEmailModal(true)
                setSmsModal(false)
            }
        }
        if (type === "sms") {
            const phonePattern = /^[0-9\-\(\)\s]+$/;
            const phoneNumber = smsData.phoneNo.replace(/\D/g, "");
            if (!smsData.phoneNo) {
                setSmsData((prev) => ({ ...prev, phoneError: "Telefoonnummer is vereist" }));
            }
            else if (phoneNumber.length < 10 || phoneNumber.length > 16 || !phonePattern.test(smsData.phoneNo)) {
                setSmsData(prev => ({ ...prev, phoneError: "Het telefoonnummer moet uit 10 tot 16 cijfers bestaan." }))
            }
            else {
                setEmailData((prev) => ({ ...prev, phoneError: "" }));
                setSmsModal(true)
                setEmailModal(false)
            }
        }
        if (type === "link") {
            const linkPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/;
            if (!powerBiLink.link) {
                setPowerBiLink((prev) => ({ ...prev, linkError: "Link is vereist" }));
            } 
            else if (!linkPattern.test(powerBiLink.link)) {
                setPowerBiLink((prev) => ({ ...prev, linkError: "Ongeldige link." }));
            } 
            else {
                setPowerBiLink((prev) => ({ ...prev, linkError: "" }));
                saveLinkHandler();
            }
        }
    }

    const saveLinkHandler = ()=>{
        const data = {
            link: powerBiLink.link
        }
        axiosInstance.post('power-bi/save-link', data).then(response => {
            if (response.data.status) {
                toast.success("Link succesvol bijgewerkt.")
            } else {
                toast.error("Kan de link niet opslaan.");
            }
        }).catch(e => console.log(e))
    }

    const handleSubmit = (type, values, setErrors) => {
        let data = {}
        const errors = {};
        if (type === "email") {
            if (!values.subject) errors.subject = 'veld is verplicht';
            if (!values.content) errors.content = 'veld is verplicht';
            if (Object.keys(errors).length > 0) {
                setErrors(errors);
                return;
            }
            data = {
                type,
                sendTo: emailData.email,
                subject: values.subject,
                content: values.content
            }
        }
        if (type === "sms") {
            if (!values.smsContent) {
                setErrors({ smsContent: 'veld is verplicht' });
                return;
            }
            data = {
                type,
                sendTo: Number(smsData.phoneNo),
                subject: "",
                content: values.smsContent
            }
        }
        axiosInstance.post('test-emails-or-sms', data).then(response => {
            if (response.data.status) {
                toast.success(type === "email" ? "E-mail succesvol verzonden" : "SMS succesvol verzonden")
                if (type === "email") {
                    setEmailModal(false)
                    setEmailData({ email: "", emailError: "" })
                } else {
                    setSmsModal(false)
                    setSmsData({ phoneNo: "", phoneError: "" })
                }
            } else {
                toast.error(type === "email" ? "Kan geen e-mail verzenden" : "Kan geen SMS versturen")
            }
        }).catch(e => console.log(e))
    }

    return (
        <>
            <Row className="mb-5">
                <Col md={6}>
                    <Card className="main-card mb-3" style={{ height: '100%' }}>
                        <CardBody className="px-4">
                            <p className="py-2 mb-3" style={{ fontWeight: '500', color: '#026094' }}>E-mail/SMS - settings</p>
                            <Row>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for="password_current">
                                            Enable Email :
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for="password_current">
                                            {generalSettings?.enableEmail !== '' ? generalSettings?.enableEmail : '--'}
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for="password">
                                            Enable SMS :
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for="password">
                                            {generalSettings?.enableSms !== '' ? generalSettings?.enableSms : '--'}
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for="password_current">
                                            Redirect Email :
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for="password_current">
                                            {generalSettings?.environment === "production" ? "No Redirection" : generalSettings?.redirectionEmail !== '' ? generalSettings?.redirectionEmail : '--'}
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for="password_current">
                                            Redirect SMS :
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for="password_current">
                                            {generalSettings?.environment === "production" ? "No Redirection" : generalSettings?.redirectionSms !== '' ? generalSettings?.redirectionSms : '--'}
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for="password_current" >
                                            Send Mail :
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col sm="6" className='d-flex gap-2 mb-3'>
                                    <div>
                                        <input name="sms" type="text" className="form-control py-2" placeholder="Enter email address" value={emailData.email} onChange={(e) => textChangeHandler(e, "email")} />
                                        {emailData?.emailError && <FormFeedback className='d-block'>{emailData?.emailError}</FormFeedback>}
                                    </div>
                                    <div>
                                        <Button className="btn-color-style opslaan-btn py-2" size="sm" outline color="primary" type="button" onClick={() => validateEmailSmsLink("email")}>
                                            Verzenden
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for="password_current">
                                            Send SMS :
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col sm="6" className='d-flex gap-2 mb-3'>
                                    <div>
                                        <input name="sms" type="number" className="form-control py-2 input-num" placeholder="Enter phone number" value={smsData.phoneNo} onChange={(e) => textChangeHandler(e, "sms")} />
                                        {smsData?.phoneError && <FormFeedback className='d-block'>{smsData.phoneError}</FormFeedback>}
                                    </div>
                                    <div>
                                        <Button className="btn-color-style opslaan-btn py-2" size="sm" outline color="primary" type="button" onClick={() => validateEmailSmsLink("sms")}>
                                            Verzenden
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className="main-card mb-3" style={{ height: '100%' }}>
                        <CardBody className="px-4">
                            <p className="py-2 mb-3" style={{ fontWeight: '500', color: '#026094' }}>Rapportage</p>
                            <Row>
                                <Col sm="4">
                                    <FormGroup>
                                        <Label for="password_current" >
                                            Link Power Bi:
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col sm="7" className='d-flex gap-2 mb-3'>
                                    <div className='w-100'>
                                        <input name="sms" type="text" className="form-control py-2" value={powerBiLink?.link} onChange={(e) => textChangeHandler(e, "link")} />
                                        {powerBiLink?.linkError && <FormFeedback className='d-block'>{powerBiLink?.linkError}</FormFeedback>}
                                    </div>
                                </Col>
                            </Row>
                            <div className="text-end mt-auto position-absolute" style={{ bottom: '25px', right: '25px' }}>
                                <Button className="btn-color-style opslaan-btn py-2 px-3" size="sm" outline color="primary" type="button" onClick={() => validateEmailSmsLink("link")}>
                                    Opslaan
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
            <Col md={6} xs={12}>
                    <Card className="main-card mb-3" style={{ height: '100%' }}>
                        <CardBody className="px-4">
                            <p className="py-2 mb-3" style={{ fontWeight: '500', color: '#026094' }}>Logging</p>
                            <Row >
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for="password_current" >
                                            Max Login Attempts
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col sm="6" className='d-flex gap-2 mb-3 px-0'>
                                    <FormGroup>
                                        <Label for="password_current">
                                            {generalSettings?.loginMaxAttempts ?? '--'}
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label for="password_current" >
                                            Block Duration
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col sm="6" className='d-flex gap-2 mb-3 px-0'>
                                    <FormGroup>
                                        <Label for="password_current">
                                            {generalSettings?.loginBlockDuration ?? '--'}
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {/* Email Modal */}
            <Modal isOpen={emailModal} className="feedback-modal-style link-form sms-form">
                <ModalHeader>
                    Send Email
                    <div className="cross-icon-color">
                        <i className="nav-link-icon lnr-cross m-1 cursor-pointer" onClick={() => setEmailModal(prev => !prev)}> </i>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <Formik
                        initialValues={{ subject: "", content: "" }}
                        validationSchema={emailDataValidationSchema}
                    >
                        {({ values, handleChange, handleBlur, setErrors, errors }) => (
                            <Form>
                                <FormGroup className="mt-3">
                                    <Label for="subject">
                                        Subject
                                    </Label>
                                    <Input name="subject"
                                        type="text" onChange={handleChange} onBlur={handleBlur}
                                        value={values.subject} />
                                    {errors.subject && <FormFeedback className='d-block'>{errors.subject}</FormFeedback>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="content" className="d-flex justify-content-between">Content</Label>
                                    <Input type="textarea" name="content" id="content" onChange={handleChange} onBlur={handleBlur}
                                        value={values.content} rows={6} />
                                    {errors.content && <FormFeedback className='d-block'>{errors.content}</FormFeedback>}
                                </FormGroup>
                                <ModalFooter className="d-flex align-items-center justify-content-center border-bottom-0 py-4 mt-4">
                                    <Button className="btn-color-style  opslaan-btn" size="sm" outline color="primary" type="button" onClick={(e) => { e.preventDefault(); handleSubmit("email", values, setErrors) }}>
                                        Indienen
                                    </Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>

            {/* SMS Modal */}
            <Modal isOpen={smsModal} className="feedback-modal-style link-form sms-form">
                <ModalHeader>
                    Send SMS
                    <div className="cross-icon-color text-end">
                        <i className="nav-link-icon lnr-cross m-1 cursor-pointer" onClick={() => setSmsModal(prev => !prev)}> </i>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <Formik
                        initialValues={{ smsContent: "" }}
                        validationSchema={smsDataValidationSchema}
                    >
                        {({ values, handleChange, handleBlur, errors, setErrors }) => (
                            <Form>
                                <FormGroup>
                                    <Label for="smsContent" className="d-flex justify-content-between">Content</Label>
                                    <Input type="textarea" name="smsContent" id="smsContent" onChange={handleChange} onBlur={handleBlur}
                                        value={values.smsContent} rows={6} />
                                    {errors.smsContent && <FormFeedback className='d-block'>{errors.smsContent}</FormFeedback>}
                                </FormGroup>
                                <ModalFooter className="d-flex align-items-center justify-content-center border-bottom-0 py-4 mt-4">
                                    <Button className="btn-color-style opslaan-btn" size="sm" outline color="primary" type="button" onClick={(e) => { e.preventDefault(); handleSubmit("sms", values, setErrors) }}>
                                        Indienen
                                    </Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>
        </>
    )
}

export default GeneralSettingsForm;
