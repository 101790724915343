import axiosInstance from "Api";

const showContract = (data) => {
    return axiosInstance.post(`instellingen/contract/show`, data)
}

const contractFilter = (communityId, contractId) => {
    return axiosInstance.get(`instellingen/contract/filters-list?iCommunityId=${communityId}&contractId=${contractId}`)
}

const contractFilterSave = (data) => {
    return axiosInstance.post("instellingen/contract/filters-save", data)
}
const contractFilterDelete = (id) => {
    return axiosInstance.get(`instellingen/contract/filter-delete/${id}`);
};

const showFilter = (data) => {
    return axiosInstance.post(`instellingen/contract/filter-show`, data);
};

const ContractWijzigenToevoegenService = {
    showContract,
    contractFilter,
    contractFilterSave,
    contractFilterDelete,
    showFilter
};


export default ContractWijzigenToevoegenService;