import React, { Fragment, useEffect, useState } from "react";
import AppHeader from "../../../../Layout/AppHeader";
import AppFooter from "Layout/AppFooter";
import { ReactComponent as Building } from "../../../../assets/utils/images/svgs/building.svg";
import { ReactComponent as FourLines } from "../../../../assets/utils/images/svgs/FourHorLine.svg";
import { ReactComponent as AddIcon } from "../../../../assets/utils/images/svgs/addBlue.svg";
import {
    Button, CardFooter, Modal, ModalBody, ModalFooter, ModalHeader,
    FormGroup, Form, Label,
    Input
} from 'reactstrap';
import Algemeen from "./Algemeen"
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PageHeading from "Layout/AppMain/PageHeading";
import { useLocation, useNavigate, useParams } from "react-router";
import { userTabs } from "./utils";
import Scholen from "./Scholen";
import VrijeVelden from "./VrijeVelden";
import { ErrorMessage, Formik } from "formik";
import { toast } from "react-toastify";
import axiosInstance from "Api";
import { debounce } from "helperFunctions";
import { DropdownList } from "react-widgets/cjs";

const validationRules = {
    Website: {
        pattern: /^(https?:\/\/)?([\w-]+(\.[\w-]+)+.*)$/i,
        errorMessage: "Ongeldige website-URL"
    },
};

const Besturens = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(userTabs.Algemeen);
    const [communityLists, setCommunityLists] = useState([]);
    const [isLoading, setLoader] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [disableModalSubmitButton, setDisableModalSubmitButton] = useState(true);
    const [modalData, setModalData] = useState({ type: '', data: {} })
    const [errorField, setErrorField] = useState('');
    const [viewData, setViewData] = useState('');
    const [isdataDelete, setDataDelete] = useState(false);
    const [editSelectedRow, setEditSelectedRow] = useState(null);
    const [pageSizeCount, setPageSizeCount] = useState(20);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [schoolTableData, setSchoolTableData] = useState([]);
    const [showSchoolLoader, setShowSchoolLoader] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [searchCommunityLists, setSearchCommunityLists] = useState([]);

    const { data, communityList } = location.state || {};
    const { id } = useParams();

    const [communityId, setCommunityId] = useState(id);
    const [formData, setFormData] = useState({
        inputField1: "",
        inputField2: "",
        inputField3: "",
        inputType1: "",
        inputType2: "",
        inputType3: "",
        useSalaryColumn: false
    });
    const [disableSubmit, setDisableSubmit] = useState(true);

    useEffect(() => {
        setLoader(true)
        fetchDetail();
        navigate(`/beheerder/besturen/${communityId}`, { state: { data: data, communityList: communityList } });
    }, [communityId])

    useEffect(() => {
        setFormData({
            inputField1: viewData?.inputField1 || "",
            inputField2: viewData?.inputField2 || "",
            inputField3: viewData?.inputField3 || "",
            inputType1: viewData?.inputType1 || "",
            inputType2: viewData?.inputType2 || "",
            inputType3: viewData?.inputType3 || "",
            useSalaryColumn: viewData?.useSalaryColumn || false
        });
    }, [viewData]);

    useEffect(() => {
        setCommunityLists(communityList)
    }, [])

    const fetchDetail = (d = null) => {
        const value = d ?? communityId;
        axiosInstance.get(`/bestuur/show/${value}`).then(response => {
            if (response?.status === 200) {
                setLoader(false);
                setViewData(response.data.data);
            } else {
            }
        })
    }

    const fetchSchoolList = () => {
        axiosInstance.get(`/bestuur/scholen-list?iCommunityId=${communityId}&pagesize=${pageSizeCount}&page=${pageNumber}&search=${searchValue}`)
            .then(response => {
                if (response?.data?.data?.schoolLists) {
                    setShowSchoolLoader(false);
                    setSchoolTableData(response.data.data.schoolLists);
                    setTotalPageCount(response.data.data.paginate);
                }
            })
            .catch(error => {
                console.error(error);
            });
    };

    const indexPageSize = (val) => {
        setPageSizeCount(val);
    };

    const functieTablePageSize = (val) => {
        setPageNumber(1);
        indexPageSize(val);
    };

    const userTablePageSize = (val) => {
        setPageNumber(1);
        indexPageSize(val);
    }

    const paginate = (val) => {
        setPageNumber(val);
    };

    const getActiveTab = (tabState) => {
        switch (tabState) {
            case userTabs.Algemeen:
                return <Algemeen setLoader={setLoader} setCommunityId={setCommunityId} communityId={communityId} fetchDetail={fetchDetail} data={viewData} communityList={communityList} isLoading={isLoading} setOpenModal={setOpenModal} setModalData={setModalData} handleFieldChange={handleFieldChange} handleIconFieldChange={handleIconFieldChange} isdataDelete={isdataDelete} setDataDelete={setDataDelete} />
            case userTabs.Scholen:
                return <Scholen tableData={schoolTableData} handleSchoolClick={handleSchoolClick} setCommunityId={setCommunityId} communityId={communityId} searchValue={searchValue} communityList={communityList} setSearchValue={setSearchValue} pageNumber={pageNumber} setPageNumber={setPageNumber} paginate={paginate} userTablePageSize={userTablePageSize} functieTablePageSize={functieTablePageSize} setPageSizeCount={setPageSizeCount} totalPageCount={totalPageCount} toggle={toggle} showLoader={showSchoolLoader} pageSizeCount={pageSizeCount} setShowLoader={setShowSchoolLoader} editSelectedRow={editSelectedRow} fetchSchoolList={fetchSchoolList} setEditSelectedRow={setEditSelectedRow} data={viewData} isLoading={isLoading} setOpenModal={setOpenModal} setModalData={setModalData} />
            case userTabs.VrijeVelden:
                return <VrijeVelden data={viewData} setFormData={setFormData} setCommunityId={setCommunityId} communityId={communityId} isLoading={isLoading} communityList={communityList} callApi={callApi} setViewData={setViewData} />

            default:
                break;
        }
    }

    const isActiveTab = (currentTab) => {
        return activeTab === currentTab
    }

    const toggle = () => {
        setOpenModal(false);
        setDataDelete && setDataDelete(false);
        setDisableModalSubmitButton(true);
        setTimeout(() => {
            setModalData({ type: '', data: {} });
        }, 500);
    }

    const modalFormSubmitHandler = () => {
        if (modalData?.type === "schoolSave") {
            axiosInstance.post(`bestuur/scholen/save`, {
                iCommunityId: viewData?.iCommunityId,
                vSchoolName: modalData?.data?.vSchoolName,
                eStatus: "Active"
            }).then((response) => {
                if (response?.data?.status) {
                    toggle();
                    fetchSchoolList();
                } else {
                    toast.error("Kan niet opslaan.");
                    toggle();
                }
            }).catch((error) => {
                toggle();
                console.error(error);
                toast.error("Er is iets misgegaan");
            })
        } else if (modalData?.type === "schoolUpdate") {
            axiosInstance.put(`bestuur/scholen/update`, {
                iCommunityId: viewData?.iCommunityId,
                vSchoolName: modalData?.data?.vSchoolName,
                iSchoolId: modalData?.data?.iSchoolId
            }).then((response) => {
                if (response?.data?.status) {
                    toggle();
                    fetchSchoolList();
                } else {
                    toast.error("Kan niet updaten.");
                }
            }).catch((error) => {
                console.error(error);
                toast.error("Er is iets misgegaan");
                toggle();
            })
        }
        else {
            axiosInstance.get(`/bestuur/delete/${viewData.iCommunityId}`).then((response) => {
                if (response?.data?.status) {
                    toggle();
                    navigate('/beheerder/besturen')
                } else {
                    toggle();
                    toast.error("Kan niet verwijderen.");
                }
            }).catch((error) => {
                toggle();
                console.error(error);
                toast.error("Kan niet verwijderen.");
            })
        }
    };

    const callApi = (field, value, setFieldError = () => { }, errorField = "") => {

        if (viewData[field] === value || (viewData[field] === null && value === "")) {
            toast.dismiss();
            // if (viewData[field] === value) toast.error("Geen wijzigingen gedetecteerd.");
            return;
        }

        if (errorField === 'Website' && value !== "") {
            const rule = validationRules[errorField];

            if (rule && !rule.pattern.test(value)) {
                setFieldError?.(field, rule.errorMessage);
                toast.dismiss();
                toast.error(rule.errorMessage);
                return;
            }
        }

        if (field === "vCommunityName" && value === "") {
            setFieldError?.(field, "Naam bestuur is vereist");
            return;
        }


        const apiData = {
            iCommunityId: parseInt(communityId),
            ...(field ? { attr: field } : {}),
            [field]: value,
        }
        axiosInstance.put('/bestuur/update', apiData)
            .then(response => {
                if (response?.data?.status) {
                    setViewData((prev) => {
                        return { ...prev, [field]: value };
                    })
                    toast.dismiss();
                    toast.success("Wijzigingen opgeslagen.");
                    return
                };
                if (!response?.data?.status) {
                    setErrorField(field);
                    const errors = response.data.data[field];
                    if (Array.isArray(errors)) {
                        toast.error(errors.join(','));
                    } else {
                        toast.error(errors);
                    }
                }
            })
            .catch(error => {
                console.error('API error:', error);
                toast.dismiss();
                toast.error("serverfout")
            })
    };

    const debouncedApiCall = debounce(callApi, 700);

    const handleFieldChange = (field, value, setFieldError = () => { }, errorField = "") => {
        debouncedApiCall(field, value, setFieldError, errorField);
    };

    const handleIconFieldChange = (field, value, setFieldError = () => { }) => {
        setFieldError = () => { }
        callApi(field, value);
    };

    const handleSchoolClick = (from, data = null) => {
        if (from === "save") {
            setOpenModal(true);
            setModalData({ type: 'schoolSave', data: {} });
        } else {
            setOpenModal(true);
            setModalData({ type: 'schoolUpdate', data: data });
        }
    }

    const isFormChanged = () => {
        return JSON.stringify(formData) !== JSON.stringify({
            inputField1: viewData?.inputField1,
            inputField2: viewData?.inputField2,
            inputField3: viewData?.inputField3,
            inputType1: viewData?.inputType1,
            inputType2: viewData?.inputType2,
            inputType3: viewData?.inputType3,
            useSalaryColumn: viewData?.useSalaryColumn
        });
    };

    useEffect(() => {
        setDisableSubmit(!isFormChanged());
    }, [formData, viewData]);

    const handleSubmitForm = () => {
        setDisableSubmit(true)
        const apiData = {
            iCommunityId: viewData?.iCommunityId,
            ...formData,
        }
        axiosInstance.post('/bestuur/save-free-fields', apiData)
            .then(response => {
                if (response?.data?.status) {
                    toast.dismiss();
                    toast.success("Bijgewerkt! De wijzigingen zijn succesvol opgeslagen.")
                } else {
                    setDisableSubmit(false)
                    toast.dismiss();
                    toast.error("Fout bij het bijwerken! Probeer het opnieuw.")
                }
            })
            .catch(error => {
                setDisableSubmit(false)
                console.error('API error:', error);
                toast.dismiss();
                toast.error("Fout bij het bijwerken! Probeer het opnieuw.")
            })
    }

    const fetchCommunityList = (searchTerm) => {
        if (searchTerm !== "") {
            axiosInstance
                .get(
                    "bestuur?search=" +
                    searchTerm
                )
                .then((response) => {
                    if (response?.data?.status) {
                        setTotalPageCount(response.data.data.paginate);
                        setSearchCommunityLists((prev) => {
                            return response.data.data.communitiesLists.map((item) => ({
                                value: item.iCommunityId,
                                label: item.vCommunityName,
                            }));
                        })
                    }
                })
                .catch((e) => console.error(e));
        } else {
            setCommunityLists(communityList)
        }
    };

    const debounceSearchCommunity = debounce(fetchCommunityList, 500);

    const fetchCommunitySearch = (searchTerm) => {
        debounceSearchCommunity(searchTerm)
    }

    const handleCommunityChange = (selectedValue) => {
        setCommunityId(selectedValue);
        setSearchCommunityLists([]);
        setCommunityLists((prev) => {
            let updatedList = [...prev];
            
            if (updatedList.length > 0) {
                updatedList.pop(); 
            }

            const selectedCommunity = searchCommunityLists.find(item => item.value === parseInt(selectedValue));
            if (selectedCommunity) {
                updatedList.unshift(selectedCommunity); 
            }

            return updatedList;
        });
    };

    return (
        <Fragment>
            <AppHeader />
            <div className="besturen-view contract-typen-view">
                <PageHeading data={data?.iCommunityId} />
                <TransitionGroup>
                    <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                        timeout={1500} enter={false} exit={false}>
                        <Fragment>
                            <div className="d-flex gap-4" style={{ marginBottom: `${activeTab === userTabs.Urenoverzicht || activeTab === userTabs.Beschikbaarheid ? '50px' : '20px'}` }}>
                                <div className={activeTab === userTabs.Beschikbaarheid ? "w-auto user-ctn col" : " user-ctn w-auto col"}>
                                    {getActiveTab(activeTab)}

                                </div>
                                {
                                    activeTab !== userTabs.Beschikbaarheid &&
                                    <div className="users-tab">
                                        <p className={isActiveTab(userTabs.Algemeen) ? 'active' : ''} onClick={() => { setActiveTab(userTabs.Algemeen) }}><i className="metismenu-icon pe-7s-home" style={{ fontSize: '24px' }}></i>Algemeen</p>
                                        <p className={isActiveTab(userTabs.Scholen) ? 'active' : ''} onClick={() => { setActiveTab(userTabs.Scholen); }}><Building style={{ width: '22px', height: '24px' }} />Scholen</p>
                                        <p className={isActiveTab(userTabs.VrijeVelden) ? 'active' : ''} onClick={() => { setActiveTab(userTabs.VrijeVelden); }}><FourLines style={{ width: '22px', height: '24px' }} />Vrije Velden</p>
                                    </div>
                                }
                            </div>
                                    <div className={`chat-layout-footer verzoken-footer-height`}>
                                        <CardFooter className="chat-layout-footer__inner">
                                            <div className={`d-flex gap-3 ${activeTab === userTabs?.VrijeVelden ? "justify-content-between" : ""}`}>
                                                <div style={{minWidth: "150px"}}>
                                                    <DropdownList
                                                        dataKey="value"
                                                        textField="label"
                                                        placeholder="Selecteer"
                                                        onChange={(e) => handleCommunityChange(e.value)}
                                                        name="communityIds"
                                                        value={communityLists?.find(item => item.value === parseInt(communityId)) || viewData?.vCommunityName}
                                                        data={searchCommunityLists?.length > 0 ? searchCommunityLists : communityLists}
                                                        onSearch={(searchTerm) => fetchCommunitySearch(searchTerm)}
                                                        dropUp
                                                        onBlur={() => setSearchCommunityLists([])}
                                                    />
                                                </div>

                                                {
                                                    activeTab === userTabs?.Scholen && <Button
                                                        outline
                                                        className="btn-outline-primary-blue btn-color-style py-2 px-3 d-flex gap-2 align-items-center"
                                                        onClick={() => handleSchoolClick("save")}
                                                        type="button"
                                                        color="primary"
                                                    >
                                                        School <AddIcon />
                                                    </Button>
                                                }

                                                {
                                                    activeTab === userTabs?.VrijeVelden &&
                                                    <Button
                                                        outline
                                                        className="btn-outline-primary-blue btn-color-style py-2 px-3 d-flex gap-2 align-items-center"
                                                        onClick={handleSubmitForm}
                                                        type="button"
                                                        color="primary"
                                                        disabled={disableSubmit}
                                                    >
                                                        Toepassen
                                                    </Button>
                                                }
                                            </div>

                                        </CardFooter>
                                    </div>
                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </div>

            <Modal
                isOpen={openModal}
                toggle={modalData?.type === "remove" ? undefined : toggle}
                className="feedback-modal-style overig-exporteren-modal-style modal-centered link-form"
            >
                <ModalHeader toggle={modalData?.type === "remove" ? undefined : toggle}>
                    {modalData?.type === "school" && "School toevoegen"}
                    {modalData?.type === "remove" && "Warning"}
                </ModalHeader>

                <ModalBody>
                    <Formik
                        enableReinitialize
                        initialValues={{ Naam: modalData?.data?.vSchoolName ?? "", deleteConfirmation: "" }}
                        onSubmit={modalFormSubmitHandler}
                    >
                        {({ values, handleChange, handleSubmit }) => (
                            <Form onSubmit={handleSubmit}>
                                {(modalData?.type === "schoolSave" || modalData?.type === "schoolUpdate") && (
                                    <>
                                        <FormGroup className="d-flex justify-content-between">
                                            <Label for="Naam" className="mt-2">Naam</Label>
                                            <div style={{ width: '60%' }}>
                                                <Input
                                                    name="Naam"
                                                    type="text"
                                                    value={values.Naam}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        setModalData(prev => {
                                                            return { ...prev, data: { ...prev.data, vSchoolName: e.target.value } };
                                                        })
                                                        if (e.target.value === modalData?.data?.vSchoolName || e.target.value === '') {
                                                            setDisableModalSubmitButton(true);
                                                        } else {
                                                            setDisableModalSubmitButton(false);
                                                        }
                                                    }}
                                                />
                                                <ErrorMessage name="Naam" component="small" className="block mt-2 invalid-feedback" />
                                            </div>
                                        </FormGroup>
                                    </>
                                )}

                                {modalData?.type === "remove" && (
                                    <>
                                        <div className="travelCost-light-text">
                                            <p>
                                                <span className="travelCost-bold-text">NOTE:</span> You mark this organisation to remove all data.
                                            </p>
                                            <p>
                                                <span className="travelCost-bold-text">Text:</span> Type the word <strong>DELETE</strong> in the field below.
                                                The user must enter "DELETE" in the message box.
                                            </p>
                                            <p>After this, all data can be deleted via the Management Delete process.</p>
                                            <Input
                                                name="deleteConfirmation"
                                                placeholder="Type DELETE in hoofdletters ter bevestiging."
                                                value={values.deleteConfirmation}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setModalData(prev => {
                                                        return { ...prev, data: e.target.value };
                                                    })
                                                    setDisableModalSubmitButton(e.target.value !== "DELETE");
                                                }}
                                            />
                                        </div>
                                    </>
                                )}

                                <ModalFooter className="d-flex align-items-center justify-content-center border-bottom-0 py-4 mt-4">
                                    <Button
                                        className="btn-color-style opslaan-btn"
                                        size="sm"
                                        outline
                                        color="primary"
                                        type="submit"
                                        disabled={disableModalSubmitButton}
                                    >
                                        Toepassen
                                    </Button>
                                    <Button
                                        className="btn-color-style opslaan-btn"
                                        size="sm"
                                        outline
                                        color="primary"
                                        onClick={toggle}
                                    >
                                        Annuleren
                                    </Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>

            <AppFooter />
        </Fragment >
    )
};

export default Besturens;