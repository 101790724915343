import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import FeedbackNavItem from "./CustomNavItems/FeedbackNavItem";
import WeekReportActueelNavItem from "./CustomNavItems/WeekReportActueelNavItem";
import HomeItemNavItem from "./CustomNavItems/HomeItemNavItem";
import { useNavigate, useLocation } from 'react-router-dom';
import { Link, useMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import HasPermission from 'Components/Router/Permission';
import AuthService from "Api/Auth";
import { setSubMenuClass } from 'reducers/ThemeOptions';
import { ReactComponent as NotesIcon } from "../../assets/utils/images/svgs/notes.svg";
import { ReactComponent as SmsIcon } from "../../assets/utils/images/svgs/sms.svg";
import { ReactComponent as FeatureIcon } from "../../assets/utils/images/svgs/db-gray.svg";
import { ReactComponent as CostIcon } from "../../assets/utils/images/svgs/cost-icon.svg";
import { ReactComponent as CalendarIcon } from "../../assets/utils/images/svgs/calendar-gray-stroke.svg";
import { ReactComponent as SendSMSIcon } from "../../assets/utils/images/svgs/send-sms.svg";
import { ReactComponent as ReplacementIcon } from "../../assets/utils/images/svgs/replacement-icon.svg";
import { ReactComponent as SchoolIcon } from "../../assets/utils/images/svgs/school.svg";
import { ReactComponent as Contracttypen } from "../../assets/utils/images/svgs/Contracttypen.svg";
import { ReactComponent as BesturenIcon } from "../../assets/utils/images/svgs/BesturenIcon.svg";
import { ReactComponent as PlanningIcon } from "../../assets/utils/images/svgs/PlanningIcon.svg";
import { ReactComponent as BesturenSIcon } from "../../assets/utils/images/svgs/BesturenSIcon.svg";

const Layout = () => {

  const enableClosedSidebar = useSelector((state) => state.ThemeOptions.enableClosedSidebar);

  // get user roles from redux
  const userRoles = useSelector((state) => state.UserPermission);
  const isAllowed = [...userRoles.roles].includes('administrator');

  // get user roles from local storage
  // const isAllowed = localStorage.getItem('USER_ROLES').includes('administrator');

  const location = useLocation();
  const dispatch = useDispatch();

  let navigate = useNavigate();

  const isGebruikers = useMatch("/gebruikers");
  const isGebruikersWithId = useMatch("/gebruikers/:id");
  const isweekstaten = useMatch("/weekstaten");
  const isweekstatenWithId = useMatch("/weekstaten/:id");

  const sidebarNewStyle = {
    fontSize: 16
  };

  const iconStyle = {
    fontSize: 24
  }

  const submenuIconStyle = {
    width: '16px',
    height: '24px'
  }

  function handleWeekstatenClick(open) {
    if (location.pathname == '/weekstaat/actueel') {
      dispatch(setSubMenuClass(' show-submenu '));
    } else {
      dispatch(setSubMenuClass(' hidden-submenu '));
    }
    navigate("/weekstaat/actueel");
  }
  function handleVerzoekenClick() {
    if (location.pathname == '/verzoeken') {
      dispatch(setSubMenuClass(' show-submenu '));
    } else {
      dispatch(setSubMenuClass(' hidden-submenu '));
    }
    navigate("/verzoeken");
  }

  function handleBeheerClick() {
    if (location.pathname == '/beheerder/gebruikers' || location.pathname == '/beheerder') {
      dispatch(setSubMenuClass(' show-submenu '));
    } else {
      dispatch(setSubMenuClass(' hidden-submenu '));
    }
    navigate("/beheerder/gebruikers");
  }
  function handleVervangingenClick() {
    navigate("/vervangingen/beschikbaarheid");
  }
  function handleCoordinatorClick() {
    dispatch(setSubMenuClass(' show-submenu '));
    navigate("/verzoeken/overzicht");
  }

  function handleReiskostenClick(){
    navigate("/reiskosten/overzicht")
    dispatch(setSubMenuClass(' show-submenu '));
  }
  
  function handleCoordWeekstatenClick(){
    if (location.pathname == '/weekstaten/overzicht') {
      dispatch(setSubMenuClass(' show-submenu '));
    } else {
      dispatch(setSubMenuClass(' hidden-submenu '));
    }
    navigate("/weekstaten/overzicht")
  }
  function handleCoorInstellingenClick() {
    if (location.pathname == '/c-instellingen/besturen' || location.pathname == '/c-instellingen') {
      dispatch(setSubMenuClass(' show-submenu '));
    } else {
      dispatch(setSubMenuClass(' hidden-submenu '));
    }
    navigate("/c-instellingen/besturen");
  }
  function handleCoorBeheerClick() {
    if (location.pathname === '/overig/sms-versturen' || location.pathname === '/overig') {
      dispatch(setSubMenuClass(' show-submenu '));
    } else {
      dispatch(setSubMenuClass(' hidden-submenu '));
    }
    navigate("/overig/sms-versturen");
  }

  return (
    <Sidebar className='new-inner-sidebar small-menu-styling' style={{ borderRightWidth: 0 }} backgroundColor="white" defaultCollapsed={enableClosedSidebar || window.innerWidth <= 1249}>
      <Menu style={sidebarNewStyle} >

        <HasPermission showFor="coordinator">
          {AuthService.getUserRoles().length > 1 && (
             <h2 className="px-5 app-sidebar__heading sidebar-roles">Coordinator</h2>
          )}
          <SubMenu label="Verzoeken" onOpenChange={handleCoordinatorClick} defaultOpen={location.pathname === "/verzoeken/overzicht"} active={location.pathname === "/verzoeken/overzicht"} icon={<i className="metismenu-icon pe-7s-home" style={iconStyle}></i>}
          >
            <MenuItem component={<Link to="/verzoeken/planbord" />} defaultOpen={location.pathname === "/verzoeken/planbord"} active={location.pathname === "/verzoeken/planbord"} icon={<i className="metismenu-icon pe-7s-target" style={iconStyle}></i>}>Planbord</MenuItem>
            <MenuItem component={<Link to="/verzoeken/indienen" />} active={location.pathname === "/verzoeken/indienen"} icon={<i className="metismenu-icon pe-7s-magic-wand" style={iconStyle}></i>}>Indienen verzoek</MenuItem>
          </SubMenu>

          <SubMenu label="Reiskosten" onOpenChange={handleReiskostenClick} defaultOpen={location.pathname === "/reiskosten/overzicht"} active={location.pathname === "/reiskosten/overzicht"} icon={<i className="metismenu-icon pe-7s-bicycle" style={iconStyle}></i>}>
            <MenuItem component={<Link to="/reiskosten/afstanden" />} active={location.pathname === "/reiskosten/afstanden"} icon={<i className="metismenu-icon pe-7s-map-2" style={iconStyle}></i>}> Afstanden</MenuItem>
            <MenuItem component={<Link to="/reiskosten/samenstellen" />} active={location.pathname === "/reiskosten/samenstellen"} icon={<i className="metismenu-icon pe-7s-right-arrow" style={iconStyle}></i>}> Samenstellen</MenuItem>
          </SubMenu>

          <SubMenu label="Weekstaten" onOpenChange={handleCoordWeekstatenClick } defaultOpen={location.pathname === "/weekstaten/overzicht"} active={isweekstaten || isweekstatenWithId} icon={<i className="metismenu-icon pe-7s-note2" style={iconStyle}></i>}>
            <MenuItem component={<Link to="/weekstaten/samenstellen" />} active={location.pathname === "/weekstaten/samenstellen"} icon={<i className="metismenu-icon pe-7s-right-arrow" style={iconStyle}></i>}> Samenstellen</MenuItem>
          </SubMenu>
          <MenuItem component={<Link to="/overig/rapportage" />} active={location.pathname === "/overig/rapportage"} icon={<i className="metismenu-icon pe-7s-graph2" style={iconStyle}></i>}>Rapportage</MenuItem>
          <MenuItem component={<Link to="/gebruikers" />} active={isGebruikers || isGebruikersWithId} icon={<i className="metismenu-icon pe-7s-users" style={iconStyle}></i>}>Gebruikers</MenuItem>

          <SubMenu label="Overig" onOpenChange={handleCoorBeheerClick}
            defaultOpen={location.pathname === "/overig/sms-versturen"}
            active={location.pathname === "/overig" || location.pathname === "/overig/sms-statistieken"
              || location.pathname === "/overig/sms-versturen"
            }
            icon={<i className="metismenu-icon pe-7s-plugin" style={iconStyle}></i>}>
            {/* <MenuItem component={<Link to="/c-instellingen/functies" />} active={location.pathname === "/c-instellingen/functies"}
              icon={<FeatureIcon className="metismenu-icon pe-7s-users"/>}>Sms-Statistieken</MenuItem> */}
            <MenuItem component={<Link to="/overig/sms-versturen"/>} active={location.pathname === "/overig/sms-versturen"}
              icon={<SendSMSIcon className="metismenu-icon pe-7s-users" style={submenuIconStyle}/>}>Sms-Versturen</MenuItem>
            <MenuItem component={<Link to="/overig/exporteren"/>} active={location.pathname === "/overig/exporteren"}
              icon={<SendSMSIcon className="metismenu-icon pe-7s-users" style={submenuIconStyle}/>}>Exporteren</MenuItem>
          </SubMenu>
          <MenuItem component={<Link to="/links/coordinator" />} active={location.pathname === "/links/coordinator"} icon={<i className="metismenu-icon pe-7s-link" style={iconStyle}></i>}>Links</MenuItem>
          <SubMenu label="Instellingen" onOpenChange={handleCoorInstellingenClick}
            defaultOpen={location.pathname === "/c-instellingen/besturen"}
            active={ location.pathname === "/c-instellingen/functies" || location.pathname === "/c-instellingen"
              || location.pathname === "/c-instellingen/Kp-Kd" || location.pathname === "/c-instellingen/dagtypen" 
              || location.pathname === "/c-instellingen/vervangingscodes" || location.pathname.includes("/c-instellingen/scholen") || location.pathname === "/c-instellingen/besturen" || location.pathname === "/c-instellingen/contracttypen" || location.pathname === "/c-instellingen/planningIcon"}
            icon={<i className="metismenu-icon pe-7s-config" style={iconStyle}></i>}>
            {/* <MenuItem component={<Link to="/c-instellingen/functies" />} active={location.pathname === "/c-instellingen/functies"}
              icon={<i className="metismenu-icon pe-7s-users" style={iconStyle}></i>}>Bestuur</MenuItem> */}
            <MenuItem component={<Link to="/c-instellingen/besturen" />} active={location.pathname === "/c-instellingen/besturen"}
              icon={<BesturenIcon className="metismenu-icon pe-7s-users" style={submenuIconStyle}/>}>Besturen</MenuItem>
            <MenuItem component={<Link to="/c-instellingen/functies" />} active={location.pathname === "/c-instellingen/functies"}
              icon={<FeatureIcon className="metismenu-icon pe-7s-users" style={submenuIconStyle}/>}>Functies</MenuItem>
             <MenuItem component={<Link to="/c-instellingen/dagtypen"/>} active={location.pathname === "/c-instellingen/dagtypen"}
              icon={<CalendarIcon className="metismenu-icon" style={submenuIconStyle}/>}>Dagtypen</MenuItem>
            <MenuItem component={<Link to="/c-instellingen/Kp-Kd"/>} active={location.pathname === "/c-instellingen/Kp-Kd"}
              icon={<CostIcon className="metismenu-icon pe-7s-users"/>}>Kp/Kd</MenuItem>
            <MenuItem component={<Link to="/c-instellingen/vervangingscodes"/>} active={location.pathname === "/c-instellingen/vervangingscodes"}
              icon={<ReplacementIcon className="metismenu-icon pe-7s-users"/>}>Vervangingscodes</MenuItem>
            <MenuItem component={<Link to="/c-instellingen/scholen"/>} active={location.pathname.includes("/c-instellingen/scholen")}
              icon={<SchoolIcon className="metismenu-icon pe-7s-users"/>}>Scholen</MenuItem>
            <MenuItem component={<Link to="/c-instellingen/contracttypen"/>} active={location.pathname.includes("/c-instellingen/contracttypen")}
              icon={<Contracttypen className="metismenu-icon pe-7s-users"/>}>Contracttypen</MenuItem>
            <MenuItem component={<Link to="/c-instellingen/planning"/>} active={location.pathname.includes("/c-instellingen/planning")}
              icon={<PlanningIcon className="metismenu-icon pe-7s-users"/>}>Planning</MenuItem>
          </SubMenu>
        </HasPermission>

        <HasPermission showFor="requestor">

          {
            AuthService.getUserRoles().length > 1 &&
            <h2 className="px-5 app-sidebar__heading sidebar-roles">Melder</h2>
          }

          <SubMenu label="Verzoeken" onOpenChange={handleVerzoekenClick}
            defaultOpen={location.pathname === "/verzoeken"}
            active={location.pathname === "/verzoeken"}
            icon={<i className="metismenu-icon pe-7s-home" style={iconStyle}></i>} >
            <MenuItem component={<Link to="/verzoeken/historie" />} active={location.pathname === "/verzoeken/historie"}
              icon={<i className="metismenu-icon pe-7s-server" style={iconStyle}></i>}>Historie</MenuItem>
          </SubMenu>

          <MenuItem component={<Link to="/indienen" />} active={location.pathname === "/indienen"} onClick={() => dispatch(setSubMenuClass(' hidden-submenu '))} icon={<i className="metismenu-icon pe-7s-magic-wand" style={iconStyle} ></i>}> Indienen</MenuItem>
          <SubMenu label={<WeekReportActueelNavItem />} onOpenChange={handleWeekstatenClick}
            defaultOpen={location.pathname === "/weekstaat/actueel"}
            active={location.pathname === "/weekstaat/actueel"}
            icon={<i className="metismenu-icon pe-7s-note2" style={iconStyle}></i>} >
            <MenuItem component={<Link to="/weekstaat/historie" />} active={location.pathname === "/weekstaat/historie"}
              icon={<i className="metismenu-icon pe-7s-server" style={iconStyle}></i>}>Historie</MenuItem>
          </SubMenu>
          <MenuItem component={<Link to="/documenten" />} active={location.pathname === "/documenten"} onClick={() => dispatch(setSubMenuClass(' hidden-submenu '))} icon={<i className="metismenu-icon pe-7s-id" style={iconStyle} ></i>}> Documenten</MenuItem>
          <MenuItem label="Terugkoppeling" component={<Link to="/terugkoppeling" />} active={location.pathname === "/terugkoppeling"} onClick={() => dispatch(setSubMenuClass(' hidden-submenu '))} icon={<i className="metismenu-icon pe-7s-loop" style={iconStyle} ></i>}><FeedbackNavItem /></MenuItem>
          <MenuItem component={<Link to="/export" />} active={location.pathname === "/export"} onClick={() => dispatch(setSubMenuClass(' hidden-submenu '))} icon={<i className="metismenu-icon pe-7s-download" style={iconStyle}></i>}> Export</MenuItem>
          <MenuItem component={<Link to="/links/requestor" />} active={location.pathname === "/links/requestor"} icon={<i className="metismenu-icon pe-7s-link" style={iconStyle}></i>}>Links</MenuItem>
        </HasPermission>

        <HasPermission showFor="substitutor">
          {
            AuthService.getUserRoles().length > 1 &&
            <h2 className="px-5 app-sidebar__heading sidebar-roles">Vervanger</h2>
          }

          <MenuItem component={<Link to="/home" />} active={location.pathname === "/home"} icon={<i className="metismenu-icon pe-7s-home" style={iconStyle}></i>}> <HomeItemNavItem /></MenuItem>
          <MenuItem component={<Link to="/beschikbaarheid" />} active={location.pathname === "/beschikbaarheid"}
            icon={<i className="metismenu-icon pe-7s-date" style={iconStyle}></i>}>Beschikbaarheid</MenuItem>
          <MenuItem component={<Link to="/v-documenten" />} active={location.pathname === "/v-documenten"} icon={<i className="metismenu-icon pe-7s-id" style={iconStyle}></i>}> Documenten</MenuItem>
          <MenuItem component={<Link to="/urenoverzicht" />} active={location.pathname === "/urenoverzicht"} icon={<i className="metismenu-icon pe-7s-graph3" style={iconStyle}></i>}> Urenoverzicht</MenuItem>
          <MenuItem component={<Link to="/instellingen" />} active={location.pathname === "/instellingen"} icon={<i className="metismenu-icon pe-7s-config" style={iconStyle}></i>}> Instellingen</MenuItem>
          <MenuItem component={<Link to="/links/substitutor" />} active={location.pathname === "/links/substitutor"} icon={<i className="metismenu-icon pe-7s-link" style={iconStyle}></i>}>Links</MenuItem>
        </HasPermission>

        <HasPermission showFor="administrator">
          <SubMenu label="Beheer" onOpenChange={handleBeheerClick}
            defaultOpen={location.pathname === "/beheerder/gebruikers"}
            active={location.pathname === "/beheerder/gebruikers" || location.pathname === "/beheerder"}
            icon={<i className="metismenu-icon pe-7s-config" style={iconStyle}></i>} >
            <MenuItem component={<Link to="/beheerder/gebruikers" />} active={location.pathname === "/beheerder/gebruikers"}
              icon={<i className="metismenu-icon pe-7s-users" style={iconStyle}></i>}>Gebruikers</MenuItem>
            <MenuItem component={<Link to="/beheerder/instellingen" />} active={location.pathname === "/beheerder/instellingen"}
              icon={<i className="metismenu-icon pe-7s-config" style={iconStyle}></i>}>Instellingen</MenuItem>
            <MenuItem component={<Link to="/beheerder/links" />} active={location.pathname === "/beheerder/links"} icon={<i className="metismenu-icon pe-7s-link" style={iconStyle}></i>}>Links</MenuItem>
            <MenuItem component={<Link to="/beheerder/api" />} active={location.pathname === "/beheerder/api"} icon={<i className="metismenu-icon pe-7s-way" style={iconStyle}></i>}>Api</MenuItem>
            <MenuItem component={<Link to="/beheerder/besturen" />} active={location.pathname === "/beheerder/besturen"}
              icon={<BesturenSIcon className="metismenu-icon pe-7s-users" style={submenuIconStyle} />}>Besturen</MenuItem>
            <MenuItem component={<Link to="/beheerder/functies" />} active={location.pathname === "/beheerder/functies"} icon={<i className='metismenu-icon'><FeatureIcon className="metismenu-icon pe-7s-users" style={submenuIconStyle} /></i>}>Functies</MenuItem>
            <MenuItem component={<Link to="/beheerder/admin-sms" />} active={location.pathname === "/beheerder/admin-sms"} icon={<i className='metismenu-icon'><SmsIcon style={{ width: "20px", height: "22px" }} /></i>}>SMS statistieken</MenuItem>
            <MenuItem component={<Link to="/beheerder/push-messages" />} active={location.pathname === "/beheerder/push-messages"} icon={<i className='metismenu-icon'><SendSMSIcon style={{ width: "20px", height: "22px" }} /></i>}>Log Push-Messages</MenuItem>
            <MenuItem component={<Link to="/beheerder/logging" />} active={location.pathname === "/beheerder/logging"}
              icon={<i className="metismenu-icon pe-7s-display2" style={iconStyle}></i>}>Log Login</MenuItem>
          </SubMenu>
        </HasPermission>
      </Menu>
    </Sidebar>
  );
}

export default Layout;
