import PageHeading from "Layout/AppMain/PageHeading";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Card, CardBody, InputGroup, CardFooter, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import InputWrapper from "Pages/Profile/Forms/InputWrapper";
import DropdownList from "react-widgets/DropdownList";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker, { registerLocale } from "react-datepicker";
import { ReactComponent as EditPen } from "../../../../../assets/utils/images/svgs/editPen.svg";
import { ReactComponent as Add } from "../../../../../assets/utils/images/svgs/Add.svg";
import nl from "date-fns/locale/nl";
import moment from "moment";
import GroupStatus from "Components/IconDropdown/Cross_Tick/GroupStatus";
import { ReactComponent as CalendarIcon } from "../../../../../assets/utils/images/svgs/calendar-blue.svg"
import { BsQuestionCircle } from "react-icons/bs";
import { toast } from "react-toastify";

registerLocale("nl", nl);

const Step1 = ({ formDataState, setFormDataState, setStep, communityId, setCommunityId, getSchoolList }) => {
    const [langeContractDisable, setLangeContractDisable] = useState(false)
    const [dagdelenDisable, setDagdelenDisable] = useState(false)
    const [communityIdError, setCommunityIdError] = useState(false)
    const [datePickerError, setDatePickerError] = useState('')
    const [emailError, setEmailError] = useState(false)
    const [radiobtnErr, setRadiobtnErr] = useState(false)
    const [memoIsOpen, setMemoIsOpen] = useState(false)
    const [checkedStates, setCheckedStates] = useState(
        new Array(formDataState.emailList.length).fill(true)
    );
    const [isTerGoedkeuringSelected, setIsTerGoedkeuringSelected] = useState(false);

    const questionCircleIconRef = useRef();

    const datePickerRefs = useRef([]);

    useEffect(() => {
        displayData();
    }, [formDataState.bShowProlonged])

    useEffect(() => {
        setFormDataState(prevState => ({
            ...prevState,
            informText: updateInformText(prevState.rdApproved)
        }));
    }, [formDataState.startDate, formDataState.endDate, formDataState.communityData])

    const displayData = () => {
        if (formDataState.bShowProlonged === 'Y') {
            setFormDataState({
                ...formDataState,
                selectLangeContract: true,
                selectDagdelen: true,
            })
            setDagdelenDisable(false)
        }
        else {
            setFormDataState({
                ...formDataState,
                selectDagdelen: true,
            })
            setDagdelenDisable(true)
        }
    }

    const toggleCheckbox = (type, value) => {
        if (type == 'selectLangeContract') {
            if (value == false) {
                setDagdelenDisable(true)
                setFormDataState({
                    ...formDataState,
                    selectLangeContract: false,
                    selectDagdelen: true,
                })
            }
            else {
                setDagdelenDisable(false)
                setFormDataState({
                    ...formDataState,
                    selectLangeContract: true,
                    selectDagdelen: true,
                })
            }
        }
        else if (type == 'selectDagdelen') {
            if (value == "0") {
                setLangeContractDisable(true)
                setFormDataState({
                    ...formDataState,
                    selectLangeContract: true,
                    selectDagdelen: false,
                })
            }
            else {
                setLangeContractDisable(false)
                setFormDataState({
                    ...formDataState,
                    selectLangeContract: true,
                    selectDagdelen: true,
                })
            }
        }
    }

    const updateInformText = (value) => {
        if (value === 1) {
            return `Op www.clooser.nl staat de weekstaat van %SCHOOL% %ORGANISATIE% voor de periode van %FROM_DATE% t/m %TO_DATE% voor u klaar.\nWilt u inloggen op www.clooser.nl en de weekstaat van bovenstaande periode beoordelen.\nIndien u de weekstaat wenst af te keuren dient u dit online toe te lichten.\nDeze mail is een systeembericht, u kunt hier niet op antwoorden.`
        } else return `Op www.clooser.nl staat de weekstaat van %SCHOOL% %ORGANISATIE% voor de periode van %FROM_DATE% t/m %TO_DATE% voor u klaar.`
    }

    const handleApproveClick = (_, value) => {
        if (value === "1") {
            setFormDataState({
                ...formDataState,
                rdApproved: parseInt(value),
                selectCoordinator: false,
                selectSchool: true,
                selectSalarisAdmin: false,
                selectCoordinatorAttach: false,
                selectSchoolAttach: false,
                selectSalarisAdminAttach: false,
                informText: updateInformText(1)
            })
        }
        else {
            setFormDataState({
                ...formDataState,
                rdApproved: parseInt(value),
                selectCoordinator: true,
                selectSchool: true,
                selectSalarisAdmin: false,
                selectCoordinatorAttach: true,
                selectSchoolAttach: true,
                selectSalarisAdminAttach: true,
                informText: updateInformText(0)
            })
        }
    }

    const toggleInformCheckboxes = (type, value) => {
        if (value === true || value === "1") {
            setFormDataState({ ...formDataState, [type]: true })
        }
        else {
            setFormDataState({ ...formDataState, [type]: false })
        }
    }

    const setEmailValue = (value) => {
        setFormDataState({ ...formDataState, informEmail: value })
    }

    function validateEmailList() {
        var emails = (formDataState.informEmail).split(',')
        var valid = true;
        var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        for (var i = 0; i < emails.length; i++) {
            if (emails[i] === "" || !regex.test(emails[i].replace(/\s/g, ""))) {
                valid = false;
            }
        }
        return valid;
    }

    const addEmail = () => {
        if (formDataState.informEmail.trim() !== '') {
            validateEmailList();
            if (validateEmailList() == true) {
                setEmailError(false)
                setFormDataState({
                    ...formDataState,
                    emailList: [...formDataState.emailList, formDataState.informEmail],
                    informEmail: ''
                });
                setCheckedStates((prevState) => [...prevState, true]);
            }
            else {
                setEmailError(true)
            }
        };
    }

    const handleEmailCheckbox = (index) => {
        const updatedCheckedStates = checkedStates.map((item, idx) =>
            idx === index ? !item : item
        );

        setCheckedStates(updatedCheckedStates);
    };

    const handleSubmit = () => {
        var startDate = moment(formDataState.startDate);
        var endDate = moment(formDataState.endDate);
        var difference = endDate.diff(startDate, 'days');
        var selectedEmails = formDataState.emailList.filter((_, idx) => checkedStates[idx]);

        const isRdApprovedNull = formDataState.rdApproved === null;
        setRadiobtnErr(isRdApprovedNull);
        const isCommunityId = communityId <= 0;
        setCommunityIdError(isCommunityId);

        if (formDataState.startDate != "" && formDataState.endDate != "") {
            isRdApprovedNull && toast.error("Selecteer Ter goedkeuring.")
            if ((difference < 62) && (difference > 0)) {
                if (!isRdApprovedNull && !isCommunityId) {
                    setFormDataState({
                        ...formDataState, datePickerStyle: "weekstaten-datePicker form-control",
                        informEmail: selectedEmails
                    })
                    getSchoolList();
                    setStep('step2');
                }
            }
            else {
                setFormDataState({ ...formDataState, datePickerStyle: "travelCost-datePicker-error" })
                setDatePickerError('Opmerking: maximaal aantal te selecteren dagen zijn 62 (2 maanden).')
            }
        }
        else {
            setFormDataState({ ...formDataState, datePickerStyle: "travelCost-datePicker-error" })
            setDatePickerError('Verplicht veld')
        }
    };

    return (
        <div>
            <div className="chat-layout-wrapper">
                <div className="app-inner-layout chat-layout">
                    <PageHeading />
                    <div className="app-inner-layout__wrapper">
                        <div className="row w-100">
                            <div className="col-xxl-5 col-md-6 col-12">
                                <Card className="main-card mb-3">
                                    <CardBody className="p-xl-5 p-4 table-responsive travelCost-card-height overflow-visible samenstellen">
                                        <InputWrapper label="Selecteer organisatie *" className="text-align-left travelCost-light-text">
                                            <InputGroup>
                                                <DropdownList
                                                    placeholder="Selecteer..."
                                                    className={"travelCost-dropdownList"}
                                                    data={formDataState.communityData.communityList}
                                                    value={communityId && communityId}
                                                    dataKey='iCommunityId'
                                                    textField='name'
                                                    onChange={(nextValue) => {
                                                        setCommunityId(nextValue.iCommunityId)
                                                    }}
                                                    required="true"
                                                    style={{ border: communityIdError ? "1px solid red" : "" }}
                                                />
                                            </InputGroup>
                                        </InputWrapper>
                                        <InputWrapper label="Toon dagdelen" className="d-flx align-items-center text-align-left travelCost-light-text">
                                            <InputGroup className="justify-content-between" style={{ height: '100%' }}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <GroupStatus disable={dagdelenDisable} iconChangeHandler={toggleCheckbox} field={"selectDagdelen"} data={formDataState.selectDagdelen ? 1 : 0} />
                                                </div>
                                            </InputGroup>
                                        </InputWrapper>
                                        {
                                            formDataState.bShowProlonged !== 'N' &&
                                            <InputWrapper label="Toon Lange contracten" className="d-flx align-items-center text-align-left travelCost-light-text">
                                                <InputGroup className="justify-content-between mt-2">
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <GroupStatus iconChangeHandler={toggleCheckbox} field={"selectLangeContract"} data={formDataState.selectLangeContract ? 1 : 0} />
                                                    </div>
                                                </InputGroup>
                                            </InputWrapper>
                                        }
                                        {
                                            !langeContractDisable &&
                                            <InputWrapper label="Selecteer periode *" className="text-align-left travelCost-light-text">
                                                <Row className="justify-content-between">
                                                    <Col xxl="6" className="pt-2">
                                                        <Col sm={12}>
                                                            <div className="customDatePicker">
                                                                <DatePicker
                                                                    ref={(el) => (datePickerRefs.current[0] = el)}
                                                                    locale="nl"
                                                                    selected={(formDataState.startDate !== '' && formDataState.startDate !== undefined) ? new Date(formDataState.startDate) : ''}
                                                                    onChange={(val) => {
                                                                        setFormDataState({ ...formDataState, startDate: moment(val).format('yyyy-MM-DD') })
                                                                    }}
                                                                    placeholderText={'dd-mm-jjjj'}
                                                                    dateFormat="dd-MM-yyyy"
                                                                    className={`form-control w-100 ${formDataState.datePickerStyle}`}
                                                                />
                                                                <CalendarIcon onClick={() => datePickerRefs.current[0]?.setFocus()} className="svg-pointer" />
                                                            </div>
                                                        </Col>
                                                    </Col>
                                                    <Col xxl="6" className="pt-2">
                                                        <Col sm={12}>
                                                            <div className="customDatePicker">
                                                                <DatePicker
                                                                    ref={(el) => (datePickerRefs.current[1] = el)}
                                                                    locale="nl"
                                                                    className={`form-control w-100 ${formDataState.datePickerStyle}`}
                                                                    selected={(formDataState.endDate !== '' && formDataState.endDate !== undefined) ? new Date(formDataState.endDate) : ''}
                                                                    onChange={(val) => {
                                                                        setFormDataState({ ...formDataState, endDate: moment(val).format('yyyy-MM-DD') })

                                                                    }}
                                                                    showPopperArrow={false}
                                                                    placeholderText={'dd-mm-jjjj'}
                                                                    dateFormat="dd-MM-yyyy"
                                                                />
                                                                <CalendarIcon onClick={() => datePickerRefs.current[1]?.setFocus()} className="svg-pointer" />
                                                            </div>
                                                            <Row>
                                                                <Col md="12" lg="12" className="weekstaten-month-text">
                                                                    Maximaal 2 maanden
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Col>
                                                </Row>
                                            </InputWrapper>
                                        }
                                        <InputWrapper label="" className="d-flx align-items-center text-align-left travelCost-light-text">
                                            <InputGroup className="justify-content-between">
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <text className="text-danger">{datePickerError}</text>
                                                </div>
                                            </InputGroup>
                                        </InputWrapper>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col-xxl-7 col-md-6 col-12">
                                <Card className="main-card mb-3">
                                    <CardBody className="p-xl-5 p-4 table-responsive travelCost-card-height samenstellen">
                                        <Row className="mb-2">
                                            <Col md={window.innerWidth < 1500 ? "6" : "3"} className="travelCost-bold-text">
                                                Ter goedkeuring: *
                                            </Col>
                                            <Col md="2" lg="2" className="d-flex justify-content-center travelCost-bold-text mb-3">
                                                <span className={`${isTerGoedkeuringSelected ? 'd-none' : ''} ps-3 d-flex`}> <BsQuestionCircle color='#F2983E' fontSize={"21px"} onClick={() => {
                                                    questionCircleIconRef.current?.props.onClick()
                                                }} /></span>

                                                <GroupStatus IconRef={questionCircleIconRef} isTerGoedkeuringSelected={isTerGoedkeuringSelected} setIsTerGoedkeuringSelected={setIsTerGoedkeuringSelected} iconChangeHandler={handleApproveClick} field={"selectCoordinator"} data={formDataState.rdApproved ? 1 : 0} />
                                            </Col>
                                        </Row>
                                        <Row className="mt-2 mb-2">
                                            <Col md={window.innerWidth < 1500 ? "6" : "3"} className="travelCost-light-text">
                                                Standaardtekst e-mail wijzigen
                                            </Col>
                                            <Col md="2" lg="2" className="d-flex justify-content-center">
                                                <EditPen onClick={() => setMemoIsOpen(true)} className="svg-pointer" />
                                            </Col>
                                        </Row>
                                        <Row className="mt-3 mb-2">
                                            <Col md={window.innerWidth < 1500 ? "6" : "3"} className="travelCost-bold-text">
                                                Informeren
                                            </Col>
                                            <Col md="2" lg="2" className="d-flex justify-content-center travelCost-bold-text">
                                                Mail
                                            </Col>
                                            <Col md="2" lg="2" className="d-flex justify-content-center travelCost-bold-text">
                                                Bijlage
                                            </Col>
                                        </Row>
                                        <Row className="align-items-center">
                                            <Col md={window.innerWidth < 1500 ? "6" : "3"} className="travelCost-light-text">
                                                Coördinator
                                            </Col>
                                            <Col md="2" lg="2" className="d-flex justify-content-center p-2">
                                                <GroupStatus iconChangeHandler={toggleInformCheckboxes} field={"selectCoordinator"} data={formDataState.selectCoordinator ? 1 : 0} />
                                            </Col>
                                            <Col md="2" lg="2" className="d-flex justify-content-center">
                                                <GroupStatus iconChangeHandler={toggleInformCheckboxes} field={"selectCoordinatorAttach"} data={formDataState.selectCoordinatorAttach ? 1 : 0} />
                                            </Col>
                                        </Row>
                                        <Row className="align-items-center">
                                            <Col md={window.innerWidth < 1500 ? "6" : "3"} className="travelCost-light-text">
                                                School
                                            </Col>
                                            <Col md="2" lg="2" className="d-flex justify-content-center p-2">
                                                <GroupStatus iconChangeHandler={toggleInformCheckboxes} field={"selectSchool"} data={formDataState.selectSchool ? 1 : 0} />
                                            </Col>
                                            <Col md="2" lg="2" className="d-flex justify-content-center">
                                                <GroupStatus iconChangeHandler={toggleInformCheckboxes} field={"selectSchoolAttach"} data={formDataState.selectSchoolAttach ? 1 : 0} />
                                            </Col>
                                        </Row>
                                        <Row className="align-items-center">
                                            <Col sm="12" md={window.innerWidth < 1500 ? "6" : "3"} className="travelCost-light-text">
                                                Salaris administratie
                                            </Col>
                                            <Col md="2" lg="2" className="d-flex justify-content-center p-2">
                                                <GroupStatus iconChangeHandler={toggleInformCheckboxes} field={"selectSalarisAdmin"} data={formDataState.selectSalarisAdmin ? 1 : 0} />
                                            </Col>
                                            <Col md="2" lg="2" className="d-flex justify-content-center">
                                                <GroupStatus iconChangeHandler={toggleInformCheckboxes} field={"selectSalarisAdminAttach"} data={formDataState.selectSalarisAdminAttach ? 1 : 0} />
                                            </Col>
                                        </Row>

                                        <Row className="mb-2 mt-3">
                                            <Col md={window.innerWidth < 1500 ? "6" : "3"} className="travelCost-bold-text mb-4">
                                                Extra e-mail adressen
                                            </Col>
                                            <Col md="6" lg="6">
                                                <Row className="ms-xl-5">
                                                    <Col md="12" lg="12" className="d-flex px-0 align-items-center">
                                                        <Input
                                                            className="travelCost-disabled-text"
                                                            placeholder="lemand@school1.nl. Il@school2.l, ..."
                                                            value={formDataState.informEmail}
                                                            onChange={(e) => { setEmailValue(e.target.value) }}
                                                            style={{ borderColor: emailError ? "red" : "" }}
                                                        />
                                                        <Add className="ms-2" style={{ height: "30px", width: "40px" }} onClick={addEmail} />
                                                    </Col>
                                                </Row>
                                                {
                                                    emailError &&
                                                    <Row className="travelCost-light-text weekstaten-email-margin">
                                                        <Col md="12" lg="12" className="text-danger">
                                                            ongeldig e-mail
                                                        </Col>
                                                    </Row>
                                                }
                                                <Row className="travelCost-light-text ms-xl-5">
                                                    <Col md="12" lg="12" className="px-0">
                                                        Gebruik bij meerdere e-mail adressen een komma als scheidingsteken.
                                                    </Col>
                                                </Row>

                                            </Col>
                                        </Row>
                                        {formDataState.emailList.map((email, index) => (
                                            <Row key={index} className="align-items-center">
                                                <Col md={window.innerWidth < 1500 ? "6" : "4"} className="travelCost-light-text"></Col>
                                                <Col md={window.innerWidth < 1500 ? "6" : "4"} className="d-flex justify-content-start p-2 informEmail-margin">
                                                    <Input
                                                        type="checkbox"
                                                        className="col-md-12"
                                                        checked={checkedStates[index]}
                                                        onChange={() => handleEmailCheckbox(index)}
                                                    />
                                                    <div className="ps-2">{email}</div>
                                                </Col>
                                            </Row>
                                        ))}
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="chat-layout-footer verzoken-footer-height">
                    <CardFooter className="chat-layout-footer__inner">
                        <div className="d-flex justify-content-end">
                            <div>
                                <Button
                                    outline
                                    className="btn-outline-primary-blue btn-color-style footer--coordinator-planboard__btn"
                                    onClick={() => handleSubmit()}
                                    type="button"
                                    color="primary"
                                >
                                    Volgende
                                </Button>
                            </div>
                            <Modal id={"tooltip"} isOpen={memoIsOpen} toggle={() => setMemoIsOpen(!memoIsOpen)} className="modal-dialog__over feedback-modal-style opmerking-modal-styling info-modal-width w-40">
                                <ModalHeader>
                                    <div className="modal-header-styling">
                                        Tekst e-mail wijzigen
                                    </div>
                                    <div className="cross-icon-color">
                                        <i className="nav-link-icon lnr-cross m-1 cursor-pointer" onClick={() => setMemoIsOpen(false)}> </i>
                                    </div>
                                </ModalHeader>

                                <ModalBody className="bg-white rounded">
                                    <div className="d-flex justify-content-center flex-column">
                                        <p className="travelCost-light-text fs-5 mb-3">Geachte heer/mevrouw,</p>
                                        <textarea onChange={(e) => {
                                            setFormDataState({ ...formDataState, informText: e.target.value })
                                        }}
                                            value={formDataState.informText}
                                            className="form-style opmerking-input-styling my-2 travelCost-light-text w-100"
                                            rows="6"
                                        ></textarea>
                                        <p className="travelCost-light-text fs-5 mt-2">Met vriendelijke groet,</p>
                                        <p className="travelCost-light-text fs-5">{formDataState?.communityData?.nameCoordinator || ''}</p>
                                    </div>
                                    <ModalFooter>
                                        <Button className="d-flex align-items-center btn-color-style back-btn" size="sm" outline color="primary" onClick={() => setMemoIsOpen(false)}>
                                            Wijzigen
                                        </Button>
                                    </ModalFooter>
                                </ModalBody>
                            </Modal>
                        </div>
                    </CardFooter>
                </div>
            </div>
        </div>
    )
};

export default Step1;
