import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Card, CardBody, Col, Row } from "reactstrap";
import PageHeading from "Layout/AppMain/PageHeading";
import axiosInstance from "Api";
import Loader from 'react-loaders';

const Reporting = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [powerBiLink, setPowerBiLink] = useState("");

    useEffect(() => {
        const fetchPowerBiLink = async () => {
            setIsLoading(true);
            try {
                const response = await axiosInstance.get('power-bi/fetch-link');
                if (response.data.status) {
                    setPowerBiLink(response.data.data);
                }
            } catch (error) {
                console.error('Error fetching Power BI link:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchPowerBiLink();
    }, []);

    return (
        <Fragment>
            <PageHeading />
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    <Row className="mb-5">
                        <Col sm={12}>
                            <div className="row">
                                <div className="col">
                                    <Card className="main-card mb-3">
                                        <CardBody className="p-5">
                                            {isLoading ? (
                                                <Loader
                                                    type="ball-grid-pulse"
                                                    className="mt-3 d-flex mx-auto justify-content-center"
                                                />
                                            ) : (
                                                powerBiLink ? (
                                                    /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/.test(powerBiLink) ? (
                                                        <iframe
                                                            width="100%"
                                                            height="676"
                                                            title="powerbi"
                                                            src={powerBiLink}
                                                            allowFullScreen={true}
                                                        ></iframe>
                                                    ) : (
                                                        <p className="text-center">Invalid Link</p>
                                                    )
                                                ) : (
                                                    <p className="text-center">No Link Added</p>
                                                )
                                            )}
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </CSSTransition>
            </TransitionGroup>
        </Fragment>
    )
}

export default Reporting