import { BsCheckCircle } from "react-icons/bs";
import { ReactComponent as CrossIcon } from "../../../assets/utils/images/svgs/Cross.svg";
export const iconSwitch = (param) => {
    switch (param) {
        case 1:
            return <BsCheckCircle color='#39A825' font-size={"18px"} style={{width: "19px", height: "19px"}}/>

        case 0:
            return <CrossIcon color='#FB2E5F' style={{width: "19px", height: "19px"}}/>

        default:
            return '--';
    }
}