import { Form, Formik } from "formik";
import { Card, CardBody, FormGroup, Input, Label } from 'reactstrap'

const Salary = ({ data, changeHandler, errorField }) => {
    const defaultValues = {
        tarief: data?.tarief,
        vDienstverbandId: data?.vDienstverbandId,
        vSalarisId: data?.vSalarisId,
        iUitlenerCommunityId: data?.iUitlenerCommunityId
    };
   
    return (
        <Card className="main-card">
            <CardBody className="d-flex flex-column">
                <h5 className="mb-4">Salaris</h5>
                <Formik
                    enableReinitialize
                    initialValues={defaultValues}
                >
                    {({ values, handleChange }) => (
                        <Form>
                            <FormGroup className={`form-element ${errorField === 'vSalarisId' ? 'error' : ''}`}>
                                <Label for="vSalarisId">Salaris-id</Label>
                                <Input
                                    name="vSalarisId"
                                    type="text"
                                    value={values.vSalarisId}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vSalarisId', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vDienstverbandId' ? 'error' : ''}`}>
                                <Label for="vDienstverbandId">Dienstverband</Label>
                                <Input
                                    name="vDienstverbandId"
                                    type="text"
                                    value={values.vDienstverbandId}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vDienstverbandId', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'iUitlenerCommunityId' ? 'error' : ''}`}>
                                <Label for="iUitlenerCommunityId">Uitlener</Label>
                                <select
                                    className="form-select"
                                    name="iUitlenerCommunityId"
                                    value={values.selectedCommunity}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('iUitlenerCommunityId', e.target.value);
                                    }}
                                >
                                    <option value="">Selecteer Uitlener</option>
                                    {data?.communityLists.map(item => {
                                        return <option key={item.iCommunityId} value={item.iCommunityId}>{item.vCommunityName}</option>
                                    })}
                                </select>
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'tarief' ? 'error' : ''}`}>
                                <Label for="tarief">Tarief</Label>
                                <div className="position-relative w-100">
                                    <p className="euro-icon">€</p>
                                    <Input
                                        name="tarief"
                                        type="text"
                                        value={values.tarief}
                                        onChange={(e) => {
                                            handleChange(e);
                                            changeHandler('tarief', e.target.value);
                                        }}
                                        className="ps-4"
                                    />
                                </div>
                            </FormGroup>
                        </Form>
                    )}
                </Formik>
            </CardBody>
        </Card>
    )
}

export default Salary