import React from "react";
import { Form, Formik } from "formik";
import { Card, CardBody, Col, FormGroup, Label, Row } from 'reactstrap'
import { ReactComponent as PenIcon } from "../../../../../assets/utils/images/svgs/editPen.svg";
import { ReactComponent as Delete } from "../../../../../assets/utils/images/svgs/Delete.svg";

const FiltersSchoolplanning = ({ filterData, showFilter, handleFilterDelete }) => {
    
    return (
        <>
            <Card className="main-card">
                <CardBody className="d-flex flex-column">
                    <div className="btn-color-style travelCost-blue-text mb-4">Filters schoolplanning</div>

                    <Formik
                        enableReinitialize
                    >
                        {({ values, setFieldValue, handleChange }) => (
                            <Form>
                                <Row>
                                    <Col sm={8} lg={5} xl={6} className="p-2">
                                        {
                                            filterData && filterData.map((item, index) => {
                                                return <FormGroup className="form-element">
                                                    <Label for="weergeven">{item?.name}</Label>
                                                    <div className="position-relative w-50">
                                                        <div className="d-flex gap-1 align-items-center">
                                                            <PenIcon className="svg-pointer" onClick={() => {
                                                                showFilter("0", item?.value, "Schoolplanning");
                                                            }} />
                                                            {(item?.removable === "yes") &&
                                                                <Delete className="svg-pointer" onClick={() => {handleFilterDelete(item?.value)}}/>}
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            })
                                        }
                                    </Col>

                                </Row>
                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card>
        </>
    )
}

export default FiltersSchoolplanning;