import { Fragment, useEffect, useState } from 'react'
import { Button, Card, CardBody, CardFooter, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Loader from 'react-loaders'
import { ReactComponent as AddIcon } from "../../../../../assets/utils/images/svgs/addBlue.svg"
import { ReactComponent as Delete } from "../../../../../assets/utils/images/svgs/DeleteBlue.svg"
import Algemeen from './Algemeen'
import Standaardkeuzes from './Standaardkeuzes'
import PageHeading from 'Layout/AppMain/PageHeading';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ControleInstellingenPlanning from './ControleInstellingenPlanning';
import CentralePlanning from './CentralePlanning';
import Schoolplanning from './Schoolplanning';
import FiltersCentralePlanning from './FiltersCentralePlanning';
import FiltersSchoolplanning from './FiltersSchoolplanning';
import { useLocation, useNavigate } from 'react-router';
import ContractTypenService from 'Api/Instellingen/ContractTypen';
import ContractWijzigenToevoegenService from 'Api/Instellingen/ContractTypen/WijzigenToevoegen/index';
import { debounce } from '../../../../../helperFunctions/index';
import { toast } from 'react-toastify';

const WijzigenToevoegen = () => {
    const navigate = useNavigate();
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [contractList, setContractList] = useState([]);
    const [errorField, setErrorField] = useState('');
    const [filterData, setFilterData] = useState({});

    const location = useLocation();
    const { contractId, communityId } = location?.state;
    const showContract = () => {
        const data = { iCommunityId: communityId, contractId: contractId }
        ContractWijzigenToevoegenService.showContract(data).then((response) => {
            if (response?.data?.status === true) {
                setContractList(response?.data?.data);
            } else {
                setContractList([]);
            }
        }).catch((error) => console.log("catch error:", error))
    }
    const callApi = (field, value, type, setFieldError, setLoadingKeys) => {
        if (value === "") {
            setFieldError(field, "required");
            toast.error("Dit veld is verplicht");
            return;
        }
        const data = {
            iCommunityId: communityId,
            contractId: contractId,
            ...(field ? { attr: field } : {}),
            [field]: value,
            ...(type ? { role: type } : {})
        }
        ContractTypenService.saveContract(data)
            .then(response => {
                const data = response?.data?.data;
                if (response?.data?.status) {
                    if (data?.hidden !== undefined || data?.iAutoLoad !== undefined ||
                        data?.uitzondering !== undefined || data?.iFavoriet !== undefined ||
                        data?.order !== undefined) {
                        setContractList(prev => ({
                            ...prev,
                            lists: {
                                ...prev.lists,
                                cp: {
                                    ...prev.lists.cp,
                                    ...data
                                }
                            }
                        }));
                    } else if (data?.m_hidden !== undefined || data?.m_iAutoLoad !== undefined ||
                        data?.m_uitzondering !== undefined || data?.m_iFavoriet !== undefined ||
                        data?.m_order !== undefined) {
                        setContractList(prev => ({
                            ...prev,
                            lists: {
                                ...prev.lists,
                                sp: {
                                    ...prev.lists.sp,
                                    ...data
                                }
                            }
                        }));
                    }
                    else {
                        setContractList(prev => ({
                            ...prev,
                            lists: {
                                ...prev.lists,
                                ...data
                            }
                        }));
                    }
                    return
                };
                if (!response.data.status) {
                    setErrorField(field);
                    const errors = response.data.data[field];
                    if (Array.isArray(errors)) {
                        toast.error(errors.join(','));
                    } else {
                        toast.error(errors);
                    }
                }
            })
            .catch(error => {
                console.error('API error:', error);
            }).finally(() => {
                if (setLoadingKeys) setLoadingKeys(prev => ({ ...prev, [field]: false }));
            });
    };

    const debouncedApiCall = debounce(callApi, 500);

    const handleFieldChange = (field, value, type, setFieldError) => {
        setErrorField('');
        debouncedApiCall(field, value, type, setFieldError);
    };
    const handleIconFieldChange = (field, value, type, setLoadingKeys) => {
        setErrorField('');
        callApi(field, value, type, null, setLoadingKeys);
    };
    useEffect(() => {
        setLoading(true);
        showContract();
        fetchFilter();
    }, [])

    if (!contractList) {
        return <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
    }

    const deleteContract = () => {
        const data = {
            iCommunityId: communityId,
            contractId: contractId
        }

        ContractTypenService.deleteContract(data)
            .then(response => {
                if (response.data.success) {
                    navigate('/c-instellingen/contracttypen');
                }
            })
            .catch(error => {
                console.error('API error:', error);
            });
    }

    const fetchFilter = () => {
        try {
            const data = { iCommunityId: communityId, contractId: contractId }
            ContractWijzigenToevoegenService.contractFilter(data.iCommunityId, data.contractId).then(response => {
                if (response.data.status) {
                    setFilterData(response?.data?.data?.filters)
                    setLoading(false);
                } else {
                    console.log(response.data.message);
                }
            });
        } catch (error) {
        }
    }
    const handleFilterDelete = (value) => {
        ContractWijzigenToevoegenService.contractFilterDelete(value)
            .then((response) => {
                if (response.data.status) {
                    fetchFilter()
                }
            })
            .catch((error) => {
                errorField.setFilterCentralePlanning(error.response?.data?.message);
            });
    }

    const filterSave = (role) => {
        const rolee = role === "school" ? "1" : "0";
        const data = {
            iCommunityId: communityId,
            iContractTypeId: contractId,
            role: rolee
        }
        ContractWijzigenToevoegenService.contractFilterSave(data)
            .then((response) => {
                if (response?.data?.status) { fetchFilter(); return };
            })
            .catch((error) => {
                errorField.setFilterCentralePlanning(error.response?.data?.message);
            });
    }

    const showFilter = (role, filterId, planning) => {
        navigate(`/c-instellingen/contracttypen/filterinstellingen/${filterId}`, { state: { planning: planning, iCommunityId: communityId, filterId: filterId, role: role } });
    }

    return (
        <Fragment>
            <PageHeading />
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    {
                        isLoading ?
                            <Card className="main-card">
                                <CardBody className="d-flex flex-column">
                                    <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
                                </CardBody>
                            </Card> : <>
                                <div className='row contract-typen-view mb-5'>
                                    <div className='col-xl-6 col-xxl-4 p-2'>
                                        <Algemeen data={contractList} changeHandler={handleFieldChange} handleIconFieldChange={handleIconFieldChange} errorField={errorField} />
                                    </div>
                                    <div className='col-xl-6 col-xxl-4 p-2'>
                                        <Standaardkeuzes data={contractList} changeHandler={handleFieldChange} errorField={errorField} />
                                    </div>
                                    <div className='col-xxl-4 p-2'>
                                        <ControleInstellingenPlanning data={contractList} handleIconFieldChange={handleIconFieldChange} />
                                    </div>
                                    <div className='col-xl-6 p-2'>
                                        <CentralePlanning data={contractList} handleIconFieldChange={handleIconFieldChange} changeHandler={handleFieldChange} />
                                    </div>
                                    <div className='col-xl-6 p-2'>
                                        <Schoolplanning data={contractList} handleIconFieldChange={handleIconFieldChange} changeHandler={handleFieldChange} />
                                    </div>
                                    <div className={`col-xl-6 p-2 ${!Number(contractList?.lists?.cp?.hidden) ? "d-block" : "d-none"}`}>
                                        <FiltersCentralePlanning fetchFilter={fetchFilter} filterData={filterData?.fcp} showFilter={showFilter} handleFilterDelete={handleFilterDelete} />
                                    </div>
                                    <div className={`col-xl-6 p-2 ${!Number(contractList?.lists?.sp?.m_hidden) ? "d-block" : "d-none"}`}>
                                        <FiltersSchoolplanning fetchFilter={fetchFilter} filterData={filterData?.fsp} showFilter={showFilter} handleFilterDelete={handleFilterDelete} />
                                    </div>
                                </div>
                                <div className="chat-layout-footer py-5">
                                    <CardFooter className="chat-layout-footer__inner gebruikers-footer">
                                        <div className="d-flex gap-3">

                                            <Button
                                                outline
                                                className="btn-outline-primary-blue d-flex justify-content-center align-items-center gap-2"
                                                onClick={() => { filterSave("central") }}
                                                type="button"
                                                color="primary"
                                                disabled={!contractList?.lists?.cp?.hidden ? false : true}
                                            >
                                                Centrale planning filter <AddIcon />
                                            </Button>
                                            <Button
                                                outline
                                                className="btn-outline-primary-blue d-flex justify-content-center align-items-center gap-2"
                                                onClick={() => { filterSave("school") }}
                                                type="button"
                                                color="primary"
                                                disabled={!contractList?.lists?.sp?.m_hidden ? false : true}
                                            >
                                                Schoolplanning filter  <AddIcon />
                                            </Button>
                                            <Button
                                                outline
                                                className="btn-outline-primary-blue d-flex justify-content-center align-items-center gap-2"
                                                onClick={() => setDeleteModalOpen(true)}
                                                type="button"
                                                color="primary"
                                                disabled={!contractList?.lists?.iFailOver ? false : true}
                                            >
                                                Contracttype  <Delete />
                                            </Button>
                                        </div>
                                    </CardFooter>
                                </div>

                                <Modal isOpen={isDeleteModalOpen} className="modal-centered contract-type-modal feedback-modal-style link-form">
                                    <ModalHeader toggle={() => setDeleteModalOpen(false)} className='px-3 mt-4'>Contracttype verwijderen</ModalHeader>

                                    <ModalBody className='text-center mt-4 mb-4'>
                                        <h5>Verwijderen?</h5>
                                    </ModalBody>
                                    <ModalFooter className='justify-content-center pt-3'>
                                        <>
                                            <Button color="link" onClick={() => { deleteContract() }} className={"btn-color-style"}>
                                                Ja
                                            </Button>
                                            <Button color="link" onClick={() => setDeleteModalOpen(false)} className={"btn-color-style"}>
                                                Nee
                                            </Button>
                                        </>
                                    </ModalFooter>
                                </Modal>
                            </>
                    }
                </CSSTransition>
            </TransitionGroup>

        </Fragment>
    )
}

export default WijzigenToevoegen