import axiosInstance from 'Api';

const getUsers = (data) => {
    return axiosInstance.post('instellingen/planning/get-users', data)
};

const saveOrUpdate = (data) => {
    return axiosInstance.post('instellingen/planning/save-or-update', data)
};

const fetchPlanning = (id) => {
    return axiosInstance.get("instellingen/planning?iCommunityId=" + id);
};

const PlanningService = {
    getUsers,
    saveOrUpdate,
    fetchPlanning,
};
export default PlanningService;