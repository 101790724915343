import React, {Fragment, useState, useEffect} from "react";
import {Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";

import Auth from "../../services/Auth";
import AppHeader from "../../Layout/AppHeader";
import AppSidebar from "../../Layout/AppSidebar";
import {CSSTransition, TransitionGroup} from "react-transition-group";

import avatar5 from "../../assets/utils/images/avatars/5.jpg";

import ChangePasswordForm from "./Forms/ChangePasswordForm";

import ProfileForm from "Pages/Profile/Forms/ProfileForm";
import ImageUploadForm from "Pages/Profile/Forms/ImageUploadForm";
import ProfileUsernameForm from "Pages/Profile/Forms/ProfileUsernameForm";
import classnames from "classnames";
import AppFooter from "Layout/AppFooter";
import PageHeading from "Layout/AppMain/PageHeading";
import {useLocation} from 'react-router-dom';
import TwoFactorAuthentication from "./Forms/TwoFactorAuthentication";
import axiosInstance from "Api";
import { useSelector } from "react-redux";

const Profile = () => {
    const userRoles = useSelector((state) => state.UserPermission);
    const [activeTab, setActiveTab] = useState("1");
    const [user, setUser] = useState(Auth.getAuthenticatedUser());
    const [userProfileData, setUserProfileData] = useState({});
    const location = useLocation();
    let from= "";
    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    useEffect(() => {
        fetchMe();
    }, []);

    function fetchMe(){
        axiosInstance.get('me').then(response => {
            if (response.status === 200) {
                setUserProfileData(response.data);
            }
        }).catch(e => console.log(e))
    } 

    useEffect(()=>{
        if(location.state !== null ){
            from = location.state.from;
        }
        if(from == 'header-password'){
            setActiveTab("2");
        } else if(from == 'header-2fa') {
            setActiveTab("3");
        } else{
            setActiveTab("1");
        }
    },[location]);

    return (
        <Fragment>
            <AppHeader/>
            <div className="app-main">
                <AppSidebar/>
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        <TransitionGroup>
                            <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                                           timeout={1500} enter={false} exit={false}>
                                <div>
                                    {/* <PageTitle heading="Profiel"
                                               icon="pe-7s-user icon-gradient bg-tempting-azure"/> */}
                                    <PageHeading />

                                    <Row className="userDetailsTabs">

                                        <Col lg="2">
                                            <ImageUploadForm user={user} image={avatar5}/>
                                        </Col>
                                        <Col lg="10" xxl="10">
                                            <Card>
                                                <CardHeader className="card-header-tab">

                                                    <Nav>
                                                        <NavItem>
                                                            <NavLink href="#"
                                                                     className={classnames({
                                                                         active: activeTab === "1",
                                                                     })}
                                                                     onClick={() => {
                                                                         toggle("1");
                                                                     }}>
                                                                Algemeen
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem>
                                                            <NavLink href="#"
                                                                     className={classnames({
                                                                         active: activeTab === "2",
                                                                     })}
                                                                     onClick={() => {
                                                                         toggle("2");
                                                                     }}>
                                                                Wachtwoord
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem>
                                                            <NavLink href="#"
                                                                     className={classnames({
                                                                         active: activeTab === "3",
                                                                     })}
                                                                     onClick={() => {
                                                                         toggle("3");
                                                                     }}>
                                                                Twee-factor-authenticatie (2fa)
                                                            </NavLink>
                                                        </NavItem>
                                                        
                                                        <NavItem>
                                                            <NavLink href="#"
                                                                     className={classnames({
                                                                         active: activeTab === "4",
                                                                     })}
                                                                     onClick={() => {
                                                                         toggle("4");
                                                                     }}>
                                                                Gebruikersnaam
                                                            </NavLink>
                                                        </NavItem>
                                                    </Nav>
                                                </CardHeader>
                                                <CardBody>
                                                    <TabContent activeTab={activeTab}>
                                                        <TabPane tabId="1">
                                                            <ProfileForm id={user.iAdminId}
                                                                         username={user.vUserName} data={userProfileData?.user}/>
                                                        </TabPane>
                                                        <TabPane tabId="2">
                                                            <ChangePasswordForm id={user.iAdminId}
                                                                                username={user.vUserName} userProfileData={userProfileData}/>
                                                        </TabPane>
                                                        <TabPane tabId="3">
                                                            <TwoFactorAuthentication id={user.iAdminId} fetchUserDetail={fetchMe} userProfileData={userProfileData}
                                                                                 username={user.vUserName} TwoFAEnabled={userProfileData?.enabled}/>
                                                        </TabPane>
                                                        <TabPane tabId="4">
                                                            <ProfileUsernameForm id={user.iAdminId}
                                                                                 username={user.vUserName}  />
                                                        </TabPane>
                                                    </TabContent>
                                                </CardBody>
                                            </Card>

                                        </Col>
                                    </Row>
                                </div>
                            </CSSTransition>
                        </TransitionGroup>
                    </div>
                </div>
                <AppFooter/>
            </div>
        </Fragment>
    )
}

export default Profile;
