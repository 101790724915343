import React, { useEffect } from 'react';
import ReactTableGenericForUsers from 'Components/ReactTableGenericForUsers';
import {
    Card, CardBody
} from 'reactstrap';
import GroupStatus from "../../../../../Components/IconDropdown/Cross_Tick/GroupStatus";
import axiosInstance from 'Api';
import { toast } from 'react-toastify';

const Scholen = ({ data, handleSchoolClick, communityId, setSearchValue, searchValue, setPageNumber, toggle, pageNumber, paginate, userTablePageSize, pageSizeCount, totalPageCount, showLoader, tableData, editSelectedRow, setShowLoader, fetchSchoolList }) => {

    useEffect(() => {
        !editSelectedRow && setShowLoader(true);
        fetchSchoolList();
    }, [pageSizeCount, pageNumber, searchValue, communityId]);

    const handleToggle = (field, data, id) => {
        handleIconFieldChange(field, data, id);
    };


    const columns = [
        {
            header: "School",
            accessorKey: "vSchoolName",
            cell: value => {
                return (
                    <div className='svg-pointer' onClick={() => handleSchoolClick("update", value.row.original)} >
                        {value.row.original.vSchoolName}
                    </div>
                );
            },
        },
        {
            header: "Plaats",
            accessorKey: "vVisitcity",
            cell: value => {
                return (
                    <div className="" >
                        {value.row.original.vVisitcity}
                    </div>
                );
            },
        },
        {
            header: <div className="text-center font-weight-bold">Actief</div>,
            accessorKey: "iSchoolId",
            cell: value => {
                const schoolId = value?.row?.original?.iSchoolId;
                return <div className="d-flex justify-content-center">
                    {
                        <GroupStatus iconChangeHandler={(field, data) => handleToggle(field, data, schoolId)} field={"iSchoolId"} data={value.row?.original.eStatus === "Active" ? 1 : 0} />
                    }
                </div >
            },
        }
    ];

    const handleIconFieldChange = (field, value, id, removeLoading) => {
        axiosInstance.post(`bestuur/scholen/toggle`, {
            iCommunityId: data?.iCommunityId,
            iSchoolId: id,
            eStatus: value === "1" ? "Active" : "Inactive"
        }).then((response) => {
            if (response?.data?.status) {
                toggle();
                fetchSchoolList();
            } else {
                toast.error("Kan niet updaten.");
                toggle();
            }
        }).catch((error) => {
            toggle();
            console.error(error);
            toast.error("Kan niet updaten.");
        })
    };
    const filterList = (val) => {
        handleInputChange(val);
    }

    const handleInputChange = (val) => {
        setPageNumber(1);
        setSearchValue(val);
    };
    return (
        <>
            <Card className="main-card pb-5">
                <CardBody className="d-flex flex-column">
                    <ReactTableGenericForUsers
                        data={tableData}
                        columns={columns}
                        userTablePageSize={userTablePageSize}
                        filterList={filterList}
                        totalPageCount={totalPageCount}
                        paginate={paginate}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                        pageSizeCount={pageSizeCount}
                        showLoader={showLoader}
                        tableClassName="mb-0 table table-borderless table-sm table-hover"
                    
                    />
                </CardBody>
            </Card >
        </>
    );
};

export default Scholen;