import DropdownList from "react-widgets/DropdownList";
import React, {useEffect, useState} from "react";
import axiosInstance from "Api";
import {useNavigate} from "react-router-dom";

const SubstituterSearch = () => {

    const [data, setData] = useState([]);
    const [substituter, setSubstituter] = useState(null);
    let navigate = useNavigate();
    useEffect(() => {

        if (data.length === 0) {
            fetchData();
        }

    }, []);


    const fetchData = () => {
        let userData = [];

        axiosInstance.get('get-sub-request-search-form-list-by-type?type=substitute').then((response) => {
            if (response.status == 200) {
                if (response.data.data != null && response.data.data != undefined) {
                    let userData = response.data.data;
                    userData.forEach((element, index) => {
                        userData[index]['userName'] = element.vLastName + ' ' + element.vFirstName + ' ' + element.vMiddleName;
                    });
                    setData(userData);
                }

            }
        }).catch((error) => console.log("catch error:", error));

    }

    const getSubRequests = (id, name) => {
        setSubstituter(id);
        navigate("/searchsubrequests/" + id, {state: { name: name} });

    }

    return (
        <>
            {
                <div className="me-2">
                    <DropdownList dataKey='iAdminId' textField='userName' placeholder="Zoek op vervanger"

                                  onChange={(nextValue) => getSubRequests(nextValue.iAdminId, nextValue.userName )}
                                  data={data}
                    /></div>
            }
        </>

    )
}

export default SubstituterSearch;