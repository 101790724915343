import React, { useState } from "react";
import { Form, Formik } from "formik";
import { Card, CardBody, FormGroup, Input, Label, Spinner } from 'reactstrap'
import { ReactComponent as PenIcon } from "../../../../../assets/utils/images/svgs/editPen.svg";
import GroupStatus from "../../../../../Components/IconDropdown/Cross_Tick/GroupStatus";

const Algemeen = ({ data, changeHandler, handleIconFieldChange, errorField }) => {
    const [editTextArea, setEditTextArea] = useState(false);
    const [loadingKeys, setLoadingKeys] = useState({})

    const defaultValue = {
        name: data?.lists?.name,
        abbr: data?.lists?.abbr,
        Tarief: data?.lists?.tarief,
        Herinneringsmail: parseInt(data?.lists?.profielmail),
        AdviesWWZ: data?.lists?.adviceketenregeling,
        Memo: data?.lists?.opmerking,
    }

    const handleToggle = (field, data) => {
        setLoadingKeys(prev => ({ ...prev, [field]: true }));
        handleIconFieldChange(field, data, null, setLoadingKeys)
    };

    return (
        <>
            <Card className="main-card">
                <CardBody className="d-flex flex-column">
                    <div className="btn-color-style travelCost-blue-text mb-4">Algemeen</div>

                    <Formik
                        enableReinitialize
                        initialValues={defaultValue}
                        validateOnChange={true}
                    >
                        {({ values, touched, errors, handleChange, setFieldError }) => (
                            <Form>
                                <FormGroup className={`form-element ${(errors.name) ? 'error' : ''}`}>
                                    <Label for="name">Omschrijving</Label>
                                    <Input
                                        name="name"
                                        type="text"
                                        value={values.name}
                                        onChange={(e) => {
                                            handleChange(e);
                                            changeHandler("name", e.target.value, "", setFieldError);
                                        }}
                                        className="w-50"
                                    />

                                </FormGroup>

                                <FormGroup className={`form-element ${(errors.abbr) ? "error" : ""}`}>
                                    <Label for="abbr">Afkorting</Label>
                                    <Input
                                        name="abbr"
                                        type="text"
                                        value={values.abbr}
                                        onChange={(e) => {
                                            handleChange(e);
                                            changeHandler("abbr", e.target.value, "", setFieldError)
                                        }}
                                        className="w-50"
                                        maxLength={3}
                                    />
                                </FormGroup>

                                <FormGroup className={`form-element ${errorField === "Tarief" ? "error" : ""}`}>
                                    <Label for="Tarief">Tarief</Label>
                                    <Input
                                        name="Tarief"
                                        type="number"
                                        value={values.Tarief}
                                        onChange={(e) => {
                                            handleChange(e);
                                            changeHandler("tarief", parseFloat(e.target.value))
                                        }}
                                        className="w-50"
                                    />
                                </FormGroup>

                                <FormGroup className={`form-element ${errorField === 'Herinneringsmail' ? 'error' : ''}`}>
                                    <Label for="Herinneringsmail">Herinneringsmail</Label>
                                    <div className="position-relative w-50">
                                        <div className="d-flex gap-1 align-items-center ">
                                            {loadingKeys["profielmail"] ? (
                                                <div className="spinner-icon-change">
                                                    <div><Spinner size="sm" color="primary" /></div>
                                                </div>
                                            ) :
                                                (
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"profielmail"} data={values?.Herinneringsmail ? 0 : 1} controlFrom={"contracttype"} />
                                                )}
                                        </div>
                                    </div>
                                </FormGroup>
                                <FormGroup className={`form-element ${errorField === 'Advies-WWZ' ? 'error' : ''}`}>
                                    <Label for="Advies-WWZ">Advies WWZ</Label>
                                    <div className="position-relative w-50">
                                        <div className="d-flex gap-1 align-items-center">
                                            {loadingKeys["adviceketenregeling"] ? (
                                                <div className="spinner-icon-change">
                                                    <div><Spinner size="sm" color="primary" /></div>
                                                </div>
                                            ) :
                                                <GroupStatus iconChangeHandler={handleToggle} field={"adviceketenregeling"} data={values?.AdviesWWZ ? 0 : 1} controlFrom={"contracttype"} />
                                            }
                                        </div>
                                    </div>
                                </FormGroup>

                                <FormGroup className={`d-flex justify-content-between ${errorField === "Memo" ? "error" : ""}`}>
                                    <Label for="Memo">Memo</Label>
                                    <div className="position-relative w-50">
                                        <div className="d-flex justify-content-end mb-2">
                                            <PenIcon className="svg-pointer" onClick={() => { setEditTextArea(!editTextArea) }} />
                                        </div>
                                        <div className="d-flex gap-1 align-items-center">
                                            <Input
                                                name="Memo"
                                                type="textarea"
                                                value={values.Memo}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    changeHandler("opmerking", e.target.value)
                                                }}
                                                rows={6}
                                                disabled={!editTextArea}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>
                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card>
        </>
    )
}

export default Algemeen