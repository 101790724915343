import React, { useState } from 'react';
import { Form, Formik, Field, ErrorMessage } from "formik";
import { Button, Card, CardBody, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Table, Tooltip } from 'reactstrap';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { GENERATE_PASSWORD, UPDATE_PASSWORD } from '../utils';
import BootstrapInputGroup from 'Pages/Profile/Forms/BootstrapInputGroup';
import GebruikerAlgemeenService from 'Api/Gebruikers/GebruikersView/Algemeen';
import { BsCheckCircle } from "react-icons/bs";
import { ReactComponent as CrossIcon } from "../../../../../assets/utils/images/svgs/Cross.svg";
import { ReactComponent as PenIcon } from "../../../../../assets/utils/images/svgs/editPen.svg";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MdContentCopy } from "react-icons/md";
import Tippy from "@tippyjs/react";

const validationSchemaForPass = Yup.object({
    password: Yup.string()
        .matches("^\\S*$", "Wachtwoord mag geen spaties hebben")
        .required('Wachtwoord is verplicht')
        .min(8, "Wachtwoord moet minimaal 8 tekens lang zijn")
        .matches("[A-Za-z]+", "Gebruik hoofdletters en kleine letters a-z en A-Z tekens")
        .matches("[0-9]+", "Wachtwoord moet cijfers bevatten")
        .matches("[`!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>\\/?~]+", "Wachtwoord moet speciale tekens bevatten !@#$%^&*()_+"),
});
const validationSchemaForUser = Yup.object({
    clooserUn: Yup.string().required('Gebruikersnaam is verplicht'),
});

const LoginDetails = ({ data, currentUser, changeHandler, errorField }) => {
    const [modal, setModal] = useState(false);
    const [generatedPassword, setGeneratedPassword] = useState('');
    const [isLoader, setLoader] = useState(false);
    const [linkGenerated, setLinkGenerated] = useState(false);
    const [modalData, setModalData] = useState({ type: '' });
    const [copyTooltipOpen, setCopyTooltipOpen] = useState(false);

    const updatePasswordHandler = (type, password = '') => {
        setGeneratedPassword('');
        setLinkGenerated(false)
        const data = {
            iAdminId: currentUser.data.clooserId,
            vPassword: type === GENERATE_PASSWORD ? '' : password
        }
        if (type === GENERATE_PASSWORD) setLoader(true);
        GebruikerAlgemeenService.updateGebruikerPassword(data)
            .then(response => {
                if (response.data.status) {
                    if (type === UPDATE_PASSWORD) {
                        setModal(false);
                        toast.success('Wachtwoord succesvol bijgewerkt.')
                    } else {
                        setGeneratedPassword(response.data.newPassword)
                        setLoader(false);
                    }
                };
            })
            .catch(error => {
                console.error('API error:', error);
            });
    }

    const forgottenPasswordLink = () => {
        setGeneratedPassword('');
        const data = {
            iAdminId: currentUser.data.clooserId
        }
        GebruikerAlgemeenService.gebruikerForgotPassword(data)
            .then(response => {
                if (response.data.status) {
                    setLinkGenerated(true);
                };
            })
            .catch(error => {
                console.error('API error:', error);
            });
    }

    return (
        <>
            <Card className="main-card">
                <CardBody className="d-flex flex-column">
                    <h5 className="mb-4">Inloggegevens</h5>

                    <Row className="mb-3 d-flex justify-content-between align-items-center">
                        <Col className="col-sm-10">Two-factor-authentication</Col>
                        <Col className="col-sm-2 d-flex justify-content-end">
                            {data?.Is2faEnabled ? (
                                <BsCheckCircle color="#39A825" fontSize="18px" />
                            ) : (
                                <CrossIcon />
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-3 mt-1 d-flex justify-content-between align-items-center">
                        <Col className="col-md-6 mt-0">Gebruikersnaam</Col>
                        <Col className="col-md-6 d-flex justify-content-between mt-0">
                            <span className='text-break'>{data?.vUserName}</span>
                            <span className='ms-3 svg-pointer' onClick={() => {
                                setModal(prev => !prev)
                                setModalData({ type: "UPDATE_USERNAME" })
                            }}>
                                <PenIcon />
                            </span>
                        </Col>
                    </Row>
                    <Row className="mb-3 mt-1 d-flex justify-content-between align-items-center">
                        <Col className="col-md-6 mt-0">Wachtwoord</Col>
                        <Col className="col-md-6 d-flex justify-content-between mt-0">
                            <span>********</span>
                            <span className='ms-3 svg-pointer' onClick={() => {
                                setModal(prev => !prev)
                                setModalData({ type: "UPDATE_PASSWORD" })
                            }}>
                                <PenIcon />
                            </span>
                        </Col>
                    </Row>

                    <div className="d-flex gap-3 justify-content-center mt-4">
                        <Button
                            outline
                            className="btn-outline-primary-blue btn-color-style py-2 px-3"
                            type="button"
                            color="primary"
                            onClick={forgottenPasswordLink}
                            style={{ minWidth: '56%' }}
                        >
                            Stuur wachtwoord vergeten-mail
                        </Button>
                        <Button
                            outline
                            className={`btn-outline-primary-blue btn-color-style py-2 px-3 ${isLoader || generatedPassword ? 'd-none' : ''}`}
                            type="button"
                            color="primary"
                            onClick={() => { updatePasswordHandler(GENERATE_PASSWORD) }}
                            style={{ minWidth: '44%', transition: '0.3s' }}
                        >
                            Genereer wachtwoord
                        </Button>
                        {isLoader && (
                            <div className="text-center d-flex align-items-center justify-content-center" style={{ minWidth: '44%' }}>
                                <span>Wachtwoord genereren...</span>
                            </div>
                        )}

                        {generatedPassword && (
                            <div className="text-center d-flex align-items-center justify-content-center"
                                style={{ minWidth: '44%', transition: '0.3s' }}>
                                <span className="me-2">{generatedPassword}</span>

                                <Tippy
                                    content={copyTooltipOpen ? 'Gekopieerd!' : 'Klik om te kopiëren'}
                                    placement="top"
                                    duration={[250, 180]}
                                    hideOnClick={false}
                                >
                                    <div className="position-relative d-inline-block">
                                        <CopyToClipboard
                                            text={generatedPassword}
                                            onCopy={() => {
                                                setCopyTooltipOpen(true);
                                                setTimeout(() => setCopyTooltipOpen(false), 2000);
                                            }}
                                        >
                                            <span>
                                                <MdContentCopy size={19} className="svg-pointer cursor-pointer" />
                                            </span>
                                        </CopyToClipboard>
                                    </div>
                                </Tippy>

                            </div>
                        )}

                    </div>
                    <div className='mt-3 text-center'>
                        {linkGenerated && <p>Wachtwoord vergeten mail is verstuurd.</p>}
                    </div>
                </CardBody>
            </Card>
            <Modal isOpen={modal} toggle={() => { setModal(prev => !prev); setModalData({ type: '' }) }} className="feedback-modal-style link-form modal-centered">
                <Formik
                    initialValues={{ password: '', clooserUn: data?.vUserName || '' }}
                    validationSchema={modalData.type === "UPDATE_PASSWORD" ? validationSchemaForPass : validationSchemaForUser}
                    onSubmit={(values) => {
                        if (modalData?.type === "UPDATE_PASSWORD") {
                            updatePasswordHandler(UPDATE_PASSWORD, values.password);
                        } else {
                            changeHandler('vUserName', values.clooserUn, setModal);
                        }
                    }}
                >

                    {({ values, handleSubmit, isSubmitting, handleChange }) => (
                        <Form onSubmit={handleSubmit}>
                            <ModalHeader toggle={() => { setModal(prev => !prev); setModalData({ type: '' }) }}>
                                {
                                    modalData?.type === "UPDATE_PASSWORD" ?
                                        "Wachtwoord" :
                                        "Gebruikersnaam"
                                }
                            </ModalHeader>
                            <ModalBody>
                                <FormGroup>
                                    <Label for={`${modalData?.type === "UPDATE_PASSWORD" ? "password" : "clooserUn"}`}>
                                        {
                                            modalData?.type === "UPDATE_PASSWORD" ?
                                                "Wachtwoord invoeren" :
                                                "Gebruikersnaam invoeren"
                                        }</Label>
                                    {
                                        modalData?.type === "UPDATE_PASSWORD" ?
                                            <Field name="password">
                                                {({ field, form, meta }) => (
                                                    <BootstrapInputGroup field={field} meta={meta} />
                                                )}
                                            </Field>
                                            :
                                            <>
                                                <Input name="clooserUn"
                                                    data-lpignore="true"
                                                    autoComplete="off"
                                                    value={values.clooserUn} type="text" onChange={(e) => {
                                                        handleChange(e);
                                                    }}
                                                />
                                                <ErrorMessage name="clooserUn" component="div" className="text-danger" />
                                            </>
                                    }
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter className="d-flex align-items-center justify-content-center border-bottom-0 py-4">
                                <Button className="btn-color-style opslaan-btn" size="sm" outline color="primary" type="submit" disabled={errorField ? false : isSubmitting}>
                                    Toepassen
                                </Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    )
};

export default LoginDetails;
