import { useState, useRef, useCallback, useMemo } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Col } from "reactstrap";
import { ReactComponent as Upload } from "../../../../../assets/utils/images/svgs/Upload.svg";
import { ReactComponent as Group } from "../../../../../assets/utils/images/svgs/Group.svg";
import { ReactComponent as PDFIcon } from "../../../../../assets/utils/images/svgs/PDF_disabled.svg";
import { useDropzone } from 'react-dropzone';
import { toast } from "react-toastify";

export default function PDFPopup({ isOpen, togglePopup, classes, file, setFile, doc, handleFileSave, isPdfUploading }) {
    const inputFile = useRef(null);
    const [showForm, setShowForm] = useState(false);
    const fileSize = 2;

    const baseStyle = {
        padding: '20px',
    };

    const activeStyle = {
        borderColor: '#2196f3'
    };

    const acceptStyle = {
        borderColor: '#00e676'
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };

    const onDrop = useCallback(acceptedFiles => {
        dropImageOrPdf(acceptedFiles);
    }, []);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        onDrop,
        accept: 'image/jpeg, image/jpg, image/png, application/pdf'
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    function toggle() {
        togglePopup(!isOpen);
        setShowForm(false);
    }

    const onButtonClick = (e) => {
        inputFile.current.click();
    };

    const uploadImageOrPdf = (e) => {

        e.preventDefault();
        const file = e.target.files[0];

        if (!file) return;

        if (file.size >= fileSize * 1024 * 1024) {
            toast.error("Maximale bestandsgrootte is 2 MB");
            return;
        }
        if (file.type === 'application/pdf') {
            setShowForm(true);
        }

        if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png') {
            toast.error("Alleen pdf is toegestaan");
            return;
        }
        setFile(file);
    };

    const dropImageOrPdf = (e) => {

        const file = e[0];

        if (file?.size >= fileSize * 1024 * 1024) {
            toast.error("Maximale bestandsgrootte is 2 MB");
            return;
        }

        if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png') {
            toast.error("Alleen pdf is toegestaan");
            return;
        }


        if (file.type === 'application/pdf') {
            setShowForm(true);
        }
        setFile(file);
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} className={classes} >
            <ModalHeader>Selecteer document  <Group onClick={toggle} style={{ cursor: 'pointer' }} /></ModalHeader>
            <ModalBody>
                <div>
                    {
                        !file ?
                            <>
                                <div {...getRootProps({ style })}>
                                    <input {...getInputProps()}
                                        name="fileinput"
                                        ref={inputFile}
                                        type="file"
                                        className="d-none"
                                        onChange={uploadImageOrPdf}
                                    />
                                    <div><Upload onClick={onButtonClick} style={{ cursor: 'pointer' }} /></div>
                                </div>
                                <p className="bold-content">Sleep of klik op het icoon om bestand toe te voegen</p>
                                <p className="normal-content">Toegestane bestandsformaten: *.pdf</p></>

                            : <FormGroup row >
                                <Label className={"setting-lebel-style text-start"}>
                                    Geselecteerd bestand
                                </Label>
                                <Col sm={12} className="setting-lebel-style text-start">
                                    <PDFIcon /> {doc !== '' && doc.name !== undefined ? doc.name : ''}
                                </Col>
                            </FormGroup>
                    }



                </div>
            </ModalBody>
            <ModalFooter>
                {
                    (showForm === true && doc !== '' && doc.name !== undefined) &&
                    <Button color="link" className="btn-color-style" disabled={isPdfUploading ? true : false} onClick={handleFileSave}>
                        Versturen
                    </Button>
                }
            </ModalFooter>
        </Modal>
    );
}
