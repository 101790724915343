import React, { useState } from 'react'
import { ButtonDropdown, DropdownMenu, DropdownToggle, Nav, NavLink } from 'reactstrap'
import { iconSwitch } from './utils'
import { BsCheckCircle } from "react-icons/bs";
import { ReactComponent as CrossIcon } from "../../../assets/utils/images/svgs/Cross.svg";
import 'tippy.js/themes/light.css';

const GroupStatus = ({ iconChangeHandler, field, data, role = null, APIcall = true, controlFrom = null, disable = false, IconRef = null, setIsTerGoedkeuringSelected = null, isTerGoedkeuringSelected = null }) => {
    const [isOpen, setOpen] = useState(false);
    const handleSelect = (d) => {
        let value;
        if (controlFrom === "contracttype") {
            value = d ? "1" : "0";
        }
        else if (controlFrom === "schoolGeneralDetail") {
            value = d ? "Inactive" : "Active";
        }
        else {
            value = d ? "0" : "1";
        }
        if (APIcall) {
            iconChangeHandler(field, value, role);
        } else {
            if (data.toString() === value) {
                iconChangeHandler(field, value, role);
            }
        }
        setOpen(false);
    };
    return (
        <div className='d-flex'>
            <ButtonDropdown isOpen={isOpen} toggle={() => setOpen(!isOpen)}>
                <DropdownToggle color="link" className="p-0 d-flex" style={{ width: "21px" }} disabled={disable ? true : false} onClick={() => setOpen(!isOpen)} ref={IconRef}>
                    {isTerGoedkeuringSelected !== null
                        ? isTerGoedkeuringSelected === true &&iconSwitch(parseInt(data))
                        : iconSwitch(parseInt(data))
                    }
                </DropdownToggle>
                {isOpen &&
                    <DropdownMenu end className="rm-pointers dropdown-menu-lg functies-dropdown-width">
                        <Nav vertical className="dropdown-custom-link">
                            <NavLink className="link-gap" href="#" onClick={() => {
                                handleSelect(0)
                                setIsTerGoedkeuringSelected && setIsTerGoedkeuringSelected(true);
                            }}>
                                <BsCheckCircle color='#39A825' fontSize={"18px"} style={{ width: "19px" }} />
                            </NavLink>

                            <NavLink className="link-gap" href="#" onClick={() => {
                                handleSelect(1);
                                setIsTerGoedkeuringSelected && setIsTerGoedkeuringSelected(true);
                            }}>
                                <CrossIcon color='#FB2E5F' style={{ width: "19px" }} />
                            </NavLink>
                        </Nav>
                    </DropdownMenu>
                }
            </ButtonDropdown>
        </div>
    )
}

export default GroupStatus