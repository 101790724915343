import axiosInstance from 'Api';

const saveDaytypeApi = (data) => {
    return axiosInstance.post('instellingen/dagtype/save', data)
};

const updateDaytypeApi = (data) => {
    return axiosInstance.put('instellingen/dagtype/update', data)
};

const fetchDaytypeData = (id) => {
    return axiosInstance.get(`instellingen/dagtype?communityId=${id}`);
};

const toggleDaytypeValue = (data)=>{
    return axiosInstance.post("instellingen/dagtype/toggle", data)
}

const InstellingenDayTypeService = {
    saveDaytypeApi,
    toggleDaytypeValue,
    fetchDaytypeData,
    updateDaytypeApi
};
export default InstellingenDayTypeService;