import React, { useEffect, useState } from "react";
import LoggingReactTable from "Components/LoggingReactTable";
import "react-datepicker/dist/react-datepicker.css";
import "tippy.js/dist/tippy.css";
import axiosInstance from "Api";
import { MdErrorOutline } from "react-icons/md";
import { FaRegCheckCircle } from "react-icons/fa";

const List = (props) => {
	const [data, setData] = useState([]);
	const [pageSizeCount, setPageSizeCount] = useState(20);
	const [pageNumber, setPageNumber] = useState(1);
	const [totalPageCount, setTotalPageCount] = useState(0);
	const [searchValue, setSearchValue] = useState("");
	const [showLoader, setShowLoader] = useState(false);

	const fetchData = () => {
		setShowLoader(true);
		axiosInstance
			.get(
				"/logging?pageSize=" +
					pageSizeCount +
					"&page=" +
					pageNumber +
					"&search=" +
					searchValue
			)
			.then((response) => {
				if (response.data.status === true) {
					setShowLoader(false);
					setData(response.data.list);
					setTotalPageCount(response.data.pageCount);
				}
			})
			.catch((e) => console.error(e));
	};

	const paginate = (val) => {
		setPageNumber(val);
	};

	useEffect(() => {
		fetchData();
	}, [searchValue, pageSizeCount, pageNumber]);

	const handleInputChange = (val) => {
		setPageNumber(1);
		setSearchValue(val);
	};

	const filterList = (val) => {
		handleInputChange(val);
	};

	const indexPageSize = (val) => {
		console.log("pageNumber", pageNumber);
		setPageSizeCount(val);
	};

	const loggingTablePageSize = (val) => {
		setPageNumber(1);
		indexPageSize(val);
	};

	const columns = [
		{
			header: "Date/time",
			accessorKey: "timestamp",
		},
		{
			header: "Username",
			accessorKey: "username",
		},
		{
			header: "Full name",
			accessorKey: "fullname",
		},
		{
			header: "Role",
			accessorKey: "role",
		},
		{
			header: "Type",
			accessorKey: "type",
		},
		{
			header: "Result",
			accessorKey: "status",
			cell: ({cell}) => <>{cell.getValue() === 'error' ? <MdErrorOutline color="red"/> : <FaRegCheckCircle color="green" />} </>,
		},
		{
			header: "Message",
			accessorKey: "message",
		},
		{
			header: "IP Address",
			accessorKey: "ipAddress",
		},
		{
			header: "Browser",
			accessorKey: "browser",
		},
	];

	return (
		<>
			<LoggingReactTable
				data={data}
				columns={columns}
				setPageNumber={setPageNumber}
				pageNumber={pageNumber}
				totalPageCount={totalPageCount}
				paginate={paginate}
				filterList={filterList}
				loggingTablePageSize={loggingTablePageSize}
				pageSizeCount={pageSizeCount}
				showLoader={showLoader}
				tableClassName="mb-0 table table-borderless table-sm table-hover table-heading-style"
			/>
		</>
	);
};

export default List;
