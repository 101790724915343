import React, { Fragment, useEffect, useMemo, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Button, Card, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import PageHeading from "Layout/AppMain/PageHeading";
import ReactTableGeneric from "Components/ReactTableGeneric";
import Loader from "react-loaders";
import { ReactComponent as AddIcon } from "../../../../assets/utils/images/svgs/addBlue.svg";
import { ReactComponent as CrossIcon } from "../../../../assets/utils/images/svgs/Cross.svg";
import { BsCheckCircle } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import { toast } from "react-toastify";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import ReplacementCodeService from "Api/Instellingen/ReplacementCode";
import CoordinatorInstellingenService from "Api/Instellingen";

const validationSchema = Yup.object({
    vCode: Yup.string().required('Code is verplicht'),
    vCodeValue: Yup.string().required('Omschrijving is verplicht').max(50, 'Omschrijving must be at most 50 characters'),
    eKetenregeling: Yup.string().required('Vervanging volgens ketenregeling is verplicht'),
    eExclude_wwz: Yup.string().required('Uitzondering WWZ is verplicht'),
    vTarief: Yup.number()
    .typeError('Must be a number')
    .positive('Must be a positive number')
    .test('is-decimal', 'Moet maximaal 2 decimalen bevatten', value =>
      value === undefined || value === null
        ? true
        : /^\d+(\.\d{1,2})?$/.test(value.toString())
    )
});

const ReplacementCodes = () => {
    const [isLoading, setLoading] = useState(false);
    const [communityId, setCommunityId] = useState('');
    const [communityList, setCommunityList] = useState([]);
    const [codesData, setCodesData] = useState();
    const [modal, setModal] = useState({
        state: false,
        type: "add",
        rowData: {}
    });
    const [costBearerData, setCostBearerData] = useState([]);

    useEffect(() => {
        setLoading(true);
        fetchCommunityList();
    }, []);

    const fetchCommunityList = () => {
        CoordinatorInstellingenService.communityList().then((response) => {
            if (response.status) {
                if (response.data.communityList?.length > 0) {
                    setCommunityList(response.data.communityList);
                    setCommunityId(response.data.communityList[0]?.iCommunityId);
                }
            }
        }).catch(() => console.error);
    };

    useEffect(() => {
        if (!communityId) return;
        fetchCodesHandler();
    }, [communityId]);

    const fetchCodesHandler = () => {
        setLoading(true);
        ReplacementCodeService.fetchCodesData(communityId).then((response) => {
            if (response.status) {
                setCodesData(response.data.data.vervangingsList);
                setCostBearerData(response.data.data.kostendragers);
            }
            setLoading(false);
        }).catch(() => console.error);
    };

    const columns = React.useMemo(
        () => [
            {
                header: () => 'Code',
                accessorKey: 'vCode',
                cell: (value)=>{
                    return <div className="cursor-pointer" onClick={()=>{setModal({
                        state: true,
                        type: 'edit',
                        rowData: value.row.original
                    })}}>{value.row.original.vCode}</div>
                }
            },
            {
                header: () => 'Omschrijving',
                accessorKey: 'vCodeValue',
                cell: (value) => {
                    return <div>{value.row.original.vCodeValue}</div>
                }
            },
            {
                header: () => 'Categorie',
                accessorKey: 'eCategory',
            },
            {
                header: () => 'Vervanging',
                accessorKey: 'eKetenregeling',
                cell: (value) => {
                    return <div className="d-flex justify-content-center">
                        {
                            value.row.original.eKetenregeling === "Yes" ?
                                <BsCheckCircle color='#39A825' fontSize={"18px"} /> :
                                <CrossIcon color='#FB2E5F' fontSize={"24px"} />
                        }
                    </div>
                },
            },
            {
                header: () => 'Kostendrager',
                accessorKey: 'eKostendrager',
            },
            {
                header: () => 'Status',
                accessorKey: 'eStatus',
                cell: (value) => {
                    return <div className="d-flex justify-content-center">
                        {
                            value.row.original.eStatus === "Active" ?
                                <BsCheckCircle color='#39A825' fontSize={"18px"} /> :
                                <CrossIcon color='#FB2E5F' fontSize={"24px"} />
                        }
                    </div>
                },
            }],
        [communityId]
    );

    const toggle = () => {
       setModal(prev=>({
        ...prev,
        state: !prev.state,
       }))
    };

    const formInitialValues = useMemo(()=>{
        const normalizeDecimalValue = (value) => {
            if (typeof value === 'string' && value) {
                return parseFloat(value.replace(',', '.')); // Replace ',' with '.' and parse as a number
            }
            return value;
        };
        if(modal.type === "edit") return {
            vCode: modal.rowData.vCode,
            vCodeValue: modal.rowData.vCodeValue,
            eCategory: modal.rowData.eCategory || "Verlof",
            eStatus: modal.rowData.eStatus || "Active",
            eKetenregeling: modal.rowData.eKetenregeling,
            eExclude_wwz: modal.rowData.eExclude_wwz,
            eKostendrager: modal.rowData.eKostendrager,
            vTarief: normalizeDecimalValue(modal.rowData.vTarief),
        }
        else return {
            vCode: '',
            vCodeValue: '',
            eCategory: 'Verlof',
            eStatus: 'Active',
            eKetenregeling: '',
            eExclude_wwz: '',
            eKostendrager: '',
            vTarief: '',
        }
    }, [modal.type, modal.rowData]);

    const handleOrgSubmit = (values) => {
        const data = {
            iCommunityId: communityId,
            vCode: values.vCode,
            vCodeValue: values.vCodeValue,
            eCategory: values.eCategory,
            eStatus: values.eStatus,
            eKetenregeling: values.eKetenregeling,
            eExclude_wwz: values.eExclude_wwz,
            eKostendrager: values.eKostendrager,
            vTarief: values.vTarief,
            type: modal.type
        };
        if(modal.type === "edit"){
            data.iCodeId = modal.rowData.iCodeId
        }
        ReplacementCodeService.saveCodeApi(data).then((response) => {
            if (response.status) {
                fetchCodesHandler();
                setModal(false);
            } else {
                toast.error("Er is iets misgegaan");
            }
        }).catch(() => console.error);
    };

    const orgName = useMemo(()=>{
        return communityList?.find(item => item.iCommunityId === Number(communityId))?.name || ""
    },[communityId, communityList]);

    return (
        <Fragment>
            <PageHeading orgName={orgName}/>
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    <Row className="mb-5">
                        <Col>
                            <Card className="main-card mb-3">
                                <CardBody className="p-3">
                                    {isLoading ?
                                        <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} /> :
                                        <ReactTableGeneric
                                            data={codesData || []}
                                            columns={columns}
                                            tableClassName="mb-0 table table-borderless table-sm table-hover tb-codes"
                                            rowCount={20}
                                            filters={{ search: false, pagination: true, sorting: false }}
                                        />
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                        <Modal isOpen={modal.state} toggle={toggle} className="feedback-modal-style link-form">
                            <ModalHeader toggle={toggle}>
                                Vervangingscode toevoegen/wijzigen
                            </ModalHeader>
                            <ModalBody>
                                <Formik
                                    initialValues={formInitialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleOrgSubmit}
                                    enableReinitialize={true}
                                >
                                    {({ setFieldValue, handleSubmit, values, handleChange, handleBlur }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <FormGroup>
                                                <Label for="vCode">Code *</Label>
                                                <Input name="vCode"
                                                    type="text" onChange={handleChange} onBlur={handleBlur}
                                                    value={values.vCode} />
                                                <ErrorMessage name="vCode" component="small"
                                                    className="block mt-2 invalid-feedback" />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="vCodeValue">Omschrijving *</Label>
                                                <Input name="vCodeValue"
                                                    type="text" onChange={handleChange} onBlur={handleBlur}
                                                    value={values.vCodeValue} />
                                                <ErrorMessage name="vCodeValue" component="small"
                                                    className="block mt-2 invalid-feedback" />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="eCategory">Selecteer categorie</Label>
                                                <Input
                                                    id="eCategory"
                                                    name="eCategory"
                                                    type="select"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.eCategory}
                                                >
                                                    <option value="Verlof">Verlof</option>
                                                    <option value="Verzuim">Verzuim</option>
                                                    <option value="Overig">Overig</option>
                                                </Input>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="eStatus">Selecteer status</Label>
                                                <Input
                                                    id="eStatus"
                                                    name="eStatus"
                                                    type="select"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.eStatus}
                                                >
                                                    <option value="Active">Actief</option>
                                                    <option value="Inactive">Inactief</option>
                                                </Input>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="eKetenregeling">Vervanging volgens ketenregeling *</Label>
                                                <Input
                                                    id="eKetenregeling"
                                                    name="eKetenregeling"
                                                    type="select"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.eKetenregeling}
                                                >
                                                    <option value=""></option>
                                                    <option value="Yes">Ja</option>
                                                    <option value="No">Nee</option>
                                                </Input>
                                                <ErrorMessage
                                                    name="eKetenregeling"
                                                    component="small"
                                                    className="block mt-2 invalid-feedback"
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="eExclude_wwz">Uitzondering WWZ *</Label>
                                                <Input
                                                    id="eExclude_wwz"
                                                    name="eExclude_wwz"
                                                    type="select"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.eExclude_wwz}
                                                >
                                                    <option value=""></option>
                                                    <option value="Yes">Ja</option>
                                                    <option value="No">Nee</option>
                                                </Input>
                                                <ErrorMessage
                                                    name="eExclude_wwz"
                                                    component="small"
                                                    className="block mt-2 invalid-feedback"
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="eKostendrager">Selecteer kostendrager</Label>
                                                <Input
                                                    id="eKostendrager"
                                                    name="eKostendrager"
                                                    type="select"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.eKostendrager}
                                                >
                                                    <option value="">--- Geen kostendrager ---</option>
                                                    {costBearerData?.map(item => {
                                                        return <option key={item.id}>{item.vKostendrager}</option>
                                                    })}
                                                </Input>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="vTarief">Tarief (€)</Label>
                                                <Input name="vTarief"
                                                    type="text" onChange={handleChange} onBlur={handleBlur}
                                                    value={values.vTarief} />
                                                <ErrorMessage name="vTarief" component="small"
                                                    className="block mt-2 invalid-feedback" />
                                            </FormGroup>
                                            <div className="ms-1 mt-2" style={{ color: "#878EA6" }}>
                                                <span className="text-danger">*</span> Verplicht veld
                                            </div>
                                            <ModalFooter className="d-flex align-items-center justify-content-center border-bottom-0 py-4 mt-4">
                                                <Button className="btn-color-style opslaan-btn" size="sm" outline color="primary" type="submit">
                                                    Toepassen
                                                </Button>
                                            </ModalFooter>
                                        </Form>
                                    )}
                                </Formik>
                            </ModalBody>
                        </Modal>
                    </Row>
                </CSSTransition>
            </TransitionGroup>
            <div className="chat-layout-footer verzoken-footer-height">
                <CardFooter className="chat-layout-footer__inner">
                    <div className="d-flex gap-3 mr-4">
                        <div>
                            <select className={`form-select`} value={communityId} onChange={(e) => { setCommunityId(e.target.value) }}>
                                <option value={''} disabled>Alle besturen</option>
                                {communityList?.map(item => {
                                    return <option key={item.iCommunityId} value={item.iCommunityId}>{item.name}</option>
                                })}
                            </select>
                        </div>
                        <Button
                            outline
                            className="btn-outline-primary-blue btn-color-style py-2 px-3 d-flex gap-2 align-items-center"
                            onClick={()=>{
                                setModal({
                                    state: true,
                                    type: "add",
                                    rowData: {}
                                });
                            }}
                            type="button"
                            color="primary"
                        >
                            Vervangingscodes <AddIcon />
                        </Button>
                    </div>
                </CardFooter>
            </div>
        </Fragment>
    );
};

export default ReplacementCodes;
