import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Button, Card, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import PageHeading from "Layout/AppMain/PageHeading";
import ReactTableGeneric from "Components/ReactTableGeneric";
import Loader from "react-loaders";
import { toast } from "react-toastify";
import { Formik } from "formik";
import ReactTableGenericForUsers from "Components/ReactTableGenericForUsers";
import BeheerService from "Api/Beheer";
import BeheerSmsService from "Api/Beheer/Sms";

const SendSMS = () => {
    let formikSubmit;
    const [usersLoader, setUsersLoader] = useState(false);
    const [pageSizeCount, setPageSizeCount] = useState(10);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [smsHistoryData, setSmsHistoryData] = useState([]);
    const [historyLoader, setHistoryLoader] = useState(false);
    const [usersData, setUsersData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [communities, setCommunities] = useState([]);
    const [filterData, setFilterData] = useState({
        community: '',
        user: '',
        contractType: '',
    });
    const [contractList, setContractList] = useState([]);
    const [remainingChars, setRemainingChars] = useState(1530);
    const [description, setDescription] = useState('');
    const [textLimit, setTextLimit] = useState(0);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);

    useEffect(() => {
        setHistoryLoader(true)
        setUsersLoader(true)
        communityContractList();
    }, []);

    useEffect(() => {
        if (communities?.length > 0 && filterData.community) {
            const data = communities.find(item => String(filterData.community) === String(item.iCommunityId));
            if (data) {
                setContractList(data?.contracttypes)
            }
            getSmsHistoryHandler(filterData.community)
        }
    }, [filterData.community, communities]);

    useEffect(() => {
        if (!filterData?.community) return;
        getUserSmsDataHandler();
    }, [filterData, pageSizeCount, pageNumber])

    const communityContractList = () => {
        BeheerService.getCommunityContractList().then((response) => {
            if (response.status === 200) {
                if (response.data?.data?.length > 0) {
                    setCommunities(response.data.data);
                    setFilterData({
                        community: response.data.data[0].iCommunityId,
                        user: '',
                        contractType: ''
                    })
                } else {
                    setCommunities([]);
                }
            }
        }).catch((err) => console.error(err));
    }

    const getSmsHistoryHandler = (id) => {
        setHistoryLoader(true);
        BeheerSmsService.getSmsHistory(id).then((response) => {
            if (response.status === 200) {
                setSmsHistoryData(response.data.data);
                setHistoryLoader(false);
            } else {
                setSmsHistoryData([]);
                setHistoryLoader(false);
            }
        }).catch((err) => console.error(err));
    }

    const getUserSmsDataHandler = () => {
        setUsersLoader(true);
        const data = {
            selUser: filterData.user,
            pageNumber,
            pageSize: pageSizeCount,
            iCommunityId: filterData.community,
            iContractType: filterData.contractType
        }
        BeheerSmsService.getUserSmsInfo(data).then((response) => {
            if (response.status === 200) {
                setUsersData(response.data.data.infoList);
                setTotalPageCount(response.data.data.count);
                setUsersLoader(false);
            } else {
                setUsersData([]);
                setUsersLoader(false);
            }
        }).catch((err) => console.error(err));
    }

    const smsHistoryCol = React.useMemo(
        () => [
            {
                header: () => 'Datum',
                accessorKey: 'Datum',
                cell: (value) => {
                    return <><div>
                        {value.row.original.date}
                    </div>
                        <div>{value.row.original.message}</div>
                    </>
                }
            },
            {
                header: () => 'Naam verzender',
                accessorKey: 'name',
            },
            {
                header: () => 'Mobiele ontvanger',
                accessorKey: 'mobile',
            },
            {
                header: () => 'Status',
                accessorKey: 'status',
            }
        ],
        [smsHistoryData]
    );

    const smsCols = React.useMemo(
        () => [
            {
                header: () => 'Naam',
                accessorKey: 'name',
            },
            {
                header: () => 'Plaats',
                accessorKey: 'vCity',
            },
            {
                header: () => 'Mobiel',
                accessorKey: 'iMobileNumber',
            },
            {
                header: () => (
                    <div onClick={(e) => e.stopPropagation()}>
                        <Input
                            type="checkbox"
                            className="col-md-12 me-1"
                            checked={selectAllChecked}
                            onChange={(e) => selectAll(e.target.checked)}
                        />
                    </div>
                ),
                accessorKey: 'rows',
                cell: (value) => {
                    return (
                        <Input
                            type="checkbox"
                            className="col-md-12"
                            checked={selectedUsers?.includes(value.row.original.iAdminId)}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    selectedUsersHandler(value.row.original.iAdminId, 'ADD');
                                } else {
                                    selectedUsersHandler(value.row.original.iAdminId, 'REMOVE');
                                }
                            }}
                        />
                    );
                },
            },
        ],
        [usersData, selectAllChecked, selectedUsers]
    );

    const handleOrgSubmit = () => {
        if(selectedUsers?.length === 0){
            toast.error("Selecteer minimaal één gebruiker.");
            return;
        }
        setBtnDisabled(true);
        const data = {
            iAdminIds: selectedUsers.join(","),
            message: description,
            iCommunityId: filterData.community
        }
        BeheerSmsService.smsSave(data).then((response) => {
            if (response.status) {
                toast.success("Sms succesvol verzonden");
                setSelectedUsers([]);
                setHistoryLoader(true);
                getSmsHistoryHandler(filterData.community);
                setBtnDisabled(false);
            }else {
                toast.error("Kan geen sms versturen.");
                setBtnDisabled(false);
            }
        }).catch((err) => console.error(err));
    }

    const paginate = (val) => {
        setUsersLoader(true);
        setPageNumber(val);
    }

    const userTablePageSize = (val) => {
        setPageNumber(1);
        setPageSizeCount(val);
    }

    const filterHandler = (type, value) => {
        setFilterData((prev) => {
            return { ...prev, [type]: value }
        })
    }
    const handleTextareaChange = (e) => {
        const textLength = e.target.value.length;
        const remaining = 1530 - textLength;

        // Only update if within the 1530 character limit
        if (remaining >= 0) {
            setDescription(e.target.value);
            setRemainingChars(remaining);
            calculateSmsLimit(textLength);
        } else {
            // If the length exceeds 1530, truncate the text to the limit
            const truncatedText = e.target.value.substring(0, 1530);
            setDescription(truncatedText);
            setRemainingChars(0);
            calculateSmsLimit(1530);
        }
    };

    const calculateSmsLimit = (textLength) => {
        const smsCount = Math.ceil(textLength / 160);
        setTextLimit(smsCount);
    };

    function selectAll(checked) {
        setSelectAllChecked(checked);
        if (checked) {
            const usersAdminIds = usersData.map((item) => item.iAdminId);
            selectedUsersHandler(usersAdminIds, 'ADD_ALL');
        } else {
            selectedUsersHandler([], 'REMOVE_ALL');
        }
    }

    function selectedUsersHandler(userAdminIds, type = "") {
        setSelectedUsers((prevSelectedUsers) => {
            switch (type) {
                case "ADD_ALL":
                    return userAdminIds;
                case "REMOVE_ALL":
                    return [];
                case "ADD":
                    return [...prevSelectedUsers, userAdminIds];
                case "REMOVE":
                    setSelectAllChecked(false);
                    return prevSelectedUsers.filter(userId => userId !== userAdminIds);
                default:
                    return prevSelectedUsers;
            }
        });
    }

    return (
        <Fragment>
            <PageHeading />
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    <Row className="mb-5">
                        <Col sm={12}>
                            <div className="row">
                                <div className="col-lg-6">
                                    <Card className="main-card mb-3" style={{height: '95%'}}>
                                        <CardBody className="p-3">
                                            <Formik
                                                initialValues={{ description: '' }}
                                                validate={(values) => {
                                                    const errors = {};
                                                    if (!values.description) {
                                                        errors.description = 'Beschrijving is verplicht.';
                                                    }
                                                    return errors;
                                                }}
                                                onSubmit={handleOrgSubmit}
                                            >
                                                {({ handleSubmit, handleBlur, handleChange, values, errors, touched, submitForm}) => {
                                                    formikSubmit = submitForm;
                                                    return <Form onSubmit={handleSubmit}>
                                                        <FormGroup>
                                                            <Input
                                                                name="description"
                                                                type="textarea"
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    handleTextareaChange(e); 
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={values.description}
                                                                rows="10"
                                                            />
                                                            {errors.description && touched.description && (
                                                                <div style={{ color: 'red', marginTop: '5px' }}>
                                                                    {errors.description}
                                                                </div>
                                                            )}
                                                        </FormGroup>
                                                        <FormGroup className="d-flex justify-content-between">
                                                            <Label for="remainingChars">Aantal tekens resterend:</Label>
                                                            <div>
                                                                <Input
                                                                    name="remainingChars"
                                                                    type="text"
                                                                    value={remainingChars}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                        <FormGroup className="d-flex justify-content-between">
                                                            <Label for="textLimit">Aantal sms per gebruiker:</Label>
                                                            <div>
                                                                <Input
                                                                    id="txtLimit"
                                                                    name="textLimit"
                                                                    type="text"
                                                                    value={textLimit}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                        <FormGroup className="d-flex justify-content-between mb-4">
                                                            <Label for="selectedUsers">Aantal geselecteerde gebruikers:</Label>
                                                            <div>
                                                                <Input
                                                                    id="selectedUsers"
                                                                    name="selectedUsers"
                                                                    type="text"
                                                                    value={selectedUsers.length}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                    </Form>
                                                }}
                                            </Formik>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className="col-lg-6">
                                    <Card className="main-card mb-3" style={{height: '95%'}}>
                                        <CardBody className="p-3">
                                            <>
                                                <ReactTableGenericForUsers
                                                    data={usersData}
                                                    tableClassName="mb-0 table table-sm table-hover tb-req"
                                                    userTablePageSize={userTablePageSize}
                                                    totalPageCount={totalPageCount}
                                                    pageNumber={pageNumber}
                                                    pageSizeCount={pageSizeCount}
                                                    showLoader={usersLoader}
                                                    setPageNumber={setPageNumber}
                                                    paginate={paginate}
                                                    columns={smsCols}
                                                    filters={{ search: false, pagination: true, sorting: true }}
                                                    rowCount={10}
                                                />
                                            </>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12}>
                            <div className="row">
                                <div className="col">
                                    <Card className="main-card mb-3">
                                        <CardBody className="p-3">
                                            {historyLoader ? <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} /> : <ReactTableGeneric
                                                data={smsHistoryData || []}
                                                columns={smsHistoryCol}
                                                tableClassName="mb-0 table table-borderless table-sm table-hover tb-api tb-links"
                                                rowCount={20}
                                                filters={{ search: false, pagination: true, sorting: false }}
                                            />}
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </CSSTransition>
            </TransitionGroup>
            <div className="chat-layout-footer verzoken-footer-height">
                <CardFooter className="chat-layout-footer__inner">
                    <div className="d-flex justify-content-between gap-3 mr-4">
                        <div className="d-flex gap-3">
                            <div>
                                <select className={`form-select`} value={filterData.community} onChange={(e) => {
                                    filterHandler('community', e.target.value);
                                    filterHandler('contractType', '')
                                }}>
                                    <option value={''}>Alle besturen</option>
                                    {communities?.map(item => {
                                        return <option value={item.iCommunityId}>{item.vCommunityName}</option>
                                    })}
                                </select>
                            </div>
                            <div>
                                <select className={`form-select`} value={filterData.user} onChange={(e) => { filterHandler('user', e.target.value) }}>
                                    <option value={''}>Alle gebruikers</option>
                                    <option value='vervanger'>Vervangers</option>
                                    <option value='melder'>Melders</option>
                                    <option value='coordinator'>Coördinatoren</option>
                                </select>
                            </div>
                            <div>
                                <select className={`form-select select`} style={{ width: '175px' }} value={filterData.contractType} onChange={(e) => { filterHandler('contractType', e.target.value) }}>
                                    <option value={''}>Alle contracttypen</option>
                                    {contractList?.map(item => {
                                        return <option value={item.id}>{`${item.abbr} - ${item.name}`}</option>
                                    })}
                                </select>
                            </div>
                        </div>

                        <Button
                            outline
                            className="btn-outline-primary-blue btn-color-style py-2 px-3 d-flex gap-2 align-items-center"
                            onClick={() => {
                                formikSubmit(); 
                            }}
                            disabled={btnDisabled}
                            type="button"
                            color="primary"
                        >
                            Versturen
                        </Button>
                    </div>
                </CardFooter>
            </div>
        </Fragment>
    )
}

export default SendSMS