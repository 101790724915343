import React from "react";
import { Card, CardBody, CardHeader, Button, Label, CardFooter } from "reactstrap";
import { DropdownList } from "react-widgets";
import DatesLayout from "Components/Availability/DatesLayout";
import CommunityTypesModal from "Components/Availability/CommunityTypesModal";
import LegendModal from "Components/Availability/LegendModal";
import { AvailabilityProvider, useAvailabilityContext } from "./context/AvailabilityContext";
import { userTabs } from "Pages/Coordinator/Gebruikers/GebruikerView/utils";
import { ReactComponent as BackIcon } from "../../assets/utils/images/svgs/arrow-left.svg";

const modalText =
	"Geef hieronder aan voor welk bestuur je een aparte bestuursbeschikbaarheid wilt invullen";

const Availability = ({ children }) => {
	return (
		<AvailabilityProvider>
			<Card className="main-card availability">{children}</Card>
		</AvailabilityProvider>
	);
};

const AvailabilityModals = ({ iAdminId = null }) => {
	return (
		<>
			<LegendModal />
			<CommunityTypesModal iAdminId={iAdminId} text={modalText} />
		</>
	);
};

const AvailabilityHeader = () => {
	const { years, setOpenLegend, setOpenInstitutions, yearsRange, setYears } =
		useAvailabilityContext();
	return (
		<CardHeader className="row m-0 justify-content-between">
			<div className="col-md-5">
				<span className="ps-0 planbord">
					<span
						className="text-planbord w-100"
						style={{ color: "#878ea6", fontWeight: 500 }}
					>
						Selecteer Jaargang
					</span>
					<DropdownList
						data={yearsRange}
						value={years}
						textField="name"
						onChange={(val) => setYears(val)}
					/>
				</span>
			</div>
			<div className="col-md-3 w-auto">
				<Button
					outline
					className="me-2 btn-outline btn-color-style custom-btn-padding"
					onClick={() => setOpenLegend(true)}
					type="button"
					color="secondary"
				>
					Legenda
				</Button>
				<Button
					outline
					className="me-2 btn-outline btn-color-style custom-btn-padding"
					onClick={() => setOpenInstitutions(true)}
					type="button"
					color="secondary"
				>
					Instellingen
				</Button>
			</div>
		</CardHeader>
	);
};

const AvailabilityTable = ({ iAdminId = null, isCoord = false }) => {
	return (
		<>
			{!isCoord ? (
				<CardBody className="p-3 table-responsive">
					<DatesLayout iAdminId={iAdminId} />
				</CardBody>
			) : (
				<div className="row beschikbaarheid">
					<div className="col-12">
						<CardBody className="p-3 table-responsive">
							<DatesLayout iAdminId={iAdminId} />
						</CardBody>
					</div>
				</div>
			)}
		</>
	);
};

const AvailabilityFooter = ({ setActiveTab }) => {
	const { setYears, yearsRange, setOpenInstitutions, setOpenLegend } = useAvailabilityContext();
	return (
		<div className="chat-layout-footer" style={{ height: "80px" }}>
			<CardFooter className="chat-layout-footer__inner gebruikers-footer">
				<div className="d-flex gap-3 align-items-end w-100">
					<div className="d-flex flex-column">
						<Label className="overview-page-label">Schooljaar</Label>
						<select
							className={`form-select`}
							onChange={(e) => setYears(e.target.value)}
						>
							<option value="" disabled>
								Selecteer schooljaar
							</option>
							{yearsRange.map((item) => {
								return (
									<option value={item} key={item}>
										{item}
									</option>
								);
							})}
						</select>
					</div>
					<Button
						outline
						className="btn-outline-primary-blue"
						onClick={() => setOpenInstitutions(true)}
						type="button"
						color="primary"
					>
						Instellingen
					</Button>
					<Button
						outline
						className="btn-outline-primary-blue"
						onClick={() => setOpenLegend(true)}
						type="button"
						color="primary"
					>
						Legenda
					</Button>
					<Button
						outline
						className="btn-outline-primary-blue ms-auto mt-auto"
						onClick={() => {
							setActiveTab(userTabs.Functies);
						}}
						type="button"
						color="primary"
					>
						<BackIcon />
					</Button>
				</div>
			</CardFooter>
		</div>
	);
};

const AvailabilityContainer = ({ children }) => {
	return <Card className="main-card mb-3">{children}</Card>;
};

Availability.Modals = AvailabilityModals;
Availability.Header = AvailabilityHeader;
Availability.Table = AvailabilityTable;
Availability.Footer = AvailabilityFooter;
Availability.Container = AvailabilityContainer;

export default Availability;
