import { Form, Formik } from "formik";
import { Card, CardBody, FormGroup, Input, Label, Spinner } from 'reactstrap';
import { ReactComponent as PenIcon } from "../../../../../../assets/utils/images/svgs/editPen.svg";
import ColorPicker from 'react-pick-color';
import { useState } from "react";
import GroupStatus from "Components/IconDropdown/Cross_Tick/GroupStatus";

const GeneralDetails = ({ data, handleIconFieldChange, changeHandler, errorField }) => {
    const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
    const [loadingKeys, setLoadingKeys] = useState({})

    const defaultValues = {
        vSchoolTitle: data?.detailList?.vSchoolTitle ?? '',
        vColor: data?.detailList?.vColor ?? '',
        vShortName: data?.detailList?.vShortName ?? '',
        iLeerlingen: data?.detailList?.iLeerlingen ?? '',
        iKostenplaats: data?.detailList?.iKostenplaats ?? '',
        exclude_travelcost: parseInt(data?.detailList?.exclude_travelcost) ?? '',
        eStatus: data?.detailList?.eStatus === "Active" ? 1 : 0
    };

    const handleToggle = (field, data) => {
        setLoadingKeys(prev => ({ ...prev, [field]: true }));
        handleIconFieldChange(field, data, setLoadingKeys)
    };

    return (
        <Card className="main-card">
            <CardBody className="d-flex flex-column">
                <h5 className="mb-4">Algemeen</h5>
                <Formik
                    enableReinitialize
                    initialValues={defaultValues}
                >
                    {({ values, handleChange, setFieldValue }) => (
                        <Form>
                            <FormGroup className={`form-element ${errorField === 'vSchoolTitle' ? 'error' : ''}`}>
                                <Label for="vSchoolTitle">Lestijden</Label>
                                <Input
                                    name="vSchoolTitle"
                                    type="text"
                                    value={values.vSchoolTitle}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vSchoolTitle', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vColor' ? 'error' : ''}`}>
                                <Label for="vColor">Kleur</Label>
                                <div className="d-flex gap-4 align-items-center w-100">
                                    <Input
                                        name="vColor"
                                        type="text"
                                        value={values.vColor}
                                        onChange={(e) => {
                                            handleChange(e);
                                            changeHandler('vColor', e.target.value);
                                        }}
                                    />
                                    <div className="position-relative">
                                        <PenIcon
                                            className="cursor-pointer"
                                            onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                                        />
                                        {isColorPickerOpen && (
                                            <div className="color-picker-wrapper">
                                                <ColorPicker
                                                    color={values.vColor}
                                                    onChange={(newColor) => {
                                                        const hexColor = newColor.hex.replace('#', '');
                                                        setFieldValue('vColor', hexColor);
                                                    }}
                                                />
                                                <button
                                                    className="btn btn-secondary mt-2"
                                                    onClick={() => {
                                                        setIsColorPickerOpen(false);
                                                        changeHandler('vColor', values.vColor);
                                                    }}
                                                >
                                                    Close
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'vShortName' ? 'error' : ''}`}>
                                <Label for="vShortName">Afkorting</Label>
                                <Input
                                    name="vShortName"
                                    type="text"
                                    value={values.vShortName}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('vShortName', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'iLeerlingen' ? 'error' : ''}`}>
                                <Label for="iLeerlingen">Leerlingen</Label>
                                <Input
                                    name="iLeerlingen"
                                    type="text"
                                    value={values.iLeerlingen}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('iLeerlingen', e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'iKostenplaats' ? 'error' : ''}`}>
                                <Label for="iKostenplaats">Kostenplaats</Label>
                                <select
                                    className="form-select"
                                    name="iKostenplaats"
                                    value={values.iKostenplaats}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changeHandler('iKostenplaats', e.target.value);
                                    }}
                                >
                                    <option value="">-- Selecteer kostenplaat ---</option>
                                    {data?.kostenplaatLists?.map(item => {
                                        return <option value={item.id} key={item.id}>{item.vKostenplaats}</option>
                                    })}
                                </select>
                            </FormGroup>
                            <FormGroup className="form-element">
                                <Label for="exclude_travelcost">Reiskosten berekenen</Label>
                                {loadingKeys["exclude_travelcost"] ? (
                                    <div className="spinner-icon-change">
                                        <div><Spinner size="sm" color="primary" /></div>
                                    </div>
                                ) :
                                    <GroupStatus iconChangeHandler={handleToggle} field={"exclude_travelcost"} data={values?.exclude_travelcost ? 1 : 0} />
                                }
                            </FormGroup>
                            <FormGroup className="form-element">
                                <Label for="eStatus">Status</Label>

                                {loadingKeys["eStatus"] ? (
                                    <div className="spinner-icon-change">
                                        <div><Spinner size="sm" color="primary" /></div>
                                    </div>
                                ) :
                                    <GroupStatus iconChangeHandler={handleToggle} field={"eStatus"} data={values?.eStatus ? 1 : 0} controlFrom={"schoolGeneralDetail"} />
                                }
                            </FormGroup>
                        </Form>
                    )}
                </Formik>
            </CardBody>
        </Card>
    );
};

export default GeneralDetails;
