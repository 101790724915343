import React from "react";
import { Form, Formik } from "formik";
import { Card, CardBody, FormGroup, Input, Label } from 'reactstrap'

const AlgemeenForm = ({ data, handleFieldChange }) => {

    const defaultValue = {
        vCommunityName: data?.vCommunityName || ''
    }

    return (
        <>
            <Card className="main-card">
                <CardBody className="d-flex flex-column mb-4">
                    <div className="btn-color-style travelCost-blue-text mb-4">Algemeen</div>

                    <Formik
                        enableReinitialize
                        initialValues={defaultValue}
                        validateOnChange={true}
                    >
                        {({ values, touched, errors, handleChange, setFieldError }) => (
                            <Form>
                                <FormGroup className={`form-element ${(errors.vCommunityName) ? 'error' : ''}`}>
                                    <Label for="vCommunityName">Naam bestuur</Label>
                                    <Input
                                        name="vCommunityName"
                                        type="text"
                                        value={values.vCommunityName}
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleFieldChange("vCommunityName", e.target.value, setFieldError);
                                        }}
                                    />
                                </FormGroup>
                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card>
        </>
    )
}

export default AlgemeenForm