import React, { useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Card, CardBody, Col, FormGroup, Label, Row, Spinner } from 'reactstrap'
import { DropdownList } from "react-widgets";
import * as Yup from "yup";
import GroupStatus from "../../../../../Components/IconDropdown/Cross_Tick/GroupStatus";

const CentralePlanning = ({ data, changeHandler, handleIconFieldChange, errorField }) => {
    const [loadingKeys, setLoadingKeys] = useState({})
    const defaultValues = {
        weergeven: Number(data?.lists?.sp?.m_hidden),
        autoload: Number(data?.lists?.sp?.m_iAutoLoad),
        uitzonderingWWZ: data?.lists?.sp?.m_uitzondering,
        standaardStatus: data?.lists?.sp?.m_iFavoriet,
        volgorde: data?.lists?.sp?.m_order
    }

    const validationSchema = Yup.object().shape({
        volgorde: Yup.number()
            .required("This field cannot be empty")
            .min(0, "Value cannot be negative"),
    });
    const handleToggle = (field, data, type) => {
        setLoadingKeys(prev => ({ ...prev, [field]: true }));
        handleIconFieldChange(field, data, type, setLoadingKeys)
    };
    return (
        <>
            <Card className="main-card">
                <CardBody className="d-flex flex-column">
                    <div className="btn-color-style travelCost-blue-text mb-4">Schoolplanning</div>

                    <Formik
                        enableReinitialize
                        initialValues={defaultValues}
                        validationSchema={validationSchema}
                    >
                        {({ values, setFieldValue, touched, errors, handleChange }) => (
                            <Form>
                                <Row>
                                    <Col sm={8} lg={5} xl={6} className="p-2">
                                        <FormGroup className="form-element min-height">
                                            <Label for="weergeven">Weergeven</Label>
                                            <div className="position-relative w-50">
                                                <div className="d-flex gap-1 align-items-center ">
                                                    {loadingKeys["m_hidden"] ? (
                                                        <div className="spinner-icon-change">
                                                            <div><Spinner size="sm" color="primary" /></div>
                                                        </div>
                                                    ) :
                                                        <GroupStatus iconChangeHandler={handleToggle} field={"m_hidden"} data={values?.weergeven ? 0 : 1} role={"1"} controlFrom={"contracttype"} />

                                                    }
                                                </div>
                                            </div>
                                        </FormGroup>

                                        <FormGroup className={`form-element min-height `}>
                                            <Label for="autoload">Autoload</Label>
                                            <div className="position-relative w-50">
                                                <div className="d-flex gap-1 align-items-center ">
                                                    {loadingKeys["m_iAutoLoad"] ? (
                                                        <div className="spinner-icon-change">
                                                            <div><Spinner size="sm" color="primary" /></div>
                                                        </div>
                                                    ) :
                                                        <GroupStatus iconChangeHandler={handleToggle} field={"m_iAutoLoad"} data={values?.autoload ? 0 : 1} role={"1"} controlFrom={"contracttype"} />
                                                    }
                                                </div>
                                            </div>
                                        </FormGroup>

                                        <FormGroup className={`form-element min-height`}>
                                            <Label for="uitzondering WWZ">Uitzondering WWZ</Label>
                                            <div className="position-relative w-50">
                                                <div className="d-flex gap-1 align-items-center ">
                                                    {loadingKeys["m_uitzondering"] ? (
                                                        <div className="spinner-icon-change">
                                                            <div><Spinner size="sm" color="primary" /></div>
                                                        </div>
                                                    ) :
                                                        <GroupStatus iconChangeHandler={handleToggle} field={"m_uitzondering"} data={values?.uitzonderingWWZ ? 0 : 1} role={"1"} APIcall={false} controlFrom={"contracttype"} />
                                                    }
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </Col>

                                    <Col sm={8} lg={5} xl={6} className="p-2">
                                        <FormGroup className="form-element">
                                            <Label for="standaardStatus">Standaard status</Label>
                                            <div className="position-relative w-50">
                                                <div className="d-flex gap-1 align-items-center">
                                                    <DropdownList dataKey='name' textField='name'
                                                        placeholder="Selecteer"
                                                        onChange={() => {
                                                            const data = values?.standaardStatus ? "0" : "1";
                                                            changeHandler('m_iFavoriet', data)
                                                        }}
                                                        name="standaardStatus"
                                                        value={values.standaardStatus === 0 ? "Aangevraagd" : "Ingepland"}
                                                        data={[{ name: "Aangevraagd", value: "0" }, { name: "Ingepland", value: "1" }]}
                                                        disabled={!values.weergeven ? false : true}
                                                    />
                                                    {errors.Status && <div className="text-danger">{errors.Status}</div>}
                                                </div>
                                            </div>
                                        </FormGroup>

                                        <FormGroup className={`form-element ${touched.volgorde && errors.volgorde ? "error" : ""}`}>
                                            <Label for="volgorde">Volgorde</Label>
                                            <div className="position-relative w-50">
                                                <div className="d-flex gap-1 align-items-center">
                                                    <Field
                                                        name="volgorde"
                                                        type="number"
                                                        min={0}
                                                        className="form-control"
                                                        value={values.volgorde}
                                                        onChange={(e) => {
                                                            const data = e.target.value;
                                                            handleChange(e);
                                                            if (data) changeHandler("m_order", data, "1");
                                                        }}
                                                        disabled={!values.weergeven ? false : true}
                                                    />
                                                </div>
                                                <ErrorMessage name="volgorde" component="div" className="text-danger" />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card >
        </>
    )
}

export default CentralePlanning;