import axiosInstance from 'Api';

const getScholenData = (communityId) => {
    return axiosInstance.get(`instellingen/scholen?iCommunityId=${communityId}`);
};

const getAlgemeenData = (communityId, schoolId)=>{
    return axiosInstance.get(`instellingen/scholen/detail/${schoolId}?iCommunityId=${communityId}`)
}

const getInstellingenData = (communityId, schoolId)=>{
    return axiosInstance.get(`instellingen/scholen/instellingen-detail/${schoolId}?iCommunityId=${communityId}`)
}

const updateScholenData = (data, schoolId)=>{
    return axiosInstance.post(`instellingen/scholen/save/${schoolId}`, data)
}

const fetchMeldersList = (data)=>{
    return axiosInstance.post(`instellingen/scholen/melder-list`, data)
}

const addAndDeleteMelder = (data)=>{
    return axiosInstance.post(`instellingen/scholen/toggle-melder`, data)
}

const getWtfData = (data)=>{
    return axiosInstance.post(`instellingen/scholen/wtf-list`, data)
}

const updateWtfData = (data)=>{
    return axiosInstance.post(`instellingen/scholen/save-wtf`, data)
}

const ScholenService = {
    getScholenData,
    getAlgemeenData,
    getInstellingenData,
    updateScholenData,
    fetchMeldersList,
    addAndDeleteMelder,
    getWtfData,
    updateWtfData
};
export default ScholenService;