import Step1 from './Step1';

const Documenten = ({ data, isLoading, currentUser, filterCommunity, setFilterCommunity, adminCommunityList, setActiveTab, updateDataHandler }) => {
    return (
        <Step1 currentUser={currentUser} isLoading={isLoading} documentData={data}
            setActiveTab={setActiveTab} filterCommunity={filterCommunity}
            adminCommunityList={adminCommunityList} setFilterCommunity={setFilterCommunity}
            updateDataHandler={updateDataHandler} />
    )
}

export default Documenten