import React, { createContext, useMemo, useState } from "react";
import { useContext } from "react";

const AvailabilityContext = createContext();

export function useAvailabilityContext() {
	const context = useContext(AvailabilityContext);

	if (!context) throw new Error("Use availability context within provider.");

	return context;
}

const getSchoolYearsRange = () => {
	const currentDate = new Date();
	const currentYear = currentDate.getFullYear();
	const startOfSchoolYearMonthIndex = 8;
	const currentMonth = currentDate.getMonth() + 1;

	let startYear = currentMonth >= startOfSchoolYearMonthIndex ? currentYear : currentYear - 1;
	return Array.from({ length: 4 }, (_, i) => `${startYear + i}-${startYear + i + 1}`);
};

export const AvailabilityProvider = ({ children }) => {
	const yearsRange = useMemo(() => getSchoolYearsRange(), []);
	const [years, setYears] = useState(yearsRange[0]);
	const [openInstitutions, setOpenInstitutions] = useState(false);
	const [openLegend, setOpenLegend] = useState(false);
	const [communities, setCommunities] = useState([]);
	const isStandart =
		communities.length && communities.some((comm) => comm.type !== "S") ? "A" : "S";
	const [type, setType] = useState(isStandart);

	return (
		<AvailabilityContext.Provider
			value={{
				years,
				setYears,
				yearsRange,
				openLegend,
				setOpenLegend,
				openInstitutions,
				setOpenInstitutions,
                communities,
                setCommunities,
                type,
                setType,
                isStandart
			}}
		>
			{children}
		</AvailabilityContext.Provider>
	);
};
