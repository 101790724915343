import {Route, Routes} from "react-router-dom";
import React, {Fragment} from "react";
import PrivateRoute from "../../Components/Router/PrivateRoute";
import Substitutions from "../../Pages/Melder/Substitutions";
import SubstitutionsHistory from "../../Pages/Melder/Substitutions/History";
import SubstitutionShow from "../../Pages/Melder/Substitutions/Show";
import WeekReport from "../../Pages/Melder/WeekReport";
import Feedback from "../../Pages/Melder/Feedback";
import Profile from "../../Pages/Profile";
import Login from "../../Pages/Login";
import Admin from "../../Pages/Admin";
import Overview from "../../Pages/Melder/Substitutions/Overview/Dashboard";
import Actueel from "../../Pages/Melder/WeekReport/Actueel";
import History from "../../Pages/Melder/WeekReport/History";
import Users from "../../Pages/Admin/Users";
import GeneralSettings from "../../Pages/Admin/GeneralSettings";
import Logging from "../../Pages/Admin/Logging";
import AppFooter from "Layout/AppFooter";
import SearchTable from "../../Pages/Melder/Search";
import Export from "../../Pages/Melder/Export";
import Home from "../../Pages/Vervanger/Home";
import Vervanger from "../../Pages/Vervanger";
import Substitutor from "../../Pages/Vervanger/Substitutor";
import { useLocation} from "react-router-dom"
import LogOut from "Pages/Logout";
import Indienen from "Pages/Melder/Indienen";
import IndienenSubstitution from "../../Pages/Melder/Indienen/Verzoek";
import Support from "Pages/Melder/Support";
// const Login = lazy(() => import("../../Pages/Login"));
import Document from "Pages/Melder/Document";
import Forbidden from "Pages/Errors/Forbidden";
// const Verzoeken = lazy(() => import("../../Pages/Verzoeken"));
import WeekReportShow from "Pages/Melder/WeekReport/Show"
import Calendar from "Pages/Melder/Substitutions/Calendar";
import CoordinatorCalendar from "Pages/Coordinator/Verzoeken/Calendar";
import Administration from "Pages/Admin/Administration";
import Availability from "Pages/Vervanger/Substitutor/Availability";
import HomeOverview from "../../Pages/Vervanger/Home/Overview/";
import HomeShow from "../../Pages/Vervanger/Home/Show";
import Settings from "../../Pages/Vervanger/Settings";
import VervangerOverview from "../../Pages/Vervanger/Overview";
import VervangerDocumenten from '../../Pages/Vervanger/Document';
import VervangerVerzoeken from '../../Pages/Coordinator/Verzoeken';
import CoordinatorOverview from "Pages/Coordinator/Verzoeken/Default";
import CoordinatorPlanboard from "Pages/Coordinator/Verzoeken/Planboard";
import CoordinatorDetailsPage from "../../Pages/Coordinator/Verzoeken/Show";
import TravelCost from "Pages/Coordinator/TravelCost";
import Distances from "Pages/Coordinator/TravelCost/Distances";
import Calculation from "Pages/Coordinator/TravelCost/Calculation";
import TravelCostOverview from "Pages/Coordinator/TravelCost/Default";
import Weekstaten from "Pages/Coordinator/Weekstaten";
import WeekstatenOverview from "Pages/Coordinator/Weekstaten/Overview"
import Compile from "Pages/Coordinator/Weekstaten/Compile";
import ManageLinks from "Pages/Admin/ManageLinks";
import Links from "Pages/Common/Links";
import Gebruikers from "Pages/Coordinator/Gebruikers";
import GebruikerView from "Pages/Coordinator/Gebruikers/GebruikerView";
import WeeklyStatements from "Pages/Coordinator/Weekstaten/WeeklyStatement";
import Apis from "Pages/Admin/Apis";
import ErrorPage from "Pages/Errors/ErrorPage";
import ResetPassword from "Pages/ResetPassword";
import Sms from "Pages/Admin/Sms";
import Instellingen from "../../Pages/Coordinator/Instellingen";
import Features from "Pages/Coordinator/Instellingen/Features";
import CostCenterUnit from "Pages/Coordinator/Instellingen/CostCenterUnit";
import DayTypes from "Pages/Coordinator/Instellingen/DayTypes";
import SendSMS from "Pages/Coordinator/Beheer/SendSMS";
import ReplacementCodes from "Pages/Coordinator/Instellingen/ReplacementCode";
import Beheer from "Pages/Coordinator/Beheer";
import SchoolsOverview from "Pages/Coordinator/Instellingen/Schools/Default";
import SchoolsView from "Pages/Coordinator/Instellingen/Schools/SchoolsView";
import Contracttypen from "Pages/Coordinator/Instellingen/ContractTypen";
import Reporting from "Pages/Coordinator/Beheer/Reporting";
import PushMessagesDefault from "Pages/Admin/LogPushMessages";
import Exporteren from "Pages/Coordinator/Beheer/Exporteren";
import PermissionDeny from "Pages/Errors/PermissionDeny";
import WijzigenToevoegen from "Pages/Coordinator/Instellingen/ContractTypen/WijzigenToevoegen/index";
import FllterInstellingen from "Pages/Coordinator/Instellingen/ContractTypen/FllterInstellingen/index";
import Besturen from "Pages/Coordinator/Instellingen/Besturen";
import Planning from "Pages/Coordinator/Instellingen/Planning";
import Functies from "Pages/Admin/Functies";
import AdminBesturen from "Pages/Admin/Besturen/index";
import Besturens from "Pages/Admin/Besturen/BesturenView";

const AppMain = () => {
    return (
        <Fragment>
           <Routes>
                <Route element={<PrivateRoute showFor={"requestor"}><Indienen/></PrivateRoute>} path="/indienen">
                    <Route index element={<IndienenSubstitution/>}/>
                </Route>
                
                <Route element={<PrivateRoute showFor="requestor"><Substitutions /></PrivateRoute>} path="/verzoeken">
                    <Route index element={<Overview />} />
                    <Route path="historie"element={<SubstitutionsHistory />} />
                    <Route path=':id' element={<SubstitutionShow />} />
                    <Route path=':id/dagdelen-toevoegen' element={<Calendar />} />
                </Route>
                <Route element={<PrivateRoute showFor="requestor"><WeekReport/></PrivateRoute>} path="/weekstaat" >
                    <Route path=':id' element={<WeekReportShow/>}/>
                    <Route index element={<Actueel/>}/>
                    <Route path='actueel' element={<Actueel/>}/>
                    <Route path='historie' element={<History/>}/>
                </Route>
                <Route element={<PrivateRoute showFor="requestor"><Feedback/></PrivateRoute>} path="/terugkoppeling" />
                <Route element={<PrivateRoute showFor="administrator"><Admin/></PrivateRoute>} path="/beheerder">
                    <Route index element={<Users/>}/>
                    {/* <Route path='users' element={<Administration/>}/> */}
                    <Route path='besturen' element={<AdminBesturen />}/>
                    <Route path='besturen/:id' element={<Besturens />}/>
                    <Route path='gebruikers' element={<Users/>}/>
                    <Route path='logging' element={<Logging/>}/>
                    <Route path='instellingen' element={<GeneralSettings/>}/>
                    <Route path='links' element={<ManageLinks/>}/>
                    <Route path='api' element={<Apis/>}/>
                    <Route path='admin-sms' element={<Sms/>}/>
                    <Route path='functies' element={<Functies />}/>
                    <Route path='push-messages' element={<PushMessagesDefault/>}/>
                </Route>
                <Route element={<PrivateRoute showFor="substitutor"><Home/></PrivateRoute>} path="/home">
                    <Route index element={<HomeOverview />} />
                    <Route path=":id"element={<HomeShow />} />
                </Route>
                <Route element={<PrivateRoute showFor="substitutor"><Vervanger/></PrivateRoute>} path="/vervanger" />
                <Route element={<PrivateRoute showFor="substitutor"><Substitutor/></PrivateRoute>} path="/beschikbaarheid">
                    <Route index element={<Availability/>}/>
                </Route>
                <Route element={<PrivateRoute showFor="substitutor"><VervangerDocumenten/></PrivateRoute>} path="/v-documenten" />
                <Route element={<PrivateRoute showFor="substitutor"><VervangerOverview/></PrivateRoute>} path="/urenoverzicht" />
                <Route element={<PrivateRoute showFor="substitutor"><Settings/></PrivateRoute>} path="/instellingen" />

                <Route  element={<PrivateRoute><Profile/></PrivateRoute>} path="/profiel" />

                <Route  element={<PrivateRoute showFor="requestor"><SearchTable/></PrivateRoute>} path="/searchsubrequests/:substitute" />
                <Route path="/" element={<Login/>} />
               <Route path="/login" element={<Login/>} />
               <Route path="/logout" element={<LogOut/>} />
                <Route element={<PrivateRoute showFor="requestor"><Export/></PrivateRoute>}  path="/export" />
                <Route  element={<PrivateRoute><Support/></PrivateRoute>} path="/support" />
                <Route element={<PrivateRoute showFor="requestor"><Document/></PrivateRoute>}  path="/documenten" />

                <Route element={<PrivateRoute showFor="coordinator"><VervangerVerzoeken/></PrivateRoute>} path="/verzoeken">
                    <Route element={<CoordinatorOverview />} path='overzicht' />
                    <Route element={<CoordinatorDetailsPage />} path='overzicht/:id' />
                    <Route element={<CoordinatorCalendar />} path='overzicht/:id/dagdelen-toevoegen'  />
                    <Route element={<CoordinatorPlanboard />} path="planbord" />
                    <Route element={<IndienenSubstitution/>} path="indienen"/>
                </Route>
                <Route element={<PrivateRoute showFor="coordinator"><TravelCost/></PrivateRoute>} path="/reiskosten" >
                    <Route element={<TravelCostOverview />} path='overzicht' />
                    <Route element={<Distances/>} path="afstanden" />
                    <Route element={<Calculation/>} path="samenstellen" />
                </Route>
                <Route element={<PrivateRoute showFor="coordinator"><Weekstaten/></PrivateRoute>} path="/weekstaten" >
                    <Route index element={<WeekstatenOverview />} path='overzicht' />
                    <Route element={<WeeklyStatements />} path=':id' />
                    <Route element={<Compile/>} path="samenstellen" />
                </Route>
                <Route element={<PrivateRoute showFor="coordinator"><Instellingen /></PrivateRoute>} path="/c-instellingen">
                    <Route index element={<Besturen />} />
                    <Route path="functies" element={<Features />} />
                    <Route path="dagtypen" element={<DayTypes />} />
                    <Route path="vervangingscodes" element={<ReplacementCodes />} />
                    <Route path="Kp-Kd" element={<CostCenterUnit />} />
                    <Route path="scholen" element={<SchoolsOverview />} />
                    <Route path="scholen/:communityId/:schoolId" element={<SchoolsView />} />
                    <Route path="contracttypen" element={<Contracttypen />} />
                    <Route path="contracttypen/wijzigen-toevoegen/:id" element={<WijzigenToevoegen />} />
                    <Route path="contracttypen/filterinstellingen/:id" element={<FllterInstellingen />} />
                    <Route path="besturen" element={<Besturen />} />
                    <Route path="planning" element={<Planning />} />
                </Route>
                <Route element={<PrivateRoute showFor="coordinator"><Beheer /></PrivateRoute>} path="/overig">
                    {/* <Route index element={<Features />} /> */}
                    {/* <Route path="sms-versturen" element={<Features />} /> */}
                    <Route path="sms-versturen" element={<SendSMS />} />
                    <Route path="rapportage" element={<Reporting/>} />
                    <Route path="exporteren" element={<Exporteren/>} />
                </Route>
                <Route element={<PrivateRoute showFor={"coordinator"}><Gebruikers/></PrivateRoute>} path="/gebruikers"></Route>
                <Route element={<PrivateRoute showFor={"coordinator"}><GebruikerView/></PrivateRoute>} path="/gebruikers/:id"></Route>
                <Route element={<PrivateRoute showFor={"coordinator"}><Links/></PrivateRoute>} path="/links/coordinator"></Route>
                <Route element={<PrivateRoute showFor={"requestor"}><Links/></PrivateRoute>} path="/links/requestor"></Route>
                <Route element={<PrivateRoute showFor={"substitutor"}><Links/></PrivateRoute>} path="/links/substitutor"></Route>
                <Route element={<Forbidden/>}  path="/no-access" />

                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/*" element={<ErrorPage />} />
                <Route path="/do-not-have-permmison" element={<PermissionDeny />} />
            </Routes>
        </Fragment>
    )
};

export default AppMain;
