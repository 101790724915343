import axiosInstance from 'Api';

const contractType = (id, iAdminId) => {
    return axiosInstance.get(`contracttypes?iCommunityId=${id}&iAdminId=${iAdminId}`);
};

const updateInstellingenDetail = (data) => {
    return axiosInstance.post('gebruiker/save-instellingen', data)
};

const contractUpdate = (data) => {
    return axiosInstance.post('gebruiker/save-contract', data)
};

const getInstellingenInfo= (data)=>{
    return axiosInstance.post('gebruiker/get-instellingen-info', data);
}

const saveInstellingenInfo = (data)=>{
    return axiosInstance.post('gebruiker/save-instellingen-info', data);
}


const GebruikerInstellingenService = {
    contractType,
    updateInstellingenDetail,
    contractUpdate,
    getInstellingenInfo,
    saveInstellingenInfo
};
export default GebruikerInstellingenService;