import React, { useState } from "react";
import { Form, Formik } from "formik";
import { Card, CardBody, FormGroup, Input, Label, Spinner } from 'reactstrap'
import 'tippy.js/themes/light.css';
import GroupStatus from "../../../../../Components/IconDropdown/Cross_Tick/GroupStatus";

const StandaardInstellingenReiskosten = ({ data, changeHandler, handleIconFieldChange, errorField }) => {
    const [loadingKeys, setLoadingKeys] = useState({})
    const defaultValue = {
        vPrice: data?.vPrice || '',
        vKm: data?.vKm || '',
        vBedrag: data?.vBedrag || '',
        vTravelAdminMailAddress: data?.vTravelAdminMailAddress || '',
        iTravelEmail: data?.iTravelEmail || '',
        iTravelPdf: data?.iTravelPdf || ''
    }

    const handleToggle = (field, data) => {
        setLoadingKeys(prev => ({ ...prev, [field]: true }));
        handleIconFieldChange(field, data, setLoadingKeys)
    };

    return (
        <>
            <Card className="main-card">
                <CardBody className="d-flex flex-column">
                    <div className="btn-color-style travelCost-blue-text mb-4">Standaard instellingen reiskosten</div>

                    <Formik
                        enableReinitialize
                        initialValues={defaultValue}
                        validateOnChange={true}
                    >
                        {({ values, touched, errors, handleChange, setFieldError }) => (
                            <Form>
                                <FormGroup className={`form-element ${(errors.vPrice) ? 'error' : ''}`}>
                                    <Label for="vPrice">Vergoeding per km afstand</Label>
                                    <Input
                                        name="vPrice"
                                        type="number"
                                        value={values.vPrice}
                                        onChange={(e) => {
                                            handleChange(e);
                                            changeHandler("vPrice", e.target.value, setFieldError, "Vergoeding per km afstand");
                                        }}
                                    />

                                </FormGroup>

                                <FormGroup className={`form-element ${(errors.vKm) ? "error" : ""}`}>
                                    <Label for="vKm">Aftrek aantal km</Label>
                                    <Input
                                        name="vKm"
                                        type="number"
                                        value={values.vKm}
                                        onChange={(e) => {
                                            handleChange(e);
                                            changeHandler("vKm", parseInt(e.target.value), null, "Aftrek aantal km")
                                        }}
                                    />
                                </FormGroup>

                                <FormGroup className={`form-element ${(errors.vBedrag) ? "error" : ""}`}>
                                    <Label for="vBedrag">Maximumbedrag per dag</Label>
                                    <Input
                                        name="vBedrag"
                                        type="number"
                                        value={values.vBedrag}
                                        onChange={(e) => {
                                            handleChange(e);
                                            changeHandler("vBedrag", e.target.value, null, "Aftrek aantal km")
                                        }}
                                    />
                                </FormGroup>

                                <FormGroup className={`form-element ${(errors.vTravelAdminMailAddress) ? "error" : ""}`}>
                                    <Label for="vTravelAdminMailAddress">Mail naar reiskostenadministratie
                                    </Label>
                                    <div className="w-100">
                                        <Input
                                            name="vTravelAdminMailAddress"
                                            type="email"
                                            value={values.vTravelAdminMailAddress}
                                            placeholder="E-mail"
                                            onChange={(e) => {
                                                handleChange(e);
                                                changeHandler("vTravelAdminMailAddress", e.target.value, setFieldError, "Email")
                                            }}
                                        />
                                    </div>
                                </FormGroup>

                                <div className="informeren-vervanger-separate pt-2">
                                    <p><strong>Informeren vervanger</strong></p>
                                    <FormGroup className={`form-element ${errorField === 'iTravelEmail' ? 'error' : ''}`}>
                                        <Label for="iTravelEmail">E-mail</Label>
                                        <div className="position-relative">
                                            <div className="d-flex gap-1 align-items-center ">
                                                {loadingKeys["iTravelEmail"] ? (
                                                    <div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iTravelEmail"} data={values?.iTravelEmail ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>

                                    <FormGroup className={`form-element ${errorField === 'iTravelPdf' ? 'error' : ''}`}>
                                        <Label for="iTravelPdf">PDF in Clooser.nl</Label>
                                        <div className="position-relative">
                                            <div className="d-flex gap-1 align-items-center ">
                                                {loadingKeys["iTravelPdf"] ? (
                                                    <div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iTravelPdf"} data={values?.iTravelPdf ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </div>

                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card>
        </>
    )
}

export default StandaardInstellingenReiskosten