import ReactTableGeneric from 'Components/ReactTableGeneric'
import { Formik, ErrorMessage } from 'formik'
import React, { useState } from 'react'
import { Button, Card, CardBody, Form, FormGroup, Input, Label, Modal, ModalFooter, ModalHeader } from 'reactstrap'
import * as Yup from 'yup'
import DatePicker from "react-datepicker";
import GebruikerInstellingenService from 'Api/Gebruikers/GebruikersView/Instellingen'
import { toast } from 'react-toastify'
import SweetAlert from "react-bootstrap-sweetalert"
import { ReactComponent as PenIcon } from "../../../../../assets/utils/images/svgs/editPen.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/utils/images/svgs/Delete.svg";
import { ReactComponent as CrossIcon } from "../../../../../assets/utils/images/svgs/Cross.svg";
import { BsCheckCircle } from "react-icons/bs";
import moment from 'moment'

const validationSchema = Yup.object().shape({
    description: Yup.string().required('Een omschrijving is verplicht. (min. 5 karakters)'),
    beginDatum: Yup.date().required('Begindatum is niet ingevuld.'),
    eindDatum: Yup.date()
        .min(Yup.ref('beginDatum'), 'Einddatum moet later zijn dan de begindatum')
        .required('Einddatum is niet ingevuld.'),
    uitlener: Yup.string().required('Maak een keuze of dagen moeten worden uitgesloten ja/nee.')
});

const Contracts = ({ data = [], setModal, modal, filterCommunity, currentUser, updateDataHandler }) => {
    const [showAlert, setShowAlert] = useState(false);
    const [contractId, setContractId] = useState('');

    const columns = React.useMemo(
        () => [
            {
                header: () => 'Omschriving',
                accessorKey: 'vDescription',
            },
            {
                header: () => 'Begindatum',
                accessorKey: 'iStartDate'
            },
            {
                header: () => 'Einddatum',
                accessorKey: 'iEndDate'
            },
            {
                header: () => 'Dagdelen uitsluiten',
                accessorKey: 'eRuleOut',
                cell: (cell) => (
                    <>
                        {cell.row.original.eRuleOut === "Y" ?
                            <BsCheckCircle color='#3AC47D' fontSize="18px" className='ms-5'/> :
                            <CrossIcon fontSize="18px" />}
                    </>
                )
            },
            {
                header: () => '',
                accessorKey: 'action',
                cell: (cell) => (
                    <div className="d-flex gap-3 justify-content-center">
                        <div className="cursor-pointer" onClick={() => { setContractId(cell.row.original.contractId); setModal({ type: "edit", state: true }) }}><PenIcon /></div>
                        <div className="cursor-pointer" onClick={() => { setContractId(cell.row.original.contractId); setModal({ type: "remove", state: false }); setShowAlert(true) }}><DeleteIcon /></div>
                    </div>
                )
            }
        ],
        [data]
    );

    const formDataState = React.useMemo(() => {
        if (modal.type === "edit" && contractId) {
            const currentContract = data.find(item => item.contractId === contractId) || {};
            return {
                description: currentContract.vDescription || '',
                beginDatum: currentContract.iStartDate ? moment(currentContract.iStartDate, "DD-MM-YYYY").toDate() : null,
                eindDatum: currentContract.iEndDate ? moment(currentContract.iEndDate, "DD-MM-YYYY").toDate() : null,
                uitlener: currentContract.eRuleOut === "Y" ? "1" : "0"
            };
        } else
            return {
                description: '', beginDatum: null, eindDatum: null, uitlener: ''
            };

    }, [contractId, data, modal]);

    const submitHandler = async (values, resetForm = () => { }) => {
        const postBody = {
            contractId: modal.type === "add" ? "" : contractId,
            iAdminId: currentUser.data.clooserId,
            vDesc: values?.description || '',
            iStart: moment(values?.beginDatum).format('YYYY-MM-DD') || '',
            iEnd:  moment(values?.eindDatum).format('YYYY-MM-DD') || '',
            type: modal.type,
            uitlener: values?.uitlener || '',
            iCommunityId: filterCommunity[0].iCommunityId || "",
        }
        try {
            const response = await GebruikerInstellingenService.contractUpdate(postBody);
            if (response.data.status) {
                const message = modal.type === "add" ? "Contracten succesvol toegevoegd." : modal.type === "edit" ? "Contracten succesvol bijgewerkt." : "Contracten succesvol verwijderd."
                toast.success(message);
                setModal({ type: "add", state: false });
                resetForm();
                updateDataHandler();
            } else {
                toast.error("Er is iets misgegaan.");
            }
        } catch (error) {
            console.error(`API error:`, error);
            setModal({ type: "add", state: false });
        }
    }

    return (
        <>
            <Card className="main-card">
                <CardBody className="d-flex flex-column">
                    <h5 className="mb-4">Contracten</h5>
                    <ReactTableGeneric
                        data={data || []}
                        columns={columns}
                        tableClassName="mb-0 table table-borderless table-sm table-hover"
                        filters={{ search: false, pagination: false, sorting: false }}
                    />
                </CardBody>
            </Card>
            <Modal isOpen={modal.state} toggle={() => setModal(prev => ({ ...prev, state: !prev.state }))} className="feedback-modal-style cnt-form-modal link-form modal-centered">
                <ModalHeader toggle={() => setModal(prev => ({ ...prev, state: !prev.state }))}>
                    Toevoegen Contracten
                </ModalHeader>
                <Formik
                    enableReinitialize
                    initialValues={formDataState}
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm }) => submitHandler(values, resetForm)}
                >
                    {({ values, handleChange, handleBlur, setFieldValue, handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                            <FormGroup>
                                <Label for="description" className="d-flex mt-3 justify-content-between">
                                    Omschrijving<span className="cursor-pointer"></span>
                                </Label>
                                <Input
                                    type="textarea"
                                    name="description"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.description}
                                    rows={5}
                                />
                                <ErrorMessage name="description" component="small" className="block mt-2 invalid-feedback" />
                            </FormGroup>

                            <FormGroup>
                                <Label for="beginDatum">Begindatum</Label>
                                <DatePicker
                                    selected={values.beginDatum}
                                    onChange={(date) => setFieldValue("beginDatum", date)}
                                    onBlur={handleBlur}
                                    placeholderText={'dd-mm-jjjj'}
                                    dateFormat="dd-MM-yyyy"
                                    className="form-control"
                                />
                                <ErrorMessage name="beginDatum" component="small" className="block mt-2 invalid-feedback" />
                            </FormGroup>

                            <FormGroup>
                                <Label for="eindDatum">Einddatum</Label>
                                <DatePicker
                                    selected={values.eindDatum}
                                    onChange={(date) => setFieldValue("eindDatum", date)}
                                    onBlur={handleBlur}
                                    placeholderText={'dd-mm-jjjj'}
                                    dateFormat="dd-MM-yyyy"
                                    className="form-control"
                                />
                                <ErrorMessage name="eindDatum" component="small" className="block mt-2 invalid-feedback" />
                            </FormGroup>

                            <FormGroup>
                                <Label for="uitlener">Dagdelen uitsluiten?</Label>
                                <select
                                    className="form-select"
                                    name="uitlener"
                                    value={values.uitlener}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                >
                                    <option value="">Kies een optie</option>
                                    <option value="1">Ja</option>
                                    <option value="0">Nee</option>
                                </select>
                                <ErrorMessage name="uitlener" component="small" className="block mt-2 invalid-feedback" />
                            </FormGroup>
                            <ModalFooter className="d-flex align-items-center justify-content-center border-bottom-0 py-4 mt-4">
                                <Button className="btn-color-style opslaan-btn" size="sm" outline color="primary" type="submit">
                                    Opslaan
                                </Button>
                                <Button className="btn-color-style opslaan-btn" size="sm" outline color="primary" type="button" onClick={() => setModal({ type: "add", state: false })}>
                                    Annuleren
                                </Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </Modal>
            <SweetAlert title="Weet je zeker dat je dit contract wilt verwijderen?" show={showAlert}
                type="warning"
                onConfirm={() => { setShowAlert(false); submitHandler({}) }}
                onCancel={() => setShowAlert(false)}
                cancelBtnText="Nee"
                confirmBtnText="Ja"
                showCancel={true}
            />
        </>
    )
}

export default Contracts