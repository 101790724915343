import React, { Fragment, useEffect, useState } from "react";
import { Alert, Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import AuthService from "Api/Auth";
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { setUserProfilePicture, setAdminId } from "reducers/UserProfile";
import { setUserRole } from 'reducers/UserPermission';
import DefaultButton from "Components/Buttons/DefaultButton";
import { getCurrentRole } from "Components/Router/Permission";
import OtpInput from 'react-otp-input';
import { toast } from "react-toastify";
import TwoFAModal from "Components/Modal/TwoFAModal";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const hasThirtyDaysPassed = (expiredDate) => { 
    const expired = new Date(expiredDate);
    const now = new Date();

    return now.getTime() > expired.getTime(); 
}

// Layout
const SignupSchema = Yup.object().shape({
    username: Yup.string()
        .required('Gebruikersnaam is verplicht'),
    password: Yup.string()
        .required('Wachtwoord is verplicht')
});

const getCookie = (cookieName) => {
    const cookies = document.cookie.split("; ");
    for (let cookie of cookies) {
        const [name, value] = cookie.split("=");
        if (name === cookieName) {
            return value;
        }
    }
    return null;
};

function getBrowser() {
    const userAgent = navigator.userAgent;

    if (navigator?.brave?.isBrave?.name === 'isBrave') {
        return "Brave"
    }

    if (userAgent.includes("Chrome") && userAgent.includes("Safari") && !userAgent.includes("Edg") && !userAgent.includes("OPR")) {
        return "Google Chrome";
    } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
        return "Safari";
    } else if (userAgent.includes("Firefox")) {
        return "Mozilla Firefox";
    } else if (userAgent.includes("Edg")) {
        return "Microsoft Edge";
    } else if (userAgent.includes("OPR") || userAgent.includes("Opera")) {
        return "Opera";
    } else if (userAgent.includes("MSIE") || userAgent.includes("Trident")) {
        return "Internet Explorer";
    } else {
        return "Unknown Browser";
    }
}

const LoginBoxed = ({ match }) => {
    const [showOTPModal, setShowOTPModal] = useState(false);
    const [isUseDetailSet, setISUserDetailsSet] = useState(false);
    const [otp, setOtp] = useState('');
    const [userResponse, setUserResponse] = useState({});
    const dispatch = useDispatch();

    let navigate = useNavigate();
    const [error, setError] = useState(null)

    useEffect(() => {
        if (AuthService.checkUserLoggedIn()) {
            const roles = JSON.parse(localStorage.getItem('USER_ROLES'));
            navigate(getCurrentRole(roles).route);
        }
    }, [isUseDetailSet]);

    const handleSubmit = async (data) => {
        try {
            const rememberToken  = getCookie("rememberToken");
            
            const result = await AuthService.login(data.username, data.password, rememberToken || undefined).catch(e => console.log(e));
            
            if (result && result.status === 200 && result.data) {
                let response = result.data;
                setUserResponse(response);
                if (response.error) {
                    setError(response.error)
                } else if (response.roles.length === 0 || response.roles[0] === "") {
                    setError("U heeft niet de juiste rol om in te loggen op dit systeem")
                } else {
                    const isUserRemembered = response.vRememberToken !== null && (response.vRememberToken === rememberToken);
                    
                    if(response.enabled && !isUserRemembered){
                        setShowOTPModal(true);
                    } else {
                        setUserDetails(response);
                    }               
                }
            } else {
                setError(result.data.error);
            }
        } catch (error) {
            let response =  error?.response?.data;

            if (response?.error != undefined && response?.error != '') {
                setError(response?.error);
            }
        }
    };

    function setUserDetails(response) {
        AuthService.makeAuth(response);
        AuthService.setUserRoles(response.roles);
        dispatch(setUserRole(response.roles));
        dispatch(setUserProfilePicture(response.user.profileImage));
        dispatch(setAdminId(response.user.iAdminId));
        navigate(getCurrentRole(response.roles).route);
    }

    const handleOTPSubmit = async (values) => {
        const data = {
            code: otp,
            remember: values.rememberOTP ? 1 : 0,
        };
    
        const url = `${baseUrl}/api/2fa`;
    
        try {
            const response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userResponse.token.access_token}`,
                },
                method: 'POST',
                body: JSON.stringify(data),
            });
    
            const responseData = await response.json();
            
            if (!responseData) {
                toast.error("Fout bij invoeren van de OTP. Serverfout!");
                return;
            }
    
            if (responseData?.status) {
                if(data.remember) {
                    setFrontendCookie("rememberToken", responseData.rememberToken);
                }
    
                setShowOTPModal(false);
                setOtp('');
                setUserDetails(userResponse);
                setISUserDetailsSet(prev => !prev);
            } else {
                toast.error("Ongeldige OTP-code");
                localStorage.clear();
                setOtp('');
            }
        } catch (error) {
            console.error('Fout tijdens OTP-inzending:', error);
            toast.error("Fout bij invoeren van de OTP. Serverfout!");
        }
    };
    
    // Function to set cooki
    const setFrontendCookie = (cookieName, cookieValue) => {
        let expires = new Date();
        expires.setFullYear(expires.getFullYear() + 100);
        document.cookie = `${cookieName}=${cookieValue}; expires=${expires.toUTCString()}; path=/; secure; samesite=strict`;
    };

    return (
        <Fragment>
            <div className="h-100 bg-heavy-rain custom-footerbar bg-animation">
                <div className="d-flex h-100 justify-content-center align-items-center">
                    <Col md="8" className="mx-auto app-login-box">
                        <div className="app-logo-inverse mx-auto mb-3" />
                        <div className="modal-dialog w-100 mx-auto">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="h5 modal-title text-center">
                                        <h4 className="mt-2">
                                            <div>Inloggen op clooser.nl</div>
                                        </h4>
                                    </div>
                                    {error ?
                                        <Alert color="danger">
                                            {error}
                                        </Alert>
                                        : null}
                                    <Formik
                                        initialValues={{
                                            username: '',
                                            password: '',
                                            rememberToken: ''
                                        }}
                                        validationSchema={SignupSchema}
                                        onSubmit={async (data) => {
                                            handleSubmit(data);
                                        }}>
                                        {props => (
                                            <Form>
                                                <Row>
                                                    <Col md={12}>
                                                        <FormGroup>
                                                            <input id="username" name="username"
                                                                className="form-control" placeholder="Gebruikersnaam"
                                                                type="username" onChange={props.handleChange}
                                                                value={props.values.username} />
                                                            {props.errors.username && props.touched.username ? (<div
                                                                style={{ color: 'red' }}>{props.errors.username}</div>) : null}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <FormGroup>
                                                            <input id="password" name="password"
                                                                className="form-control" placeholder="Wachtwoord"
                                                                type="password" onChange={props.handleChange}
                                                                value={props.values.password} />
                                                            {props.errors.password && props.touched.password ? (<div
                                                                style={{ color: 'red' }}>{props.errors.password}</div>) : null}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <div className="modal-footer clearfix">
                                                    <div className="float-start">
                                                        <a href="#" onClick={(e) => e.preventDefault()}
                                                            className="btn-lg btn btn-link btn-color-style">
                                                            Wachtwoord vergeten?
                                                        </a>
                                                    </div>
                                                    <div className="float-end">
                                                        <DefaultButton color="primary" size="lg" type="submit" className="btn-color-style">
                                                            Inloggen
                                                        </DefaultButton>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>

                                </div>
                            </div>
                        </div>
                    </Col>
                </div>
            </div>

            <TwoFAModal showOTPModal={showOTPModal} handleOTPSubmit={handleOTPSubmit} otp={otp} setOtp={setOtp} />
        </Fragment>
    )
}

export default LoginBoxed;
