import Availability from 'Components/Availability';

const Beschikbaarheid = ({ currentUser, setActiveTab }) => {
    return (
        <Availability>
            <Availability.Container>
                <Availability.Table iAdminId={currentUser.data.clooserId} isCoord={true}/>
            </Availability.Container>
            <Availability.Footer setActiveTab={setActiveTab}/>
            <Availability.Modals iAdminId={currentUser.data.clooserId}/>
        </Availability>
    )
}

export default Beschikbaarheid

