import PageHeading from "Layout/AppMain/PageHeading";
import React, { Fragment, useState, useRef, useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Card, CardBody, Col, Row, Button } from "reactstrap";
import ReactTableGeneric from "Components/ReactTableGeneric";
import DocModel from "Components/Modal/DocumentModel/index";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Calendar } from "../../../../assets/utils/images/svgs/Calendar.svg";
import { ReactComponent as Delete } from "../../../../assets/utils/images/svgs/Delete.svg";
import { ReactComponent as EyeIcon } from "../../../../assets/utils/images/svgs/Eye-icon.svg";
import { setUserProfilePicture } from "reducers/UserProfile";
import FileUploadService from "../../../../services/FileUploadService";
import axiosInstance from "Api";
import SweetAlert from 'react-bootstrap-sweetalert';
import DocumentService from "Api/Document";
import moment from "moment";
import { TiDeleteOutline } from "react-icons/ti";
import { ReactComponent as CrossIcon } from "../../../../assets/utils/images/svgs/Cross.svg";
import DatePicker, { registerLocale } from "react-datepicker";
import nl from "date-fns/locale/nl";
import { RiContactsBookLine } from "react-icons/ri";
import TextLimit from "Api/TextLimit";
import TitleModal from "Components/Modal/InfoModal/TitleModal";
registerLocale("nl", nl);

const Default = () => {
    const [openModel, setOpenModel] = useState(false);
    const dispatch = useDispatch();
    const userProfileImage = useSelector((state) => state.UserProfile.profileImage);
    const [file, setFile] = useState("");
    const [image, setImage] = useState(userProfileImage);
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState('');
    const [message, setMessage] = useState({ type: null, text: "" });
    const inputFile = useRef(null);
    const fileSize = 2;
    const [showModal, setShowModal] = useState(false);
    const [showConfirmUpdateModal, setShowConfirmUpdateModal] = useState(false);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    const [confirmation, setConfirmation] = useState(false);
  
    const saveFile = (file) => {
        setFile(file);
    }

    const toggleProfileCropPopup = val => {
        setOpenModel(val);
        if (val === false) {
            setFile("");
        }
    }

    const handleCroppedImage = (val) => {
        if (val !== '') {
            setImage(val);
            // uploadProfileImage(dataURLtoFile(val, file.name));
        }
    }

    function isDateValid(dateStr) {
        return !isNaN(new Date(dateStr));
    }

    const checkDateFormat = (value) => {
        if (isDateValid(value)) {
            var year = value.slice(0, 4);
            if (year.includes("-")) {
                return value;
            }
            return moment(value).format("DD-MM-yyyy");
        }
        return value;
    }

    useEffect(() => {
        getDocumentList();
    }, [confirmation]);

    const getDocumentList = () => {
        axiosInstance.get('vervanger/documentList').then((response) => {
            if (response.status == 200) {
                if (response.data.status === true) {
                    setData(response.data.data);
                }
            }
        }).catch((error) => console.log("catch error:", error));
    }

    const updateData = () => {
        axiosInstance.get('vervanger/documentList').then((response) => {
            if (response.status == 200) {
                if (response.data.status === true) {
                    setData(response.data.data);
                }
            }
        }).catch((error) => console.log("catch error:", error));
    }

    const confirmDelete = (id) => {
        setShowModal(true);
        setDeleteId(id);
    }

    const deleteDocument = (val) => {
       
            axiosInstance
                .get('vervanger/deleteDocument/' + val)
                .then((response) => {
                    if (response.status === 200) {
                        axiosInstance.get('vervanger/documentList').then((response) => {
                            if (response.status == 200) {
                                if (response.data.status === true) {
                                    setData(response.data.data);
                                
                                }
                            }
                        }).catch((error) => console.log("catch error:", error));
                        setShowModal(false);

                        setTimeout(() => {
                        
                        }, 950);
                    }
                })
                .catch((error) => console.log("catch error:", error));
        
    }

    function downloadFile(id) {
        DocumentService.downloadDocument(id).then((response) => {
          if( response.data.file.download !== ''){
            const linkSource = response.data.file.download;
            const downloadLink = document.createElement("a");
            const fileName = "document."+response.data.file.extension;
  
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
          }
        }).catch(() => console.error);
      }

    const messageSwitch = (type, addedDate, validUntil) => {
        switch(type) {
            case 'VOG':
                return 'Dit document wordt 3 maanden na de datum ' + validUntil + ' automatisch verwijderd';
            case 'ID-kaart / Paspoort':
                return 'Dit document wordt 3 maanden na de datum ' + validUntil + ' automatisch verwijderd';
            case 'Salarisstrook t.b.v. inschaling':
                return 'Dit document wordt 3 maanden na datum ' + addedDate + ' automatisch verwijderd';
            case 'Inlichtingenstaat':
                return 'Dit document wordt 3 maanden na datum ' + addedDate + ' automatisch verwijderd';
            case 'Loonbelastingverklaring':
                return 'Dit document wordt 3 maanden na datum ' + addedDate + ' automatisch verwijderd';
            case 'Los document':
                return 'Dit document wordt niet automatisch verwijderd';
            case 'Diploma - PABO':
                return 'Dit document wordt niet automatisch verwijderd';
            case 'Diploma - Onderwijsassistent':
                return 'Dit document wordt niet automatisch verwijderd';
            case 'Diploma - Leraarondersteuner':
                return 'Dit document wordt niet automatisch verwijderd';
            case 'Diploma - Klassenassistent':
                return 'Dit document wordt niet automatisch verwijderd';
            case 'Diploma - Tweede graads lesbevoegdheid':
                return 'Dit document wordt niet automatisch verwijderd';
            case 'Diploma - Logopedist':
                return 'Dit document wordt niet automatisch verwijderd';
            case 'Diploma - Fysiotherapeut':
                return 'Dit document wordt niet automatisch verwijderd';
            case 'Diploma - Orthopedagoog':
                return 'Dit document wordt niet automatisch verwijderd';
            case 'Diploma - Psycholoog':
                return 'Dit document wordt niet automatisch verwijderd';
            case 'Diploma - Psychologisch medewerker':
                return 'Dit document wordt niet automatisch verwijderd';
            case 'Diploma - Eerste graads lesbevoegdheid':
                return 'Dit document wordt niet automatisch verwijderd';
            default:
                return '--';
        }
    }

    const confirmUpdate = (id, date) => {
        if (date !== '') {
            setShowConfirmUpdateModal(true);
            let dataAppend = new FormData();
            dataAppend.append('doc_id', id);
            dataAppend.append('value', moment(date).format('YYYY-MM-DD'));
            setFormData(dataAppend);
        } else{
            setShowConfirmDeleteModal(true);
            let dataAppend = new FormData();
            dataAppend.append('doc_id', id);
            dataAppend.append('value', '');
            setFormData(dataAppend);
        }
    }

    const updateHerinneringDate = () => {
        axiosInstance.post('update-herinneringsdatum', formData).then((response) => {
            if (response.status == 200) {
                if (response.data.status === true) {
                    getDocumentList();
                    setShowConfirmUpdateModal(false);
                    setShowConfirmDeleteModal(false);
                }
            }
        }).catch((error) => console.log("catch error:", error));
    }

    const columns = [
        {
            header: 'Document',
            accessorKey: 'vLabel',
        },
        {
            header: 'Geldig tot',
            accessorKey: 'verloopdatum',
            cell: value => (
                value.row.original.verloopdatum !== '' &&
                checkDateFormat(value.row.original.verloopdatum)
            )
        },
        {
            header: 'Toegevoegd',
            accessorKey: 'toegevoegd'
        },
        {
            header: 'Herinnering',
            accessorKey: 'herinneringdatum',
            cell: value => (
                value.row.original.herinneringdatum !== '' &&
                <div className="d-flex justify-content-between align-items-center">
                    <span>{checkDateFormat(value.row.original.herinneringdatum)}</span>
                   <span><CrossIcon color={'#FB2E5F'} font-size={"28px"} 
                    onClick={(date) => confirmUpdate(value.row.original.id, '')}/></span> 
                </div>
            )
        },
        {
            header: '',
            accessorKey: 'id',
            cell: value => (
                <label>
                <Calendar />
                <DatePicker
                    locale="nl"
                    clearButtonTitle="Clear"
                    className="hide-datepicker"
                    onChange={(date) => confirmUpdate(value.row.original.id, date)}
                />   
                </label>
            )
        },
        {
            header: 'Bestuur',
            accessorKey: 'vCommunityName',
            cell: value => (
                window.innerWidth > 1250 ?
                TextLimit.limitStringLargeScreen(value.row.original.vCommunityName)
                : TextLimit.limitStringSmallScreen(value.row.original.vCommunityName)
            )
        },
        {
            header: 'Opmerking',
            accessorKey: 'omschrijving'
        },
        {
            header: 'Bewaarbeleid',
            accessorKey: 'vLabel',
            cell: value => (
                <TitleModal data={messageSwitch(value.row.original.vLabel, value.row.original.toegevoegd, value.row.original.verloopdatum) || ''} title="Bewaartermijn"/>
            )
        },
        
        {
            header: '',
            accessorKey: 'vDocHref',
            // cell: value => ( <a href={value.row.original.vDocHref}  className="anchor-action-link">
            //                     <EyeIcon />
            //                 </a> )
            cell: value => ( <a href="javascript:void(0)" onClick={()=>downloadFile(value.row.original.id)} className="anchor-action-link">
                                    <EyeIcon />
                                </a> )
        },
        {
            header: '',
            accessorKey: 'id',
            cell: value => (
                <span onClick={()=>confirmDelete(value.row.original.id)} style={{cursor:'pointer'}}><Delete /></span>
            )
        }
        
    ];

    return (
        <Fragment>
            <PageHeading />
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    <div>
                        <Row>
                            <Col md="12" lg="12">
                                <Card className="main-card mb-3">
                                    <CardBody className="p-3">
                                        <div className="btn-align-right">
                                            <a href="https://fd8.formdesk.com/cloosernl/vog" target="_blank" rel="noreferrer">
                                                <Button className="custom-btn-width btn-color-style mb-2 me-2" size="sm" outline color="primary">
                                                    Aanvragen VOG
                                                </Button>
                                            </a>
                                            <Button className="custom-btn-width btn-color-style mb-2 me-2" size="sm" outline color="primary" onClick={toggleProfileCropPopup}>
                                                Document toevoegen
                                            </Button>
                                        </div>
                                        <ReactTableGeneric
                                            data={data}
                                            columns={columns}
                                            tableClassName="mb-0 table table-borderless table-bordered table-sm table-hover documnent-table-style"
                                            filters={{ search: false, pagination: true, sorting: true }}
                                        />
                                    </CardBody>
                                    <SweetAlert title="Wilt u het geselecteerde document verwijderen?" show={showModal}
                                        type="warning"
                                        onCancel={() => setShowModal(false)}
                                        onConfirm={() => deleteDocument(deleteId)}
                                        cancelBtnText="Nee"
                                        confirmBtnText="Ja"
                                        showCancel={true}
                                    />
                                    <SweetAlert title="weet u zeker dat u de herinneringsdatum wilt bijwerken?" show={showConfirmUpdateModal}
                                        type="warning"
                                        onCancel={() => setShowConfirmUpdateModal(false)}
                                        onConfirm={() => updateHerinneringDate()}
                                        cancelBtnText="Nee"
                                        confirmBtnText="Ja"
                                        showCancel={true}
                                    />
                                    <SweetAlert title="Weet je zeker dat je dit wilt verwijderen?" show={showConfirmDeleteModal}
                                        type="warning"
                                        onCancel={() => setShowConfirmDeleteModal(false)}
                                        onConfirm={() => updateHerinneringDate()}
                                        cancelBtnText="Nee"
                                        confirmBtnText="Ja"
                                        showCancel={true}
                                    />
                                    <DocModel isOpen={openModel} togglePopup={toggleProfileCropPopup} croppedFile={handleCroppedImage} saveFile={saveFile} doc={file} updateData={updateData} classes="document-modal-style" />
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </Fragment>
    )
};

export default Default;