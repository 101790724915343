import {HiOutlineDotsCircleHorizontal} from "react-icons/hi";
import {BsCheckCircle} from "react-icons/bs";
import {TiDeleteOutline} from "react-icons/ti";
import Tippy from "@tippyjs/react";
import {HiOutlineQuestionMarkCircle} from "react-icons/hi";
import { ReactComponent as HistorieBlack } from "../../../../assets/utils/images/svgs/HistorieBlack.svg";
import { ReactComponent as CrossIcon } from "../../../../assets/utils/images/svgs/Cross.svg";


export const iconSwitch = (param) => {
    switch(param) {
        case 'Openstaand':
            return <Tippy content={param} placement="bottom"><span className="weekstaat-icon-align"><HiOutlineDotsCircleHorizontal fontSize={"24px"} /></span></Tippy>;
        case 'Goedgekeurd':
            return <Tippy content={param} placement="bottom"><span><BsCheckCircle color='#39A825' fontSize={"20px"}/></span></Tippy>;
        case 'Afgekeurd':
            return <Tippy content={param} placement="bottom"><span className="weekstaat-icon-align" style={{margin: -4}}><CrossIcon color='#FB2E5F' fontSize={"26px"}/></span></Tippy>;
        default:
          return '--';
    }
}

export const resultIcon = (param) => {
    switch(param) {
        case 'Openstaand':
            return <Tippy content={param} placement="bottom"><span className="weekstaat-icon-align"><HiOutlineQuestionMarkCircle fontSize={"24px"} color="#ffa73f"/></span></Tippy>;
        case 'Goedgekeurd':
            return <Tippy content={param} placement="bottom"><span><BsCheckCircle color='#39A825' fontSize={"20px"}/></span></Tippy>;
        case 'Afgekeurd':
            return <Tippy content={param} placement="bottom"><span className="weekstaat-icon-align" style={{margin: -4}}><CrossIcon color='#FB2E5F' fontSize={"26px"}/></span></Tippy>;
        case 'Historie':
            return <Tippy content={param} placement="bottom"><span><HistorieBlack /></span></Tippy>;
        default:
          return '--';
    }
}

export const parse = (sign, str, keys) => {
    const values = str.split(sign);
    let result = {};

    keys.forEach((value, index) => result[value] = values[index]);

    return result;
}
