import React from 'react'
import { Card, CardBody, Table } from 'reactstrap'
import { ReactComponent as CrossIcon } from "../../../../../../assets/utils/images/svgs/Cross.svg";
import { BsCheckCircle } from "react-icons/bs";

const HaldaysTable = ({ data, changeHandler }) => {
    return (
        <>
            <Card className="main-card">
                <CardBody className="d-flex flex-column">
                    <h5 className="mb-4">Standaard dagdelen in Wizard verzoek indienen</h5>
                    <Table responsive hover className='mb-0 tb-gebruikers'>
                        <thead>
                            <tr>
                                <th></th>
                                <th>MA</th>
                                <th>DI</th>
                                <th>WO</th>
                                <th>DO</th>
                                <th>VR</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Ochtend</td>
                                <td>{data?.ochtend?.bMo_active === "1" ? <BsCheckCircle color='#3AC47D' fontSize="18px" className='cursor-pointer' onClick={() => { changeHandler('bMo_active', "0", true) }} /> : <CrossIcon fontSize="18px" className='cursor-pointer' onClick={() => { changeHandler('bMo_active', "1", true) }} />}</td>
                                <td>{data?.ochtend?.bDo_active === "1" ? <BsCheckCircle color='#3AC47D' fontSize="18px" className='cursor-pointer' onClick={() => { changeHandler('bDo_active', "0", true) }} /> : <CrossIcon fontSize="18px" className='cursor-pointer' onClick={() => { changeHandler('bDo_active', "1", true) }} />}</td>
                                <td>{data?.ochtend?.bWo_active === "1" ? <BsCheckCircle color='#3AC47D' fontSize="18px" className='cursor-pointer' onClick={() => { changeHandler('bWo_active', "0", true) }} /> : <CrossIcon fontSize="18px" className='cursor-pointer' onClick={() => { changeHandler('bWo_active', "1", true) }} />}</td>
                                <td>{data?.ochtend?.bTo_active === "1" ? <BsCheckCircle color='#3AC47D' fontSize="18px" className='cursor-pointer' onClick={() => { changeHandler('bTo_active', "0", true) }} /> : <CrossIcon fontSize="18px" className='cursor-pointer' onClick={() => { changeHandler('bTo_active', "1", true) }} />}</td>
                                <td>{data?.ochtend?.bVo_active === "1" ? <BsCheckCircle color='#3AC47D' fontSize="18px" className='cursor-pointer' onClick={() => { changeHandler('bVo_active', "0", true) }} /> : <CrossIcon fontSize="18px" className='cursor-pointer' onClick={() => { changeHandler('bVo_active', "1", true) }} />}</td>
                            </tr>
                            <tr>
                                <td>Middag</td>
                                <td>{data?.middag?.bMm_active === "1" ? <BsCheckCircle color='#3AC47D' fontSize="18px" className='cursor-pointer' onClick={() => { changeHandler('bMm_active', "0", true) }} /> : <CrossIcon fontSize="18px" className='cursor-pointer' onClick={() => { changeHandler('bMm_active', "1", true) }} />}</td>
                                <td>{data?.middag?.bDm_active === "1" ? <BsCheckCircle color='#3AC47D' fontSize="18px" className='cursor-pointer' onClick={() => { changeHandler('bDm_active', "0", true) }} /> : <CrossIcon fontSize="18px" className='cursor-pointer' onClick={() => { changeHandler('bDm_active', "1", true) }} />}</td>
                                <td>{data?.middag?.bWm_active === "1" ? <BsCheckCircle color='#3AC47D' fontSize="18px" className='cursor-pointer' onClick={() => { changeHandler('bWm_active', "0", true) }} /> : <CrossIcon fontSize="18px" className='cursor-pointer' onClick={() => { changeHandler('bWm_active', "1", true) }} />}</td>
                                <td>{data?.middag?.bTm_active === "1" ? <BsCheckCircle color='#3AC47D' fontSize="18px" className='cursor-pointer' onClick={() => { changeHandler('bTm_active', "0", true) }} /> : <CrossIcon fontSize="18px" className='cursor-pointer' onClick={() => { changeHandler('bTm_active', "1", true) }} />}</td>
                                <td>{data?.middag?.bVm_active === "1" ? <BsCheckCircle color='#3AC47D' fontSize="18px" className='cursor-pointer' onClick={() => { changeHandler('bVm_active', "0", true) }} /> : <CrossIcon fontSize="18px" className='cursor-pointer' onClick={() => { changeHandler('bVm_active', "1", true) }} />}</td>
                            </tr>
                        </tbody>
                    </Table>
                </CardBody>
            </Card >
        </>
    )
}
export default HaldaysTable