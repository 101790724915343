import axiosInstance from 'Api';
import ReactTableGeneric from 'Components/ReactTableGeneric'
import React, { useEffect, useMemo, useState } from 'react'
import { Card, CardBody, Col } from 'reactstrap'

function HistoryTable({ selectedOrganization, fetchHistoryData, historieData}) {

    useEffect(() => {
        if (selectedOrganization !== '') {
            fetchHistoryData()
        }
    }, [selectedOrganization]);

    const historieColumns = useMemo(() => (
        [
            {
                header: () => "Datum / Tijd",
                accessorKey: "Logged_At",
            },
            {
                header: () => "Naam",
                accessorKey: "vUserName",
            },
            {
                header: () => "Export ID",
                accessorKey: "iExportId",
            },
            {
                header: () => "Type",
                accessorKey: "vTypeExport",
            },
            {
                header: () => "Filters",
                accessorKey: "vDataExport",
            }
        ]
    ), [historieData])
    return (
        <Col sm={12}>
            <div className="row">
                <div className="col">
                    <Card className="main-card mb-3">
                        <CardBody className="p-3">
                            <div className="btn-color-style travelCost-blue-text mb-2">Historie</div>
                            <ReactTableGeneric
                                key={fetchHistoryData}
                                data={historieData || []}
                                columns={historieColumns}
                                tableClassName="mb-0 table table-borderless table-sm table-hover historie-table"
                                filters={{
                                    search: false,
                                    sorting: false,
                                }}
                            />
                        </CardBody>
                    </Card>
                </div>
            </div>
        </Col>
    )
}

export default HistoryTable