export const schoolTabs = {
    Algemeen: "algemeen",
    Instellingen: "instellingen",
    Wtf: "wtf",
    Overview:"oviewview"
}

export const debounce = (fn, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            fn(...args);
        }, delay);
    };
};