import React from "react";
import { Card, CardBody, FormGroup } from "reactstrap";
import { Formik, Form, Field } from "formik";

const DocumentenSynchroniseren = ({ data, handleFieldChange }) => {
    return (
        <Card className="main-card">
            <CardBody className="d-flex flex-column mb-4">
                <div className="btn-color-style travelCost-blue-text mb-4">Documenten synchroniseren</div>

                <Formik
                    enableReinitialize
                    initialValues={{
                        eCanAutoAssignDocs: data?.eCanAutoAssignDocs === "Y",
                    }}
                >
                    {({ values, setFieldValue, setFieldError }) => (
                        <Form>
                            <div className="d-flex justify-content-between custom-switch">
                                <div style={{ width: "60%" }}>
                                    Bestanden vervanger synchroniseren met bestuur
                                </div>
                                <div style={{ width: "40%" }}>
                                    <label className="switch">
                                        <Field
                                            type="checkbox"
                                            name="eCanAutoAssignDocs"
                                            checked={values.eCanAutoAssignDocs}
                                            onChange={(e) => {
                                                setFieldValue(
                                                    "eCanAutoAssignDocs",
                                                    e.target.checked
                                                );
                                                handleFieldChange(
                                                    "eCanAutoAssignDocs",
                                                    e.target.checked ? "Y" : "N", setFieldError
                                                );
                                            }}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </CardBody>
        </Card>
    );
};

export default DocumentenSynchroniseren;
