import React, {Fragment, useEffect, useState} from "react";
import axiosInstance from "Api";


class Maintenance extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      newDb: '',
      oldDb: '',
      gitBackend: '',
      gitfrontend: '',
      maintenanceMode: Boolean(process.env.REACT_APP_MAINTAINANCE_MODE)
    }
  }

  componentDidMount() {
    if(this.state.maintenanceMode) {
      this.getdata();
    }
  }

  getdata() {
    // TODO: this should go to the caching ( Redux toolkit)
    let url = new URL(axiosInstance.defaults.baseURL + "development-detail");
    try {
      axiosInstance.get(url).then((response) => {

        if (response.data != null) {

          let data = response.data;
          this.setState({ newDb: data.newDatabse });
          this.setState({ oldDb: data.oldDatabase });
          this.setState({ gitBackend: data.gitBash });

        }
      }).catch(() => console.error);

    } catch (error) {
      console.log(error);
    }
  }


  render() {

    const isMaintenanceMode = this.state.maintenanceMode;

    if(!isMaintenanceMode) return null;

    return (
        <div className="development-details">
            Database Old: {this.state.oldDb} /
            Database New: {this.state.newDb} /
            Build Frontend : {this.state.gitfrontend}/
            Build Backend : {this.state.gitBackend}
        </div>
    );
  }
}

export default Maintenance;
