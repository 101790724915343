import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "tippy.js/dist/tippy.css";
import axiosInstance from "Api";
import { ErrorMessage, Formik } from "formik";
import {
    Button, Modal, ModalBody, ModalFooter, ModalHeader,
    FormGroup, Form, Label,
    Input
} from "reactstrap";
import Loader from "react-loaders";
import * as Yup from "yup";
import { toast } from "react-toastify";
import ReactTableGenericForUsers from "Components/ReactTableGenericForUsers";
import { useNavigate } from "react-router";

const validationSchema = Yup.object().shape({
    Naam: Yup.string().required("Veld mag niet leeg zijn"),
});

const BesturenTable = ({ toggle, openModal, editSelectedRow, setEditSelectedRow }) => {
    const [data, setData] = useState([]);
    const [pageSizeCount, setPageSizeCount] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [showLoader, setShowLoader] = useState(false);
    const [disableModalSubmitButton, setDisableModalSubmitButton] = useState(true);
    const navigate = useNavigate();
    const [communityList, setCommunityList] = useState([]);

    const fetchData = () => {
        !editSelectedRow && setShowLoader(true);
        axiosInstance
            .get(
                "bestuur?pageSize=" +
                pageSizeCount + "&page=" +
                pageNumber
            )
            .then((response) => {
                if (response?.data?.status) {
                    setShowLoader(false);
                    setData(response.data.data.communitiesLists);
                    setTotalPageCount(response.data.data.paginate);
                    setCommunityList(() => {
                        return response.data.data.communitiesLists.map((item) => ({
                            value: item.iCommunityId,
                            label: item.vCommunityName,
                        }));
                    })
                }
            })
            .catch((e) => console.error(e));
    };

    const paginate = (val) => {
        setPageNumber(val);
    };

    useEffect(() => {
        fetchData();
    }, [pageSizeCount, pageNumber]);

    useEffect(() => {
        if (!openModal) {
            setEditSelectedRow(null);
            setDisableModalSubmitButton(true);
        }
    }, [openModal]);

    const indexPageSize = (val) => {
        setPageSizeCount(val);
    };

    const functieTablePageSize = (val) => {
        setPageNumber(1);
        indexPageSize(val);
    };

    const userTablePageSize = (val) => {
        setPageNumber(1);
        indexPageSize(val);
    }

    const formSubmitHandler = (values) => {
        const isSave = editSelectedRow?.type === "save";
        const endpoint = `bestuur/${isSave ? "save" : "update"}`;
        const data = isSave
            ? { vCommunityName: values.Naam, eStatus: "Active" }
            : { iCommunityId: editSelectedRow?.value?.iCommunityId, vCommunityName: values.Naam, eStatus: "Active" };

        axiosInstance[isSave ? "post" : "put"](endpoint, data).then((response) => {
            if (response?.data?.status) {
                toggle();
                fetchData();
                toast.success(isSave ? "Opgeslagen" : "Bijgewerkt");
            } else {
                toast.error(isSave ? "Kan niet opslaan." : "Kan niet updaten.");
            }
        }).catch((error) => {
            console.error(error);
            toast.error("Er is iets misgegaan");
        })
    };

    const columns = [
        {
            header: "Bestuur",
            accessorKey: "vCommunityName",
            cell: value => {
                return (
                    <div className="svg-pointer" onClick={() => {
                        navigate(`/beheerder/besturen/${value.row.original.iCommunityId}`, { state: { data: value.row.original, communityList: communityList} });
                    }}>
                        {value.row.original.vCommunityName}
                    </div>)
            },
        },
        {
            header: "Regio",
            accessorKey: "regio",
        }
    ];

    const formDataState = {
        Naam: editSelectedRow?.value?.vCommunityName || "",
    };

    return (
        <>
            {
                showLoader ?
                    <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
                    : <ReactTableGenericForUsers
                        data={data || []}
                        columns={columns}
                        setPageNumber={setPageNumber}
                        pageNumber={pageNumber}
                        totalPageCount={totalPageCount}
                        paginate={paginate}
                        functieTablePageSize={functieTablePageSize}
                        pageSizeCount={pageSizeCount}
                        showLoader={showLoader}
                        tableClassName="mb-0 table table-borderless table-sm table-hover table-heading-style"
                        filters={{ search: false }}
                        userTablePageSize={userTablePageSize}
                    />
            }


            <Modal isOpen={openModal} toggle={toggle} className="feedback-modal-style overig-exporteren-modal-style modal-centered link-form">
                <ModalHeader toggle={toggle}>
                    Bestuur toevoegen
                </ModalHeader>
                <ModalBody>

                    <Formik
                        enableReinitialize
                        initialValues={formDataState}
                        validationSchema={validationSchema}
                        onSubmit={formSubmitHandler}
                    >
                        {({ values, handleChange, handleSubmit }) => (
                            <Form onSubmit={handleSubmit}>
                                <FormGroup className="d-flex justify-content-between">
                                    <Label for="Naam" className="mt-2">Naam</Label>

                                    <div style={{ width: '60%' }}>
                                        <Input
                                            name="Naam"
                                            type="text"
                                            value={values.Naam}
                                            onChange={(e) => {
                                                handleChange(e);
                                                setDisableModalSubmitButton(false);
                                                const isSameName = editSelectedRow?.value?.vCommunityName === e.target.value;
                                                const isEmpty = e.target.value === '';
                                                setDisableModalSubmitButton(isEmpty || isSameName);
                                                if (isSameName) {
                                                    toast.error("Naam bestaat al.");
                                                }
                                            }}
                                        />
                                        <ErrorMessage name="Naam" component="small" className="block mt-2 invalid-feedback" />
                                    </div>
                                </FormGroup>
                                <ModalFooter className="d-flex align-items-center justify-content-center border-bottom-0 py-4 mt-4">
                                    <Button className="btn-color-style opslaan-btn" size="sm" outline color="primary" type="submit" disabled={disableModalSubmitButton}>
                                        Toepassen
                                    </Button>
                                    <Button className="btn-color-style opslaan-btn" size="sm" outline color="primary" onClick={toggle}>
                                        Annuleren
                                    </Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>
        </>
    );
};

export default BesturenTable;
