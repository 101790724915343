import React, { useState } from "react";
import { Form, Formik } from "formik";
import { Card, CardBody, FormGroup, Label } from "reactstrap";
import { ReactComponent as DeleteIcon } from "../../../../../assets/utils/images/svgs/Delete.svg";
import SweetAlert from "react-bootstrap-sweetalert";

const Coordinatoren = ({ coordinatorData, addOrDeleteMelderHandler}) => {
    
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedField, setSelectedField] = useState(null);

    return (
        <Card className="main-card pb-4">
            <CardBody className="d-flex flex-column">
                <div className="btn-color-style travelCost-blue-text mb-4">Coördinatoren</div>
                <Formik enableReinitialize validateOnChange={true}>
                    {() => (
                        coordinatorData?.length>0 ?
                        <Form className="melder-list">
                            {coordinatorData.map((field, index) => (
                                <FormGroup key={index} className="protocol-coord">
                                    <Label for={field}>{field?.name}</Label>
                                    <div className="position-relative">
                                        <div className="d-flex gap-1 align-items-center">
                                            <div
                                                onClick={() => {
                                                    setSelectedField(field);
                                                    setShowDeleteModal(true);
                                                }}
                                                className="d-flex svg-pointer"
                                            >
                                                <DeleteIcon />
                                            </div>
                                        </div>
                                    </div>
                                </FormGroup>
                            ))}
                        </Form> : <p className="text-center">Gegevens zijn niet aanwezig</p>
                    )}
                </Formik>
            </CardBody>

            <SweetAlert
                title="Weet je zeker dat je dit wilt verwijderen?"
                show={showDeleteModal}
                type="warning"
                confirmBtnText="OK"
                cancelBtnText="Annuleren"
                showCancel
                onConfirm={() => {
                    if (selectedField) {
                        addOrDeleteMelderHandler('remove', selectedField?.iCoordId, selectedField?.iCommunityId);
                    }
                    setShowDeleteModal(false);
                }}
                onCancel={() => setShowDeleteModal(false)}
            />
        </Card>
    );
};

export default Coordinatoren;
