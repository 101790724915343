import React from "react";
import { Form, Formik } from "formik";
import { Card, CardBody, FormGroup, Input, Label } from 'reactstrap'
import { DropdownList } from "react-widgets";

const Standaardkeuzes = ({ data, changeHandler, errorField }) => {
    const defaultValues = {
        wtf: data?.lists?.default_wtf_id || '',
        dagtype: data?.lists?.default_daytype_id || '',
        status: data?.lists?.active || '',
    }
    return (
        <>
            <Card className="main-card">
                <CardBody className="d-flex flex-column">
                    <div className="btn-color-style travelCost-blue-text mb-4">Standaardkeuzes</div>

                    <Formik
                        enableReinitialize
                        initialValues={defaultValues}
                    >
                        {({ values, setFieldValue, errors, handleChange }) => (
                            <Form>
                                <FormGroup className="form-element">
                                    <Label for="wtf">WTF</Label>
                                    <div className="position-relative w-50">
                                        <div className="d-flex gap-1 align-items-center">


                                            <DropdownList dataKey='wtf' textField='wtf'
                                                placeholder="Selecteer"
                                                onChange={(e) => {
                                                    changeHandler("default_wtf_id", e)
                                                }}
                                                name="wtf"
                                                value={values.wtf}
                                                data={['K', 'L', 'P', 'H', '?']}
                                            />
                                            {errors.wtf && <div className="text-danger">{errors.wtf}</div>}

                                        </div>
                                    </div>
                                </FormGroup>

                                <FormGroup className={`form-element ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                    <Label for="dagtype">Dagtype</Label>
                                    <div className="position-relative w-50">

                                        <DropdownList dataKey="id"
                                            textField="abbr"
                                            placeholder="Selecteer"
                                            onChange={(e) => {
                                                changeHandler("default_daytype_id", e.id)
                                            }}
                                            name="dagtype"
                                            value={values.dagtype}
                                            data={data?.dayTypes}
                                        />
                                        
                                        {errors.dagtype && <div className="text-danger">{errors.dagtype}</div>}
                                    </div>
                                </FormGroup>

                                <FormGroup className={`form-element ${errorField === 'Tarief' ? 'error' : ''}`}>
                                    <Label for="status">Status</Label>
                                    <div className="position-relative w-50">
                                        <div className="d-flex gap-1 align-items-center">
                                            <DropdownList dataKey='name' textField='name'
                                                placeholder="Selecteer"
                                                onChange={(e) => {
                                                    const data = e.value;
                                                    changeHandler("active", data)
                                                }}
                                                name="Status"
                                                value={values.status ? "Nee" : "Ja"}
                                                data={[{ name: "Ja", value: "0" }, { name: "Nee", value: "1" }]}
                                            />
                                            {errors.status && <div className="text-danger">{errors.status}</div>}
                                        </div>
                                    </div>
                                </FormGroup>
                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card>
        </>
    )
}

export default Standaardkeuzes