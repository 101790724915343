import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";

const BestuurVerwijderen = ({setModalData, setOpenModal, isdataDelete, setDataDelete}) => {

    useEffect(() => {
        isdataDelete && handleModal();
    }, [isdataDelete])

    const handleModal = () => {
        setOpenModal(true);
        setModalData({ type: 'remove', data: {} });
    }

    return (
        <Card className="main-card">
            <CardBody className="d-flex flex-column mb-4">
                <div className="btn-color-style travelCost-blue-text mb-4">Bestuur verwijderen</div>

                <div className="d-flex justify-content-between custom-switch">
                    <div style={{ width: '60%' }}>Gegevens bestuur verwijderen</div>
                    <div style={{ width: '40%' }}>
                        <label class="switch">
                            <input type="checkbox" checked={isdataDelete} onClick={() => setDataDelete(!isdataDelete)} />
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>

            </CardBody>
        </Card>
    );
};

export default BestuurVerwijderen;