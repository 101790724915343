import axiosInstance from 'Api';

const saveCostApi = (data, type) => {
    return axiosInstance.post(`instellingen/${type}/save`, data)
};

const fetchCostData = (id, type) => {
    return axiosInstance.get(`instellingen/${type}?communityId=${id}`);
};

const deleteCost = (id, iCommunityId, type)=>{
    return axiosInstance.get(`instellingen/${type}/delete/${id}?iCommunityId=${iCommunityId}`)
}

const InstellingenCostService = {
    saveCostApi,
    fetchCostData,
    deleteCost
};
export default InstellingenCostService;