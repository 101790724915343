import VerzoekenService from 'Api/Verzoeken';
import ReactTableGeneric from 'Components/ReactTableGeneric';
import React, { useEffect, useMemo, useState } from 'react'
import Loader from 'react-loaders';
import { Button, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap'
import { ReactComponent as CrossIcon } from "../../../../../assets/utils/images/svgs/Cross.svg";
import { BsCheckCircle } from "react-icons/bs";
import { ReactComponent as LockIcon } from "../../../../../assets/utils/images/svgs/Locked.svg";
import { ReactComponent as InformationBlue } from "../../../../../assets/utils/images/svgs/InformationBlue.svg";
import { ReactComponent as EmailIcon} from "../../../../../assets/utils/images/svgs/E-mail.svg";
import TitleModal from 'Components/Modal/InfoModal/TitleModal';
import { ReactComponent as SmsIcon } from '../../../../../assets/utils/images/svgs/msgIcon.svg';

const getColValueIcon = (colValue) => {
    switch (colValue) {
        case "1":
            return <BsCheckCircle color='#3AC47D'
                fontSize={"18px"} />;
        case "locked":
            return <LockIcon />;
        case null:
        case undefined:
        case "":
            return "-";
        default:
            return <CrossIcon />;
    }
};

const PartDayData = ({data}) => {
    return <Table size="sm" borderless className="mb-0 ">
        <tbody>
            <tr className="border-bottom-0">
                <th className="fw-bold col-2">ID verzoek</th>
                <th className="fw-bold col-2">Datum</th>
                <th className="fw-bold col-2">Dagdeel</th>
                <th className="fw-bold col-2">Vervangers</th>
                <th className="fw-bold col-2">Contract type</th>
            </tr>
            <tr className="border-bottom-0">
                <td className="wl pb-4">{data?.iSubstitionId || "--"}</td>
                <td className="wl pb-4">{data?.iSubsDate || "--"}</td>
                <td className="wl pb-4">{data?.vShift || "--"}</td>
                <td className="wl pb-4">{data?.vervanger || "--"}</td>
                <td className="wl pb-4">{data?.vContracttype || "--"}</td>
            </tr>
            <tr className="border-bottom-0">
                <th className="fw-bold col-2">Gebruiker aangevraagd</th>
                <th className="fw-bold col-2">Datum aangevraagd</th>
                <th className="fw-bold col-2">Gebruiker ingepland</th>
                <th className="fw-bold col-2">Datum ingepland</th>
                <th className="fw-bold col-2">Coordinator geannuleerd</th>
            </tr>
            <tr className="border-bottom-0">
                <td className="wl pb-4">{data?.requestedUser || "--"}</td>
                <td className="wl pb-4">{data?.iRequestedWhen || "--"}</td>
                <td className="wl pb-4">{data?.scheduledUser || "--"}</td>
                <td className="wl pb-4">{data?.iPlannedWhen || "--"}</td>
                <td className="wl pb-4">{data?.cancelledUser || "--"}</td>
            </tr>
            <tr className="border-bottom-0">
                <th className="fw-bold col-2">Coordinator datum</th>
                <th className="fw-bold col-2">Vervanger geannuleerd</th>
                <th className="fw-bold col-2">Vervanger datum</th>
                <th className="fw-bold col-2">Min/Max</th>
            </tr>
            <tr className="border-bottom-0">
                <td className="wl pb-4">{data?.iCancelledWhen || "--"}</td>
                <td className="wl pb-4">{data?.cVervangerUser || "--"}</td>
                <td className="wl pb-4">{data?.iCancelByVervangerWhen || "--"}</td>
                <td className="wl pb-4">{data?.vMinMax || "--"}</td>
            </tr>
            <tr className="border-bottom-0">
                <th className="fw-bold col-2">Begin</th>
                <th className="fw-bold col-2">i36</th>
                <th className="fw-bold col-2">Eind</th>
                <th className="fw-bold col-2">VOG</th>
                <th className="fw-bold col-2">ID</th>
            </tr>
            <tr className="border-bottom-0">
                <td className="wl pb-4">{getColValueIcon(data?.iBlokkerenBegin)}</td>
                <td className="wl pb-4">{getColValueIcon(data?.i36)}</td>
                <td className="wl pb-4">{getColValueIcon(data?.iBlokkerenEnd)}</td>
                <td className="wl pb-4">{getColValueIcon(data?.eVOG)}</td>
                <td className="wl pb-4">{getColValueIcon(data?.eID)}</td>
            </tr>
            <tr className="border-bottom-0">
                <th className="fw-bold col-2">PABO</th>
                <th className="fw-bold col-2">Keten</th>
                <th className="fw-bold col-2">i130</th>
            </tr>
            <tr className="border-bottom-0">
                <td className="wl">{getColValueIcon(data?.ePABO)}</td>
                <td className="wl">{getColValueIcon(data?.vKeten)}</td>
                <td className="wl">{data?.i130 === "N" ? <CrossIcon /> : <BsCheckCircle color='#3AC47D'
                    fontSize={"18px"} />}</td>
            </tr>
        </tbody>
    </Table>
}

const Logs = ({ id, updateHandler, logType }) => {
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [partDaysData, setPartDaysData] = useState();
    const [activityLogId, setActivityLogId] = useState('');

    useEffect(() => {
        if(!logType) return;
        setIsLoading(true);
        if (logType === "gegevens" && activityLogId) {
            VerzoekenService.partDaysData(activityLogId).then((response) => {
                if (response.status) {
                    setPartDaysData(response.data.data);
                    setIsLoading(false);
                } else {
                    setPartDaysData({});
                }
            }).catch((error) => console.log("catch error:", error));
        } else {
            VerzoekenService.logsData(id, logType).then((response) => {
                if (response.status) {
                    if (Array.isArray(response.data.data)) {
                        setTableData(response.data.data);
                        setIsLoading(false);
                    } else {
                        setTableData([])
                    }
                }
            }).catch((error) => console.log("catch error:", error));
        }
    }, [logType, activityLogId]);

    const communicatieColumn = [
        {
            header: () => 'Datum',
            accessorKey: 'date'
        },
        {
            header: () => 'Naam',
            accessorKey: 'name'
        },
        {
            header: () => 'Coördinator',
            accessorKey: 'coordinator'
        },
        {
            header: () => 'Bericht',
            accessorKey: 'bericht',
            cell: value =><TitleModal data={value.row.original.note ||''} title="Bericht" Icon={value.row.original.bericht === "send_sms" ? SmsIcon : EmailIcon}/>
        }
    ];

    const dagdelenColumn = [
        {
            header: () => 'Wijzigingsdatum',
            accessorKey: 'createdOn'
        },
        {
            header: () => 'Datum',
            accessorKey: 'substitutionDate'
        },
        {
            header: () => 'Dagdeel',
            accessorKey: 'vShift'
        },
        {
            header: () => 'Gebruiker',
            accessorKey: 'name'
        },
        {
            header: () => 'Type',
            accessorKey: 'type'
        },
    ];

    const instellingenColumn = [
        {
            header: () => 'Datum',
            accessorKey: 'iSubsDate',
        },
        {
            header: () => 'Dagdeel',
            accessorKey: 'vShift'
        },
        {
            header: () => 'Datum aangevraagd',
            accessorKey: 'iRequestedWhen'
        },
        {
            header: () => 'Gebruiker aangevraagd',
            accessorKey: 'requestedUser'
        },
        {
            header: () => 'Vervangers',
            accessorKey: 'vervanger'
        },
        {
            header: () => 'Contracttype',
            accessorKey: 'vContracttype'
        },
        {
            header: () => <div style={{ width: '40px', textAlign: 'center' }}>36</div>,
            accessorKey: 'i36',
            cell: value => <div className='text-center'>{getColValueIcon(value.row.original.i36)}</div>
        },
        {
            header: () => <div style={{ width: '40px', textAlign: 'center' }}>Begin</div>,
            accessorKey: 'iBlokkerenBegin',
            cell: value => <div className='text-center'>{getColValueIcon(value.row.original.iBlokkerenBegin)}</div>
        },
        {
            header: () => <div style={{ width: '40px', textAlign: 'center' }}>Eind</div>,
            accessorKey: 'iBlokkerenEnd',
            cell: value => <div className='text-center'>{getColValueIcon(value.row.original.iBlokkerenEnd)}</div>
        },
        {
            header: () => <div style={{ width: '40px', textAlign: 'center' }}>Vog</div>,
            accessorKey: 'eVOG',
            cell: value => <div className='text-center'>{getColValueIcon(value.row.original.eVOG)}</div>
        },
        {
            header: () => <div style={{ width: '40px', textAlign: 'center' }}>Id</div>,
            accessorKey: 'eID',
            cell: value => <div className='text-center'>{getColValueIcon(value.row.original.eID)}</div>
        },
        {
            header: () => <div style={{ width: '40px', textAlign: 'center' }}>Pabo</div>,
            accessorKey: 'ePABO',
            cell: value => <div className='text-center'>{getColValueIcon(value.row.original.ePABO)}</div>
        },
        {
            header: () => <div style={{ width: '40px', textAlign: 'center' }}>Keten</div>,
            accessorKey: 'vKeten',
            cell: value => <div className='text-center'>{getColValueIcon(value.row.original.vKeten)}</div>
        },
        {
            header: () => <div style={{ width: '90px', textAlign: 'center' }}>Verloopdatum contract</div>,
            accessorKey: 'vContractExpired',
            cell: value => <div className='text-center'>{getColValueIcon(value.row.original.vContractExpired)}</div>
        },
        {
            header: () => 'Details',
            accessorKey: 'total_sent',
            cell: value => <div className='text-center cursor-pointer' onClick={() => { updateHandler(true, "gegevens"); setActivityLogId(value.row.original.activityLogId)}}><InformationBlue /></div>
        },
    ]

    const column = useMemo(() => {
        if (logType === "gegevens") return []
        else if (logType === "instellingen") return instellingenColumn
        else if (logType === "communicatie") return communicatieColumn
        else return dagdelenColumn
    }, [logType])

    return (
        <>
            <ModalHeader>
                <div className='d-flex flex-column w-100'>
                    <div className='d-flex justify-content-between w-100'>
                        <div className="modal-header-styling">
                            {logType === "gegevens" ? "Gegevens Dagdeel" : "Log"}
                        </div>
                        <div className="cross-icon-color" onClick={() => {
                            updateHandler(false, logType)
                        }}>
                            <i className="nav-link-icon lnr-cross m-1 cursor-pointer" > </i>
                        </div>
                    </div>
                    {
                        logType !== "gegevens" && <select className="form-select mt-3" onChange={(e) => updateHandler(true, e.target.value)}>
                            <option value='dagdelen'>Dagdelen</option>
                            <option value='communicatie'>Communicatie</option>
                            <option value='instellingen'>Instellingen</option>
                        </select>
                    }

                </div>
            </ModalHeader>
            <ModalBody className="bg-white rounded mb-2">
                {isLoading ?
                    <Loader type="ball-grid-pulse" className={'mb-3 d-flex mx-auto justify-content-center'} /> :
                    (logType === "gegevens" ? <>
                        <PartDayData data={partDaysData} />
                    </> : <ReactTableGeneric
                        data={tableData}
                        columns={column}
                        tableClassName={`mb-0 table table-sm table-hover tb-log ${logType === "communicatie" && "comm-log"}`}
                        filters={{ search: false, pagination: false, sorting: false }}
                    />)
                }
            </ModalBody>
            <ModalFooter>
                {logType === "gegevens" &&
                    <Button className="d-flex align-items-center btn-color-style back-btn" size="sm" outline color="primary"
                        onClick={() => updateHandler(false, "")}>
                        Venster Sluiten
                    </Button>
                }
            </ModalFooter>
        </>
    )
}

export default Logs