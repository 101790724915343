import React, { useState } from "react";
import { Button, Card, CardBody, Row } from 'reactstrap'
import 'tippy.js/themes/light.css';
import PDFPopup from "./PdfUploadModal";

const GegevensImporteren = () => {
    const [openFileUploadModal, setOpenFileUploadModal] = useState(false);
    const [file, setFile] = useState("");
    const [isPdfUploading, setisPdfUploading] = useState(false);
    const togglePDFmodle = () => {
        setOpenFileUploadModal(false);
        setFile("");
      }

    const handleFileUpload = () => {
        setOpenFileUploadModal(true);
        // setModalData({type: "fileUpload", data: {}});
    }

    const handleFileSave = () => {
        let formData = new FormData();
        // formData.append('iAdminId', bestuurList?.iAdminId)
        // formData.append('iCommunityId', communityId)
        formData.append('file', file);
        setisPdfUploading(true);
    
        // BesturenService.saveFile(formData).then((response) => {
        //   if (response?.data?.status) {
        //     toast.success('Bestand opgeslagen');
        //     togglePDFmodle();
        //     replacementProtocol();
        //     setisPdfUploading(false);
        //   } else {
        //     toast.error('Fout bij het opslaan van het bestand');
        //   }
        // }).catch((error) => toast.error('Fout bij het opslaan van het bestand'));
      }

    return (
        <>
            <Card className="main-card">
                <CardBody className="d-flex flex-column mb-4">
                    <Row>
                        <div className="btn-color-style travelCost-blue-text mb-4">Gegevens importeren</div>

                        <div className="form-element">
                            <div>Gebruikers importeren (*.xlxs)</div>
                            <div style={{ width: '45%' }}>
                                <Button
                                    outline
                                    className="btn-outline-primary-blue btn-color-style py-2 px-3 d-flex gap-2 align-items-center"
                                    onClick={handleFileUpload}
                                    type="button"
                                    color="primary"
                                >
                                    Bladeren
                                </Button>
                            </div>
                        </div>

                    </Row>
                </CardBody>
            </Card>
            <PDFPopup isOpen={openFileUploadModal} togglePopup={togglePDFmodle} file={file} setFile={setFile} doc={file} handleFileSave={handleFileSave} isPdfUploading={isPdfUploading} classes="document-modal-style modal-centered" />

        </>
    )
}

export default GegevensImporteren;