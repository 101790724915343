import axiosInstance from 'Api';

const fetchBesturen = (communityId) => {
    return axiosInstance.get(`instellingen/bestuur/lists?iCommunityId=${communityId}`)
};

const saveBesturen = (data) => {
    return axiosInstance.post(`instellingen/bestuur/save-or-update`, data);
};

const getReporterList = (communityId) => {
    return axiosInstance.get(`instellingen/bestuur/admin-lists?iCommunityId=${communityId}`)
}

const reporterListAddOrRemove = (data) => {
    return axiosInstance.post(`instellingen/bestuur/toggle-user`, data);
}

const getReplacementProtocol = (communityId) => {
    return axiosInstance.get(`instellingen/bestuur/protocol-lists?iCommunityId=${communityId}`)
}

const removeReplacementProtocol = (data) => {
    return axiosInstance.post("instellingen/bestuur/protocol-delete", data)
}

const getCoordinatorList = (communityId) => {
    return axiosInstance.get(`instellingen/bestuur/coordinator-lists?iCommunityId=${communityId}`)
}

const saveFile = (data) => {
    return axiosInstance.post(`instellingen/bestuur/protocol-save`, data);

}

const BesturenService = {
    fetchBesturen,
    saveBesturen,
    getReporterList,
    reporterListAddOrRemove,
    getReplacementProtocol,
    removeReplacementProtocol,
    getCoordinatorList,
    saveFile
};
export default BesturenService;