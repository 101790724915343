import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
    Card, CardBody, Col, CardFooter, Row, Button
} from "reactstrap";
import PageHeading from "Layout/AppMain/PageHeading";
import { ReactComponent as AddIcon } from "../../../../assets/utils/images/svgs/addBlue.svg";

import BesturenTable from "./BesturenTable";

const LinkPage = () => {
    const [openModal, setOpenModal] = useState(false);
    const [editSelectedRow, setEditSelectedRow] = useState(null);

    const handleBestuurClick = () => {
        setOpenModal(prev => !prev);
        setEditSelectedRow({
            type: "save"
        })
    }

    const toggle = () => {
        setOpenModal(!openModal);
    }
    return (
        <Fragment>
            <PageHeading />
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    <div>
                        <Row>
                            <Col md="12">
                                <Card className="main-card mb-3">
                                    <CardBody className="p-3">
                                        <BesturenTable toggle={toggle} openModal={openModal} editSelectedRow={editSelectedRow} setEditSelectedRow={setEditSelectedRow}/>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        
                    </div>
                </CSSTransition>
            </TransitionGroup>
            <div className="chat-layout-footer verzoken-footer-height">
                <CardFooter className="chat-layout-footer__inner">
                    <div className="d-flex gap-3 mr-4">

                        <Button
                            outline
                            className="btn-outline-primary-blue btn-color-style py-2 px-3 d-flex gap-2 align-items-center"
                            onClick={handleBestuurClick}
                            type="button"
                            color="primary"
                        >
                            Bestuur <AddIcon />
                        </Button>
                    </div>
                </CardFooter>
            </div>
        </Fragment>
    );
};
export default LinkPage;

