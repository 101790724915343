import React, {Fragment} from "react";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {Card, CardBody, Col, Row} from "reactstrap";
import UserTable from "Pages/Admin/Users/UsersTable";
import PageHeading from "Layout/AppMain/PageHeading";
import PushMessagesTable from "./PushMessagesTable";


const PushMessagesDefault = () => {
    
    return (
        <Fragment>
           <div className="d-flex justify-content-between align-items-center">
                <PageHeading/>
            
            </div>
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                               timeout={1500} enter={false} exit={false}>
                    <div>
                     
                        <Row>
                            <Col md="12">
                                <Card className="main-card mb-3">
                                    <CardBody className="p-3">
                                        <PushMessagesTable/>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </Fragment>
    )
};

export default PushMessagesDefault;
