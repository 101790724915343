import { useEffect, useState } from "react";
import GeneralDetails from "./GeneralDetails";
import ContactDetails from "./ContactDetails";
import { Button, Card, CardBody, CardFooter } from "reactstrap";
import { useLocation, useNavigate, useParams } from "react-router";
import { ReactComponent as BackIcon } from "../../../../../../assets/utils/images/svgs/arrow-left.svg";
import { debounce } from "../../../Schools/utils";
import { toast } from "react-toastify";
import Loader from 'react-loaders';
import Remark from "./Remark";
import ScholenService from "Api/Instellingen/Scholen";

const Algemeen = ({ setSchoolName }) => {
    const navigate = useNavigate();
    const [errorField, setErrorField] = useState('');
    const { schoolId } = useParams();
    const location = useLocation();
    const { communityId } = location.state || {};
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        fetchAlgemeenData();
    }, []);

    const fetchAlgemeenData = async () => {
        try {
            const response = await ScholenService.getAlgemeenData(communityId, schoolId);
            if (response.data.status) {
                setData(response.data.data);
                setSchoolName(response.data.data.detailList.vSchoolName || "")
            }
        } catch (error) {
            console.error(`API error:`, error);
        } finally {
            setIsLoading(false);
        }
    };
    
    const callApi = async (field, value, setLoadingKeys) => {
        const bodyData = { attr: field, [field]: value };
        try {
            const response = await ScholenService.updateScholenData(bodyData, schoolId);
            if (!response.data.status) {
                setErrorField(field);
                const errors = response.data.data[field];
                const errorMessage = Array.isArray(errors) ? errors.join(',') : errors;
                toast.error(errorMessage);
            }
            if (response.data.status) {
                setData(prev => ({
                    ...prev,
                    detailList: {
                       ...prev.detailList,
                        [field]: value
                    }
                }));
            }
            if (response.data.status && field === "vSchoolName") {
                setSchoolName(value)
            }
        } catch (error) {
            console.error('API error:', error);
        } finally {
            if (setLoadingKeys) {
                setLoadingKeys(prev => ({ ...prev, [field]: false }));
            }
        };
    };

    const debouncedApiCall = debounce(callApi, 1000);

    const handleFieldChange = (field, value) => {
        setErrorField('');
        debouncedApiCall(field, value);
    };

    const handleIconFieldChange = (field, value, setLoadingKeys = null) => {
        setErrorField('');
        callApi(field, value, setLoadingKeys);
    };

    return (
        <>
            {
                isLoading ?
                    <Card className="main-card">
                        <CardBody className="d-flex flex-column">
                            <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
                        </CardBody>
                    </Card> :
                    <>
                        <div className='row algemeen'>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-xxl-6 col-12'>
                                        <GeneralDetails data={data} errorField={errorField} handleIconFieldChange={handleIconFieldChange} changeHandler={handleFieldChange} />
                                    </div>
                                    <div className='col-xxl-6 col-12'>
                                        <ContactDetails data={data} changeHandler={handleFieldChange} errorField={errorField} />
                                    </div>
                                    <div className='col-12'>
                                        <Remark data={data?.detailList?.tSchool_memo} changeHandler={handleFieldChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="chat-layout-footer">
                            <CardFooter className="chat-layout-footer__inner gebruikers-footer">
                                <div className="d-flex gap-3">
                                    <Button
                                        outline
                                        className="btn-outline-primary-blue ms-auto"
                                        onClick={() => {
                                            navigate("/c-instellingen/scholen")
                                        }}
                                        type="button"
                                        color="primary"
                                    >
                                        <BackIcon />
                                    </Button>
                                </div>
                            </CardFooter>
                        </div>
                    </>
            }
        </>
    )
}

export default Algemeen