import React from 'react'
import ReactTableGeneric from 'Components/ReactTableGeneric'
import { Card, CardBody } from 'reactstrap'


const WorkTimeTable = ({ title, data, changeHandler }) => {
    const shiftDayHandler = (shifts = [], vWtfShift) => {
        return shifts.find(item => item.vWtfShift === vWtfShift);
    };
    const createShiftColumn = (shiftKey, shiftLabel) => ({
        header: () => shiftLabel,
        accessorKey: shiftKey,
        cell: ({ row }) => {
            const iGroupId = row.original.iGroupId;
            const shift = shiftKey.toUpperCase();
            const wtfType = title;
            const iWtf = row.original.shifts ? shiftDayHandler(row.original.shifts, shift) : {};
            return (
                <input
                    type="number"
                    defaultValue={iWtf?.vHour || ''}
                    onChange={(e) => {
                        changeHandler(iGroupId, shift, e.target.value, wtfType, iWtf?.iWtfId || "");
                    }}
                />
            );
        },
    });
    
    const shiftColumns = [
        { key: 'ma', label: 'MO' },
        { key: 'mm', label: 'MM' },
        { key: 'do', label: 'DO' },
        { key: 'dm', label: 'DM' },
        { key: 'wo', label: 'WO' },
        { key: 'wm', label: 'WM' },
        { key: 'to', label: 'TO' },
        { key: 'tm', label: 'TM' },
        { key: 'vo', label: 'VO' },
        { key: 'vm', label: 'VM' },
    ];
    
    const columns = React.useMemo(
        () => [
            {
                header: () => 'Functie',
                accessorKey: 'vGroup',
            },
            ...shiftColumns.map(({ key, label }) => createShiftColumn(key, label)),
        ],
        [data]
    );

    return (
        <>
            <Card className="main-card">
                <CardBody className="d-flex flex-column">
                    <h5 className="mb-4">{title[0]?.toUpperCase() + title?.slice(1)}</h5>
                    <ReactTableGeneric
                        data={data || []}
                        columns={columns}
                        tableClassName="mb-0 table table-borderless table-sm table-hover wtf-table"
                        filters={{ search: false, pagination: false, sorting: false }}
                    />
                </CardBody>
            </Card>
        </>
    )
}

export default WorkTimeTable