import { Table } from "reactstrap";
import React, { useEffect, useState } from "react";
import { BsCheckCircle } from "react-icons/bs";
import { ReactComponent as CrossIcon } from "../../../../assets/utils/images/svgs/Cross.svg";
import axiosInstance from "Api";
import Loader from "react-loaders";
import { useNavigate } from "react-router";

const ContractTypenTable = ({contractList, communityId }) => {
    const navigate = useNavigate();
    return (
        <>
            <div className="d-flex justify-content-between  ">
                <div className="btn-color-style travelCost-blue-text mb-2">Contracttypen</div>
            </div>
            <Table hover size="sm" className="mb-0 coor-verzoeken-table contract-typen-table">
                <thead>
                    <tr>
                        <th colSpan="3" className="text-left w-32 table-right-border">&nbsp;</th>
                        <th colSpan="1" className="text-center"></th>
                        <th colSpan="1" className="text-center ">Centrale planning</th>
                        <th colSpan="1" className="text-center table-right-border"></th>
                        <th colSpan="1" className="text-center"></th>
                        <th colSpan="1" className="text-center">Schoolplanning</th>
                        <th colSpan="1" className="text-center"></th>
                    </tr>
                    <tr>
                        <th className="text-left">Omschrijving</th>
                        <th className="text-left">Afkorting</th>
                        <th className=" table-right-border">Dagtype</th>
                        <th className="text-center">Weergeven</th>
                        <th className="text-center">Autoload</th>
                        <th className="table-right-border">Volgorde</th>
                        <th className="text-center">Weergeven</th>
                        <th className="text-center">Autoload</th>
                        <th className="">Volgorde</th>
                    </tr>
                </thead>
                <tbody>
                    {contractList.length ? contractList.map((contract, index) => (
                        <tr key={index}>
                            <td className="text-left svg-pointer" onClick={() => {navigate(`/c-instellingen/contracttypen/wijzigen-toevoegen/${contract?.ctid}`, {state: {contractId: contract?.ctid, communityId: communityId}});}}>{contract.name}</td>
                            <td className="text-left">{contract.abbr}</td>
                            <td className="table-right-border" >{contract.cdAbbr}</td>
                            <td className="text-center">
                                {!contract.cp.hidden ?
                                    <span><BsCheckCircle color='#39A825' fontSize={"18px"} className="weekstaten-check-icon" /></span>
                                    :
                                    <span><CrossIcon /></span>}
                            </td>
                            <td className="text-center">
                                {!contract.cp.iAutoLoad ?
                                    <span><BsCheckCircle color='#39A825' fontSize={"18px"} className="weekstaten-check-icon" /></span>
                                    :
                                    <span><CrossIcon /></span>}
                            </td>
                            <td className="table-right-border">{contract.cp.order}</td>
                            <td className="text-center">
                                {Array.isArray(contract.sp) && contract.sp.length === 0 ? (
                                    <>-</>
                                ) : !contract.sp.m_hidden ? (
                                    <span><BsCheckCircle color='#39A825' fontSize={"18px"} className="weekstaten-check-icon" /></span>
                                ) : (
                                    <span><CrossIcon /></span>
                                )}

                            </td>
                            <td className="text-center">
                                {Array.isArray(contract.sp) && contract.sp.length === 0 ? (
                                    <>-</>
                                ) : !contract.sp.m_iAutoLoad ?
                                    <span><BsCheckCircle color='#39A825' fontSize={"18px"} className="weekstaten-check-icon" /></span>
                                    :
                                    <span><CrossIcon /></span>}
                            </td>
                            <td>
                                {(Array.isArray(contract.sp) && contract.sp.length === 0) ? <>-</> : contract.sp.m_order}
                            </td>

                        </tr>
                    )) : <tr><td colSpan={9} align='center' style={{ textAlign: "center" }}>Geen data beschikbaar</td></tr>}
                </tbody>
            </Table>

        </>

    );
};

export default ContractTypenTable;