import React, { Fragment, useState } from "react";
import { Form, Formik } from "formik";
import { Card, CardBody, Col, FormGroup, Input, Label, Row, Spinner } from 'reactstrap'
import { BsCheckCircle } from "react-icons/bs";
import { ReactComponent as CrossIcon } from "../../../../../assets/utils/images/svgs/Cross.svg";
import { ReactComponent as CheckCircleYB } from "../../../../../assets/utils/images/svgs/checkCircleYB.svg";
import { BsQuestionCircle } from "react-icons/bs";
import { DropdownList } from "react-widgets";
import Tippy from "@tippyjs/react";
import GroupStatus from "../../../../../Components/IconDropdown/Cross_Tick/GroupStatus";

const iFV = [
    { name: 'Nvt', value: 0, icon: '' },
    { name: "Functie akkoord", value: 1, icon: <BsCheckCircle color="#39A825" fontSize="18px" /> },
    { name: "Functie niet gekozen", value: 2, icon: <BsQuestionCircle color="#FFA73F" fontSize="18px" /> },
    { name: "Functie niet akkoord", value: 3, icon: <CrossIcon /> },
    { name: "Akkoord / niet gekozen", value: 4, icon: <CheckCircleYB /> }
];
const iFB = [
    { name: 'Nvt', value: 0, icon: '' },
    { name: "Functie akkoord", value: 1, icon: <BsCheckCircle color="#39A825" fontSize="18px" /> },
    { name: "Functie niet gekozen", value: 2, icon: <BsQuestionCircle color="#FFA73F" fontSize="18px" /> },
    { name: "Functie niet akkoord", value: 3, icon: <CrossIcon /> },
    { name: "Akkoord / niet gekozen", value: 4, icon: <CheckCircleYB /> }
];

const matchCode = [...Array(18).keys()].map(i => i + 3).concat(99);
const reisafstand = Array.from({ length: 20 }, (_, i) => (i + 1) * 5);

const iAVAILABLE = [
    { name: 'Nvt', value: 0, icon: '' },
    { name: 'Available', value: 2, icon: <BsCheckCircle color="#39A825" fontSize="18px" /> },
    { name: 'Partially Available', value: 4, icon: <CheckCircleYB /> }
];

const availabilityMode = [
    { name: 'Dagdelen', value: 0 },
    { name: 'Rooster', value: 1 },
];


const InstellingenForm = ({ data, handleFieldChange, handleIconFieldChange, errorField }) => {
    const [loadingKeys, setLoadingKeys] = useState({})
    const defaultValues = {
        iFV: data?.iFV,
        iFVLOCK: parseInt(data?.iFVLOCK),
        iFVIGNORE: parseInt(data?.iFVIGNORE),
        iFB: data?.iFB,
        iFBLOCK: parseInt(data?.iFBLOCK),
        iFBIGNORE: parseInt(data?.iFBIGNORE),
        iMATCH: data?.iMATCH,
        iMATCHLOCK: parseInt(data?.iMATCHLOCK),
        iMATCHIGNORE: parseInt(data?.iMATCHIGNORE),
        iTRAVELDISTANCE: data?.iTRAVELDISTANCE || 'Uit',
        iTRAVELDISTANCELOCK: parseInt(data?.iTRAVELDISTANCELOCK),
        iTRAVELDISTANCEIGNORE: parseInt(data?.iTRAVELDISTANCEIGNORE),
        iAVAILABLE: parseInt(data?.iAVAILABLE),
        iAVAILABLELOCK: parseInt(data?.iAVAILABLELOCK),
        iAVAILABLEIGNORE: parseInt(data?.iAVAILABLEIGNORE),
        iAVAILABLEMODE: parseInt(data?.iAVAILABLEMODE),
        iVOG: parseInt(data?.iVOG),
        iVOGLOCK: parseInt(data?.iVOGLOCK),
        iVOGIGNORE: parseInt(data?.iVOGIGNORE),
        iID: parseInt(data?.iID),
        iIDLOCK: parseInt(data?.iIDLOCK),
        iIDIGNORE: parseInt(data?.iIDIGNORE),
        iPABO: parseInt(data?.iPABO),
        iPABOLOCK: parseInt(data?.iPABOLOCK),
        iPABOIGNORE: parseInt(data?.iPABOIGNORE),
        iKR: parseInt(data?.iKR),
        iKRLOCK: parseInt(data?.iKRLOCK),
        iKRIGNORE: parseInt(data?.iKRIGNORE),
        i36: parseInt(data?.i36),
        i36LOCK: parseInt(data?.i36LOCK),
        i36IGNORE: parseInt(data?.i36IGNORE),
        iBK: parseInt(data?.iBK),
        iBKLOCK: parseInt(data?.iBKLOCK),
        iBKIGNORE: parseInt(data?.iBKIGNORE),
        iGYM: parseInt(data?.iGYM),
        iGYMLOCK: parseInt(data?.iGYMLOCK),
        iGYMIGNORE: parseInt(data?.iGYMIGNORE),
    };

    const handleToggle = (field, data) => {
        setLoadingKeys(prev => ({ ...prev, [field]: true }));
        handleIconFieldChange(field, data, setLoadingKeys)
    };

    return (
        <Card className="main-card min-width">
            <CardBody className="d-flex flex-column">
                <div className="btn-color-style travelCost-blue-text mb-4">Instellingen</div>

                <Row className="mb-4 flex-nowrap ps-3">
                    <Col lg={5} sm={6} className="p-0">
                    </Col>
                    <Col sm={1}></Col>
                    <Col lg={2} sm={2} className="p-0 text-center">
                        Vastzetten
                    </Col>
                    <Col lg={2} sm={2} className="p-0 text-center">
                        Verbergen
                    </Col>
                </Row>
                <Formik
                    enableReinitialize
                    initialValues={defaultValues}
                >
                    {({ values, setFieldValue, errors, handleChange }) => (
                        <Form className="ps-3">

                            <Row className="flex-nowrap">
                                <Col lg={5} sm={6} className="p-0">
                                    <FormGroup className={`form-element ${errors.iFV ? 'error' : ''}`}>
                                        <Label for="iFV" className="mb-0 d-flex align-items-center">Functie vervanger</Label>
                                        <div className="position-relative w-50">
                                            <DropdownList
                                                dataKey="value"
                                                textField="value"
                                                data={iFV}
                                                name="iFV"
                                                value={iFV.find(option => option.value === Number(values.iFV)) || null}
                                                onChange={(e) => {
                                                    setFieldValue("iFV", Number(e.value))
                                                    handleFieldChange("iFV", e.value);
                                                }
                                                }

                                                renderListItem={({ item }) => (
                                                    <>{item?.icon} <span>{item?.name}</span></>
                                                )}
                                                renderValue={({ item }) => (
                                                    <> {item?.icon} <span>{item?.name}</span></>
                                                )}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={1}></Col>
                                <Col lg={2} sm={2} className="p-0 d-flex justify-content-center align-items-center">
                                    <FormGroup className={`text-center ${errorField === 'Afkorting' ? 'error' : ''}`} >
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center ">
                                                {loadingKeys["iFVLOCK"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iFVLOCK"} data={values?.iFVLOCK ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col lg={2} sm={2} className="p-0 d-flex justify-content-center align-items-center">
                                    <FormGroup className={`text-center ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center ">
                                                {loadingKeys["iFVIGNORE"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iFVIGNORE"} data={values?.iFVIGNORE ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className="flex-nowrap">
                                <Col lg={5} sm={6} className="p-0">
                                    <FormGroup className={`form-element ${errors.iFB ? 'error' : ''}`}>
                                        <Label for="iFB" className="mb-0 d-flex align-items-center">Functie bestuur</Label>
                                        <div className="position-relative w-50">
                                            <DropdownList
                                                dataKey="value"
                                                textField="name"
                                                data={iFB}
                                                name="iFB"
                                                value={iFB.find(option => option.value === Number(values.iFB)) || null}
                                                onChange={(e) => {
                                                    setFieldValue("iFB", Number(e.value));
                                                    handleFieldChange("iFB", e.value);
                                                }}
                                                renderListItem={({ item }) => (
                                                    <> {item?.icon} <span>{item?.name}</span></>
                                                )}
                                                renderValue={({ item }) => (
                                                    <> {item?.icon} <span>{item?.name}</span></>
                                                )}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={1}></Col>
                                <Col lg={2} sm={2} className="p-0 d-flex justify-content-center align-items-center">
                                    <FormGroup className={`text-center ${errorField === 'Afkorting' ? 'error' : ''}`}>

                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center ">
                                                {loadingKeys["iFBLOCK"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iFBLOCK"} data={values?.iFBLOCK ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col lg={2} sm={2} className="p-0 d-flex justify-content-center align-items-center">
                                    <FormGroup className={`text-center ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center ">
                                                {loadingKeys["iFBIGNORE"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iFBIGNORE"} data={values?.iFBIGNORE ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className="flex-nowrap">
                                <Col lg={5} sm={6} className="p-0">
                                    <FormGroup className={`form-element ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <Label for="iMATCH" className="mb-0 d-flex align-items-center">Matchcode</Label>
                                        <div className="position-relative w-50">
                                            <DropdownList
                                                onChange={(e) => { setFieldValue('iMATCH', e); handleFieldChange("iMATCH", e); }}
                                                name="iMATCH"
                                                data={matchCode}
                                                value={values.iMATCH}
                                                placeholder="Naam"
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={1}></Col>
                                <Col lg={2} sm={2} className="p-0 d-flex justify-content-center align-items-center">
                                    <FormGroup className={`text-center ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center ">
                                                {loadingKeys["iMATCHLOCK"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iMATCHLOCK"} data={values?.iMATCHLOCK ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col lg={2} sm={2} className="p-0 d-flex justify-content-center align-items-center">
                                    <FormGroup className={`text-center ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center ">
                                                {loadingKeys["iMATCHIGNORE"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iMATCHIGNORE"} data={values?.iMATCHIGNORE ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className="flex-nowrap">
                                <Col lg={5} sm={6} className="p-0">
                                    <FormGroup className={`form-element ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <Label for="iTRAVELDISTANCE" className="mb-0 d-flex align-items-center">Reisafstand</Label>
                                        <div className="position-relative w-50">
                                            <DropdownList dataKey='SorterenOp' textField='SorterenOp'
                                                onChange={(value) => { setFieldValue('iTRAVELDISTANCE', value); handleFieldChange("iTRAVELDISTANCE", value); }}
                                                name="iTRAVELDISTANCE"
                                                data={reisafstand}
                                                value={values.iTRAVELDISTANCE}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={1}></Col>
                                <Col lg={2} sm={2} className="p-0 d-flex justify-content-center align-items-center">
                                    <FormGroup className={`text-center ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center ">
                                                {loadingKeys["iTRAVELDISTANCELOCK"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iTRAVELDISTANCELOCK"} data={values?.iTRAVELDISTANCELOCK ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col lg={2} sm={2} className="p-0 d-flex justify-content-center align-items-center">
                                    <FormGroup className={`text-center ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center ">
                                                {loadingKeys["iTRAVELDISTANCEIGNORE"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iTRAVELDISTANCEIGNORE"} data={values?.iTRAVELDISTANCEIGNORE ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className="flex-nowrap">
                                <Col lg={5} sm={6} className="p-0">
                                    <FormGroup className={`form-element ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <Label for="iAVAILABLE" className="mb-0 d-flex align-items-center">Beschikbaarheid</Label>
                                        <div className="position-relative w-50">
                                            <DropdownList
                                                dataKey="value"
                                                textField="name"
                                                data={iAVAILABLE}
                                                name="iAVAILABLE"
                                                value={iAVAILABLE.find(option => option.value === Number(values.iAVAILABLE)) || null}
                                                onChange={(selectedOption) => {
                                                    setFieldValue("iAVAILABLE", Number(selectedOption.value));
                                                    handleFieldChange("iAVAILABLE", selectedOption.value);
                                                }}
                                                renderListItem={({ item }) => (
                                                    <> {item?.icon} <span>{item?.name}</span></>
                                                )}
                                                renderValue={({ item }) => (
                                                    <> {item?.icon} <span>{item?.name}</span></>
                                                )}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={1}></Col>
                                <Col lg={2} sm={2} className="p-0 d-flex justify-content-center align-items-center">
                                    <FormGroup className={`text-center ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center ">
                                                {loadingKeys["iAVAILABLELOCK"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iAVAILABLELOCK"} data={values?.iAVAILABLELOCK ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col lg={2} sm={2} className="p-0 d-flex justify-content-center align-items-center">
                                    <FormGroup className={`text-center ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center ">
                                                {loadingKeys["iAVAILABLEIGNORE"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iAVAILABLEIGNORE"} data={values?.iAVAILABLEIGNORE ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className="flex-nowrap">
                                <Col lg={5} sm={6} className="p-0">
                                    <FormGroup className={`form-element ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <Label for="iAVAILABLEMODE" className="mb-0 d-flex align-items-center">Beschikbaarheidmodus</Label>
                                        <div className="position-relative w-50">
                                            <DropdownList dataKey="value"
                                                textField="name"
                                                placeholder="Naam"
                                                name="iAVAILABLEMODE"
                                                data={availabilityMode}
                                                value={availabilityMode.find(option => option.value === Number(values.iAVAILABLEMODE)) || null}
                                                onChange={(e) => {
                                                    setFieldValue("iAVAILABLEMODE", Number(e.value));
                                                    handleFieldChange("iAVAILABLEMODE", (e.value).toString());
                                                }
                                                }
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className="flex-nowrap">
                                <Col lg={5} sm={6} className="p-0">
                                    <FormGroup className={`form-element ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <Label for="sorterenOp" className="mb-0 d-flex align-items-center">VOG</Label>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center">

                                                {loadingKeys["iVOG"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iVOG"} data={values?.iVOG ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={1}></Col>
                                <Col lg={2} sm={2} className="p-0 d-flex justify-content-center align-items-center">
                                    <FormGroup className={`text-center ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center ">
                                                {loadingKeys["iVOGLOCK"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iVOGLOCK"} data={values?.iVOGLOCK ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col lg={2} sm={2} className="p-0 d-flex justify-content-center align-items-center">
                                    <FormGroup className={`text-center ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center ">
                                                {loadingKeys["iVOGIGNORE"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iVOGIGNORE"} data={values?.iVOGIGNORE ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className="flex-nowrap">
                                <Col lg={5} sm={6} className="p-0">
                                    <FormGroup className={`form-element ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <Label for="sorterenOp" className="mb-0 d-flex align-items-center">Id</Label>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center">

                                                {loadingKeys["iID"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iID"} data={values?.iID ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={1}></Col>
                                <Col lg={2} sm={2} className="p-0 d-flex justify-content-center align-items-center">
                                    <FormGroup className={`text-center ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center">

                                                {loadingKeys["iIDLOCK"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iIDLOCK"} data={values?.iIDLOCK ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col lg={2} sm={2} className="p-0 d-flex justify-content-center align-items-center">
                                    <FormGroup className={`text-center ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center">

                                                {loadingKeys["iIDIGNORE"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iIDIGNORE"} data={values?.iIDIGNORE ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className="flex-nowrap">
                                <Col lg={5} sm={6} className="p-0">
                                    <FormGroup className={`form-element ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <Label for="sorterenOp" className="mb-0 d-flex align-items-center">Pabo</Label>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center">

                                                {loadingKeys["iPABO"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iPABO"} data={values?.iPABO ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={1}></Col>
                                <Col lg={2} sm={2} className="p-0 d-flex justify-content-center align-items-center">
                                    <FormGroup className={`text-center ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center">

                                                {loadingKeys["iPABOLOCK"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iPABOLOCK"} data={values?.iPABOLOCK ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col lg={2} sm={2} className="p-0 d-flex justify-content-center align-items-center">
                                    <FormGroup className={`text-center ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center ">
                                                {loadingKeys["iPABOIGNORE"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iPABOIGNORE"} data={values?.iPABOIGNORE ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className="flex-nowrap">
                                <Col lg={5} sm={6} className="p-0">
                                    <FormGroup className={`form-element ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <Label for="sorterenOp" className="mb-0 d-flex align-items-center">Ketenregeling</Label>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center">

                                                {loadingKeys["iKR"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iKR"} data={values?.iKR ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={1}></Col>
                                <Col lg={2} sm={2} className="p-0 d-flex justify-content-center align-items-center">
                                    <FormGroup className={`text-center ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center">

                                                {loadingKeys["iKRLOCK"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iKRLOCK"} data={values?.iKRLOCK ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col lg={2} sm={2} className="p-0 d-flex justify-content-center align-items-center">
                                    <FormGroup className={`text-center ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center">

                                                {loadingKeys["iKRIGNORE"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iKRIGNORE"} data={values?.iKRIGNORE ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className="flex-nowrap">
                                <Col lg={5} sm={6} className="p-0">
                                    <FormGroup className={`form-element ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <Label for="sorterenOp" className="mb-0 d-flex align-items-center">36 Maanden</Label>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center">

                                                {loadingKeys["i36"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"i36"} data={values?.i36 ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={1}></Col>
                                <Col lg={2} sm={2} className="p-0 d-flex justify-content-center align-items-center">
                                    <FormGroup className={`text-center ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center">

                                                {loadingKeys["i36LOCK"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"i36LOCK"} data={values?.i36LOCK ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col lg={2} sm={2} className="p-0 d-flex justify-content-center align-items-center">
                                    <FormGroup className={`text-center ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center">

                                                {loadingKeys["i36IGNORE"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"i36IGNORE"} data={values?.i36IGNORE ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className="flex-nowrap">
                                <Col lg={5} sm={6} className="p-0">
                                    <FormGroup className={`form-element ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <Label for="sorterenOp" className="mb-0 d-flex align-items-center">Blokkade</Label>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center">

                                                {loadingKeys["iBK"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iBK"} data={values?.iBK ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={1}></Col>
                                <Col lg={2} sm={2} className="p-0 d-flex justify-content-center align-items-center">
                                    <FormGroup className={`text-center ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center">

                                                {loadingKeys["iBKLOCK"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iBKLOCK"} data={values?.iBKLOCK ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col lg={2} sm={2} className="p-0 d-flex justify-content-center align-items-center">
                                    <FormGroup className={`text-center ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center">

                                                {loadingKeys["iBKIGNORE"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iBKIGNORE"} data={values?.iBKIGNORE ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className="flex-nowrap">
                                <Col lg={5} sm={6} className="p-0">
                                    <FormGroup className={`form-element ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <Label for="sorterenOp" className="mb-0 d-flex align-items-center">Gym</Label>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center">

                                                {loadingKeys["iGYM"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iGYM"} data={values?.iGYM ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={1}></Col>
                                <Col lg={2} sm={2} className="p-0 d-flex justify-content-center align-items-center">
                                    <FormGroup className={`text-center ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center">

                                                {loadingKeys["iGYMLOCK"] ? (
                                                    <div><div className="spinner-icon-change">
                                                        <div><Spinner size="sm" color="primary" /></div>
                                                    </div></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iGYMLOCK"} data={values?.iGYMLOCK ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col lg={2} sm={2} className="p-0 d-flex justify-content-center align-items-center">
                                    <FormGroup className={`text-center ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                        <div className="position-relative w-50">
                                            <div className="d-flex gap-1 align-items-center">

                                                {loadingKeys["iGYMIGNORE"] ? (<div>

                                                    <Spinner size="sm" color="primary" /></div>
                                                ) :
                                                    <GroupStatus iconChangeHandler={handleToggle} field={"iGYMIGNORE"} data={values?.iGYMIGNORE ? 1 : 0} />
                                                }
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </CardBody>
        </Card>
    )
}

export default InstellingenForm
    ;