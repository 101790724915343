import React, { Fragment, useEffect, useMemo, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Button, Card, CardBody, CardFooter, Col, Row } from "reactstrap";
import PageHeading from "Layout/AppMain/PageHeading";
import Loader from "react-loaders";
import { ReactComponent as AddIcon } from "../../../../assets/utils/images/svgs/addBlue.svg";
import axiosInstance from "Api";
import Algemeen from "./BesturenView/Algemeen";
import Afzendadressen from "./BesturenView/Afzendadressen";
import StandaardInstellingenReiskosten from "./BesturenView/Standaard_instellingen_reiskosten";
import Vervangersprotocol from "./BesturenView/Vervangersprotocol";
import Coordinatoren from "./BesturenView/Coordinatoren";
import { toast } from "react-toastify";
import { debounce } from "../../../../helperFunctions/index"
import PDFPopup from "./BesturenView/PdfUploadModal";
import ReporterModal from "./BesturenView/ReporterModal";
import BesturenService from "Api/Instellingen/Besturen";

const validationRules = {
  Email: {
    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    errorMessage: "Ongeldig e-mailadres"
  },
  Website: {
    pattern: /^(https?:\/\/)?([\w-]+(\.[\w-]+)+.*)$/i,
    errorMessage: "Ongeldige website-URL"
  },
};

const Besturen = () => {
  const [communityId, setCommunityId] = useState('');
  const [communityList, setCommunityList] = useState([]);
  const [bestuurList, setBestuurList] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [openFileUploadModal, setOpenFileUploadModal] = useState(false);
  const [errorField, setErrorField] = useState('');
  const [file, setFile] = useState("");
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [replacementProtocolData, setReplacementProtocolData] = useState([]);
  const [coordinatorData, setCoordinatorData] = useState([]);
  const [reporterData, setReporterData] = useState({});
  const [isPdfUploading, setisPdfUploading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getCommunityList();
  }, []);

  useEffect(() => {
    !isLoading && setLoading(true);
    if (communityId) {
      fetchBestuurList();
      getCoordinatorList();
      replacementProtocol();
    }
  }, [communityId]);

  const fetchBestuurList = () => {
    BesturenService.fetchBesturen(communityId).then((response) => {
      if (response?.data?.status) {
        if (response.data.data !== null && response.data.data !== undefined) {
          setBestuurList(response.data.data);
          setLoading(false);
        }
      }
    }).catch((error) => console.log("catch error:", error));
  }

  const orgName = useMemo(() => {
    return communityList?.find(item => item.iCommunityId === Number(communityId))?.name || ""
  }, [communityId, communityList]);

  const getCommunityList = () => {
    axiosInstance.get('organizations-list').then((response) => {
      if (response.status === 200) {
        if (response.data.data != null && response.data.data != undefined) {
          let organizationsData = response.data.data;
          setCommunityList(organizationsData);
          setCommunityId(organizationsData[0]?.iCommunityId);
        }
      }
    }).catch((error) => console.log("catch error:", error));
  }

  const callApi = (field, value, setFieldError = () => { }, errorField = "", setLoadingKeys) => {
    if (value !== "" && errorField !== "") {
      const rule = validationRules[errorField];

      if (rule && !rule.pattern.test(value)) {
        if (typeof setFieldError === "function") {
          setFieldError(field, rule.errorMessage);
          toast.dismiss();
          toast.error(rule.errorMessage);
        }
        return;
      }
    }

    if (field === "vPrice") {
      if (value < 0 || value > 99.99) {
        setFieldError(field, "De waarde moet een decimal getal tussen 0 en 99.99 liggen");
        toast.dismiss();
        toast.error("De waarde moet tussen 0 en 99.99 liggen");
        return
      }
    }

    const data = {
      iCommunityId: communityId,
      ...(field ? { attr: field } : {}),
      [field]: value,
    }
    BesturenService.saveBesturen(data)
      .then(response => {
        if (response?.data?.status) {
          setBestuurList(prev => ({
            ...prev,
            ...response?.data?.data
          }));
          if (errorField) {
            toast.dismiss();
            toast.success(errorField + " succesvol bijgewerkt.");
          }
          return
        };
        if (!response?.data?.status) {
          setErrorField(field);
          const errors = response.data.data[field];
          if (Array.isArray(errors)) {
            toast.error(errors.join(','));
          } else {
            toast.error(errors);
          }
        }
      })
      .catch(error => {
        console.error('API error:', error);
        toast.dismiss();
        toast.error("serverfout")
      }).finally(() => {
        if (setLoadingKeys) setLoadingKeys(prev => ({ ...prev, [field]: false }));
      });
  };

  const debouncedApiCall = debounce(callApi, 500);

  const handleFieldChange = (field, value, setFieldError = {}, errorField = "") => {
    setErrorField('');
    debouncedApiCall(field, value, setFieldError, errorField);
  };

  const handleIconFieldChange = (field, value, setLoadingKeys) => {
    setErrorField('');
    callApi(field, value, null, null, setLoadingKeys);
  };

  const togglePDFmodle = () => {
    setOpenFileUploadModal(false);
    setFile("");
  }


  const fetchReporterList = async () => {
    BesturenService.getReporterList(communityId).then((response) => {
      if (response?.data?.status) {
        setReporterData(response?.data.data);
        getCoordinatorList();
      }
    })
  };

  const getCoordinatorList = () => {
    BesturenService.getCoordinatorList(communityId).then((response) => {
      if (response?.data?.status) {
        if (response.data.data !== null && response.data.data !== undefined) {
          setCoordinatorData(response.data.data);
        }
      }
    });
  };

  const addOrDeleteMelderHandler = async (type, iAdminId) => {
    const data = {
      iAdminId,
      iCommunityId: communityId,
      type
    }
    BesturenService.reporterListAddOrRemove(data).then((response) => {
      if (response?.data?.status) {
        fetchReporterList();
      }
    })
  }

  const handleFileSave = () => {
    let formData = new FormData();
    formData.append('iAdminId', bestuurList?.iAdminId)
    formData.append('iCommunityId', communityId)
    formData.append('file', file);
    setisPdfUploading(true);

    BesturenService.saveFile(formData).then((response) => {
      if (response?.data?.status) {
        toast.success('Bestand opgeslagen');
        togglePDFmodle();
        replacementProtocol();
        setisPdfUploading(false);
      } else {
        toast.error('Fout bij het opslaan van het bestand');
      }
    }).catch((error) => toast.error('Fout bij het opslaan van het bestand'));
  }

  const replacementProtocol = () => {
    BesturenService.getReplacementProtocol(communityId).then((response) => {
      if (response?.data?.status) {
        if (response.data.data !== null && response.data.data !== undefined) {
          setReplacementProtocolData(response.data.data);
        }
      }
    });
  }

  return (
    <Fragment>
      <PageHeading orgName={orgName} />
      <TransitionGroup>
        <CSSTransition component="div" classNames="TabsAnimation" appear={true}
          timeout={1500} enter={false} exit={false}>
          <Row className="mb-5">
            <Col>
              {isLoading ?
                <Card className="main-card mb-3">
                  <CardBody className="p-3">
                    <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
                  </CardBody>
                </Card>
                :
                <div className='row contract-typen-view besturen-view  mb-5'>
                  <div className='col-xl-6 p-2'>
                    <div className='pb-3'>
                      <Algemeen data={bestuurList} changeHandler={handleFieldChange} handleIconFieldChange={handleIconFieldChange} errorField={errorField} />
                    </div>
                    <div>
                      <Afzendadressen data={bestuurList} changeHandler={handleFieldChange} errorField={errorField} />
                    </div>
                  </div>
                  <div className='col-xl-6 p-2'>
                    <StandaardInstellingenReiskosten data={bestuurList} changeHandler={handleFieldChange} handleIconFieldChange={handleIconFieldChange} errorField={errorField} />
                  </div>
                  <div className='col-xl-6 p-2'>
                    <Vervangersprotocol changeHandler={handleFieldChange} replacementProtocol={replacementProtocol} replacementProtocolData={replacementProtocolData} setReplacementProtocolData={setReplacementProtocolData} />
                  </div>
                  <div className='col-xl-6 p-2'>
                    <Coordinatoren data={bestuurList} changeHandler={handleFieldChange} errorField={errorField} communityId={communityId} coordinatorData={coordinatorData} getCoordinatorList={getCoordinatorList} addOrDeleteMelderHandler={addOrDeleteMelderHandler} />
                  </div>
                </div>
              }
            </Col>
          </Row>

        </CSSTransition>
      </TransitionGroup>
      <div className="chat-layout-footer verzoken-footer-height">

        {
          bestuurList ? <> <CardFooter className="chat-layout-footer__inner"><div className="d-flex gap-3 mr-4">
            <div>
              <select className={`form-select`} value={communityId} onChange={(e) => { setCommunityId(Number(e.target.value)) }}>
                {communityList?.map(item => {
                  return <option key={item.iCommunityId} value={item.iCommunityId}>{item.name}</option>
                })}
              </select>
            </div>
            <Button
              outline
              className="btn-outline-primary-blue btn-color-style py-2 px-3 d-flex gap-2 align-items-center"
              type="button"
              color="primary"
              onClick={() => {
                setOpenFileUploadModal(true);
              }}
            >
              Protocol <AddIcon />
            </Button>
            <Button
              outline
              className="btn-outline-primary-blue btn-color-style py-2 px-3 d-flex gap-2 align-items-center"
              type="button"
              color="primary"
              onClick={() => {
                setOpenSearchModal({ type: "add", state: true })
              }}
            >
              Coordinator <AddIcon />
            </Button>

            <PDFPopup isOpen={openFileUploadModal} togglePopup={togglePDFmodle} file={file} setFile={setFile} doc={file} handleFileSave={handleFileSave} isPdfUploading={isPdfUploading} classes="document-modal-style modal-centered" />
            {openSearchModal && <ReporterModal setModal={setOpenSearchModal} modal={openSearchModal} addOrDeleteMelderHandler={addOrDeleteMelderHandler} reporterData={reporterData} fetchReporterList={fetchReporterList} />}

          </div></CardFooter></> : null
        }
      </div>
    </Fragment>
  )
}

export default Besturen