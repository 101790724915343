import ReactTableGeneric from 'Components/ReactTableGeneric'
import React, { useMemo } from 'react'
import { Card, CardBody } from 'reactstrap'
import { ReactComponent as PDFIcon } from "../../../../../assets/utils/images/svgs/PDF.svg";
import { ReactComponent as DocumentsIcon } from "../../../../../assets/utils/images/svgs/Documents-Icon.svg";
import { downloadBase64File } from '../utils';
import GebruikerDocumentenService from 'Api/Gebruikers/GebruikersView/Documenten';

const SubstituteTable = ({data, community, currentUser, updateDataHandler}) => {

    const moveDocumentHandler = async (rowData)=>{
        const data = {
            iAdminId: currentUser.data.clooserId,
            iCommunityId: community.iCommunityId,
            iDocId: rowData.ViDocId
        };
        try {
            const response = await GebruikerDocumentenService.moveDocument(data);
            if (response.data.status) {
                updateDataHandler()
            }
        } catch (error) {
            console.error("API error:", error);
        }
    }

    const columns = useMemo(
        () => [
            {
                header: () => 'Document',
                accessorKey: 'Label',
            },
            {
                header: () => 'Uploaddatum',
                accessorKey: 'ViUpload'
            },
            {
                header: () => 'Verloopdatum',
                accessorKey: 'VvVerloopDatum',
                cell: (value) => (
                        value.row.original.VvVerloopDatum || "-"
                )
            },
            {
                header: () => 'Acties',
                accessorKey: 'action',
                cell: (value) => (
                    <div className='d-flex gap-3'>
                        <PDFIcon className='cursor-pointer' onClick={()=>{downloadBase64File(value.row.original.VvDocHref, value.row.original.Label)}}/>
                        <DocumentsIcon className='cursor-pointer'onClick={()=>{moveDocumentHandler(value.row.original)}}/>
                    </div>
                )
            }
        ],
        []
    );

    return (
        <Card className="main-card pb-5">
            <CardBody className="d-flex flex-column">
                <h5 className="mb-4">Toegevoegd door vervanger</h5>
                <ReactTableGeneric
                    data={data}
                    columns={columns}
                    tableClassName="mb-0 table table-borderless table-sm table-hover"
                    filters={{ search: false, pagination: false, sorting: false }}
                />
            </CardBody>
        </Card>
    )
}

export default SubstituteTable;