import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import { Card, CardBody, FormGroup, Input, Label, Spinner } from 'reactstrap'
import { ReactComponent as PenIcon } from "../../../../../assets/utils/images/svgs/editPen.svg";
import 'tippy.js/themes/light.css';
import DropdownList from "react-widgets/DropdownList";
import DatePicker, { registerLocale } from "react-datepicker";
import nl from "date-fns/locale/nl";
import { ReactComponent as CalendarIcon } from "../../../../../assets/utils/images/svgs/calendar-blue.svg"
import moment from "moment";
import GroupStatus from "../../../../../Components/IconDropdown/Cross_Tick/GroupStatus";
registerLocale("nl", nl);

const PlanningsmodusDropdownData = [
    { label: 'Centrale planning', value: '0' },
    { label: 'Schoolplanning', value: "1" },
    { label: 'Keuze in wizard', value: "2" },
    { label: 'Standaard Centrale planning', value: "3" },
    { label: 'Standaard Schoolplanning', value: "4" },
]

const KetenregelingDropdownData = [
    { label: 'Geen uitbreiding (t/m 3)', value: '0' },
    { label: 'Alles uitbreiden (t/m 6)', value: '1' },
    { label: 'Uitbreiding op basis van afwezige', value: '2' },
    { label: 'Uitbreiding op basis vervangingscodes ( 1 / 4 )', value: '3' }
]

const Algemeen = ({ planning, changeHandler, handleIconFieldChange, customContractTypeList, errorField }) => {
    const [activeTextArea, setActiveTextArea] = useState(null);
    const [loadingKeys, setLoadingKeys] = useState({})
    const defaultValue = {
        iPlanOptionVisivle: (planning?.iPlanOptionVisivle === 0 || planning?.iPlanOptionVisivle) ? planning.iPlanOptionVisivle.toString() : '',
        dKetenregeling: planning?.dKetenregeling || '',
        eKeten3to6: (planning?.eKeten3to6 === 0 || planning?.eKeten3to6) ? planning.eKeten3to6.toString() : '',
        iDefaultContractTypeId: planning?.iDefaultContractTypeId || '',
        eShowInformationForPlanning: planning?.eShowInformationForPlanning || '',
        close_substitutions_after_days: planning?.close_substitutions_after_days || '',
        eEvalutionNotify: planning?.eEvalutionNotify || '',
        tbv_information: planning?.tbv_information || '',
        tbv_information_schoolplannig: planning?.tbv_information_schoolplannig || '',
        iPrivacyDays: planning?.iPrivacyDays || ''
    }
    const [iPrivacyDays, setiPrivacyDays] = useState()
    const [close_substitutions_after_days, setClose_substitutions_after_days] = useState();
    const datePickerRefs = useRef([]);

    useEffect(() => {
        setiPrivacyDays((planning?.iPrivacyDays === 0 || planning?.iPrivacyDays === null) ? true : false);
        setClose_substitutions_after_days((planning?.close_substitutions_after_days === 0 || planning?.close_substitutions_after_days === null) ? true : false);
    }, [planning?.iPrivacyDays, planning?.close_substitutions_after_days])

    const handleEnableTextArea = (fieldName) => {
        setActiveTextArea(prev => (prev === fieldName ? null : fieldName));
    };

    const handleToggle = (field, data) => {
        setLoadingKeys(prev => ({ ...prev, [field]: true }));
        handleIconFieldChange(field, data, setLoadingKeys)
    };

    return (
        <>
            <Card className="main-card">
                <CardBody className="d-flex flex-column">
                    <div className="btn-color-style travelCost-blue-text mb-4">Algemeen</div>

                    <Formik
                        enableReinitialize
                        initialValues={defaultValue}
                        validateOnChange={true}
                    >
                        {({ values, errors, setFieldValue, handleChange, setFieldError, onBlur }) => (
                            defaultValue &&
                            <Form>
                                <FormGroup className={`form-element`}>
                                    <Label for="iPlanOptionVisivle">Planningsmodus</Label>
                                    <div className="position-relative col-4">
                                        <div className="d-flex gap-1 align-items-center">
                                            <DropdownList dataKey='value' textField='label'
                                                placeholder="Selecteer"
                                                onChange={(e) => {
                                                    changeHandler("iPlanOptionVisivle", e.value)
                                                }}
                                                name="iPlanOptionVisivle"
                                                value={values.iPlanOptionVisivle}
                                                data={PlanningsmodusDropdownData}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>

                                <FormGroup className={`form-element`}>
                                    <Label for="dKetenregeling">Startdatum ketenregeling</Label>
                                    <div className="position-relative col-4">
                                        <div className="customDatePicker">
                                            <DatePicker
                                                ref={(el) => datePickerRefs.current[0] = el}
                                                selected={values.dKetenregeling ? moment(values.dKetenregeling, 'DD-MM-YYYY').toDate() : null}
                                                onChange={(date) => {
                                                    if (date) {
                                                        const formattedDate = moment(date).format('DD-MM-YYYY');
                                                        changeHandler('dKetenregeling', formattedDate);
                                                    } else {
                                                        changeHandler('dKetenregeling', '');
                                                    }
                                                }}
                                                placeholderText={'dd-mm-jjjj'}
                                                dateFormat="dd-MM-yyyy"
                                                className="form-control w-100"
                                                locale="nl"
                                            />
                                            <CalendarIcon onClick={() => datePickerRefs.current[0]?.setFocus()} className="svg-pointer" />
                                        </div>
                                        <ErrorMessage name="begindatum" component="small" className="block mt-2 invalid-feedback" />
                                    </div>
                                    <ErrorMessage name="gelding" component="small"
                                        className="block mt-2 invalid-feedback" />

                                </FormGroup>

                                <FormGroup className={`form-element`}>
                                    <Label for="eKeten3to6">Ketenregeling maximaal aantal</Label>
                                    <div className="position-relative col-4">
                                        <div className="d-flex gap-1 align-items-center">
                                            <DropdownList dataKey='value' textField='label'
                                                placeholder="Selecteer"
                                                onChange={(e) => {
                                                    changeHandler("eKeten3to6", (e.value).toString())
                                                }}
                                                name="eKeten3to6"
                                                data={KetenregelingDropdownData}
                                                value={values.eKeten3to6}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>

                                <FormGroup className={`form-element ${errorField === 'iPrivacyDays' ? 'error' : ''}`}>
                                    <Label for="iPrivacyDays">Dagen documenten verwijderen</Label>
                                    <div className="position-relative col-4">
                                        <div className="d-flex gap-1 align-items-center ">
                                            <Input
                                                name="iPrivacyDays"
                                                type="number"
                                                value={values.iPrivacyDays}
                                                onChange={(e) => {
                                                    let value = parseInt(e.target.value, 10);
                                                    handleChange(e)
                                                    changeHandler("iPrivacyDays", value, setFieldError);
                                                }}
                                                className="col-4"
                                                readOnly={iPrivacyDays ? true : false}
                                                onClick={() => setiPrivacyDays(false)}
                                                onFocus={() => setiPrivacyDays(false)}
                                                onBlur={() => {
                                                    if (!values.iPrivacyDays || values.iPrivacyDays === 0 || values.iPrivacyDays === null) {
                                                        setiPrivacyDays(true);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>

                                <FormGroup className={`form-element`}>
                                    <Label for="iDefaultContractTypeId">Standaard contracttype</Label>
                                    <div className="position-relative col-4">
                                        <div className="d-flex gap-1 align-items-center">
                                            <DropdownList dataKey='iContractTypeId' textField='label'
                                                placeholder="Selecteer"
                                                onChange={(e) => {
                                                    changeHandler("iDefaultContractTypeId", e.iContractTypeId)
                                                }}
                                                name="iDefaultContractTypeId"
                                                data={customContractTypeList}
                                                value={values.iDefaultContractTypeId}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>

                                <FormGroup className={`form-element `}>
                                    <Label for="eShowInformationForPlanning">Bestuursinfo tonen bij plannen</Label>
                                    <div className="position-relative col-4">
                                        <div className="d-flex gap-1 align-items-center ">
                                            {loadingKeys["eShowInformationForPlanning"] ? (
                                                <div className="spinner-icon-change">
                                                    <div><Spinner size="sm" color="primary" /></div>
                                                </div>
                                            ) :
                                                <GroupStatus iconChangeHandler={handleToggle} field={"eShowInformationForPlanning"} data={values?.eShowInformationForPlanning ? 1 : 0} />
                                            }
                                        </div>
                                    </div>
                                </FormGroup>

                                <FormGroup className={`form-element ${errorField === 'close_substitutions_after_days' ? 'error' : ''}`}>
                                    <Label for="close_substitutions_after_days">Verzoeken afsluiten (dagen na laatste dagdeel) </Label>
                                    <div className="position-relative col-4">
                                        <div className="d-flex gap-1 align-items-center">
                                            <Input
                                                name="close_substitutions_after_days"
                                                type="number"
                                                value={values.close_substitutions_after_days}
                                                onChange={(e) => {
                                                    let value = parseInt(e.target.value, 10);
                                                    handleChange(e)
                                                    changeHandler("close_substitutions_after_days", value, setFieldError);
                                                }}
                                                className="col-4"
                                                min={0}
                                                max={365}
                                                onClick={() => setClose_substitutions_after_days(false)}
                                                readOnly={close_substitutions_after_days ? true : false}
                                                onFocus={() => setClose_substitutions_after_days(false)}
                                                onBlur={() => {
                                                    if (!values.close_substitutions_after_days || values.close_substitutions_after_days === 0 || values.close_substitutions_after_days === null) {
                                                        setClose_substitutions_after_days(true);
                                                    }
                                                }}
                                            />
                                        </div>

                                    </div>
                                </FormGroup>

                                <FormGroup className={`form-element `}>
                                    <Label for="eEvalutionNotify">Evaluatieherinnering</Label>
                                    <div className="position-relative col-4">
                                        <div className="d-flex gap-1 align-items-center ">

                                            {loadingKeys["eEvalutionNotify"] ? (
                                                <div className="spinner-icon-change">
                                                    <div><Spinner size="sm" color="primary" /></div>
                                                </div>
                                            ) :
                                                <GroupStatus iconChangeHandler={handleToggle} field={"eEvalutionNotify"} data={values?.eEvalutionNotify ? 1 : 0} />
                                            }

                                        </div>
                                    </div>
                                </FormGroup>

                                <FormGroup className={`d-flex justify-content-between pt-2 `}>
                                    <Label for="tbv_information">Instructie Centrale planning</Label>
                                    <div className="position-relative col-4">
                                        <div className="d-flex justify-content-end mb-2">
                                            <PenIcon className="svg-pointer" onClick={() => handleEnableTextArea("tbv_information")} />
                                        </div>
                                        <div className="d-flex gap-1 align-items-center">
                                            <Input
                                                name="tbv_information"
                                                type="textarea"
                                                value={values.tbv_information}
                                                rows={6}
                                                disabled={activeTextArea !== "tbv_information"}
                                                onChange={(e) => {
                                                    setFieldValue("tbv_information", e.target.value);
                                                    changeHandler("tbv_information", e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>

                                <FormGroup className={`d-flex justify-content-between `}>
                                    <Label for="tbv_information_schoolplannig">Instructie Schoolplanning</Label>
                                    <div className="position-relative col-4">
                                        <div className="d-flex justify-content-end mb-2">
                                            <PenIcon className="svg-pointer" onClick={() => handleEnableTextArea("tbv_information_schoolplannig")} />
                                        </div>
                                        <div className="d-flex gap-1 align-items-center">
                                            <Input
                                                name="tbv_information_schoolplannig"
                                                type="textarea"
                                                value={values.tbv_information_schoolplannig}
                                                rows={6}
                                                disabled={activeTextArea !== "tbv_information_schoolplannig"}
                                                onChange={(e) => {
                                                    setFieldValue("tbv_information_schoolplannig", e.target.value);
                                                    changeHandler("tbv_information_schoolplannig", e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>
                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card>
        </>
    )
}

export default Algemeen