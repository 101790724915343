import React, { Fragment } from "react";
import AppBreadcrumb from "Layout/AppBreadcrumb";
const PageHeading = ({children, additionalBreadcrumbs, useDefaultPath, data={}, orgName="", schoolName=""}) => {
    return (
        <div className="d-flex custom-filter-Main align-items-center mb-2">
            <AppBreadcrumb
              additionalBreadcrumbs={additionalBreadcrumbs}
              useDefaultPath={useDefaultPath}
              data={data}
              orgName={orgName}
              schoolName={schoolName}
            />
            {children}
        </div>

    );

}

export default PageHeading;
