import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "tippy.js/dist/tippy.css";
import axiosInstance from "Api";
import { BsCheckCircle } from "react-icons/bs";
import { ReactComponent as CrossIcon } from "../../../assets/utils/images/svgs/Cross.svg";
import { ReactComponent as PenIcon } from "../../../assets/utils/images/svgs/editPen.svg";
import { ErrorMessage, Formik } from "formik";
import {
    Button, Modal, ModalBody, ModalFooter, ModalHeader,
    FormGroup, Form, Label,
    Input
} from "reactstrap";
import * as Yup from "yup";
import { toast } from "react-toastify";
import ReactTableGenericForUsers from "Components/ReactTableGenericForUsers";

const validationSchema = Yup.object().shape({
    vGroup: Yup.string().required("Veld mag niet leeg zijn"),
});


const FunctiesTable = ({ toggle, openModal, editFunctie, setEditFunctie }) => {
    const [data, setData] = useState([]);
    const [pageSizeCount, setPageSizeCount] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [disableModalSubmitButton, setDisableModalSubmitButton] = useState(true);

    const fetchData = () => {
        !editFunctie && setShowLoader(true);
        axiosInstance
            .get(
                "functie?pageSize=" +
                pageSizeCount +
                "&page=" +
                pageNumber +
                "&search=" +
                searchValue
            )
            .then((response) => {
                if (response?.data?.status) {
                    setShowLoader(false);
                    setData(response.data.data.functieLists);
                    setTotalPageCount(response.data.data.paginate);
                }
            })
            .catch((e) => console.error(e));
    };

    const paginate = (val) => {
        setPageNumber(val);
    };

    useEffect(() => {
        fetchData();
    }, [searchValue, pageSizeCount, pageNumber]);

    useEffect(() => {
        if (!openModal) {
            setEditFunctie(null);
            setDisableModalSubmitButton(true);
        }
    }, [openModal]);

    const handleInputChange = (val) => {
        setPageNumber(1);
        setSearchValue(val);
    };

    const filterList = (val) => {
        handleInputChange(val);
    };

    const indexPageSize = (val) => {
        setPageSizeCount(val);
    };

    const functieTablePageSize = (val) => {
        setPageNumber(1);
        indexPageSize(val);
    };

    const userTablePageSize = (val) => {
        setPageNumber(1);
        indexPageSize(val);
    }

    const columns = [
        {
            header: "Functie",
            accessorKey: "vGroup",
        },
        {
            header: "Opvragen vervanger",
            accessorKey: "iSubstShowable",
            cell: ({ getValue }) => (
                <div className="d-flex align-items-center justify-content-center">
                    {getValue() ? (
                        <BsCheckCircle color="#39A825" fontSize="18px" className="weekstaten-check-icon" />
                    ) : (
                        <CrossIcon />
                    )}
                </div>
            )
        },
        {
            header: "Status",
            accessorKey: "eStatus",
            cell: ({ getValue }) => (
                <div className="d-flex align-items-center justify-content-center">
                    {getValue() === "Active" ? (
                        <BsCheckCircle color="#39A825" fontSize="18px" className="weekstaten-check-icon" />
                    ) : (
                        <CrossIcon />
                    )}
                </div>
            )
        },
        {
            header: "Wijzigen",
            accessorKey: "iSubGroupId",
            cell: (value) => (
                <div className="d-flex align-items-center svg-pointer justify-content-center" onClick={() => {
                    toggle();
                    setEditFunctie({
                        value: value.cell.row.original,
                        type: "update"
                    })
                }
                } >
                    <PenIcon />
                </div >
            )
        }
    ];

    const formSubmitHandler = (values) => {
        if (values.vGroup === editFunctie?.value?.vGroup && values.iSubstShowable === editFunctie?.value?.iSubstShowable) {
            return toast.error("Functie bestaat al");
        }
        let data;
        if (editFunctie?.type === "save") {
            data = {
                vGroup: values.vGroup,
                iSubstShowable: values.iSubstShowable,
                eStatus: "Active"
            }
        } else {
            data = {
                iSubGroupId: editFunctie?.value?.iSubGroupId,
                vGroup: values.vGroup,
                iSubstShowable: values.iSubstShowable,
                eStatus: "Active"
            }
        }
        axiosInstance[
            editFunctie?.type === "save" ? "post" : "put"
        ](
            `functie/${editFunctie?.type === "save" ? "save" : "update"}`,
            data
        )
            .then((response) => {
                if (response?.data?.status) {
                    toggle();
                    fetchData();
                    if (editFunctie?.type === "save") {
                        toast.success("Opgeslagen");
                    } else {
                        toast.success("Bijgewerkt");
                    }
                } else {
                    if (editFunctie?.type === "save") {
                        toast.error("Kan niet opslaan.")
                    } else {
                        toast.error("Kan niet updaten.")
                    }
                }
            })
            .catch((e) => {
                console.error(e)
                toast.error("Er is iets misgegaan")
            });
    };

    const formDataState = {
        vGroup: editFunctie?.value?.vGroup || "",
        iSubstShowable: editFunctie?.value?.iSubstShowable || 0,
    };

    return (
        <>
            <div className="functiesTable">
                <ReactTableGenericForUsers
                    data={data || []}
                    columns={columns}
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                    totalPageCount={totalPageCount}
                    paginate={paginate}
                    filterList={filterList}
                    functieTablePageSize={functieTablePageSize}
                    pageSizeCount={pageSizeCount}
                    showLoader={showLoader}
                    tableClassName="mb-0 table table-borderless table-sm table-hover table-heading-style"
                    userTablePageSize={userTablePageSize}
                />
            </div>

            <Modal isOpen={openModal} toggle={toggle} className="feedback-modal-style overig-exporteren-modal-style modal-centered link-form">
                <ModalHeader toggle={toggle}>
                    Functie toevoegen / wijzigen
                </ModalHeader>
                <ModalBody>
                    <Formik
                        enableReinitialize
                        initialValues={formDataState}
                        validationSchema={validationSchema}
                        onSubmit={formSubmitHandler}
                    >
                        {({ values, handleChange, setFieldValue, handleSubmit }) => (
                            <Form onSubmit={handleSubmit}>
                                <FormGroup className={`form-element`}>
                                    <Label for="vGroup">Functie</Label>
                                    <Input
                                        name="vGroup"
                                        type="text"
                                        value={values.vGroup}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setDisableModalSubmitButton(false)
                                        }}
                                    />
                                    <ErrorMessage name="vGroup" component="small" className="block mt-2 invalid-feedback" />
                                </FormGroup>

                                <FormGroup className="row justify-content-between">
                                    <Label for="iSubstShowable" style={{ width: 'fit-content' }}>Functie vervanger opvragen</Label>
                                    <div style={{ width: 'fit-content' }}>
                                        {values.iSubstShowable ?
                                            <BsCheckCircle color="#39A825" fontSize="18px" className="weekstaten-check-icon svg-pointer" onClick={() => {
                                                setFieldValue("iSubstShowable", 0)
                                                setDisableModalSubmitButton(false)
                                            }} />
                                            :
                                            <CrossIcon onClick={() => {
                                                setFieldValue("iSubstShowable", 1)
                                                setDisableModalSubmitButton(false)
                                            }} className="svg-pointer" />}
                                    </div>
                                </FormGroup>

                                <ModalFooter className="d-flex align-items-center justify-content-center border-bottom-0 py-4 mt-4">
                                    <Button className="btn-color-style opslaan-btn" size="sm" outline color="primary" type="submit" disabled={disableModalSubmitButton}>
                                        Toepassen
                                    </Button>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>
        </>
    );
};

export default FunctiesTable;