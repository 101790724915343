import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import { Card, CardBody, FormGroup, Label, Spinner } from 'reactstrap'
import { BsCheckCircle } from "react-icons/bs";
import { ReactComponent as CrossIcon } from "../../../../../assets/utils/images/svgs/Cross.svg";
import DropdownList from "react-widgets/DropdownList";
import DatePicker, { registerLocale } from "react-datepicker";
import nl from "date-fns/locale/nl";
import { ReactComponent as CalendarIcon } from "../../../../../assets/utils/images/svgs/calendar-blue.svg"
import { ReactComponent as InfoIcon } from "../../../../../assets/utils/images/svgs/InformationBlue.svg";
import PlanningService from 'Api/Instellingen/Planning';
import moment from "moment";
import Tippy from "@tippyjs/react";
import GroupStatus from "../../../../../Components/IconDropdown/Cross_Tick/GroupStatus";
registerLocale("nl", nl);

const Herinneringsmail = [
    { label: 'Kies de periode...', value: 0 },
    { label: '1x per week', value: 1 },
    { label: '1x per 2 weken', value: 2 },
    { label: '1x per 4 weken', value: 4 }
]

const Verzenddag = [
    { label: 'Kies de verzenddag...', value: 0 },
    { label: 'maandag', value: 1 },
    { label: 'dinsdag', value: 2 },
    { label: 'woensdag', value: 3 },
    { label: 'donderdag', value: 4 },
    { label: 'vrijdag', value: 5 },
]


const Terugkoppeling = ({ planning, customContractTypeList, changeHandler, handleIconFieldChange, communityId, fetchPlanning }) => {
    const [loadingKeys, setLoadingKeys] = useState({});
    const [contractTypeId, setContractTypeId] = useState();
    const [gebruikerData, setGebruikerData] = useState([]);
    const [isStatusFalse, setStatusIsFalse] = useState(false);
    const datePickerRefs = useRef([]);

    useEffect(() => {
        if (planning?.iDefaultContractTypeId !== undefined) {
            setContractTypeId(planning.iNoOneAvailableContractType);
        }
    }, [planning?.iDefaultContractTypeId])

    useEffect(() => {
        if (contractTypeId && contractTypeId !== -1) {
            getUser();
        }
    }, [contractTypeId])

    const getUser = () => {
        const data = {
            iContractId: contractTypeId,
            iCommunityId: communityId
        }
        PlanningService.getUsers(data).then(response => {
            if (response?.data?.status === true) {
                setGebruikerData(response.data.data);
            } else {
                setGebruikerData([]);
            }
        })
    }

    const contractDropDownData = [
        ...([{ label: 'Selecteer', iContractTypeId: -1 }]),
        ...customContractTypeList
    ];
    const GebruikerDropDownData = [
        ...([{ name: 'Kies een contracttype hierboven...', iAdminId: -1 }]),
        ...gebruikerData
    ];


    const defaultValue = {
        iDefaultContractTypeId: planning?.iDefaultContractTypeId,
        iNoOneAvailableContractTypeAdmins: planning?.iNoOneAvailableContractTypeAdmins,
        iNoOneAvailableActive: planning?.iNoOneAvailableActive,
        iNoOneAvailableDay: planning?.iNoOneAvailableDay,
        iNoOneAvailableContractType: planning?.iNoOneAvailableContractType,
        dNoOneAvailableStartDate: planning?.dNoOneAvailableStartDate,
        iNoOneAvailablePeriode: planning?.iNoOneAvailablePeriode,
    }

    const handleToggle = (field, data) => {
        setLoadingKeys(prev => ({ ...prev, [field]: true }));
        handleIconFieldChange(field, data, setLoadingKeys)
    };

    isStatusFalse && fetchPlanning(setStatusIsFalse);

    return (
        <>
            <Card className="main-card">
                <CardBody className="d-flex flex-column">
                    <div className="d-flex align-items-center mb-4" style={{ gap: '20px' }}>
                        <div className="btn-color-style travelCost-blue-text ">
                            Terugkoppeling Niemand beschikbaar
                        </div>
                        <Tippy content={"Will follow"} placement="right" >
                            <InfoIcon className="mt-1 svg-pointer" />
                        </Tippy>
                    </div>


                    <Formik
                        enableReinitialize
                        initialValues={defaultValue}
                        validateOnChange={true}
                    >
                        {({ values, touched, errors, handleChange, setFieldError, setFieldValue }) => (
                            <Form>
                                <FormGroup className="form-element">
                                    <Label for="iNoOneAvailableActive">Status</Label>
                                    <div className="position-relative col-4">
                                        <div className="d-flex gap-1 align-items-center ">
                                            {loadingKeys["iNoOneAvailableActive"] ? (
                                                <div className="spinner-icon-change">
                                                    <div><Spinner size="sm" color="primary" /></div>
                                                </div>
                                            ) :
                                                <GroupStatus iconChangeHandler={handleToggle} field={"iNoOneAvailableActive"} data={values?.iNoOneAvailableActive ? 1 : 0} />
                                            }
                                        </div>
                                    </div>
                                </FormGroup>

                                <FormGroup className="form-element">
                                    <Label for="iNoOneAvailableContractType">Contracttype</Label>
                                    <div className="position-relative col-4">
                                        <div className="d-flex gap-1 align-items-center">
                                            <DropdownList dataKey='iContractTypeId' textField='label'
                                                placeholder="Selecteer"
                                                onChange={(e) => {
                                                    changeHandler("iNoOneAvailableContractType", e.iContractTypeId)
                                                    setContractTypeId(e.iContractTypeId);
                                                }}
                                                name="Contracttype"
                                                data={contractDropDownData}
                                                value={values.iNoOneAvailableContractType}
                                                disabled={values?.iNoOneAvailableActive === 0 ? true : false}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>

                                <FormGroup className="form-element">
                                    <Label for="iNoOneAvailableContractTypeAdmins">Gebruiker</Label>
                                    <div className="position-relative col-4">
                                        <div className="d-flex gap-1 align-items-center">
                                            <DropdownList dataKey='iAdminId' textField='name'
                                                placeholder="Selecteer"
                                                onChange={(e) => {
                                                    changeHandler("iNoOneAvailableContractTypeAdmins", e.iAdminId)
                                                }}
                                                name="iNoOneAvailableContractTypeAdmins"
                                                value={values.iNoOneAvailableContractTypeAdmins}
                                                data={GebruikerDropDownData}
                                                disabled={values?.iNoOneAvailableActive === 0 ? true : false}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>

                                <FormGroup className={`form-element ${(errors.dNoOneAvailableStartDate) ? "error" : ""}`}>
                                    <Label for="dNoOneAvailableStartDate">Begindatum</Label>
                                    <div className="position-relative col-4">
                                        <div className="customDatePicker">
                                            <DatePicker
                                                ref={(el) => datePickerRefs.current[0] = el}
                                                selected={values.dNoOneAvailableStartDate ? moment(values.dNoOneAvailableStartDate, 'DD-MM-YYYY').toDate() : null}
                                                onChange={(date) => {
                                                    if (date) {
                                                        const formattedDate = moment(date).format('DD-MM-YYYY');
                                                        changeHandler('dNoOneAvailableStartDate', formattedDate);
                                                    } else {
                                                        changeHandler('dNoOneAvailableStartDate', '');
                                                    }
                                                }}
                                                placeholderText={'dd-mm-jjjj'}
                                                dateFormat="dd-MM-yyyy"
                                                className="form-control w-100"
                                                disabled={values?.iNoOneAvailableActive === 0 ? true : false}
                                            />
                                            <CalendarIcon onClick={() => datePickerRefs.current[0]?.setFocus()} className={` ${values?.iNoOneAvailableActive === 0 ? '' : 'svg-pointer'}`} />
                                        </div>
                                        <ErrorMessage name="begindatum" component="small" className="block mt-2 invalid-feedback" />
                                    </div>

                                </FormGroup>

                                <FormGroup className="form-element">
                                    <Label for="iNoOneAvailablePeriode">Herinneringsmail</Label>
                                    <div className="position-relative col-4">
                                        <div className="d-flex gap-1 align-items-center">
                                            <DropdownList dataKey='value' textField='label'
                                                placeholder="Selecteer"
                                                onChange={(e) => {
                                                    changeHandler("iNoOneAvailablePeriode", e.value)
                                                }}
                                                name="iNoOneAvailablePeriode"
                                                value={values.iNoOneAvailablePeriode}
                                                data={Herinneringsmail}
                                                disabled={values?.iNoOneAvailableActive === 0 ? true : false}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>

                                <FormGroup className="form-element">
                                    <Label for="iNoOneAvailableDay">Verzenddag</Label>
                                    <div className="position-relative col-4">
                                        <div className="d-flex gap-1 align-items-center">
                                            <DropdownList dataKey='value' textField='label'
                                                placeholder="Selecteer"
                                                onChange={(e) => {
                                                    changeHandler("iNoOneAvailableDay", e.value)
                                                }}
                                                name="iNoOneAvailableDay"
                                                value={values.iNoOneAvailableDay}
                                                data={Verzenddag}
                                                disabled={values?.iNoOneAvailableActive === 0 ? true : false}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>
                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card>
        </>
    )
}

export default Terugkoppeling;