import React from "react";
import { Form, Formik } from "formik";
import { Card, CardBody, FormGroup, Input, Label } from 'reactstrap'
import 'tippy.js/themes/light.css';

const Afzendadressen = ({ data, changeHandler, errorField }) => {

    const defaultValue = {
        vEmailMelderVA: data?.vEmailMelderVA || '',
        vEmailReiskostenVA: data?.vEmailReiskostenVA || '',
        vEmailWeekstaatVA: data?.vEmailWeekstaatVA || ''
    }

    return (
        <>
            <Card className="main-card">
                <CardBody className="d-flex flex-column">
                    <div className="btn-color-style travelCost-blue-text mb-4">Afzendadressen</div>

                    <Formik
                        enableReinitialize
                        initialValues={defaultValue}
                        validateOnChange={true}
                    >
                        {({ values, errors, handleChange, setFieldError }) => (
                            <Form>
                                <FormGroup className={`form-element ${(errors.vEmailMelderVA) ? 'error' : ''}`}>
                                    <Label for="vEmailMelderVA">Melder/vervanger informeren</Label>
                                    <div className="w-100">
                                        <Input
                                            name="vEmailMelderVA"
                                            type="email"
                                            value={values.vEmailMelderVA}
                                            placeholder="E-mail"
                                            onChange={(e) => {
                                                handleChange(e);
                                                changeHandler("vEmailMelderVA", e.target.value, setFieldError, "Email");
                                            }}
                                            className="w-100"
                                        />
                                    </div>
                                </FormGroup>

                                <FormGroup className={`form-element ${(errors.vEmailReiskostenVA) ? "error" : ""}`}>
                                    <Label for="vEmailReiskostenVA">Weekstaat</Label>
                                    <div className="w-100">
                                        <Input
                                            name="vEmailReiskostenVA"
                                            type="email"
                                            value={values.vEmailReiskostenVA}
                                            placeholder="E-mail"
                                            onChange={(e) => {
                                                handleChange(e);
                                                changeHandler("vEmailReiskostenVA", e.target.value, setFieldError, "Email")
                                            }}
                                        />
                                    </div>
                                </FormGroup>

                                <FormGroup className={`form-element ${(errors.vEmailWeekstaatVA) ? "error" : ""}`}>
                                    <Label for="vEmailWeekstaatVA">Reiskosten</Label>
                                    <div className="w-100">
                                        <Input
                                            name="vEmailWeekstaatVA"
                                            type="email"
                                            value={values.vEmailWeekstaatVA}
                                            placeholder="E-mail"
                                            onChange={(e) => {
                                                handleChange(e);
                                                changeHandler("vEmailWeekstaatVA", e.target.value, setFieldError, "Email")
                                            }}
                                        />
                                    </div>
                                </FormGroup>
                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card>
        </>
    )
}

export default Afzendadressen