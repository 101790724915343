import axiosInstance from 'Api';

const getUserSmsInfo = (data) => {
    return axiosInstance.post('beheer/users-sms-info', data)
};

const getSmsHistory = (id) => {
    return axiosInstance.get(`beheer/sent-sms-to-users?iCommunityId=${id}`);
};

const smsSave = (data) => {
    return axiosInstance.post('beheer/sms/save', data);
};

const BeheerSmsService = {
    getUserSmsInfo,
    getSmsHistory,
    smsSave
};
export default BeheerSmsService;