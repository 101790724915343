import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardFooter } from 'reactstrap';
import Loader from 'react-loaders';
import AlgemeenForm from './AlgemeenForm';
import VerlofVerzuimForm from './VerlofVerzuim';
import GegevensImporteren from './GegevensImporteren';
import DocumentenSynchroniseren from './DocumentenSynchroniseren';
import BestuurVerwijderen from './BestuurVerwijderen';
import LangeVerzoeken from './LangeVerzoeken';

const Algemeen = ({ isLoading, data, setOpenModal, setModalData, handleFieldChange, isdataDelete, setDataDelete }) => {

    return (
        <>
            {
                isLoading ?
                    <Card className="main-card">
                        <CardBody className="d-flex flex-column">
                            <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
                        </CardBody>
                    </Card> :
                    <>
                        <div className='row'>
                            <div className='row algemeen'>
                                <div className='col-xl-6 mb-4'>
                                    <AlgemeenForm data={data} handleFieldChange={handleFieldChange} />
                                </div>
                                <div className='col-xl-6 mb-4'>
                                    <DocumentenSynchroniseren data={data} handleFieldChange={handleFieldChange} />
                                </div>
                            </div>

                            <div className='row algemeen '>
                                <div className='col-xl-6 mb-4'>
                                    <VerlofVerzuimForm data={data} handleFieldChange={handleFieldChange} />
                                </div>
                                <div className='col-xl-6 mb-4'>
                                    <BestuurVerwijderen setOpenModal={setOpenModal} setModalData={setModalData} setDataDelete={setDataDelete} isdataDelete={isdataDelete} />
                                </div>
                            </div>

                            <div className='row algemeen'>
                                <div className='col-xl-6 mb-4'>
                                    <GegevensImporteren setOpenModal={setOpenModal} setModalData={setModalData} />
                                </div>
                                <div className='col-xl-6 mb-4'>
                                    <LangeVerzoeken data={data} handleFieldChange={handleFieldChange} />
                                </div>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default Algemeen