import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { Button, Card, CardBody, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import DatePicker from "react-datepicker";
import { ReactComponent as LockIcon } from "../../../../../assets/utils/images/svgs/Locked.svg";
import { ReactComponent as UnLockIcon } from "../../../../../assets/utils/images/svgs/unlock.svg";
import { ReactComponent as InfoIcon } from "../../../../../assets/utils/images/svgs/InformationBlue.svg";
import moment from "moment";
import ReactTableGeneric from "Components/ReactTableGeneric";
import Loader from "react-loaders";
import { BsCheckCircle } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import { ReactComponent as CrossIcon } from "../../../../../assets/utils/images/svgs/Cross.svg";
import { ReactComponent as CalendarIcon } from "../../../../../assets/utils/images/svgs/calendar-blue.svg"
import GebruikerInstellingenService from "Api/Gebruikers/GebruikersView/Instellingen";
import Tippy from "@tippyjs/react";

const Arrangement = ({data, changeHandler, errorField, iAdminId, currentUser, iCommunityId, updateDataHandler }) => {
    const [isModal, setModal] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showHistory, setShowHistory] = useState(false);

    const defaultValues = {
        iKetenregeling: data?.iKetenregeling,
        d36mnd: data?.d36mnd && data?.d36mnd !== "Invalid Da" ? moment(data?.d36mnd, 'DD-MM-YYYY').toDate() : null,
        dblokkeren: data?.dblokkeren && data?.dblokkeren !== "Invalid Da" ? moment(data?.dblokkeren, 'DD-MM-YYYY').toDate() : null,
        dblokkerenend: data?.dblokkerenend && data?.dblokkerenend !== "Invalid Da" ? moment(data?.dblokkerenend, 'DD-MM-YYYY').toDate() : null,
        dKetenregeling: data?.dKetenregeling && data?.dKetenregeling !== "Invalid Da" ? moment(data?.dKetenregeling, 'DD-MM-YYYY').toDate() : null,
    };

    const defaultValuesForInfo = {
        dblokkeren: data?.dblokkeren && data?.dblokkeren !== "Invalid Da" ? moment(data?.dblokkeren, 'DD-MM-YYYY').toDate() : null,
        dblokkerenend: data?.dblokkerenend && data?.dblokkerenend !== "Invalid Da" ? moment(data?.dblokkerenend, 'DD-MM-YYYY').toDate() : null,
        dKetenregeling: data?.dKetenregeling && data?.dKetenregeling !== "Invalid Da" ? moment(data?.dKetenregeling, 'DD-MM-YYYY').toDate() : null,
        dKetenregelingBestur: data?.dKetenregelingBestur && data?.dKetenregelingBestur !== "Invalid Da" ? moment(data?.dKetenregelingBestur, 'DD-MM-YYYY').toDate() : null,
        d36mnd: data?.d36mnd && data?.d36mnd !== "Invalid Da" ? moment(data?.d36mnd, 'DD-MM-YYYY').toDate() : null,
        iBreakDate: data?.iBreakDate && data?.iBreakDate !== "Geen advies" ? moment(data?.iBreakDate, 'DD-MM-YYYY').toDate() : null,
        iBreakDate2: data?.iBreakDate2 && data?.iBreakDate2 !== "Geen advies" ? moment(data?.iBreakDate2, 'DD-MM-YYYY').toDate() : null,
    };

    useEffect(() => {
        if (isModal) {
            setIsLoading(true);
            fetchInstellingenData();
        }
    }, [showHistory, isModal])

    const fetchInstellingenData = async () => {
        const data = {
            iAdminId,
            iCommunityId
        }
        try {
            const response = await GebruikerInstellingenService.getInstellingenInfo(data);
            if (response.data.status && response.data.data) {
                const verzoekenList = response.data.data.verzoekenList ?? [];
                const contracten = response.data.data.contracten ?? [];
                let updatedVerzoekenList;
                if (showHistory) {
                    updatedVerzoekenList = verzoekenList;
                } else {
                    updatedVerzoekenList = verzoekenList.filter(item =>
                        !(item.wwz === 1 || (data?.iBreakDateTimeStamp > item?.iTimestamp)))
                };

                const updatedTableData = [
                    ...updatedVerzoekenList,
                    ...contracten
                ];

                setTableData(updatedTableData);
            }
        } catch (error) {
            console.error(`API error:`, error);
        } finally {
            setIsLoading(false);
        }
    };

    const saveInstellingenData = async (values) => {
        const data = {
            iAdminId,
            iCommunityId,
            dKetenregeling: values.dKetenregeling ? moment(values.dKetenregeling).format('MM/DD/YYYY') : null,
            d36mnd: values.d36mnd ? moment(values.d36mnd).format('MM/DD/YYYY') : null,
            dblokkeren: values.dblokkeren ? moment(values.dblokkeren).format('MM/DD/YYYY') : null,
            dblokkerenend: values.dblokkerenend ? moment(values.dblokkerenend).format('MM/DD/YYYY') : null,
        };
        try {
            const response = await GebruikerInstellingenService.saveInstellingenInfo(data);
            if (response.data.status) {
                updateDataHandler();
                setModal(false);
            }
        } catch (error) {
            console.error('API error:', error);
        }
    };

    const columns = React.useMemo(
        () => [
            {
                header: () => 'Verzoek-ID',
                accessorKey: 'omschrijving'
            },
            {
                header: () => 'Code',
                accessorKey: 'vCode'
            },
            {
                header: () => 'Begin datum',
                accessorKey: 'minDate',
            },
            {
                header: () => 'Eind datum',
                accessorKey: 'maxDate',
            },
            {
                header: () => <div className="text-center">wwz</div>,
                accessorKey: 'wwz',
                cell: (value) => {
                    return <div className="d-flex justify-content-center">
                        {
                            value.row.original.wwz === 1 ?
                                <BsCheckCircle color='#39A825' fontSize={"18px"} /> :
                                <CrossIcon color='#FB2E5F'  />
                        }
                    </div>
                },
            }],
        [tableData]
    );

    return (
        <>
            <Card className="main-card">
                <CardBody className="d-flex flex-column">
                    <h5 className="mb-4">Ketenregeling</h5>
                    <Formik
                        enableReinitialize
                        initialValues={defaultValues}
                    >
                        {({ values, setFieldValue, handleChange }) => (
                            <Form>
                                <FormGroup className="form-element">
                                    <Label for="iKetenregeling">Stand ketenregeling</Label>
                                    <div className="position-relative w-100">
                                        {data?.iKetenregeling_is_locked ?
                                            <LockIcon className="lock-icon" onClick={() => { changeHandler('iKetenregeling', values.iKetenregeling, false) }} /> : <UnLockIcon className="lock-icon" onClick={() => { changeHandler('iKetenregeling', values.iKetenregeling, true) }} />}
                                        <div className="d-flex gap-1 align-items-center">
                                            <Input
                                                name="iKetenregeling"
                                                type="text"
                                                value={values.iKetenregeling}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    changeHandler('iKetenregeling', e.target.value);
                                                }}
                                                disabled
                                                className="disabled"
                                            />
                                            <InfoIcon className="cursor-pointer" onClick={() => setModal(true)} />
                                        </div>
                                    </div>
                                </FormGroup>
                                <FormGroup className={`form-element ${errorField === 'dKetenregeling' ? 'error' : ''}`}>
                                    <Label for="dKetenregeling">Begindatum ketenregeling</Label>
                                    <div className="position-relative w-100">
                                        <DatePicker
                                            selected={values.dKetenregeling}
                                            onChange={(date) => {
                                                const formattedDate = moment(date).format('DD-MM-YYYY');
                                                setFieldValue("dKetenregeling", date);
                                                changeHandler('dKetenregeling', formattedDate);
                                            }}
                                            placeholderText={'dd-mm-jjjj'}
                                            locale="nl"
                                            dateFormat="dd-MM-yyyy"
                                            className="form-control"
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup className={`form-element ${errorField === 'd36mnd' ? 'error' : ''}`}>
                                    <Label for="d36mnd">36 Maanden datum</Label>
                                    <div className="position-relative w-100">
                                        {data?.d36mnd_is_locked ? <LockIcon className="lock-icon" onClick={() => { changeHandler('d36mnd', values.d36mnd, false) }} /> : <UnLockIcon className="lock-icon" onClick={() => { changeHandler('d36mnd', values.d36mnd, true) }} />}
                                        <div className="d-flex gap-1 align-items-center">
                                            <DatePicker
                                                selected={values.d36mnd}
                                                onChange={(date) => {
                                                    setFieldValue("d36mnd", date);
                                                    changeHandler('d36mnd', moment(date).format('YYYY-MM-DD'));
                                                }}
                                                placeholderText={'dd-mm-jjjj'}
                                                locale="nl"
                                                dateFormat="dd-MM-yyyy"
                                                className="form-control"
                                            />
                                            <InfoIcon className="cursor-pointer" onClick={() => setModal(true)} />
                                        </div>
                                    </div>
                                </FormGroup>
                                <FormGroup className={`form-element ${errorField === 'dblokkeren' ? 'error' : ''}`}>
                                    <Label for="dblokkeren">Blokkeerdatum begin</Label>
                                    <div className="position-relative w-100">
                                        <div className="d-flex gap-1 align-items-center">
                                            <DatePicker
                                                selected={values.dblokkeren}
                                                onChange={(date) => {
                                                    const formattedDate = moment(date).format('DD-MM-YYYY');
                                                    setFieldValue("dblokkeren", date);
                                                    changeHandler('dblokkeren', formattedDate);
                                                }}
                                                placeholderText={'dd-mm-jjjj'}
                                                locale="nl"
                                                dateFormat="dd-MM-yyyy"
                                                className="form-control"
                                            />
                                            <InfoIcon className="cursor-pointer" onClick={() => setModal(true)} />
                                        </div>
                                    </div>
                                </FormGroup>
                                <FormGroup className={`form-element ${errorField === 'dblokkerenend' ? 'error' : ''}`}>
                                    <Label for="dblokkerenend">Blokkeerdatum eind</Label>
                                    <div className="position-relative w-100">
                                        <div className="d-flex gap-1 align-items-center">
                                            <DatePicker
                                                selected={values.dblokkerenend}
                                                onChange={(date) => {
                                                    const formattedDate = moment(date).format('DD-MM-YYYY');
                                                    setFieldValue("dblokkerenend", date);
                                                    changeHandler('dblokkerenend', formattedDate);
                                                }}
                                                placeholderText={'dd-mm-jjjj'}
                                                locale="nl"
                                                dateFormat="dd-MM-yyyy"
                                                className="form-control"
                                            />
                                            <InfoIcon className="cursor-pointer" onClick={() => setModal(true)} />
                                        </div>
                                    </div>
                                </FormGroup>
                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card>
            {/* Modal */}
            <Modal isOpen={isModal} toggle={() => setModal(prev => !prev)} className="modal-dialog__over feedback-modal-style opmerking-modal-styling log-modal arrange-modal">
                <div className="modal-content-wraper">
                    <div className="modalHeader-wraper">
                        <ModalHeader toggle={() => setModal(prev => !prev)}>
                            <span className="modal-header-styling">Ketenregeling/{currentUser?.data?.name}</span>
                        </ModalHeader>
                    </div>
                    
                    <Card className="row main-card">
                        <div className="col-xl-6">
                            <CardBody className="d-flex flex-column card-body-1">
                                <ModalBody className="bg-white rounded mb-2">
                                    {isLoading ?
                                        <Loader type="ball-grid-pulse" className={'mb-3 d-flex mx-auto justify-content-center'} /> :
                                        <>
                                            <div className="tb-log">
                                                <ReactTableGeneric
                                                    data={tableData}
                                                    columns={columns}
                                                    tableClassName={`mb-0 table table-sm table-hover`}
                                                    filters={{ search: false, pagination: false, sorting: false }}
                                                />
                                            </div>
                                            <FormGroup check className="d-flex justify-content-center mt-4">
                                                <Input type="checkbox" id="loadmore" checked={showHistory} onChange={() => setShowHistory(prevState => !prevState)} />
                                                <Label for="loadmore" check className="ms-3 cursor-pointer"
                                                    style={{ fontSize: '16px', color: '#026094', fontWeight: 600 }}>
                                                    Toon volledige historie
                                                </Label>
                                            </FormGroup>
                                        </>
                                    }
                                </ModalBody>
                            </CardBody>
                        </div>
                        <div className="col-xl-6">
                            <CardBody className="d-flex flex-column card-body-2 pl-0">
                                <Formik
                                    enableReinitialize
                                    initialValues={defaultValuesForInfo}
                                    onSubmit={(values) => saveInstellingenData(values)}
                                >
                                    {({ values, setFieldValue, handleSubmit }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <Row className="row justify-content-center">
                                                <Col lg={5} sm={8} xl={12} className="p-0">
                                                    <div className='d-flex justify-content-between align-items-center mb-3'>
                                                        <Label className="overview-page-label">Blokkeerdatum begin</Label>
                                                        <div className="periode-input-style">
                                                            <DatePicker
                                                                locale="nl"
                                                                dateFormat="dd-MM-yyyy"
                                                                placeholderText="dd-mm-jjjj"
                                                                selected={values.dblokkeren}
                                                                onChange={(date) => setFieldValue('dblokkeren', date)}
                                                            />
                                                            <CalendarIcon />
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-between align-items-center mb-3'>
                                                        <Label className="overview-page-label">Startdatum ketenregeling bestuur</Label>
                                                        <div className="periode-input-style">
                                                            <DatePicker
                                                                locale="nl"
                                                                dateFormat="dd-MM-yyyy"
                                                                placeholderText="dd-mm-jjjj"
                                                                selected={values.dKetenregelingBestur}
                                                                disabled
                                                                onChange={(date) => setFieldValue('dKetenregelingBestur', date)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-between align-items-center mb-3'>
                                                        <Label className="overview-page-label">Startdatum ketenregeling vervanger</Label>
                                                        <div className="periode-input-style">
                                                            <DatePicker
                                                                locale="nl"
                                                                dateFormat="dd-MM-yyyy"
                                                                placeholderText="dd-mm-jjjj"
                                                                selected={values.dKetenregeling}
                                                                onChange={(date) => setFieldValue('dKetenregeling', date)}
                                                            />
                                                            <CalendarIcon />
                                                        </div>
                                                    </div>
                                        
                                                    <div className='d-flex justify-content-between align-items-center mb-3'>
                                                        <Label className="overview-page-label">Advies nieuwe startdatum ketenregeling
                                                            {data.iBreakDate !== "Geen advies" && <Tippy content={data?.iBreakDateReason} placement="bottom"><InfoIcon className="ms-1" /></Tippy>}
                                                        </Label>
                                                        <div className="periode-input-style">
                                                            <DatePicker
                                                                locale="nl"
                                                                dateFormat="dd-MM-yyyy"
                                                                placeholderText="dd-mm-jjjj"
                                                                disabled
                                                                selected={values.iBreakDate}
                                                                onChange={(date) => setFieldValue('iBreakDate', date)}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col lg={2} className="d-xl-none d-xl-block"></Col>

                                                <Col lg={5} sm={8} xl={12} className="p-0">
                                                    <div className='d-flex justify-content-between align-items-center mb-3'>
                                                        <Label className="overview-page-label">Blokkeerdatum eind</Label>
                                                        <div className="periode-input-style">
                                                            <DatePicker
                                                                locale="nl"
                                                                dateFormat="dd-MM-yyyy"
                                                                placeholderText="dd-mm-jjjj"
                                                                selected={values.dblokkerenend}
                                                                onChange={(date) => setFieldValue('dblokkerenend', date)}
                                                            />
                                                            <CalendarIcon />
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-between align-items-center mb-3'>
                                                        <Label className="overview-page-label">36 maanden datum</Label>
                                                        <div className="periode-input-style">
                                                            <DatePicker
                                                                locale="nl"
                                                                dateFormat="dd-MM-yyyy"
                                                                placeholderText="dd-mm-jjjj"
                                                                selected={values.d36mnd}
                                                                onChange={(date) => setFieldValue('d36mnd', date)}
                                                            />
                                                            <CalendarIcon />
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-between align-items-center mb-3'>
                                                        <Label className="overview-page-label">Advies 36 maanden datum
                                                            {data.iBreakDate !== "Geen advies" && <Tippy content={data?.iBreakDateReason2} placement="bottom"><InfoIcon className="ms-1" /></Tippy>}
                                                        </Label>
                                                        <div className="periode-input-style">
                                                            <DatePicker
                                                                locale="nl"
                                                                dateFormat="dd-MM-yyyy"
                                                                placeholderText="dd-mm-jjjj"
                                                                disabled
                                                                selected={values.iBreakDate2}
                                                                onChange={(date) => setFieldValue('iBreakDate2', date)}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                            <ModalFooter className="d-flex align-items-center justify-content-center border-bottom-0 py-4 mt-4">
                                                <Button className="btn-color-style opslaan-btn" size="sm" outline color="primary" type="submit">
                                                    Toepassen
                                                </Button>
                                                <Button className="btn-color-style opslaan-btn" size="sm" outline color="primary" type="button" onClick={() => setModal(prev => !prev)}>
                                                    Annuleren
                                                </Button>
                                            </ModalFooter>
                                            </Col>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            </CardBody>
                        </div>
                    </Card>
                </div>
            </Modal>
        </>
    )
}

export default Arrangement