import { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardFooter } from 'reactstrap'
import Loader from 'react-loaders'
import { ReactComponent as BackIcon } from "../../../../../../assets/utils/images/svgs/arrow-left.svg";
import { debounce, schoolTabs } from '../../utils'
import WorkTimeTable from './WorkTimeTable';
import { useLocation, useParams } from 'react-router';
import ScholenService from 'Api/Instellingen/Scholen';

const Wtf = ({ setActiveTab }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { schoolId } = useParams();
    const location = useLocation();
    const { communityId } = location.state || {};
    const [wtfData, setWtfData] = useState([]);

    useEffect(() => {
        fetchWtfData();
    }, []);

    const fetchWtfData = async () => {
        setIsLoading(true);
        const payload = {
            iCommunityId: communityId,
            iSchoolId: schoolId
        }
        try {
            const response = await ScholenService.getWtfData(payload);
            if (response.data.status) {
                setWtfData(response.data.data);
            }
        } catch (error) {
            console.error(`API error:`, error);
        } finally {
            setIsLoading(false);
        }
    };

    const callApi = (groupId, shift, value, wtfType, iWtfId) => {
        const bodyData = {
            iSchoolId: schoolId,
            iCommunityId: communityId,
            iGroupId: groupId,
            shift,
            value,
            wtfType,
            iWtfId
        }
        ScholenService.updateWtfData(bodyData)
            .then(response => {
                console.log('API response:', response.data);
            })
            .catch(error => {
                console.error('API error:', error);
            });
    };
    const debouncedApiCall = debounce(callApi, 1000);

    const handleFieldChange = (groupId, shift, value, wtfType, iWtfId) => {
        debouncedApiCall(groupId, shift, value, wtfType, iWtfId);
    };

    return (
        <>
            {
                isLoading ?
                    <Card className="main-card">
                        <CardBody className="d-flex flex-column">
                            <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
                        </CardBody>
                    </Card> :
                    <>
                        <div className='row instellingen'>
                            {
                                wtfData && Object.entries(wtfData)?.map(([key, value]) => {
                                    return <div className='col-12' key={key}>
                                        <WorkTimeTable title={key} data={value} changeHandler={handleFieldChange} />
                                    </div>
                                })
                            }
                        </div>
                        <div className="chat-layout-footer py-5">
                            <CardFooter className="chat-layout-footer__inner gebruikers-footer">
                                <div className="d-flex gap-3">
                                    <Button
                                        outline
                                        className="btn-outline-primary-blue ms-auto"
                                        onClick={() => {
                                            setActiveTab(schoolTabs.Instellingen)
                                        }}
                                        type="button"
                                        color="primary"
                                    >
                                        <BackIcon />
                                    </Button>
                                </div>
                            </CardFooter>
                        </div>
                    </>
            }
        </>
    )
}

export default Wtf