import React from 'react'
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { Form, Formik } from 'formik';
import OtpInput from 'react-otp-input';
import * as Yup from "yup";

const OTPSchema = Yup.object().shape({
    rememberOTP: Yup.boolean()
});

const TwoFAModal = ({ showOTPModal, setShow2FAModal, handleOTPSubmit = () => { }, otp, setOtp, showRememberMe = true, buttonPress = false }) => {
    return (
        <Modal isOpen={showOTPModal} className="modal-centered login-OTP-modal">

            <ModalHeader toggle={!showRememberMe ? (() => setShow2FAModal(false)) : (false)} className='modalHeader modal-header-color'>
                <div className="logo">
                    <div></div>
                </div>
                <div>
                    <span style={{ color: '#878EA6', fontWeight: 500 }}>2FA {"  "}</span>
                    <span style={{ color: '#878EA6', fontWeight: 500 }}>Two-factor authentication</span>
                </div>
            </ModalHeader>

            <ModalBody className='modalBody-centered'>

                <Formik
                    initialValues={{
                        rememberOTP: false,
                    }}
                    validationSchema={OTPSchema}
                    onSubmit={handleOTPSubmit}
                >
                    {({ setFieldValue, handleSubmit, values, handleBlur, errors, touched }) => (
                        <Form onSubmit={handleSubmit} className="login-OTP-form">
                            <FormGroup className='input'>
                                <Label>Gebruik de beveiligingscode van uw Authenticator-app.</Label>
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    inputType="tel"
                                    renderInput={(props) => <input {...props} className="otp-input" />}
                                />
                                {showRememberMe && (
                                    <Label className="label-2">
                                        <Input
                                            onChange={(e) => setFieldValue('rememberOTP', e.target.checked)}
                                            id="2fa-code"
                                            name="2fa-code"
                                            type="checkbox"
                                            value={values.rememberOTP}
                                            onBlur={handleBlur}
                                        />
                                        <div>
                                            <div>Onthoud dit apparaat</div>
                                            <div style={{ color: '#80808091', fontWeight: 400, fontSize: '12px' }}>Net aan bevolen voor openbare of gedeelde computers</div>
                                        </div>
                                    </Label>
                                )}

                            </FormGroup>

                            <ModalFooter className={`d-flex align-items-center justify-content-center border-bottom-0 py-4 ${showRememberMe ? "mt-4" : "mt-5"}`}>
                                <Button disabled={buttonPress} type={otp?.length < 6 ? "button" : "submit"} color="link" className={"btn-color-style"} style={{ cursor: otp?.length < 6 ? "no-drop" : "pointer" }}>
                                    Bevestigen
                                </Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </ModalBody>


        </Modal>
    )
}

export default TwoFAModal;