import React, { Fragment } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Col, Row } from "reactstrap";
import PageHeading from "Layout/AppMain/PageHeading";
import Availability from "Components/Availability";

const SubstitutorAvailability = () => {
	return (
		<Fragment>
			<TransitionGroup>
				<CSSTransition
					component="div"
					classNames="TabsAnimation"
					appear={true}
					timeout={1500}
					enter={false}
					exit={false}
				>
					<div>
						<PageHeading />

						<Row>
							<Col md="12" lg="12">
								<Availability>
									<Availability.Modals />
									<Availability.Container>
										<Availability.Header />
										<Availability.Table/>
									</Availability.Container>
								</Availability>
							</Col>
						</Row>
					</div>
				</CSSTransition>
			</TransitionGroup>
		</Fragment>
	);
};

export default SubstitutorAvailability;
