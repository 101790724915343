import React, { Fragment, useState } from "react";
import { Form, Formik } from "formik";
import { Card, CardBody, FormGroup, Input, Label, Spinner } from 'reactstrap'
import { BsCheckCircle } from "react-icons/bs";
import { ReactComponent as CrossIcon } from "../../../../../assets/utils/images/svgs/Cross.svg";
import DropdownList from "react-widgets/DropdownList";
import Tippy from "@tippyjs/react";
import GroupStatus from "../../../../../Components/IconDropdown/Cross_Tick/GroupStatus";

const sorterenOp = [{ name: 'Naam', value: 1 }, { name: 'Afstand', value: 2 }, { name: 'Leeftijd', value: 3 }, { name: 'Beschikbaarheid', value: 4 }];

const FllterInstellingenPlanning = ({ data, planning, handleFieldChange, handleIconFieldChange, errorField }) => {
    const [loadingKeys, setLoadingKeys] = useState({})

    const defaultValues = {
        name: data?.name,
        status: Number(data?.active),
        SorterenOp: data?.iSORT,
        index: data?.index
    };

    const handleToggle = (field, data) => {
        setLoadingKeys(prev => ({ ...prev, [field]: true }));
        handleIconFieldChange(field, data, setLoadingKeys)
    };

    return (
        <Card className="main-card">
            <CardBody className="d-flex flex-column">
                <div className="btn-color-style travelCost-blue-text mb-4">Fllterinstellingen {planning}</div>

                <Formik
                    enableReinitialize
                    initialValues={defaultValues}
                >
                    {({ values, errors, setFieldValue, handleChange, setFieldError }) => (
                        <Form>
                            <FormGroup className={`form-element ${(errors.name) ? 'error' : ''}`}>
                                <Label for="name">Naam</Label>
                                <div className="position-relative w-50">
                                    <div className="d-flex gap-1 align-items-center">
                                        <Input
                                            name="name"
                                            type="text"
                                            value={values.name}
                                            onChange={(e) => {
                                                setFieldValue('name', e.target.value);
                                                handleFieldChange("name", e.target.value, setFieldError);
                                            }}
                                        />
                                    </div>
                                </div>
                            </FormGroup>
                            <FormGroup className={`form-element ${(errors.status) ? 'error' : ''}`}>
                                <Label for="status">Status</Label>
                                <div className="position-relative w-50">
                                    <div className="d-flex gap-1 align-items-center ">
                                        {loadingKeys["active"] ? (
                                            <div className="spinner-icon-change">
                                                <div><Spinner size="sm" color="primary" /></div>
                                            </div>
                                        ) :
                                            <GroupStatus iconChangeHandler={handleToggle} field={"active"} data={values?.status ? 0 : 1} controlFrom={"contracttype"} />
                                        }
                                    </div>
                                </div>
                            </FormGroup>
                            <FormGroup className={`form-element ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                <Label for="SorterenOp">Sorteren op</Label>
                                <div className="position-relative w-50">
                                    <DropdownList dataKey='name' textField='name'
                                        onChange={(e) => { setFieldValue('SorterenOp', e.value); handleFieldChange("iSORT", e.value, setFieldError); }}
                                        name="schoolId"
                                        data={sorterenOp}
                                        filter={false}
                                        value={sorterenOp.find(option => option.value === values.SorterenOp)}
                                    /></div>
                                {errors.schoolId && <div className="text-danger">{errors.schoolId}</div>}
                            </FormGroup>
                            <FormGroup className={`form-element ${(errors.index) ? 'error' : ''}`}>
                                <Label for="volgorde">Volgorde</Label>
                                <div className="position-relative w-50">
                                    <div className="d-flex gap-1 align-items-center">
                                        <Input
                                            name="volgorde"
                                            type="text"
                                            value={values.index}
                                            onChange={(e) => {
                                                setFieldValue('index', e.target.value);
                                                handleFieldChange("index", e.target.value, setFieldError);
                                            }}
                                        />
                                    </div>
                                </div>
                            </FormGroup>
                        </Form>
                    )}
                </Formik>
            </CardBody>
        </Card>
    )
}

export default FllterInstellingenPlanning;