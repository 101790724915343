export const SET_USER_PROFILE_PICTURE = 'SET_PROFILE_PICTURE';
export const SET_ADMIN_ID = 'SET_ADMIN_ID';


export const setUserProfilePicture = profileImage => ({
    type: SET_USER_PROFILE_PICTURE,
    profileImage
});

export const setAdminId = iAdminId => ({
    type: SET_ADMIN_ID,
    iAdminId
});

export default function UserProfile(state = {
    profileImage: '',
    iAdminId: ''
}, action) {
    switch (action.type) {
        case SET_USER_PROFILE_PICTURE:
            return {
                ...state,
                profileImage: action.profileImage
            };
        case SET_ADMIN_ID:
            return {
                ...state,
                iAdminId: action.iAdminId
            };
        default:
            return state;
    }
}
