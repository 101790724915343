import PageHeading from "Layout/AppMain/PageHeading";
import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Card, CardBody, Col, Row, CardHeader, Table, Label, FormGroup, Input, Button, NavLink, Nav, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";
import ReactTableGeneric from "Components/ReactTableGeneric";
import { ReactComponent as PdfIcon } from "../../../../assets/utils/images/svgs/PDF.svg";
import { BsCheckCircle } from "react-icons/bs";
import { BsQuestionCircle } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import { ReactComponent as CrossIcon } from "../../../../assets/utils/images/svgs/Cross.svg";
import axiosInstance from "Api";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { ErrorMessage } from "formik";
import DocumentService from "Api/Document";
import TextLimit from "Api/TextLimit";


const Default = () => {

    const [besturenList, setBesturenList] = useState([]);
    const [functiesList, setFunctiesList] = useState([]);
    const [functiesListChunk, setFunctiesListChunk] = useState([]);
    const [details, setDetails] = useState([]);
    const [value, setValue] = useState(0);

    useEffect(() => {
        axiosInstance.get('settings/besturen').then((response) => {
            if (response.status == 200) {
                setBesturenList(response.data.data);
            }
        }).catch((error) => console.log("catch error:", error));
    }, []);

    const chunk = (arr, size) =>
        Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
            arr.slice(i * size, i * size + size)
        );

    useEffect(() => {
        axiosInstance.get('settings/functies').then((response) => {
            if (response.status == 200) {
                setFunctiesListChunk(chunk(response.data.data, 3));
                setFunctiesList(response.data.data);
            }
        }).catch((error) => console.log("catch error:", error));
    }, []);

    useEffect(() => {
        axiosInstance.get('settings/details').then((response) => {
            if (response.status == 200) {
                setDetails(response.data.data);
            }
        }).catch((error) => console.log("catch error:", error));
    }, []);

    const iconSwitch = (param) => {
        switch (param) {
            case '+':
                return <span class="float-end"><BsCheckCircle color='#39A825' font-size={"18px"} /></span>;
            case '?':
                return <span class="float-end"><BsQuestionCircle color='#F2983E' font-size={"18px"} /></span>;
            case '-':
                return <span class="float-end delete-icon-margin"><CrossIcon color='#FB2E5F'  /></span>;
            default:
                return '--';
        }
    }

    function downloadFile(id) {
        DocumentService.downloadProtocol(id).then((response) => {
            if (response.data.file.download !== '') {
                const linkSource = response.data.file.download;
                const downloadLink = document.createElement("a");
                const fileName = "document." + response.data.file.extension;

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }
        }).catch(() => console.error);
    }

    const columns = [
        {
            header: 'Bestuur',
            accessorKey: 'vCommunityName',
            cell: value => value.row.original.vCommunityName !== undefined && value.row.original.vCommunityName !== '' &&
                (
                    window.innerWidth > 1250 ?
                        TextLimit.limitStringLargeScreen(value.row.original.vCommunityName)
                        : TextLimit.limitStringSmallScreen(value.row.original.vCommunityName)
                )

        },
        {
            header: 'Regio',
            accessorKey: 'regio'
        },
        {
            header: 'Website',
            accessorKey: 'website'
        },
        {
            header: 'Protocol',
            accessorKey: 'iProtocolId',
            cell: value => value.row.original.iProtocolId !== undefined && value.row.original.iProtocolId !== '' &&
                <a href="javascript:void(0)" onClick={() => downloadFile(value.row.original.iProtocolId)} className="anchor-action-link">
                    <PdfIcon />
                </a>
        },
    ];


    const changeStatus = (groupId, vValue) => {
        var group = [];
        var newGroup = functiesList;

        group.map((value, index) => {
            if (value.id === groupId) {
                group.splice(index, 1);
            }
        })
        group.push({ iSubGroupId: groupId, vValue: vValue });
        newGroup.map((value) => {
            if (value && value.iSubGroupId == groupId) {
                value.vValue = vValue
            }
        })

        setFunctiesList(newGroup);
        setValue(valuee => valuee + 1);
        let dataToSend;
        var statusVal = '';
        if (vValue === '+') {
            statusVal = '1';
        } else if (vValue === '-') {
            statusVal = '0';
        } else {
            statusVal = '2';
        }

        dataToSend = {
            groupId: groupId,
            value: statusVal,

        };

        axiosInstance.post('group/update', dataToSend).then((response) => {

        }).catch((error) => console.log("catch error:", error));
    }

    const validationSchema = Yup.object().shape({
        iMaxTravelDistance: Yup.number().max(300).min(0),
    });

    const handleSubmit = (values, setSubmitting) => {

        setTimeout(() => {
            console.log(values);
            let data;
            data = {
                iMaxTravelDistance: values.iMaxTravelDistance,
                reminderSetting: values.reminderSetting,
                memo: values.memo,
                eGym: values.eGym,
            };
            axiosInstance.post('settings/update', data).then((response) => {

            }).catch((error) => console.log("catch error:", error));

            setSubmitting(false);
        }, 400);
    };


    var i = 1;

    return (
        <Fragment>
            <PageHeading />
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    <>
                        <Formik
                            enableReinitialize
                            initialValues={details}
                            validationSchema={validationSchema}
                            onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md="4" sm="4" lg="4">
                                            <Card className="main-card mb-3">
                                                <CardBody className="p-3 pb-5">
                                                    <FormGroup row >
                                                        <Label className={"setting-lebel-style"}>
                                                            Maximale reisafstand
                                                        </Label>
                                                        <Col sm={12} >
                                                            <Input
                                                                type="number"
                                                                name="iMaxTravelDistance"
                                                                className="me-2 input-border-styling"
                                                                placeholder="Kilometer"
                                                                onChange={handleChange}
                                                                defaultValue={details !== undefined && details.iMaxTravelDistance !== undefined ? details.iMaxTravelDistance : ''}
                                                            />
                                                            <ErrorMessage name="iMaxTravelDistance" component="small"
                                                                className="block mt-2 invalid-feedback" />
                                                        </Col>
                                                    </FormGroup>
                                                    <div className="custom-styling  mb-3">
                                                        <label for="eGym" className="input-wraper-style setting-lebel-style col-sm-12 col-form-label">Gymakte </label>
                                                        <select id="eGym" name="eGym" className=" rw-dropdown-list rw-widget rw-widget-input rw-widget-picker rw-widget-container rw-placeholder rw-dropdown-list-input"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}>
                                                            <option value="">Selecteer</option>
                                                            <option value="Ja" selected={details !== undefined && details.eGym !== undefined && details.eGym === 'Ja' ? true : false}>Ja</option>
                                                            <option value="Nee" selected={details !== undefined && details.eGym !== undefined && details.eGym === 'Nee' ? true : false}>Nee</option>
                                                            <option value="i.o." selected={details !== undefined && details.eGym !== undefined && details.eGym === 'i.o.' ? true : false}>In opleiding</option>
                                                        </select>
                                                        <ErrorMessage name="eGym" component="small"
                                                            className="block mt-2 invalid-feedback" />
                                                    </div>

                                                    <div className="custom-styling  mb-4">
                                                        <label for="reminderSetting" className="input-wraper-style setting-lebel-style col-sm-12 col-form-label">Herinneringsmail ontbrekende gegevens </label>
                                                        <select id="reminderSetting" name="reminderSetting" className=" rw-dropdown-list rw-widget rw-widget-input rw-widget-picker rw-widget-container rw-placeholder rw-dropdown-list-input"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}>
                                                            <option value="">Selecteer</option>
                                                            <option value="1" selected={details !== undefined && details.reminderSetting !== undefined && details.reminderSetting == '1' ? true : false}>Ja</option>
                                                            <option value="0" selected={details !== undefined && details.reminderSetting !== undefined && details.reminderSetting == '0' ? true : false}>Nee</option>
                                                        </select>
                                                        <ErrorMessage name="reminderSetting" component="small"
                                                            className="block mt-2 invalid-feedback" />
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                        <Col md="8" sm="8" lg="8">
                                            <Card className="main-card mb-3">
                                                <CardBody className="p-3">
                                                    <FormGroup row >
                                                        <Label className={"setting-lebel-style"}>
                                                            Profiel
                                                        </Label>
                                                        <Col sm={12} >
                                                            <Input
                                                                type="textarea"
                                                                name="memo"
                                                                className="me-2 textarea-styling"
                                                                onChange={handleChange}
                                                                defaultValue={details !== undefined && details.memo !== undefined ? details.memo : ''}
                                                            />
                                                            <ErrorMessage name="memo" component="small"
                                                                className="block mt-2 invalid-feedback" />
                                                        </Col>
                                                    </FormGroup>
                                                    <Button className="d-flex align-items-center btn-color-style mb-2 me-2 float-end" size="sm" outline color="primary" type="submit" disabled={isSubmitting}>
                                                        Opslaan
                                                    </Button>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                        <Card className="main-card mb-3">
                            <CardHeader className="head-text-color px-3">
                                <h5 className="menu-header-title text-capitalize">
                                    Besturen
                                </h5>
                            </CardHeader>
                            <CardBody className="p-3">
                                <ReactTableGeneric
                                    data={besturenList}
                                    columns={columns}
                                    tableClassName="mb-0 table table-borderless table-bordered table-sm table-hover protocol-col-style"
                                    filters={{ search: false, pagination: false, sorting: true }}
                                />
                            </CardBody>
                        </Card>
                        <Card className="main-card mb-3">
                            <CardHeader className="head-text-color px-3">
                                <h5 className="menu-header-title text-capitalize">
                                    Functies
                                </h5>
                            </CardHeader>
                            <CardBody className="p-3">
                                <Table hover size="sm" borderless className="mb-0 table table-borderless table-bordered table-sm table-hover">
                                    <thead>
                                        <tr>
                                            <th class="col-4">Functie</th>
                                            <th class="col-4">Functie</th>
                                            <th class="col-4">Functie</th>
                                        </tr>
                                    </thead>
                                    <tbody className="functies-table">
                                        {functiesListChunk.length > 0 && functiesListChunk.map((value, index) => {

                                            return (<tr class="functies-table-style">
                                                {value.map((key, index1) => {
                                                    return (<td >{window.innerWidth > 1250 ? TextLimit.limitFunctiesLargeScreen(key.vGroup) : TextLimit.limitFunctiesSmallScreen(key.vGroup)}

                                                        {(key !== undefined && key.vValue !== undefined) &&
                                                            <UncontrolledButtonDropdown className="float-end">
                                                                <DropdownToggle color="link" className="p-0">

                                                                    {iconSwitch(key !== undefined && key.vValue !== undefined ? key.vValue : '')}
                                                                </DropdownToggle>
                                                                <DropdownMenu end className="rm-pointers dropdown-menu-lg functies-dropdown-width">

                                                                    <Nav vertical className="dropdown-custom-link">
                                                                        <NavLink className="link-gap" href="#" onClick={() => changeStatus(
                                                                            key.iSubGroupId, '+'
                                                                        )}>
                                                                            <span><BsCheckCircle color='#39A825' font-size={"21px"} />  </span>
                                                                            {/* <span>Beschikbaar voor deze functie</span> */}
                                                                        </NavLink>

                                                                        <NavLink className="link-gap navlink-border" href="#" onClick={() => changeStatus(
                                                                            key.iSubGroupId, '?'
                                                                        )}>
                                                                            <span><BsQuestionCircle color='#F2983E' font-size={"21px"} />  </span>
                                                                            {/* <span>Geen keuze gemaakt</span> */}
                                                                        </NavLink>

                                                                        <NavLink className="link-gap" href="#" onClick={() => changeStatus(
                                                                            key.iSubGroupId, '-'
                                                                        )}>
                                                                            <span><CrossIcon color='#FB2E5F' /> </span>
                                                                            {/* <span style={{ marginLeft: '-5px' }}> Niet beschikbaar voor deze functie</span> */}
                                                                        </NavLink>
                                                                    </Nav>
                                                                </DropdownMenu>
                                                            </UncontrolledButtonDropdown>

                                                        }

                                                    </td>)
                                                })}

                                            </tr>)
                                        })}
                                    </tbody>
                                </Table>
                                <div className="d-flex align-items-end mt-2">
                                    <div><span className="me-1"><BsCheckCircle color='#39A825' font-size={"18px"} /></span></div>
                                    <div className="me-5 calendar-agenda__item ">Beschikbaar voor deze functie</div>
                                    <div><span className="me-1"><BsQuestionCircle color='#F2983E' font-size={"18px"} /></span></div>
                                    <div className="me-5 calendar-agenda__item">Geen keuze gemaakt</div>
                                    <div><span className="me-1"><CrossIcon color='#FB2E5F' /></span></div>
                                    <div className="me-5 calendar-agenda__item">Niet beschikbaar voor deze functie</div>
                                </div>
                            </CardBody>
                        </Card>
                    </>
                </CSSTransition>
            </TransitionGroup>
        </Fragment>
    )
};

export default Default;

