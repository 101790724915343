import React, { Fragment, useState } from 'react'
import { Button, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FaCheck } from "react-icons/fa";
import SweetAlert from 'react-bootstrap-sweetalert';
import DefaultButton from 'Components/Buttons/DefaultButton';
import AdminService from 'Api/Admin';
import Loader from 'react-loaders';
import { Formik } from 'formik';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import moment from 'moment';
import TwoFAModal from 'Components/Modal/TwoFAModal';
import UserProfile from 'reducers/UserProfile';

const validationSchema = Yup.object().shape({
    verification_code: Yup.string().required('Voer qrcode in'),
});

function TwoFactorAuthentication({ ...props }) {
    const [isShowAlertModalOpen, setIsShowALertModalOpen] = useState(false)
    const [qrCodeSVG, setQrCodeSVG] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [otp, setOtp] = useState('');
    const [show2FAModal, setShow2FAModal] = useState(false);
    const [qrError, setQrError] = useState('');
    const [showImpersonateClickModal, setShowImpersonateClickModal] = useState(false);
    const [buttonPress, setButtonPress] = useState(false);
    const [recoveryCode, setRecoveryCode] = useState(null);

    const toggleModal = () => {
        setShowModal(!showModal);
        setQrError('');
        setRecoveryCode(null);
    }

    const fetchUser = () => {
        props?.fetchUserDetail();
        setShowModal(!showModal);
    }

    const enableTwoFactorAuthentication = (values) => {
        if (values.verification_code !== '' && !props?.userProfileData?.user?.impersonate) {
            const data = { verification_code: values.verification_code };

            AdminService.postQRCode(data)
                .then((response) => {
                    if (response?.data?.status) {
                        toast.success("Twee-factor-authentiek succes ingeschakeld!");
                        setRecoveryCode(response?.data?.recoveryCodes);
                        setQrCodeSVG('');
                    } else {
                        toast.error("Niet in staat om in te schakelen. Probeer het opnieuw!");
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            toast.error("Alleen ingelogde gebruikers kunnen de 2fa inschakelen.");
        }
    };

    const toggleALertModal = () => {
        setIsShowALertModalOpen(!isShowAlertModalOpen)
    }

    const getCookie = (cookieName) => {
        const cookies = document.cookie.split("; ");
        for (let cookie of cookies) {
            const [name, value] = cookie.split("=");
            if (name === cookieName) {
                return value;
            }
        }
        return null;
    };

    const open2FAModal = () => {
        if (!props?.userProfileData?.user?.impersonate) {
            setIsShowALertModalOpen(false);
            setShow2FAModal(!show2FAModal);
        } else {
            disable2FA();
        }
    }

    const handleOTPSubmit = () => {
        disable2FA();
    }

    const disable2FA = () => {
        const rememberToken = getCookie("rememberToken");
        const data = {
            rememberToken: rememberToken,
            ...((otp !== '' || otp) && { one_time_password: otp }),
        };

        show2FAModal && setButtonPress(true);

        AdminService.disable2FA(data)
            .then((response) => {
                if (response?.data?.status) {
                    props?.fetchUserDetail();
                    toast.success("Tweefactorauthenticatie uitgeschakeld!");
                    deleteCookie("rememberToken");
                    props?.userProfileData?.user?.impersonate && setIsShowALertModalOpen(prev => !prev);
                } else if (response?.data?.error) {
                    toast.error("Ongeldige otp");
                } else {
                    toast.error("Niet in staat om uit te schakelen. Probeer het opnieuw!");
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error("Niet in staat om uit te schakelen. Probeer het opnieuw!");
            }).finally(() => {
                setOtp('');
                setShow2FAModal(false);
                show2FAModal && setButtonPress(false);
            });
    };

    const deleteCookie = (cookieName) => {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; secure; samesite=strict`;
    };

    const generateQR = () => {
        setQrCodeSVG('');

        if (!props?.TwoFAEnabled && !props?.userProfileData?.user?.impersonate) {
            if (!qrError) toggleModal();

            setQrError('');
            AdminService.getQR()
                .then((response) => {
                    if (response?.data?.status) {
                        setQrCodeSVG(response?.data.qr);
                        setQrError('');
                    } else {
                        setQrCodeSVG('');
                        setQrError("QR-generatie mislukt. Probeer het opnieuw!");
                    }
                })
                .catch((error) => {
                    console.error(error);
                    setQrCodeSVG('');
                    setQrError('Er is iets misgegaan. Probeer het alstublieft opnieuw.');
                });
        } else {
            toggleALertModal();
        }
    };

    const handleImpersonateClick = () => {
        setShowImpersonateClickModal(!showImpersonateClickModal)
    }

    return (
        <Fragment>
            <Container className='two-factor-authentication'>
                <div className='two-factor-authentication-left custom-padding'>
                    <div className='wrapper'>
                        <h4><b>Twee-Factor Authenticatie (2FA)</b></h4>
                        <p>Met two-factor authentication voeg je een extra
                            beveiligingslaag toe aan account bij Clooser.nl</p>

                        <div className='two-factor-authentication-button'>
                            {props?.TwoFAEnabled ? <p>
                                <FaCheck color={props?.TwoFAEnabled && '#5ab65a'} />
                                <span style={props?.TwoFAEnabled && { color: '#5ab65a' }}>Twee-factor-authenticatie (2FA) is ingeschakeld</span>
                            </p> : ""}

                            <DefaultButton disabled={JSON.stringify(props?.userProfileData) === JSON.stringify({})} type="button" className='py-1 w-100 ' onClick={props?.userProfileData?.user?.impersonate ? (!props?.TwoFAEnabled ? handleImpersonateClick : generateQR) : generateQR}>
                                {props?.TwoFAEnabled ? 'Twee-factor Authentication Uitschakelen' : 'Twee-factor Authentication Inschakelen'}
                            </DefaultButton>
                        </div>
                    </div>
                </div>
                <div className='two-factor-authentication-right'>
                    <p><b>Two-factor authentication inschakelen</b></p>
                    <p>Met two-factor authentication voeg je een extra beveiligingslaag toe aan je account bij Clooser.nl </p>
                    <ol>
                        <li>Om gebruik te maken van two-factor authentication is het van belang dat je eerst een 2FA-app als Google Authenticator downloadt in de App
                            Store of Play Store.
                        </li>
                        <li>_Schakel je two-factor authentication in waarna een QR-code geladen wordt.
                        </li>
                        <li>Voeg een YubiKey toe via een PIN-code</li>
                        <li>Als de QR-code wordt herkend zal je authenticator-app een account toevoegen met de naam <b>'Clooser.nl (accountnaam)'</b>. Die laat ook
                            een verificatiecode zien welke je dien<b></b>t in te voeren onder het kopje <b>'Two-factor 2fa code'</b> Klik vervolgens op <b>'Toepassen'</b>.</li>
                        <li>De volgende keer dat je inlogt op het controlepaneel zal je na het opgeven van je gebruikersnaam en wachtwoord gevraagd worden om een
                            verificatiecode op te geven die je met je authenticator-app genereert.</li>
                    </ol>
                </div>
            </Container>

            {!props?.TwoFAEnabled ? (<Modal isOpen={showModal} className="modal-centered two-factor-authentication-modal">
                <ModalHeader toggle={recoveryCode ? fetchUser :toggleModal} className='modalHeader modal-header-color'>
                    2FA Inschakelen
                </ModalHeader>
                <ModalBody className='modalBody-centered'>
                    {recoveryCode && (
                        <>
                            <p className='text-center'><strong>Dit is je herstelcodes</strong></p>
                            <div className="row text-center">
                                {recoveryCode.map((code, index) => (
                                    <div key={index} className="col-md-6">
                                        <p className="text-monospace">{code}</p>
                                    </div>
                                ))}
                            </div>
                                <p className='text-center text-danger'><strong>Bewaar deze codes. ze worden slechts één keer weergegeven!</strong></p>
                         </>)}     

                         {!recoveryCode && (
                             qrCodeSVG ? (
                                 <div className="text-center mb-4" >
                                      <img
                                         src={qrCodeSVG}
                                         alt="QR Code"
                                     /> 
                                 </div>
                             ) : (
                                 qrError === '' ? <Loader className="mx-auto w-30 mb-4" type="ball-grid-pulse" /> :
                                     <div className="text-danger ps-3">{qrError}</div>
                             )
                         )  }

                    {!recoveryCode && (qrError === '' ? (
                        <Formik
                            initialValues={{
                                verification_code: ''
                            }}
                            validationSchema={validationSchema}
                            onSubmit={enableTwoFactorAuthentication}
                        >
                            {({ setFieldValue, handleSubmit, values, handleBlur, errors, touched }) => (
                                <Form onSubmit={handleSubmit}>
                                    <FormGroup className='input'>
                                        <Label for="2fa-code">
                                            Twee-Factor (2FA) code
                                        </Label>
                                        <Input
                                            onChange={(e) => setFieldValue('verification_code', e.target.value)}
                                            id="2fa-code"
                                            name="2fa-code"
                                            type="text"
                                            value={values.verification_code}
                                            onBlur={handleBlur}
                                        />
                                        {touched.verification_code && errors.verification_code && (
                                            <div className="invalid-feedback d-block">{errors.verification_code}</div>
                                        )}
                                    </FormGroup>

                                    <ModalFooter className="d-flex align-items-center justify-content-center border-bottom-0 py-4 mt-4">
                                        <Button type='submit' color="link" className={"btn-color-style"}>
                                            Toepassen
                                        </Button>
                                        <Button color="link" onClick={recoveryCode ? fetchUser : toggleModal} className={"btn-color-style"}>
                                            Annuleren
                                        </Button>
                                    </ModalFooter>
                                </Form>
                            )}
                        </Formik>
                    ) : (
                        <ModalFooter className="d-flex align-items-center justify-content-center border-bottom-0 py-4 mt-4">
                            <Button color="link" className={"btn-color-style"} onClick={generateQR}>
                                Opnieuw Proberen
                            </Button>
                        </ModalFooter>
                    ))}
                </ModalBody>
            </Modal>
            ) : (<SweetAlert title="Weet je zeker dat je 2FA wilt uitschakelen?" show={isShowAlertModalOpen}
                type="warning"
                onCancel={toggleALertModal}
                onConfirm={open2FAModal}
                cancelBtnText="Nee"
                confirmBtnText="Ja"
                showCancel={true} />)}

            {(props?.userProfileData?.user?.impersonate && !props?.TwoFAEnabled) ? <SweetAlert title="Alleen ingelogde gebruikers kunnen de 2fa inschakelen." show={showImpersonateClickModal}
                type="warning"
                onConfirm={() => setShowImpersonateClickModal(!showImpersonateClickModal)}
                confirmBtnText={"OK"} /> : null}

            {!props?.userProfileData?.user?.impersonate && <TwoFAModal buttonPress={buttonPress} setShow2FAModal={setShow2FAModal} showRememberMe={false} showOTPModal={show2FAModal} handleOTPSubmit={handleOTPSubmit} otp={otp} setOtp={setOtp} />}
        </Fragment>
    )
}

export default TwoFactorAuthentication
