import React, {
  Fragment,
  useState,
  useEffect,
  useMemo,
  forwardRef,
} from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Card, CardBody, Col, Row, Button } from "reactstrap";
import axiosInstance from "Api";
import { ReactComponent as CalendarIcon } from "../../../../assets/utils/images/svgs/calendar-blue.svg";
import { ReactComponent as CalendarIconWhite } from "../../../../assets/utils/images/svgs/calendar-white.svg";
import Loader from "react-loaders";
import AbsentReactTable from "Pages/Melder/Substitutions/Overview/Dashboard/AbsentReactTable";
import moment from "moment";
import {
  convertHeaders,
  isWeekday,
  assignColorsToSubstitutions,
} from "./tableUtils";
import DatePicker, { registerLocale } from "react-datepicker";
import nl from "date-fns/locale/nl";
import AppInnerCard from "Layout/AppInnerCard";
import AppInnerFooter from "Layout/AppInnerFooter";
import Select from "Components/Select";
import PlanboardService from "Api/Verzoeken/Planboard";
registerLocale("nl", nl);

const Default = () => {
  const [showLoader, setShowLoader] = useState(true);
  //fetched data
  const [schoolsList, setSchoolsList] = useState([]);
  const [communityList, setCommunityList] = useState([]);
  const [codes, setCodes] = useState([]);
  const [contractTypes, setContractTypes] = useState([]);

  //selected data
  const [communityId, setCommunityId] = useState();
  const [filterCommunityId, setFilterCommunityId] = useState();
  const [schoolId, setSchoolId] = useState();
  const [codeId, setCodeId] = useState();
  const [contractTypeId, setContractTypeId] = useState();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [absentsFilter, setAbsenceFilter] = useState("actueel");

  const [calendarBtn, setCalendarBtn] = useState(false);
  const [absentData, setAbsentData] = useState([]);
  const [absentHeaders, setAbsentHeaders] = useState([]);

  const [isOpenCalendar, setIsOpenCalendar] = useState(false);

  const absentDataRows = useMemo(
      () => absentData,
      [absentData]
  );

  const absentCols = useMemo(() => absentHeaders, [absentHeaders]);
  const absentSchools = useMemo(() => schoolsList, [schoolsList]);

  useEffect(() => {
    setShowLoader(true);
    PlanboardService.communityList().then((response) => {
        if (response.data.status === true || response.status === 200) {
          setCommunityList(response.data.data);
        }
      })
      .catch(() => console.error);

    getSchoolList();
  }, []);

  const getSchoolList = (community = 0) => {
    let link = "schools";
    if (community !== 0) {
      link = "schools-list?iCommunityId=" + community;
    }
    axiosInstance
      .get(link)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.data != null && response.data.data != undefined) {
            let schoolsData = response.data.data;
            setSchoolsList(schoolsData);
          }
        }
      })
      .catch((error) => console.log("catch error:", error));
  };

  useEffect(() => {
    if (filterCommunityId !== communityId) {
      setCodeId("");
      setContractTypeId("");
    }
    setCommunityId(filterCommunityId);

    if (filterCommunityId !== "" && filterCommunityId !== undefined) {
      PlanboardService.absentCodeList(filterCommunityId).then((response) => {
          if (response.status == 200) {
            if (response.data.data != null && response.data.data != undefined) {
              let codesData = response.data.data;
              setCodes(codesData);
            }
          }
        })
        .catch((error) => console.log("catch error:", error));

      getSchoolList(filterCommunityId);

      PlanboardService.contractType(filterCommunityId).then((response) => {
          if (response.status == 200) {
            if (response.data.data != null && response.data.data != undefined) {
              let contractTypesData = response.data.data;
              setContractTypes(contractTypesData);
            }
          }
        })
        .catch((error) => console.log("catch error:", error));
    } else {
      setCodes([]);
      getSchoolList();
      setContractTypes([]);
    }
  }, [filterCommunityId]);

  useEffect(() => {
    if (!schoolsList.find((school) => school.iSchoolId == schoolId)) {
      setSchoolId("");
    }
  }, [schoolsList]);

  const fetchAbsents = async () => {
    const dt = currentDate.toLocaleDateString("es-CL");
    let url = new URL(axiosInstance.defaults.baseURL + "planboard");

    url.searchParams.append("start-date", dt);

    if (schoolId) {
      url.searchParams.append("school", schoolId);
    }
    if (communityId) {
      url.searchParams.append("community", communityId);
    }
    if (codeId) {
      url.searchParams.append("code", codeId);
    }
    if (contractTypeId) {
      url.searchParams.append("contract-type", contractTypeId);
    }
    if (absentsFilter !== "actueel") {
      url.searchParams.append("filter", absentsFilter);
    }

    setShowLoader(true);

    const response = await axiosInstance
      .get(url)
      .catch((err) => console.log(err));

    console.log(response);

    if (response && response.status === 200) {
      if (response.data === null) {
        setAbsentData({ errorMessage: "No data" });
        setShowLoader(false);
      } else if (
        !response.data.absents.headerDates ||
        !response.data.absents.data ||
        response.data.absents.headerDates.length !== 5
      ) {
        setAbsentData({
          errorMessage:
            "There is no enough data to build table(Server error)" +
            response.data.absents,
        });
        setShowLoader(false);
      } else {
        response.data.absents.data.forEach((substitutor) => {
          assignColorsToSubstitutions(substitutor);
        });

        setAbsentData(response.data.absents.data);

        const headerDates = JSON.parse(
          JSON.stringify(response.data.absents.headerDates)
        );
        headerDates.map(
          (data) =>
            data.date !== null &&
            (data.date = new Date(moment(data.date, "DD-MM-YYYY"))
              .toLocaleDateString("nl-NL", { weekday: "short" })
              .concat(moment(data.date, "DD-MM-YYYY").format(" DD-MM-YYYY")))
        );
        const headers = convertHeaders(headerDates);
        setAbsentHeaders(headers);

        setShowLoader(false);
      }
    } else {
      setAbsentData({ errorMessage: "Internal Server Error" });
      setShowLoader(false);
    }
  };

  useEffect(() => {
    fetchAbsents();
  }, [
    currentDate,
    schoolId,
    communityId,
    codeId,
    contractTypeId,
    absentsFilter,
  ]);

  useEffect(() => {
    if (absentsFilter === 'alle') {
      setCodeId("");
      setSchoolId("");
    }
  }, [absentsFilter])

  const handleCalendarButtonClick = () => {
    setIsOpenCalendar(!isOpenCalendar);
  };

  const handleCalendarDataChange = (date) => {
    setCurrentDate(date);
    setIsOpenCalendar(false);
  };

  const CustomCalendarInput = forwardRef((props, ref) => (
    <button
      ref={ref}
      onClick={handleCalendarButtonClick}
      onMouseEnter={() => setCalendarBtn(true)}
      onMouseLeave={() => setCalendarBtn(false)}
      className="footer--coordinator-planboard__calendar-btn"
      type="button"
    >
      {calendarBtn ? <CalendarIconWhite /> : <CalendarIcon />}
    </button>
  ));

  const prevWeek = () => {
    currentDate.setDate(currentDate.getDate() - 7);
    setCurrentDate(new Date(currentDate));
  };

  const nextWeek = () => {
    let monday = getMonday(currentDate.setDate(currentDate.getDate() + 7));
    setCurrentDate(monday);
  };

  const getMonday = (d) => {
    d = new Date(d);
    let day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  };

  const minDate = useMemo(() => {
    return new Date(new Date().setFullYear(currentDate.getFullYear() - 10));
  }, [currentDate]);

  const maxDate = useMemo(() => {
    return new Date(new Date().setFullYear(currentDate.getFullYear() + 10));
  }, [currentDate]);

  return (
    <>
      <Fragment>
        <TransitionGroup>
          <CSSTransition component="div" className="TabsAnimation" appear={true}
            timeout={1500} enter={false} exit={false}>
            <div>
            <AppInnerCard 
                    headingProps={{
                      useDefaultPath: false,
                      additionalBreadcrumbs: [{ href: "/#/verzoeken/planbord", label: "Verzoeken" }],
                    }}
                   
                  >
              <Row className="tableMainRow">
                <Col md="12" lg="12">
                  <Card className="main-card"> 
                    <CardBody className=" d-flex justify-content-center text-capitalize">

                      {showLoader ? (
                        <Loader type="ball-grid-pulse" />
                      ) : absentData.errorMessage ? (
                        <div>{absentData.errorMessage}</div>
                      ) : (
                        <AbsentReactTable
                          data={absentDataRows}
                          columns={absentCols}
                          filters={{
                            search: false,
                            pagination: false,
                            sorting: false,
                          }}
                          school={absentSchools}
                          tableClassName="absent-table-dashboard mb-0 table table-borderless table-sm subs-main-table planboard"
                        />
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              </AppInnerCard>
              <AppInnerFooter
                footerWrapperClassName="footer--coordinator-planboard"
                cardFooterClassName="flex-wrap flex-xxl-nowrap gap-2 margin-bottom-20 justify-content-center justify-content-xxl-between"
              >
                <div className="flex-xxl-grow-1 flex-wrap flex-xxl-nowrap d-flex justify-content-center justify-content-xxl-start gap-2 footer--coordinator-planboard__checkbox-container">
                  <Select
                    onChange={(event) =>
                      setAbsenceFilter(event.target.value)
                    }
                    value={absentsFilter}
                    selectClassName='footer--coordinator-planboard__checkbox'
                    options={[
                      { label: 'Actueel', value: 'actueel', selected: absentsFilter == "actueel" },
                      { label: 'Alle', value: 'alle', selected: absentsFilter == "alle" }
                    ]}
                  />

                  <Select
                    selectClassName='footer--coordinator-planboard__checkbox'
                    options={[
                      { value: '', label: "Alle besturen", selected: communityId === '' },
                      ...communityList.map((community) => ({
                        value: community.iCommunityId,
                        label: community.vCommunityName ?? community.name,
                        selected: communityId == community.iCommunityId,
                      })),
                    ]}
                    value={filterCommunityId}
                    onChange={(event) =>
                      setFilterCommunityId(event.target.value)
                    }
                  />

                  <Select
                    selectClassName='footer--coordinator-planboard__checkbox'
                    options={[
                      { value: '', label: "Selecteer school", selected: schoolId === "" },
                      ...schoolsList.map((school) => ({
                        value: school.iSchoolId,
                        label: school.vSchoolName ?? school.vSchoolName,
                        selected: schoolId == school.iSchoolId,
                      })),
                    ]}
                    value={schoolId}
                    onChange={(event) => setSchoolId(event.target.value)}
                    disabled={absentsFilter === "alle"}
                  />

                  <Select
                    selectClassName='footer--coordinator-planboard__checkbox'
                    options={[
                      { value: '', label: "Alle contracttypes", selected: contractTypeId === "" },
                      ...contractTypes.map((ct) => ({
                        value: ct.iContractTypeId,
                        label: ct.vContractTypeAbbr + " - " + ct.vContractTypeName,
                        selected: contractTypeId == ct.iContractTypeId,
                      })),
                    ]}
                    value={contractTypeId}
                    disabled={contractTypes.length === 0}
                    onChange={(event) =>
                      setContractTypeId(event.target.value)
                    }
                  />
                  <Select
                    selectClassName='footer--coordinator-planboard__checkbox'
                    options={[
                      { value: "", label: "Selecteer code", selected: codeId === "" },
                      ...codes.map((code) => ({
                        value: code.iCodeId,
                        label: code.vCode,
                        selected: codeId == code.iCodeId,
                      })),
                    ]}
                    value={codeId}
                    disabled={codes.length === 0 || absentsFilter == "alle"}
                    onChange={(event) => setCodeId(event.target.value)}
                  />
                </div>
                <div className="d-flex gap-2">
                  <Button
                    outline
                    className="btn-outline-primary-blue btn-color-style footer--coordinator-planboard__btn"
                    onClick={prevWeek}
                    type="button"
                    color="primary"
                  >
                    Vorige
                  </Button>
                  <Button
                    outline
                    className="btn-outline-primary-blue btn-color-style footer--coordinator-planboard__btn"
                    onClick={nextWeek}
                    type="button"
                    color="primary"
                  >
                    Volgende
                  </Button>
                  <DatePicker
                    wrapperClassName="footer--coordinator-planboard__calendar-wrapper"
                    popperClassName="footer--coordinator-planboard__calendar"
                    locale="nl"
                    selected={currentDate}
                    onChange={(date) => handleCalendarDataChange(date)}
                    onClickOutside={() => setIsOpenCalendar(false)}
                    customInput={<CustomCalendarInput />}
                    open={isOpenCalendar}
                    enableTabLoop={false}
                    filterDate={isWeekday}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    minDate={minDate}
                    maxDate={maxDate}
                    popperPlacement="top-end"
                  />
                </div>
              </AppInnerFooter>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    </>
  );
};

export default Default;
