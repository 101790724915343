import React, { Fragment, useState } from "react";
import { ReactComponent as Setting } from "../../../../../assets/utils/images/svgs/setting-gray.svg";
import { ReactComponent as BuildingIcon } from "../../../../../assets/utils/images/svgs/building.svg";
import { ReactComponent as HistoryClockIcon } from "../../../../../assets/utils/images/svgs/HistoryClock.svg";
import Algemeen from "./Algemeen"
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PageHeading from "Layout/AppMain/PageHeading";
import Instellingen from "./Instellingen";
import { schoolTabs } from "../utils";
import Wtf from "./Wtf";

const SchoolsView = () => {
    const [activeTab, setActiveTab] = useState(schoolTabs.Algemeen);
    const [schoolName, setSchoolName] = useState("");
    const getActiveTab = (tabState) => {
        switch (tabState) {
            case schoolTabs.Instellingen:
                return (<Instellingen setActiveTab={setActiveTab}/>);
            case schoolTabs.Wtf:
                return (<Wtf setActiveTab={setActiveTab}/>);
            default: return (
                <Algemeen setSchoolName={setSchoolName}/>);
        }
    };

    const isActiveTab = (currentTab) => {
        return activeTab === currentTab
    }

    return (
        <div className="gebruikers-view scholen-view">
            <PageHeading schoolName={schoolName}/>
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    <Fragment>
                        <div className="d-flex gap-4" style={{ marginBottom: '20px' }}>
                            <div className="w-auto user-ctn col">
                                {getActiveTab(activeTab)}
                            </div>
                            <div className="users-tab">
                                <p className={isActiveTab(schoolTabs.Algemeen) ? 'active' : ''} onClick={() => { setActiveTab(schoolTabs.Algemeen) }}><Setting style={{ width: '22px', height: '24px' }} />Algemeen</p>
                                <p className={isActiveTab(schoolTabs.Instellingen) ? 'active' : ''} onClick={() => { setActiveTab(schoolTabs.Instellingen) }}><BuildingIcon style={{ width: '20px', height: '20px' }} />Instellingen</p>
                                <p className={isActiveTab(schoolTabs.Wtf) ? 'active' : ''} onClick={() => { setActiveTab(schoolTabs.Wtf) }}><HistoryClockIcon style={{ width: '20px', height: '20px' }} />Wtf</p>
                            </div>
                        </div>
                    </Fragment>
                </CSSTransition>
            </TransitionGroup>
        </div>
    )
};

export default SchoolsView;