import React, { useState } from "react";
import { Form, Formik } from "formik";
import { Card, CardBody, FormGroup, Label, Spinner } from 'reactstrap'
import 'tippy.js/themes/light.css';
import GroupStatus from "../../../../../Components/IconDropdown/Cross_Tick/GroupStatus";

const ControleInstellingenPlanning = ({ data, handleIconFieldChange, errorField }) => {
    const [loadingKeys, setLoadingKeys] = useState({})

    const defaultValues = {
        Geldigid: data?.lists?.iCheckID,
        GeldigeVOG: data?.lists?.iCheckVOG,
        Pabo: data?.lists?.iCheckPABO,
        Ketenregeling: data?.lists?.iCheckKETEN,
        Maanden: data?.lists?.iCheck36,
        Blokkeerdatum: data?.lists?.iCheckBK,
    }

    const handleToggle = (field, data) => {
        setLoadingKeys(prev => ({ ...prev, [field]: true }));
        handleIconFieldChange(field, data, null, setLoadingKeys)
    };

    return (
        <>
            <Card className="main-card">
                <CardBody className="d-flex flex-column">
                    <div className="btn-color-style travelCost-blue-text mb-4">Controle-instellingen planning</div>

                    <Formik
                        enableReinitialize
                        initialValues={defaultValues}
                    >
                        {({ values }) => (
                            <Form>
                                <FormGroup className="form-element">
                                    <Label for="Geldigid">Geldig Id</Label>
                                    <div className="position-relative w-50">
                                        <div className="d-flex gap-1 align-items-center ">
                                            {loadingKeys["iCheckID"] ? (
                                                <div className="spinner-icon-change">
                                                    <div><Spinner size="sm" color="primary" /></div>
                                                </div>
                                            ) :
                                                <GroupStatus iconChangeHandler={handleToggle} field={"iCheckID"} data={values?.Geldigid ? 0 : 1} controlFrom={"contracttype"} />
                                            }
                                        </div>
                                    </div>
                                </FormGroup>

                                <FormGroup className={`form-element svg-pointer`}>
                                    <Label for="GeldigeVOG">Geldige VOG</Label>
                                    <div className="position-relative w-50">
                                        <div className="d-flex gap-1 align-items-center ">
                                            {loadingKeys["iCheckVOG"] ? (
                                                <div className="spinner-icon-change">
                                                    <div><Spinner size="sm" color="primary" /></div>
                                                </div>
                                            ) :
                                                <GroupStatus iconChangeHandler={handleToggle} field={"iCheckVOG"} data={values?.GeldigeVOG ? 0 : 1} controlFrom={"contracttype"} />
                                            }
                                        </div>
                                    </div>
                                </FormGroup>

                                <FormGroup className={`form-element ${errorField === 'Tarief' ? 'error' : ''}`}>
                                    <Label for="Pabo">Pabo</Label>
                                    <div className="position-relative w-50">
                                        <div className="d-flex gap-1 align-items-center ">
                                            {loadingKeys["iCheckPABO"] ? (
                                                <div className="spinner-icon-change">
                                                    <div><Spinner size="sm" color="primary" /></div>
                                                </div>
                                            ) :
                                                <GroupStatus iconChangeHandler={handleToggle} field={"iCheckPABO"} data={values?.Pabo ? 0 : 1} controlFrom={"contracttype"} />
                                            }
                                        </div>
                                    </div>
                                </FormGroup>

                                <FormGroup className="form-element">
                                    <Label for="Ketenregeling">Ketenregeling</Label>
                                    <div className="position-relative w-50">
                                        <div className="d-flex gap-1 align-items-center ">
                                            {loadingKeys["iCheckKETEN"] ? (
                                                <div className="spinner-icon-change">
                                                    <div><Spinner size="sm" color="primary" /></div>
                                                </div>
                                            ) :
                                                <GroupStatus iconChangeHandler={handleToggle} field={"iCheckKETEN"} data={values?.Ketenregeling ? 0 : 1} controlFrom={"contracttype"} />
                                            }
                                        </div>
                                    </div>
                                </FormGroup>

                                <FormGroup className={`form-element ${errorField === 'Afkorting' ? 'error' : ''}`}>
                                    <Label for="Maanden">36 Maanden</Label>
                                    <div className="position-relative w-50">
                                        <div className="d-flex gap-1 align-items-center ">
                                            {loadingKeys["iCheck36"] ? (
                                                <div className="spinner-icon-change">
                                                    <div><Spinner size="sm" color="primary" /></div>
                                                </div>
                                            ) :
                                                <GroupStatus iconChangeHandler={handleToggle} field={"iCheck36"} data={values?.Maanden ? 0 : 1} controlFrom={"contracttype"} />
                                            }
                                        </div>
                                    </div>
                                </FormGroup>

                                <FormGroup className={`form-element ${errorField === 'Tarief' ? 'error' : ''}`}>
                                    <Label for="Blokkeerdatum">Blokkeerdatum</Label>
                                    <div className="position-relative w-50">
                                        <div className="d-flex gap-1 align-items-center ">
                                            {loadingKeys["iCheckBK"] ? (
                                                <div className="spinner-icon-change">
                                                    <div><Spinner size="sm" color="primary" /></div>
                                                </div>
                                            ) :
                                                <GroupStatus iconChangeHandler={handleToggle} field={"iCheckBK"} data={values?.Blokkeerdatum ? 0 : 1} controlFrom={"contracttype"} />
                                            }
                                        </div>
                                    </div>
                                </FormGroup>
                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card>
        </>
    )
}

export default ControleInstellingenPlanning;