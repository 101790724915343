export const debounce = (fn, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            fn(...args);
        }, delay);
    };
};

export const downloadBase64File = (file, fileName) => {
       const [prefix, data] = file.split(",");
       const mimeType = prefix.match(/:(.*?);/)[1]; 
   
       const binaryData = atob(data);
       const byteNumbers = new Array(binaryData.length);
       for (let i = 0; i < binaryData.length; i++) {
           byteNumbers[i] = binaryData.charCodeAt(i);
       }
       const byteArray = new Uint8Array(byteNumbers);
   
       const blob = new Blob([byteArray], { type: mimeType });
       const blobUrl = URL.createObjectURL(blob);
   
       const link = document.createElement("a");
       link.href = blobUrl;
       link.download = fileName || "downloaded_file";
       document.body.appendChild(link);
   
       link.click();
       document.body.removeChild(link);
       URL.revokeObjectURL(blobUrl);
}