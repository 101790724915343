import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, CardBody, CardFooter, FormGroup, Input, Label } from 'reactstrap'
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PageHeading from "Layout/AppMain/PageHeading";
import { useLocation, useNavigate } from "react-router";
import FllterInstellingenPlanning from "./FllterInstellingen";
import InstellingenForm from "./InstellingenForm";
import { ReactComponent as BackIcon } from "../../../../../assets/utils/images/svgs/arrow-left.svg";
import ContractWijzigenToevoegenService from "Api/Instellingen/ContractTypen/WijzigenToevoegen";
import Loader from 'react-loaders'
import { toast } from "react-toastify";
import { debounce } from "../../../../../helperFunctions/index";
const FllterInstellingen = ({ errorField }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { planning, iCommunityId, filterId, role } = location.state;

    const [filterValue, setFilterValue] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        showFilter();
    }, [])

    const showFilter = () => {
        const data = {
            iCommunityId: iCommunityId,
            filterId: filterId,
            role: planning === "Schoolplanning" ? "1" : "0"
        }
        ContractWijzigenToevoegenService.showFilter(data).then((response) => {
            if (response?.data.status) {
                setFilterValue(response?.data.data)
                setIsLoading(false);
            }
        })
    }

    const callApi = (field, value, setFieldError, setLoadingKeys) => {
        if (value === "") {
            setFieldError(field, "required");
            return;
        }
        const data = {
            iCommunityId: iCommunityId,
            filterId: filterId,
            role: planning === "Schoolplanning" ? "1" : "0",
            ...(field ? { attr: field } : {}),
            [field]: value,
        }
        ContractWijzigenToevoegenService.contractFilterSave(data)
            .then(response => {
                if (response.data.status) {
                    setFilterValue(prev => ({
                        ...prev,
                        ...response.data.data
                    }));
                };
                if (!response.data.status) {
                    const errors = response.data.data[field];
                    if (Array.isArray(errors)) {
                        toast.error(errors.join(','));
                    } else {
                        toast.error(errors);
                    }
                }
            })
            .catch(error => {
                console.error('API error:', error);
                toast.error("Er is iets misgegaan");
            }).finally(() => { if (setLoadingKeys) setLoadingKeys(prev => ({ ...prev, [field]: false })); return });
    };

    const debouncedApiCall = debounce(callApi, 500);

    const handleFieldChange = (field, value, setFieldError) => {
        debouncedApiCall(field, value, setFieldError);
    };
    const handleIconFieldChange = (field, value, setLoadingKeys) => {
        callApi(field, value, null, setLoadingKeys);
    };

    return (
        <Fragment>
            <PageHeading />
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    {isLoading ? <Card className="main-card">
                                <CardBody className="d-flex flex-column"><Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} /></CardBody></Card>
                        :
                        <>
                            <div className='row contract-typen-view mb-5'>
                                <div className='col-xl-4 p-2'>
                                    <FllterInstellingenPlanning data={filterValue} planning={planning} handleIconFieldChange={handleIconFieldChange} handleFieldChange={handleFieldChange} />
                                </div>
                                <div className='col-xl-8 p-2'>
                                    <InstellingenForm data={filterValue} handleIconFieldChange={handleIconFieldChange} handleFieldChange={handleFieldChange} />
                                </div>
                            </div>

                            <div className="chat-layout-footer">
                                <CardFooter className="chat-layout-footer__inner gebruikers-footer">
                                    <div className="d-flex gap-3">
                                        <Button
                                            outline
                                            className="btn-outline-primary-blue ms-auto"
                                            onClick={() => {
                                                navigate(-1)
                                            }}
                                            type="button"
                                            color="primary"
                                        >
                                            <BackIcon />
                                        </Button>
                                    </div>
                                </CardFooter>
                            </div>
                        </>
                    }
                </CSSTransition>
            </TransitionGroup>
        </Fragment>
    )
}

export default FllterInstellingen;