import PageHeading from "Layout/AppMain/PageHeading";
import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { CardFooter, Col, Row } from "reactstrap";
import Records from "./table";
import Loader from "react-loaders";
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";
import DistanceService from "Api/Distances";

const Default = () => {

    const [data, setData] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [selectedCommunity, setSelectedCommunity] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [deleteId, setDeleteId] = useState(0);

    useEffect(() => {
        getSubstitutorDistanceList(selectedCommunity);
    }, [selectedCommunity]);

    const getSubstitutorDistanceList = (value) => {
        DistanceService.distanceList(value).then((response) => {
            if (response.data.status === true) {
                setData(response.data.data)
                setIsLoading(false)
            }
        }).catch(() => console.error);
    }

    const confirmDelete = (id) => {
        setShowModal(true);
        setDeleteId(id);
    }

    const deleteDistance = (id) => {
        setShowModal(false);
        const dataToSend = { iAdminId: id };
        DistanceService.deleteDistance(dataToSend).then((response) => {
            if (response.data.status === true) {
                getSubstitutorDistanceList(selectedCommunity)
            }
        }).catch(() => console.error);
        toast.success("Afstand succesvol verwijderd");
    }

    const updateDistance = (() => {
        let timeout;
        return (distance, adminId, schoolId) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                const dataToSend = { dist: distance, iAdminId: adminId, iSchoolId: schoolId };
                DistanceService.updateDistance(dataToSend).then((response) => {
                        if (response.data.status === true) {
                            toast.success("Afstand succesvol verwijderd");
                            getSubstitutorDistanceList(selectedCommunity)
                        }
                    })
                    .catch(() => console.error);
            }, 1000); 
        };
    })();
    
    const handleCommunityChange = (event) => {
        setIsLoading(true)
        setSelectedCommunity(event.target.value);
    };

    return (
        <Fragment>
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    <div>
                        <Row>
                            <Col md="10" lg="10">
                                <PageHeading />
                            </Col>
                        </Row>
                        {isLoading ?
                            <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
                            :
                            (
                                <Row>
                                    <Col md="12" lg="12" className="pb-5">
                                        <Records data={data} deleteDistance={confirmDelete} updateDistance={updateDistance}/>
                                    </Col>
                                </Row>
                            )
                        }
                        <SweetAlert title="Wilt u de geselecteerde afstand verwijderen?" show={showModal}
                            type="warning"
                            onCancel={() => setShowModal(false)}
                            onConfirm={() => deleteDistance(deleteId)}
                            cancelBtnText="Nee"
                            confirmBtnText="Ja"
                            showCancel={true}
                        />
                    </div>
                </CSSTransition>
            </TransitionGroup>
            { !isLoading && 
                <div className="chat-layout-footer verzoken-footer-height">
                <CardFooter className="chat-layout-footer__inner gebruikers-footer">
                    <div className="d-flex gap-3">
                         <select
                            className="form-select"
                            style={{ height: '33px' }}
                            value={selectedCommunity}
                            onChange={handleCommunityChange}
                        >
                            <option value='' disabled>Alle Organisaties</option>
                            {data?.communityList?.map((community, index) => (
                                <option key={index} value={community.iCommunityId}>{community.name}</option>
                            ))}
                        </select>
                    </div>
                </CardFooter>
                </div>
            }
        </Fragment>
    )
};

export default Default;
