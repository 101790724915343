import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import SweetAlert from 'react-bootstrap-sweetalert';
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Col,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavLink,
    Row,
    UncontrolledButtonDropdown,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup, Label,
    ButtonGroup
} from "reactstrap";
// import {IoIosGrid} as Ionicons from "react-icons/io";
import Details from "./Details";
import Chat from "./Chat";
import RequestList from "./RequestList";
import PageHeading from "Layout/AppMain/PageHeading";
import { ReactComponent as Arrow } from "../../../../../assets/utils/images/svgs/Arrow 3.svg";
import { ReactComponent as EditPen } from "../../../../../assets/utils/images/svgs/editPen.svg";
import { ReactComponent as GrrayCircle } from "../../../../../assets/utils/images/svgs/GrrayCircle.svg";
import { ReactComponent as CalenderIcon } from "../../../../../assets/utils/images/svgs/CalenderIcon.svg";
import { ReactComponent as Home } from "../../../../../assets/utils/images/svgs/home.svg";
import { ReactComponent as Save } from "../../../../../assets/utils/images/svgs/save_two.svg";
import { ReactComponent as Small_Arrow } from "../../../../../assets/utils/images/svgs/Small_Arrow.svg";
import { ReactComponent as Rood } from "../../../../../assets/utils/images/svgs/Rood.svg";
import { ReactComponent as Groen } from "../../../../../assets/utils/images/svgs/Groen.svg";
import { ReactComponent as Paars } from "../../../../../assets/utils/images/svgs/Paars.svg";
import { ReactComponent as Oranje } from "../../../../../assets/utils/images/svgs/Oranje.svg";
import { ReactComponent as Grijs } from "../../../../../assets/utils/images/svgs/Grijs.svg";
import { ReactComponent as ShowSchool } from "../../../../../assets/utils/images/svgs/ShowSchool.svg";
import { ReactComponent as RaedSchool } from "../../../../../assets/utils/images/svgs/RaedSchool.svg";
import { ReactComponent as Teachershow } from "../../../../../assets/utils/images/svgs/Teacher-show.svg";
import { ReactComponent as Teacher } from "../../../../../assets/utils/images/svgs/Teacher.svg";
import { ReactComponent as TeacherRead } from "../../../../../assets/utils/images/svgs/teacher-read.svg";
import { ReactComponent as LogIcon } from "../../../../../assets/utils/images/svgs/log.svg";
import UpdateRequestModel from "../../../../../Components/Modal/UpdateRequestModel"
import DropdownList from "react-widgets/DropdownList";
import { ErrorMessage, Formik, Field } from "formik";
import * as Yup from "yup";
import moment from "moment";
import UpdateRequestInBulkModel from "Components/Modal/UpdateRequestInBulkModel";
import InformationModel from "Components/Modal/InformationModal";
import VerzoekenService from "Api/Verzoeken";
import Logs from "./Logs";
import Loader from "react-loaders";

const Default = (props) => {
    const params = useParams();
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    const [value, setValue] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [isLongContractOpen, setIsLongContractOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [isEmpty, setIsEmpty] = useState(false);
    const [comments, setComments] = useState([]);
    const [list, setList] = useState([]);
    const substitutionId = params.id || '';
    const [isChecked, setIsChecked] = useState(false);
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [homeDropdownShow, setHomeDropdownShow] = useState(false);
    const [userDropdownShow, setUserDropdownShow] = useState(false);
    const [adminList, setAdminList] = useState([]);
    const [type, setType] =useState('');
    const [longContractId, setLongContractId] = useState(0);
    const [menuShow, setMenuShow] = useState(false);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [editRequestIsOpen, setEditRequestIsOpen] = useState(false);
    const [bulkRequestIsOpen, setBulkRequestIsOpen] = useState(false);
    const [bulkShiftData, setBulkShiftData] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [isInformationModalOpen, setIsInformationModalOpen] = useState(false);
    const [statusCount, setStatusCount] = useState(0);
    const [changedStatusIds, setChangedStatusIds] = useState([]);
    const [bulkFormData, setBulkFormData] = useState({
        start: '',
        end: '',
        substitutor: '',
        current_state: '',
        newDayType: '',
        newWtfType: '',
        newStatus: '',
        newInform: 'yes',
        items: [],
        wtfItems: [],
    });
    const [formDataState, setFormDataState] = useState({
        ma: '',
        di: '',
        wo: '',
        doo: '',
        vr: '',
        date1: '',
        date2: '',
        iAdminId: '',
    });

    const [defaultValue, setDefaultValue] = useState({
        ma: '0,',
        di: '0,',
        wo: '0,',
        doo: '0,',
        vr: '0,',
        date1: '',
        date2: '',
        iAdminId: '',
    });

    const [logModalContentType, setLogModalContentType] = useState({
        state: false,
        type: 'dagdelen'
    });

    const updateModalContentType = (state, type)=>{
        setLogModalContentType({
            state,
            type 
        });
    }

    useEffect(() => {
        getRequests();
    }, []);

    const deleteDates = (dayPartId) => {
        VerzoekenService.deleteDayPartById(dayPartId).then((response) => {
            if (response.status === 200) {
                getRequests();
            }
        })
        .catch((error) => console.log("catch error:", error));
        toast["success"]("Dagdelen zijn succesvol verwijderd!")
    }

    const updateStatus = (iSubsDateId, eSubStatus) => {

        let formData = new FormData();
        formData.append('iSubstitutionId', substitutionId);
        formData.append('iSubsDateId', iSubsDateId);
        formData.append('eSubStatus', eSubStatus);

        VerzoekenService.updateDayPartStatus(formData).then((response) => {
            if (response.status === 200) {
                // getRequests();
            }
        })
        .catch((error) => console.log("catch error:", error));
    }

    const updateWtf = (iSubsDateId, wtf) => {
        let formData = new FormData();
        formData.append('iSubstitutionId', substitutionId);
        formData.append('iSubsDateId', iSubsDateId);
        formData.append('wtf', wtf);

        VerzoekenService.updateWtf(formData).then((response) => {
            if (response.status === 200) {
                toast["success"]("wtf is succesvol bijgewerkt")
            }
        })
        .catch((error) => console.log("catch error:", error));
    }

    const getRequests = () => {
        setIsLoading(true);
        VerzoekenService.getRequestById(substitutionId).then((response) => {
            if (response.status == 200) {
                setShowModal(false);
                if (response.data.status === true) {
                    setData(response.data);
                    setComments(response.data.data.comments);
                    setList(response.data.data.list);
                }
                setIsLoading(false);
            }
        }).catch((error) => console.log("catch error:", error));
    }

    const changeStatus = (iSubsDateId, eSubStatus) => {
        Object.keys(data.data?.list).map(function (key, index) {
            if (data.data.list[key]['data'].Ochtend && data.data.list[key]['data'].Ochtend.iSubsDateId == iSubsDateId) {
                data.data.list[key]['data'].Ochtend.eSubStatus = eSubStatus
            }
            else {
                if (data.data.list[key]['data'].Middag && data.data.list[key]['data'].Middag.iSubsDateId == iSubsDateId)
                    data.data.list[key]['data'].Middag.eSubStatus = eSubStatus
            }
        })
        setData(data);
        updateStatus(iSubsDateId, eSubStatus)
        setValue(value => value + 1);
    }

    const confirmDelete = (id) => {
        setShowModal(true);
        setDeleteId(id);
    }

    const sendMessage = (e) => {
        e.preventDefault()

        if (message.trim().length) {

            const msg = {
                text: message,
                substitution: substitutionId
            }

            VerzoekenService.saveMessage(substitutionId,msg).then((response) => {
                if (response.data.status === "success") {
                    setIsOpen(false);
                    setComments([...comments, response.data.message])
                    setMessage("")
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        } else {
            setIsEmpty(true)
        }
    }

    const onChangeMessage = (e) => {
        setMessage(e.target.value);
        setIsEmpty(false);
    }

    const getArchivedMessages = (type) => {
        VerzoekenService.getArchivedMessageList(substitutionId,type).then((response) => {
            if (response.status == 200) {
                setShowModal(false);
                if (response.data.status === true) {
                    setComments(response.data.data);
                }
            }
        }).catch((error) => console.log("catch error:", error));
    }

    const switchIsChecked = (type) => {
        if (isChecked == false) {
            setIsChecked(true);
        } else {
            setIsChecked(false);
        }
        getArchivedMessages(type);
    }

    const saveShiftData = (value, type) => {
        let formData = new FormData();
        formData.append('iSubstitutionId', substitutionId);
        formData.append('value', value);
        formData.append('type', type);

        VerzoekenService.saveShiftData(formData).then((response) => {
            if (response.data.status == true) {
                setShow(false);
                setUserDropdownShow(false);
                setHomeDropdownShow(false);
                getRequests();
                toast["success"]("Uw wijzigingen zijn succesvol bijgewerkt.");
            } else {
                toast["error"]("Uw wijzigingen konden niet succesvol worden bijgewerkt.");
            }
        }).catch((error) => console.log("catch error:", error));
    }

    const dropDownIsOpen = () => {
        setMenuShow(false);
        setUserDropdownShow(false);
        setHomeDropdownShow(false);
        if (show === false) {
            setShow(true);
        } else {
            setShow(false);
        }
    }

    const menuIsOpen = () => {
        setShow(false);
        setUserDropdownShow(false);
        setHomeDropdownShow(false);
        if (menuShow === false) {
            setMenuShow(true);
        } else {
            setMenuShow(false);
        }
    }

    const homeIsOpen = () => {
        setMenuShow(false);
        setShow(false);
        setUserDropdownShow(false);
        if (homeDropdownShow === false) {
            setHomeDropdownShow(true);
        } else {
            setHomeDropdownShow(false);
        }
    }

    const userIsOpen = () => {
        setMenuShow(false);
        setShow(false);
        setHomeDropdownShow(false);
        if (userDropdownShow === false) {
            setUserDropdownShow(true);
        } else {
            setUserDropdownShow(false);
        }
    }

    const validationSchema = Yup.object().shape({
        ma: Yup.number()
        .transform((_, value) => {
           if (value.includes('.')) {
             return null;
           }
           return +value.replace(/,/, '.');
         })
         .test(
            'at-least-one-field',
            'Ten minste één veld moet een waarde hebben',
            function (value) {
                const { wo, di, vr, doo} = this.parent || {};
                return (value && value !== '') || (wo && wo !== '') || (di && di !== '') || (vr && vr !== '') || (doo && doo !== '');
            }
        ),

        di: Yup.string().test(
            'at-least-one-field',
            'Ten minste één veld moet een waarde hebben',
            function (value) {
                const { ma, wo, vr, doo} = this.parent || {};
                return (value && value !== '') || (ma && ma !== '') || (wo && wo !== '') || (vr && vr !== '') || (doo && doo !== '');
            }
        ),

        wo: Yup.string().test(
            'at-least-one-field',
            'Ten minste één veld moet een waarde hebben',
            function (value) {
                const { ma, di, vr, doo} = this.parent || {};
                return (value && value !== '') || (ma && ma !== '') || (di && di !== '') || (vr && vr !== '') || (doo && doo !== '');
            }
        ),

        doo: Yup.string().test(
            'at-least-one-field',
            'Ten minste één veld moet een waarde hebben',
            function (value) {
                const { ma, di, vr, wo} = this.parent || {};
                return (value && value !== '') || (ma && ma !== '') || (di && di !== '') || (wo && wo !== '') || (vr && vr !== '');
            }
        ),

        vr: Yup.string().test(
            'at-least-one-field',
            'Ten minste één veld moet een waarde hebben',
            function (value) {
                const { ma, di, wo, doo} = this.parent || {};
                return (value && value !== '') || (ma && ma !== '') || (di && di !== '') || (wo && wo !== '') || (doo && doo !== '');
            }
        ),
        date1: Yup.string().required("veld is verplicht"),
        date2: Yup.string().required("veld is verplicht"),
        iAdminId: Yup.string().required("veld is verplicht"),
    });

    const longContractOpen = () => {
        setIsLongContractOpen(true);
        VerzoekenService.getLongContractAdminList(substitutionId).then((response) => {
            if (response.data.status === true) {

                let list = response.data.data;
                list.forEach(element => {
                    element.name = element.vLastName + ', ' + element.vMiddleName + ' ' + element.vFirstName;
                });
                setAdminList(list);
            }
        })
        .catch((error) => console.log("catch error:", error));
    }

    const resetFormValues = () => {
        setFormDataState({
            ...formDataState,
            ma: '',
            di: '',
            wo: '',
            doo: '',
            vr: '',
            date1: '',
            date2: '',
            iAdminId: '',
        });
        setDefaultValue({
            ...formDataState,
            ma: '0,',
            di: '0,',
            wo: '0,',
            doo: '0,',
            vr: '0,',
            date1: '',
            date2: '',
            iAdminId: '',
        });
    }

    const handleSubmit = () => {
        let formData = new FormData();
        formData.append('ma', defaultValue.ma);
        formData.append('di', defaultValue.di);
        formData.append('wo', defaultValue.wo);
        formData.append('doo', defaultValue.doo);
        formData.append('vr', defaultValue.vr);
        formData.append('date1', defaultValue.date1);
        formData.append('date2', defaultValue.date2);
        formData.append('iSubstitutionId', substitutionId);
        formData.append('iAdminId', defaultValue.iAdminId);
        formData.append('id', longContractId);

        if (type === 'update') {
            VerzoekenService.updateLongContract(formData).then((response) => {
                if (response.data.status === true) {
                    setType('');
                    toast["success"]("Het lange contract is succesvol bijgewerkt.")
                    resetFormValues();
                    setIsLongContractOpen(false);
                    getRequests();
                } else if (response.data.status === 304) {
                    setIsLongContractOpen(false);
                    toast["warning"]("datum bestaat al!")
                    resetFormValues();
                }
            })
            .catch((error) => console.log("catch error:", error));
        } else {
            VerzoekenService.saveLongContract(formData).then((response) => {
                if (response.data.status === true) {
                    toast["success"]("Het lange contract is succesvol opgeslagen.")
                    resetFormValues();
                    setIsLongContractOpen(false);
                    getRequests();
                } else if (response.data.status === 304) {
                    setIsLongContractOpen(false);
                    toast["warning"]("datum bestaat al!")
                    resetFormValues();
                }
            })
            .catch((error) => console.log("catch error:", error));
        }
        
    }

    const getLongContractDetails = (id) => {
        setLongContractId(id);
        VerzoekenService.getLongContractDetailsById(id).then((response) => {
            if (response.data.status === true) {
                setFormDataState({
                    ...formDataState,
                    ma: response.data.data.iWtfMa,
                    di: response.data.data.iWtfDi,
                    wo: response.data.data.iWtfWo,
                    doo: response.data.data.iWtfDo,
                    vr: response.data.data.iWtfVr,
                    date1: response.data.data.dDateBegin,
                    date2: response.data.data.dDateEnd,
                    iAdminId: response.data.data.iAdminId,
                });
                setDefaultValue({
                    ...formDataState,
                    ma: response.data.data.iWtfMa,
                    di: response.data.data.iWtfDi,
                    wo: response.data.data.iWtfWo,
                    doo: response.data.data.iWtfDo,
                    vr: response.data.data.iWtfVr,
                    date1: response.data.data.dDateBegin,
                    date2: response.data.data.dDateEnd,
                    iAdminId: response.data.data.iAdminId,
                });
                longContractOpen();
            }
        })
        .catch((error) => console.log("catch error:", error));
    }

    const userIconSwitch = (value) => {
        switch (value) {
            case 'No':
                return <TeacherRead />;
            case 'Yes':
                return <Teacher />;
            case 'Yes1':
                return <Teachershow />;
            default:
                return '';
        }
    }

    const homeIconSwitch = (value) => {
        switch (value) {
            case 'No':
                return <RaedSchool />;
            case 'Yes':
                return <Home />;
            case 'Yes1':
                return <ShowSchool />;
            default:
                return '';
        }
    }

    const bulkShiftInformation = () => {
        VerzoekenService.getBulkShiftDetailsById(substitutionId).then((response) => {
            if (response.data.status === true) {
                setBulkRequestIsOpen(true)
                setBulkShiftData(response.data.data);
                setCheckedList(Object.fromEntries(Object.entries(response.data.data.dayparts).filter(([key, value]) => value === 0)));
    
                let currentState = '';
                let newStatus = '';
                if (response.data.data.substList.length === 0) {
                    currentState = 'Openstaand';
                    newStatus = 'Verwijderen';
                } else {
                    currentState = '-1';
                    newStatus = '-1';
                }

                setBulkFormData({
                    start: response.data.data.startDate,
                    end: response.data.data.endDate,
                    substitutor: '-1',
                    current_state: currentState,
                    newDayType: '-1',
                    newWtfType: '-1',
                    newStatus: newStatus,
                    newInform: 'yes',
                    items: Object.fromEntries(Object.entries(response.data.data.dayparts).filter(([key, value]) => value === 0)),
                });
            }
        })
        .catch((error) => console.log("catch error:", error));
    }
    const handleDatepicker = (e) => {
        if (e) e.stopPropagation();
        setIsDatePickerOpen((prevState) => !prevState);
    };

    return (
        <>
            <Fragment>

                <TransitionGroup>
                    <CSSTransition component="div" className="TabsAnimation" appear={true}
                        timeout={1500} enter={false} exit={false}>
                        <div>
                            <div className="chat-layout-wrapper">
                                <div className="app-inner-layout chat-layout" >
                                    <PageHeading />
                                    <div className="app-inner-layout__wrapper">

                                        <Card className="app-inner-layout__content ">
                                        
                                            <Row>
                                                {isLoading ? <Col md={window.innerWidth < 1500 ? "12" : "10"} sm="12" className="pe-xxl-5">
                                                    {<Card className="main-card mb-3 ">
                                                        <CardBody className="p-3 d-flex justify-content-center ">
                                                        <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
                                                        </CardBody>
                                                    </Card>
                                                    }
                                                </Col>
                                                : <Col md={window.innerWidth < 1500 ? "12" : "10"} sm="12" className="pe-xxl-5">
                                                    <Details data={data?.data} getRequests={getRequests} getLongContractDetails={getLongContractDetails} setType={setType}/>
                                                    <Card className="main-card mb-3 ">
                                                        <CardBody className="p-3 d-flex justify-content-center ">
                                                            <RequestList dayTypeList={data.data?.dayType} list={data.data?.list} confirmDelete={confirmDelete} updateStatus={updateStatus} changeStatus={changeStatus} updateWtf={updateWtf} getRequests={getRequests} setStatusCount={setStatusCount} changedStatusIds={changedStatusIds} setChangedStatusIds={setChangedStatusIds} statusCount={statusCount}/>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                }
                                                 <Col md={window.innerWidth < 1500 ? "12" : "2"} sm="12">
                                                    <Chat setIsOpen={setIsOpen} comments={comments} list={list} getRequests={getRequests} nameList={data.data?.vervangerNameList} getArchivedMessages={getArchivedMessages} isChecked={isChecked} setIsChecked={setIsChecked} switchIsChecked={switchIsChecked}/>
                                                </Col>
                                            </Row>
                                        </Card>
                                        
                                    </div>

                                </div>

                                <div className="chat-layout-footer">
                                    <CardFooter className="d-block text-center d-flex chat-layout-footer__inner">
                                        <div className="flex-grow-1 d-flex justify-content-start">

                                        <UncontrolledButtonDropdown isOpen={menuShow}>
                                                <DropdownToggle color="link" className="p-0">
                                                    <Button className="d-flex align-items-center btn-color-style markeren-btn mb-2 me-2" size="sm" outline color="primary" onClick={() => menuIsOpen()}>
                                                        <EditPen />
                                                    </Button>
                                                </DropdownToggle>
                                                <DropdownMenu className={"rm-pointers dropdown-menu-lg menu-dropdown"}>
                                                    <Nav vertical>
                                                        <NavLink onClick={() => {longContractOpen()
                                                            setMenuShow(false)}
                                                        }>
                                                            Lang verzoek toevoegen
                                                        </NavLink>

                                                        <NavLink className="navlink-border" onClick={() => navigate(`/verzoeken/overzicht/${substitutionId}/dagdelen-toevoegen`)}>
                                                            Dagdeel toevoegen
                                                        </NavLink>

                                                        <NavLink className="navlink-border" onClick={() => {setMenuShow(false)
                                                            setEditRequestIsOpen(true)}
                                                        }>
                                                            Wijzig algemene gegevens
                                                        </NavLink>

                                                        <NavLink className="navlink-border" onClick={() => {
                                                            setMenuShow(false)
                                                            bulkShiftInformation();
                                                        }}>
                                                            Wijzig dagdelen
                                                        </NavLink>
                                                    </Nav>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                           
                                            {
                                                data.data !== undefined && 
                                                    <UncontrolledButtonDropdown isOpen={homeDropdownShow}>
                                                    <DropdownToggle color="link" className="p-0">
                                                            <Button className="d-flex align-items-center btn-color-style markeren-btn mb-2 me-2" size="sm" outline color="primary" onClick={() => homeIsOpen()}>
                                                                {homeIconSwitch(data.data.eOpmerkingen)}
                                                            </Button>
                                                        </DropdownToggle>
                                                        <DropdownMenu className={"rm-pointers dropdown-menu-lg  verzoeken-dropdown"} >

                                                            <Nav vertical>
                                                                <NavLink className="link-gap" onClick={() => saveShiftData('No', 'eOpmerkingen')}>
                                                                    <span><RaedSchool /></span>
                                                                    <span>Verbergen</span>
                                                                </NavLink>

                                                                <NavLink className="link-gap navlink-border " onClick={() => saveShiftData('Yes', 'eOpmerkingen')}>
                                                                    <span><Home /></span>
                                                                    <span>Tonen</span>
                                                                </NavLink>

                                                                <NavLink className="link-gap navlink-border " onClick={() => saveShiftData('Yes1', 'eOpmerkingen')}>
                                                                    <span><ShowSchool /></span>
                                                                    <span >Gelezen</span>
                                                                </NavLink>
                                                            </Nav>
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                            }
                                            
                                            {
                                                data.data !== undefined && 
                                                    <UncontrolledButtonDropdown isOpen={userDropdownShow}>
                                                        <DropdownToggle color="link" className="p-0">
                                                            <Button className="d-flex align-items-center markeren-btn btn-color-style mb-2 me-2" size="sm" outline color="primary" onClick={() => userIsOpen()}>
                                                                {userIconSwitch(data.data !== undefined ? data.data.eMarkeren : '')}
                                                            </Button>
                                                        </DropdownToggle>
                                                        <DropdownMenu className={"rm-pointers dropdown-menu-lg  verzoeken-dropdown"} >

                                                            <Nav vertical>

                                                                <NavLink className="link-gap navlink-border" onClick={() => saveShiftData('No', 'eMarkeren')}>
                                                                    <span><TeacherRead /></span>
                                                                    <span>Verbergen</span>
                                                                </NavLink>

                                                                <NavLink className="link-gap navlink-border " onClick={() => saveShiftData('Yes', 'eMarkeren')}>
                                                                    <span><Teacher /></span>
                                                                    <span>Tonen</span>
                                                                </NavLink>

                                                                <NavLink className="link-gap navlink-border " onClick={() => saveShiftData('Yes1', 'eMarkeren')}>
                                                                    <span><Teachershow /></span>
                                                                    <span >Gelezen</span>
                                                                </NavLink>
                                                            </Nav>
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                            }

                                            <UncontrolledButtonDropdown isOpen={show}>
                                                <DropdownToggle color="link" className="p-0">
                                                    <Button className="d-flex align-items-center btn-color-style markeren-btn mb-2 me-2" size="sm" outline color="primary" onClick={() => dropDownIsOpen()}>
                                                        <GrrayCircle fill={data.data !== undefined && data.data.markereColor !== null ? data.data.markereColor : '#878EA6'} />
                                                    </Button>
                                                </DropdownToggle>
                                                <DropdownMenu className={"rm-pointers dropdown-menu-lg verzoeken-dropdown"} >
                                                    <span className="markeren-title">Markeren</span>
                                                    <Nav vertical>

                                                        <NavLink className="link-gap" onClick={() => saveShiftData('null', 'markereColor')}>
                                                            <span><Grijs /></span>
                                                            <span>Grijs</span>
                                                        </NavLink>

                                                        <NavLink className="link-gap navlink-border " onClick={() => saveShiftData('#FF0000', 'markereColor')}>
                                                            <span><Rood /></span>
                                                            <span>Rood</span>
                                                        </NavLink>

                                                        <NavLink className="link-gap navlink-border " onClick={() => saveShiftData('#008000', 'markereColor')}>
                                                            <span><Groen /></span>
                                                            <span >Groen</span>
                                                        </NavLink>

                                                        <NavLink className="link-gap navlink-border " onClick={() => saveShiftData('#800080', 'markereColor')}>
                                                            <span><Paars /></span>
                                                            <span>Paars</span>
                                                        </NavLink>

                                                        <NavLink className="link-gap navlink-border " onClick={() => saveShiftData('#FFA500', 'markereColor')}>
                                                            <span><Oranje /></span>
                                                            <span>Oranje</span>
                                                        </NavLink>

                                                    </Nav>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>


                                            <ButtonGroup style={{height: 'min-content'}} className="me-2 ">
                                                <Button outline className="btn-color-style btn-datepicker rounded d-flex align-items-center py-2" onClick={handleDatepicker} style={{padding: "12px"}}>
                                                    <CalenderIcon />
                                                </Button>
                                               
                                                <DatePicker
                                                    open={isDatePickerOpen}
                                                    locale="nl"
                                                    clearButtonTitle="Clear"
                                                    onChange={(value) => {
                                                        saveShiftData(moment(value).format('yyyy-MM-DD'), 'dParkDate')
                                                        handleDatepicker()
                                                    }
                                                    }
                                                    popperPlacement="bottom-end"
                                                    className="hide-datepicker display-none"
                                                />
                                            </ButtonGroup>
                                             
                                            <Col md="1" className="me-2 footer-option">
                                                <div className=" selectBy p-0">
                                                    <select className="form-select select-arrow-style" style={{ height: '37px'}} onChange={(e) => saveShiftData(e.target.value, 'eStatus')}>
                                                        <option value="">Selecteren</option>
                                                        <option value='Openstaand' selected={data.data !== undefined && ( (data.data.dParkdate === null || data.data.dParkdate === '30-11--1') || new Date(data.data.dParkdate) <= new Date()) && data.data.eStatus === 'Openstaand' ? true : false}>Openstaand</option>
                                                        <option value='Behandeld' selected={data.data !== undefined && data.data.eStatus === 'Behandeld' ? true : false}>Behandeld</option>
                                                        <option value='Afgesloten' selected={data.data !== undefined && data.data.eStatus === 'Afgesloten' ? true : false}>Afgesloten</option>
                                                        {data.data !== undefined && (data.data.eStatus === 'Openstaand' || data.data.eStatus === '') && data.data.dParkdate !== '30-11--1' && new Date(data.data.dParkdate) >= new Date() &&

                                                            <option value='Geparkeerd' selected={true}>Geparkeerd: {moment(new Date(data.data.dParkdate)).format('DD-MM-YY')}</option>

                                                        }
                                                    </select>
                                                </div>
                                            </Col>
                                            <Button className="d-flex align-items-center btn-color-style me-2 mb-2 px-2" size="sm" outline color="primary" onClick={()=>updateModalContentType(true, "dagdelen")}>
                                               <LogIcon/>
                                            </Button>
                                        </div>
                                        <div>
                                            <Button size="sm" outline className="me-2 btn-color-style footer-btn-styling" color="primary" onClick={() => setIsInformationModalOpen(true)}>
                                                <span className="ms-1">Infomeren</span>
                                                <span>
                                                    <span className="blue-circle">{statusCount}</span>
                                                </span>
                                            </Button>
                                            <Button size="sm" outline className="me-2 btn-color-style footer-btn-styling" color="primary" onClick={()=>navigate("/verzoeken/overzicht")}>
                                                <Arrow />
                                            </Button>
                                            <SweetAlert title="Wilt u de geselecteerde dagdelen verwijderen?" show={showModal}
                                                type="warning"
                                                onCancel={() => setShowModal(false)}
                                                onConfirm={() => deleteDates(deleteId)}
                                                cancelBtnText="Nee"
                                                confirmBtnText="Ja"
                                                showCancel={true}
                                            />

                                            <Modal id={"tooltip"} isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} className="modal-dialog__over feedback-modal-style opmerking-modal-styling ">
                                                <ModalHeader>
                                                    <div className="modal-header-styling">
                                                        Opmerking
                                                    </div>
                                                    <div className="cross-icon-color">
                                                        <i className="nav-link-icon lnr-cross m-1 cursor-pointer" onClick={() => setIsOpen(false)}> </i>
                                                    </div>
                                                </ModalHeader>

                                                <ModalBody className="d-flex bg-white rounded">
                                                    <textarea onChange={onChangeMessage}
                                                        className="form-style opmerking-input-styling"
                                                        rows="6"
                                                    ></textarea>
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button className="d-flex align-items-center btn-color-style  opslaan-btn" size="sm" outline color="primary" onClick={sendMessage}>
                                                        <Save />
                                                        Opslaan
                                                    </Button>
                                                    <Button className="d-flex align-items-center btn-color-style back-btn" size="sm" outline color="primary" onClick={() => setIsOpen(false)}>
                                                        <Small_Arrow />
                                                        Terug
                                                    </Button>
                                                </ModalFooter>
                                            </Modal>

                                            {/* Log Modal */}
                                            <Modal id={"tooltip"} isOpen={logModalContentType.state} toggle={() => setLogModalContentType(prev=>({...prev, state: !prev.state}))} className="modal-dialog__over feedback-modal-style opmerking-modal-styling log-modal">
                                                <Logs id={substitutionId} logType={logModalContentType.type} updateHandler={updateModalContentType}/>
                                            </Modal>

                                            <Modal id={"tooltip"} isOpen={isLongContractOpen} toggle={() => setIsLongContractOpen(!isOpen)} className="modal-dialog__over feedback-modal-style opmerking-modal-styling">
                                                <ModalHeader>
                                                    <div className="modal-header-styling">
                                                        Lang verzoek
                                                    </div>
                                                    <div className="cross-icon-color">
                                                        <i className="nav-link-icon lnr-cross m-1 cursor-pointer" onClick={() => {
                                                            setType('')
                                                            setIsLongContractOpen(false)
                                                            setFormDataState({
                                                                ...formDataState,
                                                                ma: '',
                                                                di: '',
                                                                wo: '',
                                                                doo: '',
                                                                vr: '',
                                                                date1: '',
                                                                date2: '',
                                                                iAdminId: '',
                                                            })
                                                            setDefaultValue({
                                                                ...formDataState,
                                                                ma: '0,',
                                                                di: '0,',
                                                                wo: '0,',
                                                                doo: '0,',
                                                                vr: '0,',
                                                                date1: '',
                                                                date2: '',
                                                                iAdminId: '',
                                                            });
                                                        }}> </i>
                                                    </div>
                                                </ModalHeader>

                                                <ModalBody className="bg-white rounded">

                                                    <Formik
                                                        enableReinitialize
                                                        initialValues={formDataState}
                                                        validationSchema={validationSchema}
                                                        onSubmit={
                                                            (values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
                                                    >
                                                        {({
                                                            values,
                                                            errors,
                                                            touched,
                                                            handleChange,
                                                            handleBlur,
                                                            handleSubmit,
                                                            isSubmitting,
                                                        }) => (
                                                            <Form onSubmit={handleSubmit}>
                                                                <>
                                                                    <Row>
                                                                        <Col sm={6}>
                                                                            <FormGroup>

                                                                                <Label>
                                                                                    Begindatum
                                                                                </Label>
                                                                                <DatePicker
                                                                                    locale="nl"
                                                                                    name="date1"
                                                                                    dateFormat="dd-MM-yy"
                                                                                    placeholderText={'dd-mm-yy'}
                                                                                    className="long-contract-modal-style"
                                                                                    selected={(formDataState.date1 !== '') ? new Date(formDataState.date1) : ''}
                                                                                    onChange={(val) => {
                                                                                        setFormDataState({ ...formDataState, date1: moment(val).format('yyyy-MM-DD') })
                                                                                        setDefaultValue({ ...defaultValue, date1: moment(val).format('yyyy-MM-DD') })
                                                                                    }}
                                                                                />
                                                                                <ErrorMessage name="date1" component="small"
                                                                                    className="block mt-2 invalid-feedback" />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col sm={6}>
                                                                            <FormGroup>
                                                                                <Label>
                                                                                    Einddatum
                                                                                </Label>
                                                                                <DatePicker
                                                                                    locale="nl"
                                                                                    name="date2"
                                                                                    dateFormat="dd-MM-yy"
                                                                                    placeholderText={'dd-mm-yy'}
                                                                                    className="long-contract-modal-style"
                                                                                    selected={(formDataState.date2 !== '') ? new Date(formDataState.date2) : ''}
                                                                                    onChange={(val) => {
                                                                                        setFormDataState({ ...formDataState, date2: moment(val).format('yyyy-MM-DD') })
                                                                                        setDefaultValue({ ...defaultValue, date2: moment(val).format('yyyy-MM-DD') })
                                                                                    }}
                                                                                />
                                                                                <ErrorMessage name="date2" component="small"
                                                                                    className="block mt-2 invalid-feedback" />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row>
                                                                        <Col sm={6}>
                                                                            <FormGroup>
                                                                                <Label>
                                                                                    Vervanger
                                                                                </Label>
                                                                                <DropdownList dataKey='iAdminId' textField='name'
                                                                                    placeholder="Selecteer een vervanger..." 
                                                                                    name="iAdminId"
                                                                                    onChange={(val) => {
                                                                                        setFormDataState({ ...formDataState, iAdminId: val.iAdminId })
                                                                                        setDefaultValue({ ...defaultValue, iAdminId: val.iAdminId })
                                                                                    }}
                                                                                    defaultValue={defaultValue.iAdminId}
                                                                                    // value={defaultValue}
                                                                                    data={adminList}
                                                                                />
                                                                                <ErrorMessage name="iAdminId" component="small"
                                                                                    className="block mt-2 invalid-feedback" />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col sm={6}>
                                                                            <FormGroup>
                                                                                <Label>
                                                                                    Wtf MA
                                                                                </Label>
                                                                                <Field
                                                                                    className={"form-control"}
                                                                                    name="ma"
                                                                                    value={defaultValue.ma}
                                                                                    onChange={(e) => {
                                                                                        setFormDataState({ ...formDataState, ma: e.target.value })
                                                                                        setDefaultValue({ ...defaultValue, ma: e.target.value })
                                                                                    }}
                                                                                />
                                                                                <ErrorMessage name="ma" component="small"
                                                                                    className="block mt-2 invalid-feedback" />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row>
                                                                        <Col sm={6}>
                                                                            <FormGroup>
                                                                                <Label>
                                                                                    Wtf DI
                                                                                </Label>
                                                                                <Field
                                                                                    value={defaultValue.di}
                                                                                    onChange={(e) => {
                                                                                        setFormDataState({ ...formDataState, di: e.target.value })
                                                                                        setDefaultValue({ ...defaultValue, di: e.target.value })
                                                                                    }}
                                                                                    className={"form-control"}
                                                                                    name="di"
                                                                                />
                                                                                <ErrorMessage name="di" component="small"
                                                                                    className="block mt-2 invalid-feedback" />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col sm={6}>
                                                                            <FormGroup>
                                                                                <Label>
                                                                                    Wtf WO
                                                                                </Label>
                                                                                <Field
                                                                                    value={defaultValue.wo}
                                                                                    onChange={(e) => {
                                                                                        setFormDataState({ ...formDataState, wo: e.target.value })
                                                                                        setDefaultValue({ ...defaultValue, wo: e.target.value })
                                                                                    }}
                                                                                    className={"form-control"}
                                                                                    name="wo"
                                                                                />
                                                                                <ErrorMessage name="wo" component="small"
                                                                                    className="block mt-2 invalid-feedback" />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row>
                                                                        <Col sm={6}>
                                                                            <FormGroup>
                                                                                <Label>
                                                                                    Wtf DO
                                                                                </Label>
                                                                                <Field
                                                                                    value={defaultValue.doo}
                                                                                    onChange={(e) => {
                                                                                        setFormDataState({ ...formDataState, doo: e.target.value })
                                                                                        setDefaultValue({ ...defaultValue, doo: e.target.value })
                                                                                    }}
                                                                                    className={"form-control"}
                                                                                    name="doo"
                                                                                />
                                                                                <ErrorMessage name="doo" component="small"
                                                                                    className="block mt-2 invalid-feedback" />
                                                                            </FormGroup>
                                                                        </Col>

                                                                        <Col sm={6}>
                                                                            <FormGroup>
                                                                                <Label>
                                                                                    Wtf VR
                                                                                </Label>
                                                                                <Field
                                                                                    value={defaultValue.vr}
                                                                                    onChange={(e) => {
                                                                                        setFormDataState({ ...formDataState, vr: e.target.value })
                                                                                        setDefaultValue({ ...defaultValue, vr: e.target.value })
                                                                                    }}
                                                                                    className={"form-control"}
                                                                                    name="vr"
                                                                                />
                                                                                <ErrorMessage name="vr" component="small"
                                                                                    className="block mt-2 invalid-feedback" />
                                                                            </FormGroup>
                                                                        </Col>

                                                                        {/* <Col sm={6}>
                                                                            <InputWrapper label="Wtf TOT">
                                                                                <Input
                                                                                    type="text"
                                                                                    name="label"
                                                                                    className="form-control"
                                                                                    value={'hggfh'}
                                                                                    onChange={(e) => {
                                                                                        setFormDataState({ ...formDataState, vr: e.target.value })
                                                                                    }}
                                                                                    maxLength="100"
                                                                                />
                                                                            </InputWrapper>
                                                                        </Col> */}
                                                                    </Row>


                                                                    <Button className="d-flex align-items-center btn-color-style  opslaan-btn" size="sm" outline color="primary" type="submit">
                                                                        <Save />
                                                                        Opslaan
                                                                    </Button>

                                                                </>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </ModalBody>
                                            </Modal>
                                            {
                                                data && data.data && data.data.recipientsList &&
                                                <InformationModel isOpen={isInformationModalOpen} setIsInformationModalOpen={setIsInformationModalOpen} data={data.data.recipientsList} substitutionId={substitutionId} coordinatorName = {data.data.coordinatorName} setChangedStatusIds={setChangedStatusIds} changedStatusIds={changedStatusIds} statusCount={statusCount} setStatusCount={setStatusCount}/>
                                            }
                                            <UpdateRequestModel data={data?.data} isOpen={editRequestIsOpen} setEditRequestIsOpen={setEditRequestIsOpen} getRequests={getRequests} />
                                            <UpdateRequestInBulkModel isOpen={bulkRequestIsOpen} data={bulkShiftData} bulkFormData={bulkFormData} setBulkFormData={setBulkFormData} setBulkRequestIsOpen={setBulkRequestIsOpen} checkedList={checkedList} setCheckedList={setCheckedList} getRequests={getRequests} />

                                        </div>
                                    </CardFooter>
                                </div>

                            </div>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>
        </>
    )
}


export default Default;
