import axiosInstance from 'Api';

const saveFeatureApi = (data) => {
    return axiosInstance.post('instellingen/functie/save', data)
};

const fetchFunctieData = (id) => {
    return axiosInstance.get(`instellingen/functie?communityId=${id}`);
};

const deleteFunctie = (iSubGroupId, iCommunityId)=>{
    return axiosInstance.get(`instellingen/functie/delete/${iSubGroupId}?iCommunityId=${iCommunityId}`)
}

const toggleFunctieValue = (data)=>{
    return axiosInstance.post("instellingen/functie/toggle", data)
}

const InstellingenFeatureService = {
    saveFeatureApi,
    fetchFunctieData,
    deleteFunctie,
    toggleFunctieValue
};
export default InstellingenFeatureService;