import React from "react";
import { Form, Formik } from "formik";
import { Card, CardBody, FormGroup, Input, Label } from 'reactstrap'
import 'tippy.js/themes/light.css';
import DropdownList from "react-widgets/DropdownList";

const inputType1DropdownData = [
    { label: 'Tekst (Standaard)', value: 'tekst' },
    { label: 'Nummer', value: "nummer" },
    { label: 'Datum', value: "datum" }
]
const inputType2DropdownData = [
    { label: 'Tekst (Standaard)', value: 'tekst' },
    { label: 'Nummer', value: "nummer" },
    { label: 'Datum', value: "datum" }
]
const inputType3DropdownData = [
    { label: 'Tekst (Standaard)', value: 'tekst' },
    { label: 'Nummer', value: "nummer" },
    { label: 'Datum', value: "datum" }
]

const VrijeVeldenForm = ({ data, setFormData, handleSetFormData }) => {

    const defaultValue = {
        inputField1: data?.inputField1 ?? '',
        inputType1: data?.inputType1 || '',
        inputField2: data?.inputField2 || '',
        inputType2: data?.inputType2 || '',
        inputField3: data?.inputField3 || '',
        inputType3: data?.inputType3 || '',
    }

    return (
        <>
            <Card className="main-card">
                <CardBody className="d-flex flex-column mb-4">

                    <Formik
                        enableReinitialize
                        initialValues={defaultValue}
                        validateOnChange={true}
                    >
                        {({ values, errors, setFieldValue, handleChange, setFieldError, onBlur }) => (
                            <Form>

                                <FormGroup className={`form-element ${(errors.inputField1) ? 'error' : ''}`}>
                                    <Label for="inputField1">Vrije veld 1</Label>
                                    <Input
                                        name="inputField1"
                                        type="text"
                                        value={values.inputField1}
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleSetFormData("inputField1", e.target.value, setFormData);
                                        }}
                                    />
                                </FormGroup>

                                <FormGroup className={`form-element`}>
                                    <Label for="inputType1"></Label>
                                    <div className="position-relative col-4">
                                        <div className="d-flex gap-1 align-items-center">
                                            <DropdownList
                                                dataKey="value"
                                                textField="label"
                                                placeholder="Selecteer"
                                                onChange={(e) => {
                                                    setFieldValue("inputType1", e.value);
                                                    handleSetFormData("inputType1", e.value, setFormData);
                                                }}
                                                name="inputType1"
                                                value={values.inputType1}
                                                data={inputType1DropdownData}
                                            />

                                        </div>
                                    </div>
                                </FormGroup>

                                <FormGroup className={`form-element ${(errors.inputField2) ? 'error' : ''}`}>
                                    <Label for="inputField2">Vrije veld 2</Label>
                                    <Input
                                        name="inputField2"
                                        type="text"
                                        value={values.inputField2}
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleSetFormData("inputField2", e.target.value, setFormData);
                                        }}
                                    />
                                </FormGroup>

                                <FormGroup className={`form-element`}>
                                    <Label for="inputType2"></Label>
                                    <div className="position-relative col-4">
                                        <div className="d-flex gap-1 align-items-center">
                                            <DropdownList dataKey='value' textField='label'
                                                placeholder="Selecteer"
                                                onChange={(e) => {
                                                    setFieldValue("inputType2", e.value);
                                                    handleSetFormData("inputType2", e.value, setFormData);
                                                }}
                                                name="inputType2"
                                                value={values.inputType2}
                                                data={inputType2DropdownData}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>

                                <FormGroup className={`form-element ${(errors.inputField3) ? 'error' : ''}`}>
                                    <Label for="inputField3">Vrije veld 3</Label>
                                    <Input
                                        name="inputField3"
                                        type="text"
                                        value={values.inputField3}
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleSetFormData("inputField3", e.target.value, setFormData);
                                        }}
                                    />
                                </FormGroup>

                                <FormGroup className={`form-element`}>
                                    <Label for="inputType3"></Label>
                                    <div className="position-relative col-4">
                                        <div className="d-flex gap-1 align-items-center">
                                            <DropdownList dataKey='value' textField='label'
                                                placeholder="Selecteer"
                                                onChange={(e) => {
                                                    setFieldValue("inputType3", e.value);
                                                    handleSetFormData("inputType3", e.value, setFormData);
                                                }}
                                                name="inputType3"
                                                value={values.inputType3}
                                                data={inputType3DropdownData}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>

                            </Form>
                        )}
                    </Formik>
                </CardBody>
            </Card>
        </>
    )
}

export default VrijeVeldenForm;